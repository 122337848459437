import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { Button } from 'react-bootstrap';

import { Loading } from 'components';
import { IStore, apAccountContainerTypes, mgReducerTypes } from 'types';
import { mgGetCloudResponseList, addPostGetSelectedContentObj, mgSetAddedAssetsCount, mgResetCustomVideoThumbnailResponse, mediaGalleryUpdateSettings, mgResetMediaRelease } from 'actions';
import {
  AP_AVAIL_MEDIA_FOR_USER,
  CREATOR_USER_OWNERSHIP,
  RIPPLE_COLOR,
  AP_ADD_TO_POST_TAB_NAMES,
  MG_MEDIA_TYPE,
  IMAGE_SUPPORTED_FORMAT,
  VIDEO_SUPPORTED_FORMAT,
  FILE_SUPPORTED_FORMAT,
  MediaDropPages,
  AddMediaExpirationValues,
  ICommonDateTypes,
  DATEPICKER_DATE_ONLY_FORMAT,
  AP_MEDIA_EXPIRATION,
  TOOLTIP_PLACEMENT,
  MEDIA_RELEASE_INFO_TEXT,
  MG_MEDIA_RELEASE_OPTIONS,
  MG_MEDIA_RELEASE_VALUES
} from 'utils/constants';
import { ImageValidation, Video } from 'widgets/Media';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { getCurrentDate, getEndOfDate, uploadFileToCloud } from 'utils/helpers';
import { CustomDatePicker } from 'widgets/DatePicker';
import { CustomTooltip } from 'widgets/Tooltip';
import { MediaReleaseSignatureUploadModal } from 'containers/Content/MediaGallery/SubPages';

export const AddMediaDragAndDrop = (props: apAccountContainerTypes.IAddPostMediaDragAndDropProps) => {
  const dispatch = useDispatch();

  const { mediaName, from, title, aiPlaybookDocType, videoCloudinaryId } = props;

  const creatorCompName = useSelector((state: IStore) => state.common.creatorCompName);
  const addedAssetsCount = useSelector((state: IStore) => state.mediaGallery.uploadCloudinaryMedia.addedAssetsCount);
  const uploadProgressInfo = useSelector((state: IStore) => state.mediaGallery.uploadProgressInfo);
  const response = useSelector((state: IStore) => state.mediaGallery.uploadCloudinaryMedia.response);
  const contentObj = useSelector((state: IStore) => state.addPostFranchisor.contentObj);
  const isContentSupplier = useSelector((state: IStore) => state.franchisors.franchisorDetails?.content_supplier);
  const mediaReleaseSignatures = useSelector((state: IStore) => state.mediaGallery.mediaReleaseSignatures);
  const mediaGallerySettings = useSelector((state: IStore) => state.mediaGallery.mediaGallerySettings);

  const { have_media_release } = mediaGallerySettings;

  const [mediaReleaseModalOpen, setMediaReleaseModalOpen] = useState(false);
  const [selectedCloudinaryRes, setSelectedCloudinaryRes] = useState<mgReducerTypes.IMGCloudinaryDataObj | null>(null);

  const disableUpload =
    from === MediaDropPages.CUSTOMIZE_THUMBNAIL ? false : (response.length === 1 && response[0]?.mediaType === MG_MEDIA_TYPE.VIDEO) || (response.length === 1 && from === MediaDropPages.CREATOR);
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      uploadFileToCloud({ acceptedFiles, response, addedAssetsCount, from, dispatch, aiPlaybookDocType, videoCloudinaryId });
    },
    [addedAssetsCount, response, from, aiPlaybookDocType, videoCloudinaryId, dispatch]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    accept:
      from === MediaDropPages.CUSTOMIZE_THUMBNAIL || mediaName === AP_ADD_TO_POST_TAB_NAMES.PHOTOS
        ? IMAGE_SUPPORTED_FORMAT
        : mediaName === AP_ADD_TO_POST_TAB_NAMES.VIDEOS
        ? VIDEO_SUPPORTED_FORMAT
        : mediaName === AP_ADD_TO_POST_TAB_NAMES.FILES
        ? FILE_SUPPORTED_FORMAT
        : [],
    ...((mediaName === AP_ADD_TO_POST_TAB_NAMES.VIDEOS || from === MediaDropPages.CREATOR) && { maxFiles: 1 }),
    noClick: false,
    onDrop
  });

  const handleRemoveAssetsUpload = (cloudUrl: null | string) => {
    if (cloudUrl) {
      const filteredCloudList = response.filter((listItem) => listItem.secureUrl !== cloudUrl);
      dispatch(mgGetCloudResponseList(filteredCloudList));
      dispatch(mgSetAddedAssetsCount(filteredCloudList.length));
    }
    dispatch(mgResetCustomVideoThumbnailResponse());
  };

  const handleMGSettings = (key: string, value: string | boolean) => {
    dispatch(mediaGalleryUpdateSettings({ [key]: value }));
  };

  return (
    <div className="at-cnt creator-post-media-wrp amseWrap me-lv-wrap">
      {creatorCompName === CREATOR_USER_OWNERSHIP.FRANCHISOR && from === MediaDropPages.MEDIA_GALLERY && !isContentSupplier && (
        <div className="me-lv-item">
          <h3 className="me-lv-left">Availability</h3>
          <div className="newimage-avaliable-section me-lv-right">
            {AP_AVAIL_MEDIA_FOR_USER.map((userItem, idx: number) => {
              return (
                <label className={`${contentObj.mediaAvailForUser === userItem.name ? ` active` : ``}`} key={`creator-fran-avail-${idx}`}>
                  <input
                    type="radio"
                    className="option-input radio r-ml2"
                    value={userItem.name}
                    checked={contentObj.mediaAvailForUser === userItem.name}
                    onChange={(event) => dispatch(addPostGetSelectedContentObj({ ...contentObj, mediaAvailForUser: event.target.value }))}
                  />
                  <span className={`${contentObj.mediaAvailForUser === userItem.name ? `labelText` : ``}`}>{userItem.label}</span>
                </label>
              );
            })}
          </div>
        </div>
      )}

      {!([MediaDropPages.CUSTOMIZE_THUMBNAIL, MediaDropPages.CREATOR, MediaDropPages.SETTINGS] as string[]).includes(from) && (
        <div className="me-lv-item">
          <h3 className="me-lv-left">Expiration</h3>
          <div className="mExpMainRight amSetExpiration me-lv-right">
            <div className="mExpTop">
              <div className="form-group">
                {AP_MEDIA_EXPIRATION.map((item, idx: number) => {
                  return (
                    <label className={`${AP_MEDIA_EXPIRATION.length - 1 === idx ? `r-ml0` : ``}${contentObj?.expiration?.type === item.name ? ` active` : ``}`} key={`mg-media-avail-${idx}`}>
                      <input
                        type="radio"
                        className="option-input radio"
                        value={item.name}
                        checked={contentObj?.expiration?.type === item.name}
                        onChange={(event) => dispatch(addPostGetSelectedContentObj({ ...contentObj, expiration: { type: event.target.value, date: null } }))}
                      />
                      <span className="labelText">{item.label}</span>
                    </label>
                  );
                })}
              </div>
            </div>
            <div className="mExpBase">
              {contentObj?.expiration?.type === AddMediaExpirationValues.EXPIRE ? (
                <div className="sc-cdtWrap">
                  <div className="wdt-item da-date">
                    <CustomDatePicker
                      selectedDate={contentObj?.expiration.date}
                      dateRangeType={ICommonDateTypes.TO_DATE}
                      onChange={(date) => dispatch(addPostGetSelectedContentObj({ ...contentObj, expiration: { ...contentObj?.expiration, date: getEndOfDate(date) } }))}
                      customClassname={`fltr-date-item fltr-from-date`}
                      minDate={getCurrentDate()}
                      autoComplete="off"
                      placeholderText={'Select Date here'}
                      dateFormat={DATEPICKER_DATE_ONLY_FORMAT}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}

      {!([MediaDropPages.CUSTOMIZE_THUMBNAIL, MediaDropPages.CREATOR, MediaDropPages.SETTINGS] as string[]).includes(from) && (
        <div className="me-lv-item amrWrap">
          <h3 className="me-lv-left">
            Media Release{' '}
            <CustomTooltip
              customPlacement={TOOLTIP_PLACEMENT.TOP}
              customClassname={'custom-tooltip-long-text'}
              customTooltipText={MEDIA_RELEASE_INFO_TEXT}
              customInput={() => (
                <div className="info__tool--tip ms-2">
                  <ImageValidation isImgValid defaultImg="information-grey" customName={'activate'} />
                </div>
              )}
            />
          </h3>

          <div className="mExpMainRight me-lv-right">
            <div className="mExpTop">
              {MG_MEDIA_RELEASE_OPTIONS.map((option, index) => {
                const isChecked = have_media_release === option.value;
                return (
                  <label className={`${isChecked ? ` active` : ``}`} key={`media-release-option-${index}`}>
                    <input
                      type="radio"
                      className="option-input radio"
                      value={option.value}
                      checked={isChecked}
                      onChange={(event) => {
                        handleMGSettings('have_media_release', event.target.value);
                        dispatch(mgResetMediaRelease());
                      }}
                    />
                    <span className="labelText">{option.label}</span>
                  </label>
                );
              })}
            </div>
            <div className="mExpBase">
              {!mediaReleaseSignatures?.length && mediaGallerySettings?.have_media_release === MG_MEDIA_RELEASE_VALUES.YES ? null : (
                <Button
                  className={`ac-btn ac-primary`}
                  variant="primary"
                  onClick={() => {
                    setMediaReleaseModalOpen(true);
                  }}
                >
                  {mediaReleaseSignatures?.length && mediaGallerySettings?.have_media_release === MG_MEDIA_RELEASE_VALUES.YES ? 'Edit' : 'Add'} Media Release
                </Button>
              )}
            </div>
          </div>
        </div>
      )}

      <input {...getInputProps()} />
      <div
        className={`drag__drop--box${disableUpload ? ` pointer-events-none` : ``}`}
        {...getRootProps({
          onClick: (event) => event
        })}
      >
        <div className="drag__drop--txt">
          <div className="single-icon">
            <ImageValidation isImgValid defaultImg={'upload-blue'} customName={'Media drag and drop'} />
          </div>
          <span>{title}</span>
        </div>
        <CustomRippleButton rippleClass={`ripple-unset ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
          <button className="drag__drop--btn" onClick={open}>
            Browse
          </button>
        </CustomRippleButton>
      </div>
      {from === MediaDropPages.MEDIA_GALLERY && (
        <div className="mg-upload post-assets-upload add__image--upload">
          <div className="masonry-grid">
            {response.length
              ? response.map((cloudResponse, index) => {
                  return !cloudResponse.cloudinaryId ? (
                    <div className="m-item mi-skeleton" key={`ap-cloudinary-without-data-${index}`}>
                      <div className="m-ast">
                        <div className="m-ast-itm m-ast-img">
                          <div className="mast-green">
                            <div className="mast-loader">
                              <Loading />
                            </div>
                            <div className="mast-skltn-cnt mg-upload-progress-bar">
                              <span className="msc-fileName">{cloudResponse.fileName}</span>
                              <span className="msc-count-percntge-load">
                                {uploadProgressInfo.percentage}% - {uploadProgressInfo.remainingTime} Sec. remaining
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="m-item" key={`ap-cloudinary-with-data-${index}`}>
                      <div className="m-ast">
                        {cloudResponse.mediaType === MG_MEDIA_TYPE.PHOTO && cloudResponse.secureUrl ? (
                          <div className="m-ast-itm m-ast-img">
                            <ImageValidation imgUrl={cloudResponse.secureUrl} customName={'Media Asset'} />
                          </div>
                        ) : cloudResponse.thumbnail_url ? (
                          <div className="m-ast-itm m-ast-img">
                            <ImageValidation imgUrl={cloudResponse.thumbnail_url} customName={'Media Asset'} />
                          </div>
                        ) : cloudResponse.mediaType === MG_MEDIA_TYPE.VIDEO && cloudResponse.secureUrl ? (
                          <Video videoUrl={cloudResponse.secureUrl} customClassname="m-ast-itm m-ast-img" />
                        ) : null}
                        <div className="lb-btn lb-close" onClick={() => handleRemoveAssetsUpload(cloudResponse.secureUrl)}>
                          <ImageValidation isImgValid defaultImg={'remove-white'} customName={'Remove'} />
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      )}

      <MediaReleaseSignatureUploadModal
        isShowModal={mediaReleaseModalOpen}
        onModalClose={() => {
          setMediaReleaseModalOpen(false);
          setSelectedCloudinaryRes(null);
        }}
        cloudinaryRes={selectedCloudinaryRes}
      />
    </div>
  );
};
