import { Tag } from 'react-tag-autocomplete';
import { MentionData } from '@draft-js-plugins/mention';
import { EditorState } from 'draft-js';

import {
  apFranApiTypes,
  postsApiTypes,
  apFranReducerTypes,
  mgApiTypes,
  apAccountApiTypes,
  apFranActionApiTypes,
  calendarApiTypes,
  apAccountContainerTypes,
  postsReducerTypes,
  reviewsContainerTypes,
  apFranContainerTypes,
  franchisorApiTypes,
  apFranActionReducerTypes
} from 'types';
import {
  getStartOfDate,
  getEndOfDate,
  getCurrentDate,
  getValidFBTagPreview,
  getFacebookPageTagList,
  getUTCDateAndTimeForSelectedData,
  getPostedTextWithFBTagging,
  getUTCDateForPosts,
  convertMomentObjToDate,
  getDefaultDateObjForNextDay,
  getDefaultFromAndToDateForOneYear,
  convertStringToEditorState,
  createEmptyEditorState,
  comparyTwoArrays,
  getContentTextWithMaximumLimit,
  getUniqueValues,
  isSocialMediaActive,
  postPlatformText,
  convertStringToDate,
  getFormattedDate,
  convertFBTextToEditorState
  // getParsedDate
} from 'utils/helpers';
import {
  ICommonDateTypes,
  // AP_CONTENT_INIT_OBJ,
  MG_MEDIA_TYPE,
  COMMA_SPLITTER,
  FranCreatorScheduleType,
  AP_CONTENT_ACTION_TYPE,
  USER_OWNERSHIP,
  LOCATION,
  FRANCHISORS_SELECTIONS,
  LOCATION_LISTS,
  FRAN_SCHEDULE_POST_TYPE,
  FranCreatorScheduleDateType,
  COMMON_SOCIAL_PROVIDER_TYPE,
  CreatorInstaPhotoType,
  AP_ACTION_TYPES,
  AP_INIT_EDITOR_STATE_FOR_CUSTOM_TEXT,
  ADD_POST_CONTENT_MAX_TEXT_LENGTH,
  GOOGLE_CALL_TO_ACTION_SELECT_OPTIONS,
  FB_CALL_TO_ACTION_SELECT_OPTIONS,
  socialMediaCallToActionCallOptions,
  CALENDAR_YR_MN_DATE_FORMAT,
  HASHTAG_BLOCK_DEFAULT_SPACING
} from 'utils/constants';

/** Add Post - Get the content request payload */
export const getContentPostReqPayload = (
  id: null | number,
  userOwnership: null | string,
  useMediaContentData?: null | apFranContainerTypes.IUseMediaContentData
): apAccountApiTypes.IAPGetContentPostDataReqPayload => {
  return {
    account_list_id: null,
    auto_schedule: false,
    boost_offer_id: null,
    campaign_ids: [],
    content_origins: false,
    created_at: null,
    date_range_locked: false,
    delete_from_platforms_after_expiry: false,
    disabled: false,
    end_date: null,
    facebook_text: useMediaContentData?.facebook_text || '',
    franchisor_id: id && userOwnership === USER_OWNERSHIP.FRANCHISOR ? id : null,
    franchisor_list_id: null,
    google_text: useMediaContentData?.google_text || '',
    has_parent: false,
    instagram_text: useMediaContentData?.instagram_text || '',
    link_id: null,
    linkedin_text: useMediaContentData?.linkedin_text || '',
    locked: false,
    mobile_app_only_enabled: true,
    moderation_status: 'moderation_not_required',
    personalized_facebook_text: '',
    personalized_google_text: '',
    personalized_instagram_text: '',
    personalized_linkedin_text: '',
    personalized_twitter_text: '',
    personalized_tiktok_text: '',
    personalized_pinterest_text: '',
    photo_ids: [],
    page_tag_details: {},
    pad_instagram_photos: true,
    repeat: true,
    scheduled_anywhere: false,
    sent_to_franchisor: true,
    start_date: null,
    status_id: `${1}`,
    syndicate_to_account_databases: false,
    tags_list: null,
    tone_cautionary: 0,
    tone_educational: 0,
    tone_empathetic: 0,
    tone_engaging: 0,
    tone_humorous: 0,
    tone_inspirational: 0,
    tone_professional: 0,
    tone_quirky: 0,
    trashed: false,
    twitter_text: useMediaContentData?.twitter_text || '',
    tiktok_text: useMediaContentData?.tiktok_text || '',
    pinterest_text: useMediaContentData?.pinterest_text || '',
    use_facebook: useMediaContentData?.use_facebook || false,
    use_google: useMediaContentData?.use_google || false,
    use_instagram: useMediaContentData?.use_instagram || false,
    use_linkedin: useMediaContentData?.use_linkedin || false,
    use_tiktok: useMediaContentData?.use_tiktok || false,
    use_pinterest: useMediaContentData?.use_pinterest || false,
    use_shortened_link: false,
    use_twitter: useMediaContentData?.use_twitter || false,
    user_id: null,
    verticals_list: null,
    video_id: null,
    instagram_reel: false,
    facebook_reel: false,
    facebook_story: false,
    instagram_story: false,
    pinterest_board_name: null
  };
};

/** Get initial content object based on added content data */
export const getInitialSelectedContentData = (
  activePlatforms: string[],
  initContentData: postsReducerTypes.IPostDetailsData,
  updatedContentData: apFranReducerTypes.IAddPostFranContentObj,
  actionType: string,
  linkData: null | calendarApiTypes.ICouponLinks,
  isContentSupplierFranchisor?: boolean,
  savedHashtags?: string[]
): apFranReducerTypes.IAddPostFranContentObj => {
  const isFacebook = Boolean((isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK) && initContentData?.use_facebook) || Boolean(savedHashtags?.length));
  const isTwitter = Boolean(isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.TWITTER) && initContentData?.use_twitter);
  const isLinkedin = Boolean(isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN) && initContentData?.use_linkedin);
  const isInstagram = Boolean(isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM) && initContentData?.use_instagram);
  const isGoogle = Boolean(isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE) && initContentData?.use_google);
  const isTiktok = Boolean(isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK) && initContentData?.use_tiktok);
  const isPinterest = Boolean(isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST) && initContentData?.use_pinterest);
  const startDate = getStartOfDate(getCurrentDate());
  const endDate = updatedContentData?.dateType === FranCreatorScheduleDateType.CUSTOM ? getEndOfDate(getCurrentDate()) : null;
  const validStartDate = initContentData?.start_date ? getUTCDateForPosts(convertStringToDate(initContentData?.start_date), 0, 0, 0).selectedDate : getUTCDateForPosts(startDate, 0, 0, 0).selectedDate;
  const utcStartDate = initContentData?.start_date ? getUTCDateForPosts(convertStringToDate(initContentData?.start_date), 0, 0, 0).utcTime : getUTCDateForPosts(startDate, 0, 0, 0).utcTime;
  const validEndDate = initContentData?.end_date
    ? getUTCDateForPosts(convertStringToDate(initContentData?.end_date), 23, 59, 59).selectedDate
    : endDate
    ? getUTCDateForPosts(endDate, 23, 59, 59).selectedDate
    : null;
  const utcEndDate = initContentData?.end_date
    ? getUTCDateForPosts(convertStringToDate(initContentData?.end_date), 23, 59, 59).utcTime
    : endDate
    ? getUTCDateForPosts(endDate, 23, 59, 59).utcTime
    : null;
  const linkIndex =
    linkData?.link_preview_image_url_choices.length && linkData?.link_preview_image_url ? linkData?.link_preview_image_url_choices.findIndex((datum) => datum === linkData?.link_preview_image_url) : 0;
  const stdDateAndTime = initContentData?.start_date
    ? getUTCDateForPosts(convertStringToDate(initContentData?.start_date), 0, 0, 0).selectedDate
    : convertMomentObjToDate(getDefaultDateObjForNextDay());

  return {
    ...updatedContentData,
    currentTab: isFacebook
      ? COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
      : isInstagram
      ? COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
      : isTwitter
      ? COMMON_SOCIAL_PROVIDER_TYPE.TWITTER
      : isLinkedin
      ? COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN
      : isGoogle
      ? COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE
      : isTiktok
      ? COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK
      : isPinterest
      ? COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST
      : activePlatforms[0],
    isPostLock: initContentData?.locked || false,
    scheduledBy: initContentData?.auto_schedule
      ? FranCreatorScheduleType.AUTO_SCHEDULE
      : initContentData?.syndicate_to_account_databases
      ? FranCreatorScheduleType.SYNDICATE_TO_ACCOUNT_DB
      : FranCreatorScheduleType.MANUAL_SCHEDULE,
    isPostAvailToEmployee: initContentData?.mobile_app_only_enabled || false,
    isDeleteAutomatically:
      actionType === AP_CONTENT_ACTION_TYPE.APPROVE_AND_SYNDICATE && updatedContentData?.isDeleteAutomatically ? true : initContentData?.delete_from_platforms_after_expiry || false,
    dateType:
      (initContentData?.start_date && initContentData?.end_date) || (actionType === AP_CONTENT_ACTION_TYPE.APPROVE_AND_SYNDICATE && updatedContentData?.customDate?.toDate?.selected)
        ? FranCreatorScheduleDateType.CUSTOM
        : FranCreatorScheduleDateType.ANYTIME,
    isRepeatAnnually: initContentData?.repeat || false,
    customDate: {
      [ICommonDateTypes.FROM_DATE]: {
        selected: validStartDate,
        utcFormat: utcStartDate
      },
      [ICommonDateTypes.TO_DATE]: {
        selected: validEndDate,
        utcFormat: utcEndDate
      }
    },
    defaultDate:
      initContentData?.start_date && initContentData?.end_date
        ? {
            fromDate: {
              selected: stdDateAndTime,
              utcFormat: getUTCDateAndTimeForSelectedData(stdDateAndTime)
            },
            toDate: {
              selected: validEndDate,
              utcFormat: getUTCDateAndTimeForSelectedData(validEndDate)
            }
          }
        : getDefaultFromAndToDateForOneYear(),
    dateAndTime: {
      selected: stdDateAndTime,
      utcFormat: stdDateAndTime ? getUTCDateAndTimeForSelectedData(stdDateAndTime) : null
    },
    personalizedText: {
      facebook: isFacebook
        ? initContentData?.facebook_story
          ? ''
          : getPostedTextWithFBTagging(initContentData?.personalized_facebook_text || '', initContentData?.page_tag_details?.facebook) || ''
        : '',
      twitter: isTwitter ? initContentData?.personalized_twitter_text || '' : '',
      instagram: isInstagram ? (initContentData?.instagram_story ? '' : initContentData?.personalized_instagram_text || '') : '',
      linkedin: isLinkedin ? initContentData?.personalized_linkedin_text || '' : '',
      google: isGoogle ? initContentData?.personalized_google_text || '' : '',
      tiktok: isTiktok ? initContentData?.personalized_tiktok_text || '' : '',
      pinterest: isPinterest ? initContentData?.personalized_pinterest_text || '' : ''
    },
    customText: {
      facebook: isFacebook
        ? initContentData?.facebook_story
          ? ''
          : getPostedTextWithFBTagging(
              initContentData?.facebook_text || (savedHashtags?.length ? `${HASHTAG_BLOCK_DEFAULT_SPACING}${savedHashtags?.join(' ')}` : ''),
              initContentData?.page_tag_details?.facebook
            ) || ''
        : '',
      twitter: isTwitter ? initContentData?.twitter_text || '' : '',
      instagram: isInstagram ? (initContentData?.instagram_story ? '' : initContentData?.instagram_text || '') : '',
      linkedin: isLinkedin ? initContentData?.linkedin_text || '' : '',
      google: isGoogle ? initContentData?.google_text || '' : '',
      tiktok: isTiktok ? initContentData?.tiktok_text || '' : '',
      pinterest: isPinterest ? initContentData?.pinterest_text || '' : ''
    },
    postStatus: {
      facebook: isFacebook,
      twitter: isTwitter,
      instagram: isInstagram,
      linkedin: isLinkedin,
      google: isGoogle,
      tiktok: isTiktok,
      pinterest: isPinterest
    },
    isThumbnailShow: actionType === AP_ACTION_TYPES.ADD ? true : linkData?.link_preview_image_url_choices.length && linkData?.link_preview_image_url ? true : false,
    linkIndex: actionType === AP_ACTION_TYPES.EDIT ? linkIndex : 0,
    isNotShowPost: updatedContentData.isNotShowPost || false,
    instagram_reel: initContentData?.instagram_reel || false,
    facebook_reel: initContentData?.facebook_reel || false,
    google_call_to_action_type: initContentData?.google_call_to_action_type || null,
    google_call_to_action_url: initContentData?.google_call_to_action_url || null,
    facebook_call_to_action_type: initContentData?.facebook_call_to_action_type || null,
    facebook_call_to_action_link: initContentData?.facebook_call_to_action_link || null,
    personalized_facebook_call_to_action_link: initContentData?.personalized_facebook_call_to_action_link || '',
    instagram_story: initContentData?.instagram_story || false,
    facebook_story: initContentData?.facebook_story || false,
    ...(isContentSupplierFranchisor && initContentData?.content_supplier?.is_content_supplier && { franSyndicationMinMaxDate: { minDate: validStartDate, maxDate: validEndDate } }),
    pinterest_board_name: initContentData?.pinterest_board_name || null
  };
  // : AP_CONTENT_INIT_OBJ;
};

export const getReqPayloadForUpdateContent = (
  franchisorId: null | number,
  userId: number,
  contentCreatedDate: string,
  updatedContentData: apFranReducerTypes.IAddPostFranContentObj,
  selectedMediaList: mgApiTypes.IMGListResponseObj[],
  selectedTagsList: Tag[],
  fbTagList: MentionData[],
  actionType: string,
  selectedLocData: postsApiTypes.IPostListDetailData,
  linkData: null | calendarApiTypes.ICouponLinks,
  instaType: string,
  campaignData: postsApiTypes.IPostListDetailData[],
  contentOrigins: boolean,
  fbEditorState: EditorState,
  postsDetails?: postsReducerTypes.IPostDetailsData | null,
  // accountIds?: number[],
  isAiPost?: boolean,
  translationConfig?: Record<string, apFranActionReducerTypes.ITranslateConfig> | null
): apAccountApiTypes.IAPGetContentPostDataReqPayload => {
  return {
    account_list_id: selectedLocData?.id ? `${selectedLocData?.id}` : null,
    // ...(accountIds?.length && { account_ids: accountIds }),
    // account_list_id: null,
    auto_schedule: updatedContentData.scheduledBy === FranCreatorScheduleType.AUTO_SCHEDULE ? true : false,
    boost_offer_id: null,
    ...([AP_CONTENT_ACTION_TYPE.SINGLE_CAMPAIGN_POST, AP_CONTENT_ACTION_TYPE.MULTIPLE_CAMPAIGN_POSTS].includes(actionType) &&
      campaignData?.length && { campaign_ids: campaignData.map((it) => +it.id) }),
    content_origins: contentOrigins || false,
    created_at: contentCreatedDate,
    date_range_locked: false,
    delete_from_platforms_after_expiry: updatedContentData.isDeleteAutomatically,
    disabled: false,
    end_date: updatedContentData.dateType === FranCreatorScheduleDateType.CUSTOM ? getFormattedDate(updatedContentData.customDate.toDate.utcFormat, CALENDAR_YR_MN_DATE_FORMAT) : null,
    // facebook_text: updatedContentData?.facebook_story
    //   ? ''
    //   : updatedContentData.postStatus.facebook
    //   ? updatedContentData.customText.facebook
    //     ? getValidFBTagPreview(updatedContentData.customText.facebook, fbTagList) || ''
    //     : linkData?.url || ''
    //   : '',
    facebook_text: updatedContentData?.facebook_story ? '' : updatedContentData.postStatus.facebook ? getValidFBTagPreview(updatedContentData.customText.facebook, fbTagList) || '' : '',
    franchisor_id: franchisorId ? `${franchisorId}` : null,
    franchisor_list_id: null,
    google_text: updatedContentData.postStatus.google ? getContentTextWithMaximumLimit(updatedContentData.customText.google, COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE) : '',
    tiktok_text: updatedContentData.postStatus.tiktok ? updatedContentData.customText.tiktok : '',
    pinterest_text: updatedContentData.postStatus.pinterest ? updatedContentData.customText.pinterest : '',
    has_parent: false,
    instagram_text: updatedContentData?.instagram_story
      ? ''
      : updatedContentData.postStatus.instagram
      ? getContentTextWithMaximumLimit(updatedContentData.customText.instagram, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM)
      : '',
    link_id: linkData?.id ? `${linkData?.id}` : null,
    linkedin_text: updatedContentData.postStatus.linkedin ? getContentTextWithMaximumLimit(updatedContentData.customText.linkedin, COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN) : '',
    locked: updatedContentData.isPostLock,
    mobile_app_only_enabled: updatedContentData.isPostAvailToEmployee,
    moderation_status: 'moderation_not_required',
    personalized_facebook_text: updatedContentData?.facebook_story
      ? ''
      : updatedContentData.postStatus.facebook
      ? getValidFBTagPreview(updatedContentData.personalizedText.facebook || '', fbTagList) || ''
      : '',
    personalized_google_text: updatedContentData.postStatus.google ? updatedContentData.personalizedText.google : '',
    personalized_instagram_text: updatedContentData?.instagram_story ? '' : updatedContentData.postStatus.instagram ? updatedContentData.personalizedText.instagram || '' : '',
    personalized_linkedin_text: updatedContentData.postStatus.linkedin ? updatedContentData.personalizedText.linkedin : '',
    personalized_twitter_text: updatedContentData.postStatus.twitter ? updatedContentData.personalizedText.twitter : '',
    personalized_tiktok_text: updatedContentData.postStatus.tiktok ? updatedContentData.personalizedText.tiktok || '' : '',
    personalized_pinterest_text: updatedContentData.postStatus.pinterest ? updatedContentData.personalizedText.pinterest || '' : '',
    photo_ids: selectedMediaList.length
      ? selectedMediaList[0].media_type === MG_MEDIA_TYPE.PHOTO
        ? selectedMediaList.map((datum) => `${datum.id}`)
        : []
      : postsDetails
      ? postsDetails?.photo_ids
      : [],
    page_tag_details: {
      ...(fbTagList.length && updatedContentData.postStatus.facebook && { facebook: getFacebookPageTagList(fbTagList) })
    },
    pad_instagram_photos: instaType === CreatorInstaPhotoType.PAD ? true : false,
    repeat: updatedContentData.isRepeatAnnually,
    scheduled_anywhere: false,
    sent_to_franchisor: true,
    start_date: updatedContentData.dateType === FranCreatorScheduleDateType.CUSTOM ? getFormattedDate(updatedContentData.customDate.fromDate.utcFormat, CALENDAR_YR_MN_DATE_FORMAT) : null,
    status_id: actionType === AP_CONTENT_ACTION_TYPE.SAVE_DRAFT ? `${1}` : `${3}`,
    syndicate_to_account_databases: actionType === AP_CONTENT_ACTION_TYPE.APPROVE_AND_SCHEDULE ? false : updatedContentData.scheduledBy === FranCreatorScheduleType.SYNDICATE_TO_ACCOUNT_DB,
    tags_list: selectedTagsList.map((datum) => datum.label).join(COMMA_SPLITTER) || postsDetails?.tags_list || '',
    tone_cautionary: 0,
    tone_educational: 0,
    tone_empathetic: 0,
    tone_engaging: 0,
    tone_humorous: 0,
    tone_inspirational: 0,
    tone_professional: 0,
    tone_quirky: 0,
    trashed: false,
    twitter_text: updatedContentData.postStatus.twitter
      ? getContentTextWithMaximumLimit(`${updatedContentData.customText.twitter}${linkData?.url ? ` ${linkData?.url}` : ''}` || '', COMMON_SOCIAL_PROVIDER_TYPE.TWITTER)
      : '',
    use_facebook: updatedContentData.postStatus.facebook || false,
    use_google: updatedContentData.postStatus.google || false,
    use_instagram: updatedContentData.postStatus.instagram || false,
    use_linkedin: updatedContentData.postStatus.linkedin || false,
    use_shortened_link: false,
    use_twitter: updatedContentData.postStatus.twitter || false,
    use_tiktok: updatedContentData.postStatus.tiktok || false,
    use_pinterest: updatedContentData.postStatus.pinterest || false,
    user_id: `${userId}`,
    verticals_list: '',
    video_id: selectedMediaList.length ? (selectedMediaList[0].media_type === MG_MEDIA_TYPE.VIDEO ? `${selectedMediaList[0].id}` : null) : postsDetails ? postsDetails?.video_id : null,
    instagram_reel: updatedContentData.instagram_reel || false,
    facebook_reel: updatedContentData.facebook_reel || false,
    ai_post: isAiPost ? true : false,
    facebook_story: updatedContentData?.facebook_story || false,
    instagram_story: updatedContentData?.instagram_story || false,
    personalized_facebook_call_to_action_link: updatedContentData.personalized_facebook_call_to_action_link || '',
    pinterest_board_name: updatedContentData?.pinterest_board_name || null,
    ...(updatedContentData.postStatus.facebook && {
      facebook_call_to_action_type: FB_CALL_TO_ACTION_SELECT_OPTIONS.find((selectOption) => selectOption.value === updatedContentData.facebook_call_to_action_type)?.value || null,
      facebook_call_to_action_link: updatedContentData.facebook_call_to_action_type
        ? socialMediaCallToActionCallOptions.includes(updatedContentData.facebook_call_to_action_type)
          ? `tel:${updatedContentData.facebook_call_to_action_link}`
          : updatedContentData.facebook_call_to_action_link
        : null
    }),
    ...(updatedContentData.postStatus.google && {
      google_call_to_action_type: GOOGLE_CALL_TO_ACTION_SELECT_OPTIONS.find((selectOption) => selectOption.value === updatedContentData.google_call_to_action_type)?.value || null,
      google_call_to_action_url: updatedContentData.google_call_to_action_url || null
    }),
    ...(selectedMediaList?.[0]?.media_type === MG_MEDIA_TYPE.VIDEO && { video_cover_cloudinary_id: selectedMediaList?.[0]?.cover_cloudinary_id || null }),
    ...(translationConfig && { translation_config: translationConfig })
  };
};

/** Add post - account selections and locations lists dropdown */
export const addPostLocationListDropdown = (dataArray: apFranApiTypes.IAPGetAccountListResponse) => {
  let data: apFranContainerTypes.ILocationSelectionDropdown[] = [];
  if (dataArray.account_selections?.length) {
    const returnData = dataArray.account_selections
      .reduce((acc: apFranContainerTypes.ILocationSelectionDropdown[], curr) => {
        acc = [...acc, { ...curr, type: LOCATION }];
        return acc;
      }, [])
      .sort((a, b) => a.name.localeCompare(b.name));

    data = [...data, ...returnData];
  }
  if (dataArray.account_lists?.length) {
    const returnData = dataArray.account_lists
      .reduce((acc: apFranContainerTypes.ILocationSelectionDropdown[], curr) => {
        acc = [...acc, { id: curr.id, name: curr.name, locationsCount: curr.locations_count || 0, type: LOCATION_LISTS, feed_type: curr.feed_type, locked: curr.locked }];
        return acc;
      }, [])
      .sort((a, b) => a.name.localeCompare(b.name));
    data = [...data, ...returnData];
  }
  if (dataArray.franchisor_selections?.length) {
    const returnData = dataArray.franchisor_selections
      ? dataArray.franchisor_selections
          .reduce((acc: apFranContainerTypes.ILocationSelectionDropdown[], curr) => {
            acc = [...acc, { ...curr, locationsCount: curr.locations_count || 0, type: FRANCHISORS_SELECTIONS }];
            return acc;
          }, [])
          .sort((a, b) => a.name.localeCompare(b.name))
      : [];
    data = [...data, ...returnData];
  }
  return data;
};

/** Add post - Get unique selected location list */
export const getSelectedLocationList = (previousLocList: postsApiTypes.IPostListDetailData[], currentSelectedLocList: postsApiTypes.IPostListDetailData[]): postsApiTypes.IPostListDetailData[] => {
  const retainLocationList = [...previousLocList, ...currentSelectedLocList];
  const locationIds = retainLocationList.map((datum) => datum.id);
  return retainLocationList.filter(({ id }, index) => !locationIds.includes(id, index + 1));
};

/** Add post - Get unique selected location list */
export const getUniqueSelectedLocationList = (locationList: postsApiTypes.IPostListDetailData[]): postsApiTypes.IPostListDetailData[] => {
  const locationIds = locationList.map((datum) => datum.id);
  return locationList.filter(({ id }, index) => !locationIds.includes(id, index + 1));
};

export const getIncludedAccounts = (schLocationTree: franchisorApiTypes.IDirectLocsFromHubOrListData[], scheduledLocationIds: number[] = []) => {
  const includedAccounts = schLocationTree.flatMap((locationOrList) => {
    // The variable locationOrList is either an account (in which case it has a
    // blank `type`) or a list (otherwise, in which case it has a `locations`
    // array).
    if (!scheduledLocationIds.includes(locationOrList.id) && !locationOrList?.type) {
      return [{ id: locationOrList.id, name: locationOrList.name }];
    } else {
      const locations = locationOrList.locations || [];
      return locations.filter((location) => location.active).map((location) => ({ id: location.id, name: location.name }));
    }
  });
  return getUniqueSelectedLocationList(includedAccounts);
};

export const getHubOrLocationListIds = (schLocationTree: franchisorApiTypes.IDirectLocsFromHubOrListData[], type: string) => {
  return schLocationTree.reduce((acc: number[], curr) => {
    if (curr?.type === type && curr.locations?.every((it) => it.active)) acc = acc.concat(curr.id);
    return acc;
  }, []);
};

/** Add post - Get scheduled post request payload */
export const getScheduledPostReqPayload = (
  contentId: number,
  franchisorId: number,
  isPermissionOverride: boolean,
  locationType: string,
  accountSelectionList: reviewsContainerTypes.IReviewsLocationObj[],
  schLocationTree: franchisorApiTypes.IDirectLocsFromHubOrListData[],
  utcFormat: string,
  actionType: string,
  boostData: null | apFranApiTypes.IAPScheduledMultipostsBoost,
  corporateAccountId?: number
): apFranApiTypes.IAPScheduledMultipostsReqPayload => {
  const includedAccounts = getIncludedAccounts(schLocationTree);
  const includedAccountIDs = new Set(includedAccounts.map((it) => it.id));
  return {
    boost: boostData,
    content_id: `${contentId}`,
    created_at: null,
    force_approved: actionType === AP_CONTENT_ACTION_TYPE.POST_NOW ? true : isPermissionOverride,
    franchisor_id: `${franchisorId}`,
    has_any_boost: false,
    facebook_dark_post: boostData?.facebook_dark_post || false,
    included_accounts:
      locationType === FRAN_SCHEDULE_POST_TYPE.ALL_LOCATIONS
        ? accountSelectionList.map((it) => ({ id: it.id, name: it.value }))
        : locationType === FRAN_SCHEDULE_POST_TYPE.CORPORATE_ONLY
        ? !corporateAccountId
          ? []
          : accountSelectionList.reduce((acc: postsApiTypes.IPostListDetailData[], curr) => {
              if (Number(curr.id) === Number(corporateAccountId)) acc = [...acc, { id: curr.id, name: curr.value }];
              return acc;
            }, [])
        : includedAccounts,
    missing_accounts:
      locationType === FRAN_SCHEDULE_POST_TYPE.ALL_LOCATIONS
        ? []
        : locationType === FRAN_SCHEDULE_POST_TYPE.CORPORATE_ONLY
        ? !corporateAccountId
          ? []
          : accountSelectionList.reduce((acc: postsApiTypes.IPostListDetailData[], curr) => {
              if (Number(curr.id) !== Number(corporateAccountId)) acc = [...acc, { id: curr.id, name: curr.value }];
              return acc;
            }, [])
        : accountSelectionList.reduce((acc: postsApiTypes.IPostListDetailData[], curr) => {
            if (!includedAccountIDs.has(curr.id)) acc = [...acc, { id: curr.id, name: curr.value }];
            return acc;
          }, []),
    post_now: actionType === AP_CONTENT_ACTION_TYPE.POST_NOW,
    schedule_to_account_list_ids: getUniqueValues(getHubOrLocationListIds(schLocationTree, LOCATION_LISTS)),
    schedule_to_franchisor_ids: getUniqueValues(getHubOrLocationListIds(schLocationTree, FRANCHISORS_SELECTIONS)),
    schedule_to_all_accounts: locationType === FRAN_SCHEDULE_POST_TYPE.ALL_LOCATIONS,
    scheduled_for: actionType === AP_CONTENT_ACTION_TYPE.POST_NOW ? getUTCDateAndTimeForSelectedData() : utcFormat
  };
};

export const getLinkDataReqPayload = (linkData: calendarApiTypes.ICouponLinks, linkIndex: number, showThumbnail: boolean): apFranApiTypes.IAPUpdateLinkReqPayload => {
  return {
    link_preview_description: linkData.link_preview_description || '',
    link_preview_image_url: showThumbnail ? linkData.link_preview_image_url_choices[linkIndex] : null,
    link_preview_image_url_choices: linkData.link_preview_image_url_choices,
    link_preview_title: linkData.link_preview_title,
    short_url: linkData.short_url || null,
    tags_list: linkData.tags_list || '',
    tracpoint_coupon_id: linkData.tracpoint_coupon_id || '',
    url: linkData.url,
    verticals_list: linkData.verticals_list || ''
  };
};

/** Add post > Get franchisor add content list */
export const getFranApAddContentList = (customText: apAccountContainerTypes.IAddPostContentTextObj) => {
  return Object.entries(customText).reduce((acc: string[], [key, value]) => {
    if (value && key) acc = [...acc, key];
    return acc;
  }, []);
};

export const getLinkDataForSelectedCoupon = (linkResponse: apFranActionApiTypes.ICreateLinkForAccCouponResponse): calendarApiTypes.ICouponLinks => {
  return {
    id: Math.floor(Math.random() * 100),
    url: linkResponse.url,
    short_url: '',
    verticals_list: '',
    tags_list: '',
    link_preview_title: linkResponse.link_preview_title,
    link_preview_description: linkResponse.link_preview_description,
    link_preview_image_url: linkResponse.link_preview_image_url,
    link_preview_image_url_choices: linkResponse.link_preview_image_url_choices,
    tracpoint_coupon_id: ''
  };
};

/** Get modified status for content post */
export const getModifiedStatusForContentPost = (
  contentData: null | postsApiTypes.IPostDetailSavedPost | postsApiTypes.IPostDetailContentPost,
  contentObj: apFranReducerTypes.IAddPostFranContentObj,
  mediaList: mgApiTypes.IMGListResponseObj[],
  linkData: null | calendarApiTypes.ICouponLinks,
  selectedTags: Tag[],
  selectedLocData: postsApiTypes.IPostListDetailData,
  campaignData: postsApiTypes.IPostListDetailData[]
) => {
  const scheduledType = contentData?.auto_schedule
    ? FranCreatorScheduleType.AUTO_SCHEDULE
    : contentData?.syndicate_to_account_databases
    ? FranCreatorScheduleType.SYNDICATE_TO_ACCOUNT_DB
    : FranCreatorScheduleType.MANUAL_SCHEDULE;
  const dateRangeType = contentData?.start_date && contentData?.end_date ? FranCreatorScheduleDateType.CUSTOM : FranCreatorScheduleDateType.ANYTIME;
  if (!contentData) return false;
  else if (
    contentData.facebook_text !== contentObj.customText.facebook ||
    contentData.twitter_text !== contentObj.customText.twitter ||
    contentData.instagram_text !== contentObj.customText.instagram ||
    contentData.linkedin_text !== contentObj.customText.linkedin ||
    contentData.google_text !== contentObj.customText.google
  ) {
    return true;
  } else if (
    contentData.use_facebook !== contentObj.postStatus.facebook ||
    contentData.use_twitter !== contentObj.postStatus.twitter ||
    contentData.use_instagram !== contentObj.postStatus.instagram ||
    contentData.use_linkedin !== contentObj.postStatus.linkedin ||
    contentData.use_google !== contentObj.postStatus.google
  ) {
    return true;
  } else if (
    contentData.personalized_facebook_text !== contentObj.personalizedText.facebook ||
    contentData.personalized_twitter_text !== contentObj.personalizedText.twitter ||
    contentData.personalized_instagram_text !== contentObj.personalizedText.instagram ||
    contentData.personalized_linkedin_text !== contentObj.personalizedText.linkedin ||
    contentData.personalized_google_text !== contentObj.personalizedText.google
  ) {
    return true;
  } else if (mediaList[0]?.media_type === MG_MEDIA_TYPE.PHOTO && contentData?.photo_urls?.length !== mediaList.length) return true;
  else if (mediaList[0]?.media_type === MG_MEDIA_TYPE.VIDEO && contentData?.video_url !== mediaList[0]?.url) return true;
  else if (contentData?.link_id !== (linkData?.id || null)) return true;
  else if (contentData.mobile_app_only_enabled !== contentObj.isPostAvailToEmployee) return true;
  else if (contentData.locked !== contentObj.isPostLock) return true;
  else if (selectedTags.map((dataItem) => dataItem.label).join() !== contentData.tags_list) return true;
  else if (scheduledType !== contentObj.scheduledBy) return true;
  else if (contentData.delete_from_platforms_after_expiry !== contentObj.isDeleteAutomatically) return true;
  else if (dateRangeType !== contentObj.dateType) return true;
  else if (contentData?.account_list && contentData?.account_list?.id !== selectedLocData?.id) return true;
  else if (
    comparyTwoArrays(
      contentData?.campaigns?.map((it) => +it.id) || [],
      campaignData.map((it) => +it.id)
    )
  ) {
    return true;
  } else if (Boolean(contentData?.instagram_reel) !== contentObj.instagram_reel) return true;
  else if (Boolean(contentData?.facebook_reel) !== contentObj.facebook_reel) return true;
  else if (Boolean(contentData?.facebook_story) !== contentObj.facebook_story) return true;
  else if (Boolean(contentData?.instagram_story) !== contentObj.instagram_story) return true;
  else return false;
};

/** Get updated link as hyperlink in content post */
export const getUpdatedLinkInContentPost = (activeSocialMedia: string[], contentObj: apFranReducerTypes.IAddPostFranContentObj, linkData: calendarApiTypes.ICouponLinks) => {
  return {
    ...contentObj,
    ...(linkData?.url && {
      customText: {
        ...contentObj.customText,
        ...(activeSocialMedia.includes(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK) && {
          facebook: `${contentObj.customText.facebook} ${linkData?.url}`
        }),
        ...(activeSocialMedia.includes(COMMON_SOCIAL_PROVIDER_TYPE.TWITTER) && {
          twitter: `${contentObj.customText.twitter} ${linkData?.url}`.slice(0, ADD_POST_CONTENT_MAX_TEXT_LENGTH.TWITTER)
        }),
        ...(activeSocialMedia.includes(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN) && {
          linkedin: `${contentObj.customText.linkedin} ${linkData?.url}`.slice(0, ADD_POST_CONTENT_MAX_TEXT_LENGTH.LINKEDIN)
        }),
        ...(activeSocialMedia.includes(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE) && {
          google: `${contentObj.customText.google} ${linkData?.url}`.slice(0, ADD_POST_CONTENT_MAX_TEXT_LENGTH.GOOGLE)
        })
      }
    }),
    facebook_call_to_action_type: null
  };
};

/** Get updated link as hyperlink in content post text editor */
export const getUpdatedLinkInContentPostTextEditor = (
  activeSocialMedia: string[],
  contentObj: apFranReducerTypes.IAddPostFranContentObj,
  linkData: calendarApiTypes.ICouponLinks
): apFranContainerTypes.ICreatorEditorStateTextObj => {
  return {
    ...(activeSocialMedia.includes(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK)
      ? {
          facebook: convertStringToEditorState(`${contentObj.customText.facebook} ${linkData?.url}`)
        }
      : { facebook: createEmptyEditorState() }),
    ...(activeSocialMedia.includes(COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM)
      ? {
          instagram: convertStringToEditorState(`${contentObj.customText.instagram}`.slice(0, ADD_POST_CONTENT_MAX_TEXT_LENGTH.INSTAGRAM))
        }
      : { instagram: createEmptyEditorState() }),
    ...(activeSocialMedia.includes(COMMON_SOCIAL_PROVIDER_TYPE.TWITTER)
      ? {
          twitter: convertStringToEditorState(`${contentObj.customText.twitter} ${linkData?.url}`.slice(0, ADD_POST_CONTENT_MAX_TEXT_LENGTH.TWITTER))
        }
      : { twitter: createEmptyEditorState() }),
    ...(activeSocialMedia.includes(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN)
      ? {
          linkedin: convertStringToEditorState(`${contentObj.customText.linkedin} ${linkData?.url}`.slice(0, ADD_POST_CONTENT_MAX_TEXT_LENGTH.LINKEDIN))
        }
      : { linkedin: createEmptyEditorState() }),
    ...(activeSocialMedia.includes(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE)
      ? {
          google: convertStringToEditorState(`${contentObj.customText.google} ${linkData?.url}`.slice(0, ADD_POST_CONTENT_MAX_TEXT_LENGTH.GOOGLE))
        }
      : { google: createEmptyEditorState() }),
    ...(activeSocialMedia.includes(COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK)
      ? {
          tiktok: convertStringToEditorState(`${contentObj.customText.tiktok} ${linkData?.url}`)
        }
      : { tiktok: createEmptyEditorState() }),
    ...(activeSocialMedia.includes(COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST)
      ? {
          pinterest: convertStringToEditorState(`${contentObj.customText.pinterest} ${linkData?.url}`)
        }
      : { pinterest: createEmptyEditorState() })
  };
};

/** Get preview list for active social media for a post */
export const getPreviewBrandHubCreatorActions = (postStatus: apAccountContainerTypes.IAddPostContentPostStatusObj) => {
  return Object.entries(postStatus).reduce((acc: string[], [key, value]) => {
    if (value) acc = [...acc, key];
    return acc;
  }, []);
};

/** Get content text field */
export const getPostContentTextField = (
  activePlatforms: string[],
  postDetail: postsReducerTypes.IPostDetailsData | calendarApiTypes.IGetScheduledPostResponse,
  savedHashtags?: string[]
): apFranContainerTypes.ICreatorEditorStateTextObj => {
  return postDetail?.id
    ? {
        facebook: isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK)
          ? postDetail.use_facebook && postDetail.facebook_text
            ? convertStringToEditorState(
                getPostedTextWithFBTagging(postPlatformText(postDetail, COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK, savedHashtags || []), postDetail.page_tag_details?.facebook) || ''
              )
            : postDetail.use_facebook && postDetail.long_text
            ? convertStringToEditorState(getPostedTextWithFBTagging(postDetail.long_text || '', postDetail.page_tag_details?.facebook) || '')
            : createEmptyEditorState()
          : createEmptyEditorState(),
        instagram: isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM)
          ? postDetail.use_instagram
            ? convertStringToEditorState(postPlatformText(postDetail, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM, savedHashtags || []))
            : createEmptyEditorState()
          : createEmptyEditorState(),
        twitter: isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.TWITTER)
          ? postDetail.use_twitter
            ? convertStringToEditorState(postPlatformText(postDetail, COMMON_SOCIAL_PROVIDER_TYPE.TWITTER, savedHashtags || []))
            : createEmptyEditorState()
          : createEmptyEditorState(),
        linkedin: isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN)
          ? postDetail.use_linkedin
            ? convertStringToEditorState(postPlatformText(postDetail, COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN, savedHashtags || []))
            : createEmptyEditorState()
          : createEmptyEditorState(),
        google: isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE)
          ? postDetail.use_google
            ? convertStringToEditorState(postPlatformText(postDetail, COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE, savedHashtags || []))
            : createEmptyEditorState()
          : createEmptyEditorState(),
        tiktok: isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK)
          ? postDetail.use_tiktok
            ? convertStringToEditorState(postPlatformText(postDetail, COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK, savedHashtags || []))
            : createEmptyEditorState()
          : createEmptyEditorState(),
        pinterest: isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST)
          ? postDetail.use_pinterest
            ? convertStringToEditorState(postPlatformText(postDetail, COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST, savedHashtags || []))
            : createEmptyEditorState()
          : createEmptyEditorState()
      }
    : AP_INIT_EDITOR_STATE_FOR_CUSTOM_TEXT;
};

/** Get personalized text field */
export const getPostPersonalizedTextField = (
  activePlatforms: string[],
  postDetail: postsReducerTypes.IPostDetailsData | calendarApiTypes.IGetScheduledPostResponse
): apFranContainerTypes.ICreatorEditorStateTextObj => {
  return postDetail?.id
    ? {
        facebook: isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK)
          ? postDetail.use_facebook && postDetail.personalized_facebook_text
            ? convertFBTextToEditorState(postDetail.personalized_facebook_text, postDetail.page_tag_details?.facebook || [])
            : createEmptyEditorState()
          : createEmptyEditorState(),
        instagram: isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM)
          ? postDetail.use_instagram && postDetail.personalized_instagram_text
            ? convertStringToEditorState(postDetail.personalized_instagram_text || '')
            : createEmptyEditorState()
          : createEmptyEditorState(),
        twitter: isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.TWITTER)
          ? postDetail.use_twitter && postDetail.personalized_twitter_text
            ? convertStringToEditorState(postDetail.personalized_twitter_text || '')
            : createEmptyEditorState()
          : createEmptyEditorState(),
        linkedin: isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN)
          ? postDetail.use_linkedin && postDetail.personalized_linkedin_text
            ? convertStringToEditorState(postDetail.personalized_linkedin_text || '')
            : createEmptyEditorState()
          : createEmptyEditorState(),
        google: isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE)
          ? postDetail.use_google && postDetail.personalized_google_text
            ? convertStringToEditorState(postDetail.personalized_google_text || '')
            : createEmptyEditorState()
          : createEmptyEditorState(),
        tiktok: isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK)
          ? postDetail.use_tiktok && postDetail.personalized_tiktok_text
            ? convertStringToEditorState(postDetail.personalized_tiktok_text || '')
            : createEmptyEditorState()
          : createEmptyEditorState(),
        pinterest: isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST)
          ? postDetail.use_pinterest && postDetail.personalized_pinterest_text
            ? convertStringToEditorState(postDetail.personalized_pinterest_text || '')
            : createEmptyEditorState()
          : createEmptyEditorState()
      }
    : AP_INIT_EDITOR_STATE_FOR_CUSTOM_TEXT;
};

export const getSyndicatedAccountIds = (
  locationType: string,
  accountSelectionList: postsApiTypes.IPostListDetailData[],
  selectedLocList: postsApiTypes.IPostListDetailData[],
  corporateAccountId?: number
) => {
  return locationType === FRAN_SCHEDULE_POST_TYPE.ALL_LOCATIONS
    ? accountSelectionList.map((it) => it.id)
    : locationType === FRAN_SCHEDULE_POST_TYPE.CORPORATE_ONLY && corporateAccountId
    ? accountSelectionList.filter((it) => Number(it.id) === Number(corporateAccountId)).map((it) => it.id)
    : selectedLocList.map((it) => it.id);
};

export const getValidContentData = (activePlatforms: string[], postDetails: postsApiTypes.IPostDetailSavedPost | postsApiTypes.IPostDetailContentPost) => {
  const isFacebook = Boolean(isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK) && postDetails.use_facebook);
  const isTwitter = Boolean(isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.TWITTER) && postDetails.use_twitter);
  const isLinkedin = Boolean(isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN) && postDetails.use_linkedin);
  const isInstagram = Boolean(isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM) && postDetails.use_instagram);
  const isGoogle = Boolean(isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE) && postDetails.use_google);

  return {
    ...postDetails,
    use_facebook: isFacebook,
    use_twitter: isTwitter,
    use_linkedin: isLinkedin,
    use_instagram: isInstagram,
    use_google: isGoogle,
    facebook_text: isFacebook ? postDetails.facebook_text || '' : '',
    twitter_text: isTwitter ? postDetails.twitter_text || '' : '',
    linkedin_text: isLinkedin ? postDetails.linkedin_text || '' : '',
    instagram_text: isInstagram ? postDetails.instagram_text || '' : '',
    google_text: isGoogle ? postDetails.google_text || '' : '',
    personalized_facebook_text: isFacebook ? postDetails.personalized_facebook_text || '' : '',
    personalized_twitter_text: isTwitter ? postDetails.personalized_twitter_text || '' : '',
    personalized_linkedin_text: isLinkedin ? postDetails.personalized_linkedin_text || '' : '',
    personalized_instagram_text: isInstagram ? postDetails.personalized_instagram_text || '' : '',
    personalized_google_text: isGoogle ? postDetails.personalized_google_text || '' : ''
  };
};

export const socialMediaTotalTextCount = (socialMedia: string) => {
  return socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER
    ? ADD_POST_CONTENT_MAX_TEXT_LENGTH.TWITTER
    : socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
    ? ADD_POST_CONTENT_MAX_TEXT_LENGTH.INSTAGRAM
    : socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN
    ? ADD_POST_CONTENT_MAX_TEXT_LENGTH.LINKEDIN
    : ADD_POST_CONTENT_MAX_TEXT_LENGTH.GOOGLE;
};

export const getSelectedMediaSortedList = (mediaList: mgApiTypes.IMGListResponseObj[], dropIndex: number, draggedItem: mgApiTypes.IMGListResponseObj) => {
  const draggedMediaId = draggedItem.id;
  const filteredMediaList = mediaList.filter((it) => it.id !== draggedMediaId);
  filteredMediaList.splice(dropIndex, 0, draggedItem);
  return filteredMediaList;
};
