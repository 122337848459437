import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { PostsAction } from 'actions';
import { postsReducerTypes } from 'types';
import { POSTS_INIT_FILTER_DATA, POSTS_REJECT_SAVED_POST_SUCCESSFULLY, POSTS_INIT_STATS_DATA } from 'utils/constants';
import { getPostDetailDataBasedOnAccSwitcher, getLinkDetails, getPostsListBasedOnAccountSwitcher, getUniquePostsInternalNotes, generateOptionsForReactTag } from 'utils/helpers';

const initialState: postsReducerTypes.IPostsReducer = {
  isFetching: true,
  isDetailFetching: true,
  isPostsStatsFetching: true,
  isActionFetching: false,
  isCopyPostFetching: false,
  isTagFetching: false,
  isPublishedStatusFetching: false,
  currentPage: 1,
  multiSelectListCurrentPage: 1,
  error: null,
  postsStats: POSTS_INIT_STATS_DATA,
  postsList: [],
  postsDetails: null,
  postsTagsList: [],
  postsCopy: [],
  postsCopyError: null,
  deletePostsResponse: null,
  deletePostsError: null,
  isAvailBoostedPosts: false,
  isIntNotesFetching: false,
  internalNotesList: [],
  internalNoteId: null,
  boostOfferData: [],
  postsLinks: [],
  postsMediaList: [],
  isRejectPostFetching: false,
  detailViewError: null,
  postSyndicationSuccessMsg: null,
  postPublishedStatus: [],
  // postsSyndicateExpireDate: POST_VALIDITY_INITIAL_VALUE,
  postsTagsStats: {
    page: 0,
    response: {
      total_tags_count: 0
    }
  },
  isTagStatsFetching: false,
  multiSelectPostsList: [],
  selectedPosts: [],
  isMultiSelectPostsFetching: false,
  editedPostDetails: null,
  postPerformanceRate: null
};

const postsReducer: Reducer<postsReducerTypes.IPostsReducer, PostsAction> = (state: postsReducerTypes.IPostsReducer = initialState, action: PostsAction): postsReducerTypes.IPostsReducer => {
  switch (action.type) {
    // Posts Stats
    case types.POSTS_STATS_REQUEST:
      return {
        ...state,
        error: null,
        isPostsStatsFetching: true,
        postsStats: POSTS_INIT_STATS_DATA
      };
    case types.POSTS_STATS_RESPONSE:
      return {
        ...state,
        isPostsStatsFetching: false,
        postsStats: {
          approvedPosts: action.payload.approved_posts || 0,
          boostedPosts: action.payload.boosted_posts || 0,
          draftPosts: action.payload.draft_posts || 0,
          notPublishedPosts: action.payload.not_published_posts || 0,
          pendingPosts: action.payload.pending_posts || 0,
          pendingPostsLocationContent: action.payload.location_pending_posts || 0,
          postedPosts: action.payload.posted_posts || 0,
          publishedPosts: action.payload.published_posts || 0,
          ...(action.payload?.rejected_posts !== undefined && { rejectedPosts: action.payload?.rejected_posts }),
          totalPosts: action.payload.total_posts || 0,
          aiDraftPosts: action.payload.ai_draft_posts || 0,
          brandPosts: action.payload.brand_posts || 0,
          syndicatedPosts: action.payload.syndicated_posts || 0,
          locationPosts: action.payload.location_posts || 0
        }
      };
    case types.POSTS_STATS_REQUEST_FAILURE:
      return {
        ...state,
        isPostsStatsFetching: false,
        postsStats: POSTS_INIT_STATS_DATA,
        error: action.payload || 'Unexpected error'
      };
    // Posts List
    case types.POSTS_LIST_REQUEST:
      const { isInitialPostRequest } = action.payload;
      return {
        ...state,
        isFetching: !action.payload.postsDataId,
        isDetailFetching: Boolean(action.payload.postsDataId) || state.isDetailFetching,
        error: null,
        ...(isInitialPostRequest && { filters: POSTS_INIT_FILTER_DATA }),
        currentPage: action.payload.page || 1
      };
    case types.POSTS_LIST_RESPONSE:
      const postsDataList = getPostsListBasedOnAccountSwitcher(action.payload, state.postsList);
      return {
        ...state,
        isFetching: false,
        postsList: postsDataList,
        multiSelectPostsList: postsDataList
      };
    case types.POSTS_LIST_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload || 'Unexpected error',
        postsList: []
      };

    // multi select posts
    case types.GET_MULTI_SELECT_POSTS_LIST_REQUEST:
      return {
        ...state,
        isMultiSelectPostsFetching: !action.payload.postsDataId,
        isDetailFetching: Boolean(action.payload.postsDataId) || state.isDetailFetching,
        error: null,
        multiSelectListCurrentPage: action.payload.page || 1
      };
    case types.GET_MULTI_SELECT_POSTS_LIST_RESPONSE:
      const multiSelectPostsDataList = getPostsListBasedOnAccountSwitcher(action.payload, state.multiSelectPostsList);
      return {
        ...state,
        isMultiSelectPostsFetching: false,
        multiSelectPostsList: multiSelectPostsDataList
      };
    case types.GET_MULTI_SELECT_POSTS_LIST_REQUEST_FAILURE:
      return {
        ...state,
        isMultiSelectPostsFetching: false,
        error: action.payload || 'Unexpected error',
        multiSelectPostsList: []
      };
    // POSTS BRAND VIEW LOCATION CONTENT
    case types.POSTS_LOCATION_CONTENT_REQUEST:
      return {
        ...state,
        isFetching: true,
        currentPage: action.payload.page || 1
      };
    case types.POSTS_LOCATION_CONTENT_RESPONSE:
      return {
        ...state,
        isFetching: false,
        postsList: action.payload.page === 1 ? action.payload.saved_post_summaries : state.postsList.concat(action.payload.saved_post_summaries)
      };
    case types.POSTS_LOCATION_CONTENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        postsList: [],
        error: action.payload || 'Unexpected error'
      };
    // Delete Post
    case types.POSTS_DETAIL_RESPONSE:
      return {
        ...state,
        isFetching: false,
        isDetailFetching: false,
        postsDetails: getPostDetailDataBasedOnAccSwitcher(action.payload),
        boostOfferData: action.payload.boost_offers,
        postsLinks: !action.payload?.links
          ? initialState.postsLinks
          : action.payload.links.length
          ? action.payload.links
          : action.payload.saved_post?.link_preview_image_url_choices?.length
          ? getLinkDetails(action.payload.saved_post)
          : initialState.postsLinks,
        postsMediaList:
          !action.payload?.photos || !action.payload?.videos
            ? initialState.postsMediaList
            : action.payload.photos.length
            ? action.payload.photos
            : action.payload.videos.length
            ? action.payload.videos
            : initialState.postsMediaList,
        editedPostDetails: getPostDetailDataBasedOnAccSwitcher(action.payload)
      };
    case types.POSTS_DETAIL_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        isDetailFetching: false,
        postsDetails: null,
        editedPostDetails: null,
        error: action.payload || 'Unexpected error',
        detailViewError: action.payload || 'No data found'
      };
    // Delete Post
    case types.POSTS_DELETE_REQUEST:
      return {
        ...state,
        isActionFetching: true,
        deletePostsError: null
      };
    case types.POSTS_DELETE_RESPONSE:
      return {
        ...state,
        isActionFetching: false,
        deletePostsResponse: action.payload.message
      };
    case types.POSTS_DELETE_FAILURE:
      return {
        ...state,
        isActionFetching: false,
        deletePostsError: action.payload.message
      };

    // Posts Tags
    case types.POSTS_TAGS_REQUEST:
      return {
        ...state,
        isTagFetching: true,
        error: null,
        ...(action?.payload?.page && {
          postsTagsStats: {
            ...state.postsTagsStats,
            page: action?.payload?.page || 1
          }
        })
      };
    case types.POSTS_TAGS_RESPONSE:
      return {
        ...state,
        isTagFetching: false,
        postsTagsList: state.postsTagsStats?.page > 1 ? state.postsTagsList.concat(generateOptionsForReactTag(action.payload.response)) : generateOptionsForReactTag(action.payload.response),
        ...(action.payload?.page && {
          postsTagsStats: {
            ...state.postsTagsStats,
            page: action.payload.page
          }
        })
      };
    case types.POSTS_TAGS_FAILURE:
      return {
        ...state,
        isTagFetching: false,
        postsTagsList: [],
        error: action.payload || 'Unexpected error'
      };
    case types.GET_POSTS_TAGS_STATS_REQUEST:
      return {
        ...state,
        isTagStatsFetching: true,
        error: null
      };
    case types.GET_POSTS_TAGS_STATS_RESPONSE:
      return {
        ...state,
        isTagStatsFetching: false,
        postsTagsStats: {
          ...state.postsTagsStats,
          response: action.payload
        }
      };
    case types.GET_POSTS_TAGS_STATS_FAILURE:
      return {
        ...state,
        isTagStatsFetching: false,
        error: action.payload || 'Unexpected error'
      };
    // COPY POST
    case types.POSTS_COPY_REQUEST:
      return {
        ...state,
        isCopyPostFetching: true
      };
    case types.POSTS_COPY_RESPONSE:
      return {
        ...state,
        isCopyPostFetching: false,
        postsCopy: action.payload ? [action.payload] : []
      };
    case types.POSTS_COPY_FAILURE:
      return {
        ...state,
        isCopyPostFetching: false,
        postsCopyError: action.payload || 'Unexpected error'
      };
    // Post detail get internal notes list
    case types.POSTS_DETAIL_INT_NOTES_LIST_REQUEST:
      return {
        ...state,
        isIntNotesFetching: true
      };
    case types.POSTS_DETAIL_INT_NOTES_LIST_RESPONSE:
      const uniqueInternalNotesList = getUniquePostsInternalNotes([...state.internalNotesList, ...action.payload]);
      return {
        ...state,
        isIntNotesFetching: false,
        internalNotesList: uniqueInternalNotesList,
        internalNoteId: initialState.internalNoteId
      };
    case types.POSTS_DETAIL_INT_NOTES_LIST_FAILURE:
      return {
        ...state,
        isIntNotesFetching: false,
        internalNotesList: [],
        error: action.payload || 'Unexpected error'
      };
    // Add internal notes for post detail
    case types.POSTS_DETAIL_ADD_INTERNAL_NOTE_REQUEST:
      return {
        ...state,
        isIntNotesFetching: true
      };
    case types.POSTS_DETAIL_ADD_INTERNAL_NOTE_RESPONSE:
      return {
        ...state,
        internalNoteId: action.payload?.id || null
      };
    case types.POSTS_DETAIL_ADD_INTERNAL_NOTE_FAILURE:
      return {
        ...state,
        internalNoteId: null,
        isIntNotesFetching: false,
        error: action.payload || 'Unexpected error'
      };
    // Post edit content draft
    case types.POSTS_EDIT_CONTENT_DRAFT_REQUEST:
      return {
        ...state
      };
    case types.POSTS_EDIT_CONTENT_DRAFT_RESPONSE:
      return {
        ...state,
        postsDetails: state.postsDetails
          ? {
              ...state.postsDetails,
              ...(action.payload.status_id && { status_id: action.payload.status_id })
            }
          : null,
        postsStats: {
          ...state.postsStats,
          approvedPosts: state.postsStats.approvedPosts + 1,
          pendingPosts: state.postsStats.pendingPosts - 1
        }
      };
    case types.POSTS_EDIT_CONTENT_DRAFT_FAILURE:
      return {
        ...state,
        error: action.payload || 'Unexpected error'
      };
    // POSTS - UPDATE SAVED POST LIST
    case types.POSTS_UPDATE_SAVED_POST_REQUEST:
      return {
        ...state
      };
    case types.POSTS_UPDATE_SAVED_POST_RESPONSE:
      return {
        ...state,
        postsDetails: state.postsDetails
          ? {
              ...state.postsDetails,
              ...(action.payload.status_id && { status_id: action.payload.status_id })
            }
          : null,
        postsStats: {
          ...state.postsStats,
          totalPosts: state.postsStats.totalPosts - 1,
          pendingPostsLocationContent: state.postsStats.pendingPostsLocationContent - 1
        }
      };
    case types.POSTS_UPDATE_SAVED_POST_FAILURE:
      return {
        ...state,
        error: action.payload || 'Unexpected error'
      };
    // POSTS SYNDICATE EXPIRE DATE UPDATE
    case types.POSTS_DETAIL_UPDATE_REQUEST:
      return {
        ...state
      };
    case types.POSTS_DETAIL_UPDATE_RESPONSE:
      return {
        ...state,
        postsDetails: state.postsDetails
          ? {
              ...state.postsDetails,
              start_date: action.payload.start_date,
              end_date: action.payload.end_date,
              locked: action.payload.locked
            }
          : null,
        editedPostDetails: state.editedPostDetails
          ? {
              ...state.editedPostDetails,
              start_date: action.payload.start_date,
              end_date: action.payload.end_date,
              locked: action.payload.locked
            }
          : null
      };
    case types.POSTS_DETAIL_UPDATE_FAILURE:
      return {
        ...state,
        error: action.payload || 'Unexpected error'
      };
    // SET POSTS SYNDICATE EXPIRE DATE UPDATE
    // case types.POSTS_SYNDICATE_EXPIRE_DATE:
    //   return {
    //     ...state,
    //     postsSyndicateExpireDate: action.payload
    //   };
    // POSTS - REJECT SAVED POST
    case types.POSTS_REJECT_SAVED_POST_REQUEST:
      return {
        ...state,
        isRejectPostFetching: true
      };
    case types.POSTS_REJECT_SAVED_POST_RESPONSE:
      return {
        ...state,
        isRejectPostFetching: false,
        deletePostsResponse: action.payload.id && action.payload.rejected ? POSTS_REJECT_SAVED_POST_SUCCESSFULLY : null
      };
    case types.POSTS_REJECT_SAVED_POST_FAILURE:
      return {
        ...state,
        isRejectPostFetching: false,
        deletePostsError: action.payload,
        error: action.payload || 'Unexpected error'
      };
    case types.POSTS_PUBLISHED_STATUS_REQUEST:
      return {
        ...state,
        isPublishedStatusFetching: true,
        postPublishedStatus: []
      };
    case types.POSTS_PUBLISHED_STATUS_RESPONSE:
      return {
        ...state,
        isPublishedStatusFetching: false,
        postPublishedStatus: action.payload
      };
    case types.POSTS_PUBLISHED_STATUS_FAILURE:
      return {
        ...state,
        isPublishedStatusFetching: false,
        postPublishedStatus: [],
        error: action.payload || 'Unexpected error'
      };
    case types.POSTS_DETAIL_VIEW_ERROR:
      return {
        ...state,
        detailViewError: action.payload
      };
    case types.POSTS_DELETE_DATA_RESET:
      return {
        ...state,
        isActionFetching: false,
        deletePostsError: null,
        deletePostsResponse: null,
        isRejectPostFetching: false
      };
    case types.POSTS_SYNDICATE_SUCCESS_RESPONSE:
      return {
        ...state,
        postSyndicationSuccessMsg: action.payload
      };
    case types.POSTS_DETAIL_DATA_RESET:
      return {
        ...state,
        postsDetails: initialState.postsDetails,
        internalNoteId: initialState.internalNoteId,
        boostOfferData: initialState.boostOfferData,
        postsMediaList: initialState.postsMediaList,
        postsLinks: initialState.postsLinks,
        internalNotesList: initialState.internalNotesList,
        // postsSyndicateExpireDate: initialState.postsSyndicateExpireDate,
        editedPostDetails: initialState.editedPostDetails
      };
    case types.POSTS_LISTS_DATA_RESET:
      return {
        ...state,
        currentPage: initialState.currentPage,
        postsList: initialState.postsList
      };
    case types.POSTS_COPY_DATA_RESET:
      return {
        ...state,
        postsCopy: initialState.postsCopy,
        postsCopyError: initialState.postsCopyError
      };
    case types.EDIT_POST_DETAILS_REQUEST:
      return {
        ...state,
        editedPostDetails: action.payload
      };
    case types.GET_POST_PERFORMANCE_RATE_REQUEST:
      return {
        ...state,
        isPublishedStatusFetching: true,
        postPerformanceRate: initialState.postPerformanceRate
      };
    case types.GET_POST_PERFORMANCE_RATE_RESPONSE:
      return {
        ...state,
        isPublishedStatusFetching: false,
        postPerformanceRate: action.payload
      };
    case types.GET_POST_PERFORMANCE_RATE_FAILURE:
      return {
        ...state,
        error: action.payload,
        isPublishedStatusFetching: false,
        postPerformanceRate: initialState.postPerformanceRate
      };
    case types.POSTS_RESET_ALL:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export { postsReducer };
