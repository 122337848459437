import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { useNavigate } from 'react-router';
import parse from 'html-react-parser';

import { CarousalImages, ReactVideoPlayer, ImageValidation } from 'widgets/Media';
import {
  POSTS_DETAIL_TAB_NAMES,
  COMMON_SOCIAL_PROVIDER_TYPE,
  POSTS_INIT_FILTER_DATA,
  USER_OWNERSHIP,
  MG_MEDIA_TYPE,
  AP_FRAN_SHOW_LINE_BREAK_TYPES,
  COMMON_PAGE_TYPE,
  NoDataFoundProps,
  SIDE_NAV_NAME,
  FranchisorSyndicationMediaType,
  GOOGLE_CALL_TO_ACTION_SELECT_OPTIONS,
  POSTS_STATUS_TYPE
  // POST_VALIDITY_INITIAL_VALUE
} from 'utils/constants';
import {
  getPostsRequest,
  getPostsInternalNotesListRequest,
  postDetailDataReset,
  addPostActionsResetAll,
  accountGetProfileRequest,
  getFranchiseePermissionRequest,
  getFranchisorDetailsRequest,
  getFranchisorSyndicationRequest,
  postPublishedStatusRequest,
  // setPostsSyndicateExpireDate,
  addPostEnablePostNow,
  getPostPerformanceRateRequest
} from 'actions';
import { IStore, postsContainerTypes } from 'types';
import {
  // addPadStart,
  capitalizeName,
  checkCurrentDateGreater,
  convertLocalTimezone,
  convertURLAsLink,
  getInitials,
  getPermittedImgsBasedOnPlatform,
  postPlatformText
} from 'utils/helpers';
import { BrandSchedulePostModal, Loading, NoDataFound, PostsDeleteModal } from 'components';
import { LocationDetails, Paragraph } from 'widgets/Text';
import { PostsDetailViewBoostOffer } from './PostsDetailViewBoostOffer';
import { PostsDetailViewInternalNotes } from './PostsDetailViewInternalNotes';
import { PostsDetailViewDetails } from './PostsDetailViewDetails';
import { PostsDetailActions } from './PostsDetailActions';
import { SocialMediaLinkPreview } from 'widgets/Preview';
import { useHideFilter, useAccountSwitcherData, useParamsDeconstructor, useActivePlatform, useNavBarData } from 'utils/hooks';
import { SchedulePostModal } from 'containers/Content/Creator/AccountSubPages';
import { RenderPublishStatusTable } from './RenderPublishStatusTable';

export const PostsDetailView = ({ id, userOwnership, postId, tabName, handlePostsStats, handlePostListBasedOnFilters }: postsContainerTypes.IPostDetailViewProps) => {
  useHideFilter();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { currentRoute, optionalParams, isValidId } = useAccountSwitcherData();
  const { queryParams } = useParamsDeconstructor(POSTS_INIT_FILTER_DATA);
  const activePlatforms = useActivePlatform(userOwnership === USER_OWNERSHIP.ACCOUNT ? true : false);
  const { subNavPageName } = useNavBarData();

  const accountDetails = useSelector((state: IStore) => state.accounts?.accountDetails);
  const postsList = useSelector((state: IStore) => state.posts.postsList);
  const postsDetails = useSelector((state: IStore) => state.posts.postsDetails);
  const internalNoteId = useSelector((state: IStore) => state.posts.internalNoteId);
  const internalNotesList = useSelector((state: IStore) => state.posts.internalNotesList);
  const postsLinks = useSelector((state: IStore) => state.posts.postsLinks);
  const postsMediaList = useSelector((state: IStore) => state.posts.postsMediaList);
  const currentName = useSelector((state: IStore) => state.accountSwitcher.currentName);
  const activeUserFirstName = useSelector((state: IStore) => state.login.activeUser?.first_name || '');
  const activeUserLastName = useSelector((state: IStore) => state.login.activeUser?.last_name || '');
  const isDetailFetching = useSelector((state: IStore) => state.posts.isDetailFetching);
  const postPublishedStatus = useSelector((state: IStore) => state.posts.postPublishedStatus);

  const [activeSocialMediaTab, setActiveSocialMediaTab] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const isFranchisor = userOwnership === USER_OWNERSHIP.FRANCHISOR;
  const postUserOwnership = isFranchisor && postsDetails?.account?.id ? USER_OWNERSHIP.ACCOUNT : userOwnership;

  const validCampaignId = subNavPageName === SIDE_NAV_NAME.CONTENT_CAMPAIGNS ? +optionalParams[0] : 0;
  const postType = subNavPageName === SIDE_NAV_NAME.CONTENT_CAMPAIGNS ? optionalParams[1] : optionalParams[0];

  useEffect(() => {
    return () => {
      dispatch(postDetailDataReset());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!postsList.length && postId) {
      if (typeof handlePostsStats === 'function') handlePostsStats();
      if (typeof handlePostListBasedOnFilters === 'function') handlePostListBasedOnFilters();
    }
  }, [postsList.length, postId]); // eslint-disable-line

  useEffect(() => {
    if (isValidId && postId) {
      dispatch(
        getPostsRequest({
          postsDataId: postId,
          ...(id && { id }),
          userOwnership: postType === COMMON_PAGE_TYPE.DETAILS ? USER_OWNERSHIP.FRANCHISOR : USER_OWNERSHIP.ACCOUNT
        })
      );
      dispatch(postPublishedStatusRequest({ userOwnership: queryParams?.status === POSTS_STATUS_TYPE.AA_LOCATION_CONTENT ? USER_OWNERSHIP.ACCOUNT : userOwnership, id: postId }));
      dispatch(getPostPerformanceRateRequest({ [isFranchisor ? 'content_id' : 'saved_post_id']: postId }));
      if (isFranchisor) {
        dispatch(getFranchisorSyndicationRequest({ id: postId, mediaType: FranchisorSyndicationMediaType.CONTENT }));
      }
    }
  }, [id, userOwnership, isValidId, postId, postType, queryParams?.status, isFranchisor, dispatch]);

  useEffect(() => {
    if (postsDetails?.id && isFranchisor && postsDetails?.account?.id) {
      dispatch(accountGetProfileRequest({ id: postsDetails?.account?.id, isGetAccountDetailsOnly: true }));
      dispatch(getFranchiseePermissionRequest(postsDetails?.account?.id));
    }
  }, [postsDetails?.id, userOwnership, postsDetails?.account?.id]); // eslint-disable-line

  useEffect(() => {
    if (id && isFranchisor) dispatch(getFranchisorDetailsRequest({ id, userOwnership }));
  }, [id, userOwnership, isFranchisor, dispatch]);

  useEffect(() => {
    if (postsDetails && Object.keys(postsDetails).length) {
      activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK) && postsDetails.use_facebook
        ? setActiveSocialMediaTab(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK)
        : activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM) && postsDetails.use_instagram
        ? setActiveSocialMediaTab(COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM)
        : activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.TWITTER) && postsDetails.use_twitter
        ? setActiveSocialMediaTab(COMMON_SOCIAL_PROVIDER_TYPE.TWITTER)
        : activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN) && postsDetails.use_linkedin
        ? setActiveSocialMediaTab(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN)
        : activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK) && postsDetails.use_tiktok
        ? setActiveSocialMediaTab(COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK)
        : activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE) && postsDetails.use_google
        ? setActiveSocialMediaTab(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE)
        : activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST) && postsDetails.use_pinterest
        ? setActiveSocialMediaTab(COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST)
        : setActiveSocialMediaTab('');
    }
  }, [postsDetails, activePlatforms.length]); // eslint-disable-line

  useEffect(() => {
    handleGetInternalNotes();
    if (postsDetails?.start_date) {
      dispatch(addPostEnablePostNow(!checkCurrentDateGreater(postsDetails?.start_date) ? false : true));
    }
  }, [id, userOwnership, isValidId, postsDetails?.id]); // eslint-disable-line

  useEffect(() => {
    if (internalNoteId) handleGetInternalNotes();
  }, [internalNoteId]); // eslint-disable-line

  const handleGetInternalNotes = () => {
    if (id && postUserOwnership && isValidId && postsDetails?.id) {
      dispatch(
        getPostsInternalNotesListRequest({
          postsDataId: postsDetails?.id,
          id,
          userOwnership: postUserOwnership
        })
      );
    }
  };

  const handleNext = () => {
    let postIndexValue = !postsDetails?.id
      ? 0
      : postsList.findIndex((detailItem) => (`saved_post_id` in detailItem ? detailItem.saved_post_id === postsDetails?.id : detailItem.content_id === postsDetails?.id));
    postIndexValue += 1;
    if (id && userOwnership && postsDetails) {
      if (postsList.length > postIndexValue) {
        routePush(
          postType === COMMON_PAGE_TYPE.DETAILS
            ? `/${userOwnership}/${id.toString()}/${currentRoute}/preview/${postsList[postIndexValue]?.content_id?.toString()}/${POSTS_DETAIL_TAB_NAMES.DETAILS}`
            : `/${userOwnership}/${id.toString()}/${currentRoute}/${postsList[postIndexValue]?.saved_post_id?.toString()}/${POSTS_DETAIL_TAB_NAMES.DETAILS}`,
          queryParams
        );
      }
    }
    // dispatch(setPostsSyndicateExpireDate(POST_VALIDITY_INITIAL_VALUE));
  };

  const handlePrev = () => {
    let preIndexValue = !postsDetails?.id
      ? 0
      : postsList.findIndex((detailItem) => (`saved_post_id` in detailItem ? detailItem.saved_post_id === postsDetails?.id : detailItem.content_id === postsDetails?.id));
    preIndexValue -= 1;
    if (id && userOwnership && postsDetails) {
      if (preIndexValue >= 0) {
        routePush(
          postType === COMMON_PAGE_TYPE.DETAILS
            ? `/${userOwnership}/${id.toString()}/${currentRoute}/preview/${postsList[preIndexValue]?.content_id?.toString()}/${POSTS_DETAIL_TAB_NAMES.DETAILS}`
            : `/${userOwnership}/${id.toString()}/${currentRoute}/${postsList[preIndexValue]?.saved_post_id?.toString()}/${POSTS_DETAIL_TAB_NAMES.DETAILS}`,
          queryParams
        );
      }
    }
    // dispatch(setPostsSyndicateExpireDate(POST_VALIDITY_INITIAL_VALUE));
  };

  const routePush = (path: string, searchParams: { [key: string]: string } = {}) => {
    navigate({ pathname: path, search: searchParams ? `?${new URLSearchParams(searchParams).toString()}` : `` });
  };

  const handleSwitchPostDetailTab = (tabName: null | string) => {
    const campaignPathname = validCampaignId ? `/${validCampaignId}` : '';
    if (tabName) {
      routePush(
        `/${userOwnership}/${id.toString()}/${currentRoute}${campaignPathname}/${
          postType === COMMON_PAGE_TYPE.DETAILS ? `preview/${postId.toString()}/${tabName}` : `${postId.toString()}/${tabName}`
        }`,
        queryParams
      );
    } else {
      routePush(
        `/${userOwnership}/${id.toString()}/${currentRoute}${campaignPathname}/${
          postType === COMMON_PAGE_TYPE.DETAILS ? `preview/${postId.toString()}/${POSTS_DETAIL_TAB_NAMES.DETAILS}` : `${postId.toString()}/${POSTS_DETAIL_TAB_NAMES.DETAILS}`
        }`,
        queryParams
      );
    }
  };

  const handleSwitchPostSocialMediaTab = (socialMediaName: null | string) => {
    if (socialMediaName) setActiveSocialMediaTab(socialMediaName);
    else setActiveSocialMediaTab(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK);
  };

  const handleCancelDeletePost = () => {
    if (postUserOwnership === USER_OWNERSHIP.FRANCHISOR) {
      dispatch(addPostActionsResetAll());
    }
    setShowDeleteModal(false);
  };

  const disablePreviousButton = () => {
    return !postsList.find((datum) => (`saved_post_id` in datum ? datum.saved_post_id === postsDetails?.id : datum.content_id === postsDetails?.id))
      ? true
      : !postsList.findIndex((datum) => (`saved_post_id` in datum ? datum.saved_post_id === postsDetails?.id : datum.content_id === postsDetails?.id))
      ? true
      : false;
  };

  const disableNextButton = () => {
    return !postsList.find((datum) => (`saved_post_id` in datum ? datum.saved_post_id === postsDetails?.id : datum.content_id === postsDetails?.id))
      ? true
      : postsList.findIndex((datum) => (`saved_post_id` in datum ? datum.saved_post_id === postsDetails?.id : datum.content_id === postsDetails?.id)) + 1 === postsList.length
      ? true
      : false;
  };

  const renderTabData = (tabName: string) => {
    const platformText = postPlatformText(postsDetails, tabName);
    const imagesUrl = getPermittedImgsBasedOnPlatform(tabName, tabName === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM ? postsDetails?.instagram_photo_urls || [] : postsDetails?.photo_urls || []);
    return (
      postsDetails && (
        <div className="st-tc-item">
          <div className="li-top g-10">
            {/* <div className="round-asset rel-icons rel-initials"> */}
            <div className="round-asset">
              {/* <span className="rInitials">{currentName ? getInitials(currentName) : getInitials(`${activeUserFirstName} ${activeUserLastName}`)}</span> */}
              <span className="round__asset--txt">{currentName ? getInitials(currentName) : getInitials(`${activeUserFirstName} ${activeUserLastName}`)}</span>
            </div>
            {/* <div className="lvt-details"> */}
            <div>
              <h3 className="user__info--title">{currentName ? capitalizeName(currentName) : capitalizeName(`${activeUserFirstName} ${activeUserLastName}`)}</h3>
              <LocationDetails
                customLocationClassname={'lvt-txt'}
                customDateClassname={'lvt-txt'}
                customClassname="user__sub--title"
                createdAt={`@ ${convertLocalTimezone(postsDetails.created_at)}`}
              />
            </div>
          </div>

          <div className="li-base msg-wrapper">
            <Paragraph
              customClassname="post-message"
              customText={platformText}
              facebookTagList={postsDetails.use_facebook && activeSocialMediaTab === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? postsDetails?.page_tag_details?.facebook || [] : []}
              actionType={AP_FRAN_SHOW_LINE_BREAK_TYPES.PREVIEW}
            />
          </div>

          <div className="lv-assets">
            {/* Image preview */}
            {imagesUrl?.length && activeSocialMediaTab !== COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK ? (
              <CarousalImages key={`post-detail-${postsDetails?.id}-${activeSocialMediaTab}`} imagesList={imagesUrl} showThumbs={false} customClassname="gma-assets gma-img" />
            ) : null}
            {/* Video preview */}
            {!postsMediaList.length && postsDetails.video_url ? (
              <ReactVideoPlayer videoUrl={postsDetails.video_url || ''} light={postsDetails?.video_thumbnail_url} customClassname="gma-assets gma-video" />
            ) : postsMediaList[0]?.media_type === MG_MEDIA_TYPE.VIDEO ? (
              <ReactVideoPlayer videoUrl={postsMediaList[0]?.medium_size_url || ''} light={postsMediaList[0]?.thumbnail_url || ''} customClassname="gma-assets gma-video" />
            ) : null}
            {/* Link preview */}
            {postsDetails?.url || postsLinks.length ? (
              <SocialMediaLinkPreview
                imageUrl={postsDetails?.link_preview_image_url || postsLinks[0]?.link_preview_image_url}
                linkPreviewTitle={postsDetails?.link_preview_title || postsLinks[0]?.link_preview_title}
                linkUrl={postsDetails?.url || postsLinks[0]?.url}
                isShowThumbnail={postsDetails?.link_preview_image_url || postsLinks[0]?.link_preview_image_url ? true : false}
                linkPreviewDescription={postsDetails?.link_preview_description || postsLinks[0]?.link_preview_description}
                socialMedia={activeSocialMediaTab}
                isLinkCustomized={postsDetails?.link_preview_customized || postsLinks[0]?.link_preview_customized || false}
                actionType={activeSocialMediaTab === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? postsDetails?.facebook_call_to_action_type : postsDetails?.google_call_to_action_type || null}
              />
            ) : null}
            {activeSocialMediaTab === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE && postsDetails.google_call_to_action_url && postsDetails.google_call_to_action_type && (
              <>
                <div className="p-footer" />
                <div className="insta-social-sec-wrp">
                  <div className="lefticon">
                    {parse(
                      convertURLAsLink(
                        postsDetails.google_call_to_action_url,
                        GOOGLE_CALL_TO_ACTION_SELECT_OPTIONS.find((option) => option.value === postsDetails.google_call_to_action_type)?.label || ''
                      )
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )
    );
  };

  return (
    <>
      {isDetailFetching ? (
        <Loading />
      ) : (
        <div className={`mainContent animate__animated animate__fadeIn mg-detailed post-detailed post-detailed__main lpx vpy-20`}>
          <div className="blue-trans-box mb-20 r-flx r-flx-ac r-flx-je">
            <div className="blue__trans--icon cu-pointer">
              <ImageValidation
                isImgValid
                defaultImg={'xmark'}
                customName={'close'}
                onClick={() => {
                  routePush(`/${userOwnership}/${id.toString()}/${currentRoute}${validCampaignId ? `/${validCampaignId}` : ''}`, queryParams);
                  // dispatch(setPostsSyndicateExpireDate(POST_VALIDITY_INITIAL_VALUE));
                }}
              />
            </div>
          </div>

          {!postsDetails ? (
            <NoDataFound size={NoDataFoundProps.FULL} />
          ) : (
            // <div className="white-box post__white--box pt-details">
            <div className="post__white--box pt-details">
              <div className="social-tabs">
                {subNavPageName === SIDE_NAV_NAME.CONTENT_POSTS ? (
                  <div className="media-features">
                    <div className="scl-controls slide-controls r-flx r-flx-ac">
                      <Button className={`primeblueaction cmn-blue-btn sc-prev${disablePreviousButton() ? ` pointer-events-none` : ``}`} variant="primary" onClick={() => handlePrev()}>
                        <span className="scp-txt">Previous</span>
                      </Button>
                      <Button className={`primeblueaction cmn-blue-btn sc-next${disableNextButton() ? ` pointer-events-none` : ``}`} variant="primary" onClick={() => handleNext()}>
                        <span className="scp-txt">Next</span>
                      </Button>
                    </div>
                  </div>
                ) : null}

                <div className="icon-tabs">
                  <Tabs activeKey={activeSocialMediaTab} className="r-flx-jc" id="detailed-tabs" onSelect={(event: null | string) => handleSwitchPostSocialMediaTab(event)}>
                    {activePlatforms.length
                      ? activePlatforms.map((platformItem, index) => {
                          return (
                            <Tab
                              key={`posts-detail-platform-${index}`}
                              tabClassName={
                                postsDetails[`use_${platformItem}`] &&
                                (postsDetails[`${platformItem}_text`] ||
                                  postsDetails?.long_text ||
                                  postsDetails?.photo_urls.length ||
                                  postsDetails?.video_url ||
                                  postsMediaList.length ||
                                  postsLinks.length ||
                                  postsDetails?.url) &&
                                (isFranchisor ||
                                  (platformItem === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE
                                    ? 'events-none icon-disabled'
                                    : userOwnership === USER_OWNERSHIP.ACCOUNT && platformItem === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
                                    ? accountDetails?.account_profiles[0][`has_${platformItem}_medium`] ||
                                      accountDetails?.account_profiles[0][`has_${platformItem}_hootsuite_medium`] ||
                                      accountDetails?.account_profiles[0][`has_${platformItem}_business_page_medium`]
                                    : accountDetails?.account_profiles[0][`has_${platformItem}_medium`]))
                                  ? ''
                                  : 'events-none icon-disabled'
                              }
                              eventKey={platformItem}
                              title={platformItem}
                            >
                              {renderTabData(platformItem)}
                            </Tab>
                          );
                        })
                      : null}
                  </Tabs>
                </div>
              </div>
            </div>
          )}

          {postsDetails ? (
            // <div className="white-box post__white--box ed-main">
            <div className="ed-main">
              <div className="social-tabs">
                <Tabs activeKey={tabName || POSTS_DETAIL_TAB_NAMES.DETAILS} className="g-20" id="social-ed-tabs" onSelect={(event: null | string) => handleSwitchPostDetailTab(event)}>
                  <Tab eventKey={POSTS_DETAIL_TAB_NAMES.DETAILS} title="Details">
                    <PostsDetailViewDetails activeSocialMediaTab={activeSocialMediaTab} />
                  </Tab>
                  <Tab eventKey={POSTS_DETAIL_TAB_NAMES.PUBLISH_STATUS} title={`Publishing History (${postPublishedStatus.length || 0})`}>
                    <RenderPublishStatusTable />
                  </Tab>
                  <Tab
                    eventKey={POSTS_DETAIL_TAB_NAMES.INTERNAL_NOTE}
                    title={`Internal Note${internalNotesList.length > 1 ? 's' : ''}
                (${
                  internalNotesList.filter((datum) => (`saved_post_id` in datum && datum.saved_post_id === postsDetails.id) || (`content_id` in datum && datum.content_id === postsDetails.id))
                    .length || 0
                })`}
                  >
                    <PostsDetailViewInternalNotes />
                  </Tab>
                  {postsDetails.boost_offer_id && (
                    <Tab eventKey={POSTS_DETAIL_TAB_NAMES.BOOST_OFFER} title="Boost Offer">
                      <PostsDetailViewBoostOffer />
                    </Tab>
                  )}
                </Tabs>
              </div>
            </div>
          ) : null}
          {subNavPageName === SIDE_NAV_NAME.CONTENT_POSTS ? <PostsDetailActions postId={postId} setShowDeleteModal={setShowDeleteModal} activeSocialMediaTab={activeSocialMediaTab} /> : null}
        </div>
      )}
      <BrandSchedulePostModal
        isModalShow={optionalParams[2] === POSTS_DETAIL_TAB_NAMES.SCHEDULE}
        handleModalClose={() => {
          handleSwitchPostDetailTab(POSTS_DETAIL_TAB_NAMES.DETAILS);
        }}
      />
      <SchedulePostModal
        isModalShow={optionalParams[1] === POSTS_DETAIL_TAB_NAMES.SCHEDULE}
        handleModalClose={() => {
          handleSwitchPostDetailTab(POSTS_DETAIL_TAB_NAMES.DETAILS);
        }}
        containerClassName={'aps-modal'}
      />
      <PostsDeleteModal isModalShow={showDeleteModal} handleModalClose={() => handleCancelDeletePost()} />
    </>
  );
};
