import { SocialIcons } from 'widgets/Icons';
import { ImageValidation, Video } from 'widgets/Media';
import { AP_FRAN_SHOW_LINE_BREAK_TYPES, COMMON_SOCIAL_PROVIDER_TYPE } from 'utils/constants';
import { dateFormatterForPostDetail, isSocialMediaActive } from 'utils/helpers';
import { useActivePlatform } from 'utils/hooks';
import { Paragraph } from 'widgets/Text';
import { postsApiTypes } from 'types';

export const PostCard = ({ listItem }: { listItem: postsApiTypes.IPostListSavedPostSummaries | postsApiTypes.IPostListContentPostSummaries }) => {
  const activePlatforms = useActivePlatform();

  const handleCheckActivePlatform = (platform: string) => {
    return isSocialMediaActive(activePlatforms, platform);
  };
  return (
    <div className="mi-front">
      <div className="mif-head">
        <div className="mifh-top">
          <div className="mif-left">
            <div className="round-asset brand-icon">
              <ImageValidation
                isImgValid
                defaultImg={
                  listItem.status_id === 3
                    ? 'stats/approved'
                    : [2, 5].includes(listItem.status_id)
                    ? 'pending-filled'
                    : listItem.status_id === 1 && !listItem?.rejected
                    ? 'draft-filled'
                    : listItem.status_id === 1 && listItem.rejected
                    ? 'stats/rejected'
                    : listItem?.published
                    ? 'published'
                    : ''
                }
                customName={'Asset status'}
              />
            </div>
            <div className="prime-sub-dtls">
              {/* <h3>{`ID: ${addPadStart(listItem.id, 'E')}`}</h3> */}
              <h3 className="crtdOn">Created on</h3>
              <span className="sub-dtls">{dateFormatterForPostDetail(listItem.created_at)}</span>
            </div>
          </div>
          <div className="mif-right">
            <SocialIcons
              useFacebook={handleCheckActivePlatform(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK) && listItem.has_facebook}
              useTwitter={handleCheckActivePlatform(COMMON_SOCIAL_PROVIDER_TYPE.TWITTER) && listItem.has_twitter}
              useInstagram={handleCheckActivePlatform(COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM) && listItem.has_instagram}
              useLinkedIn={handleCheckActivePlatform(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN) && listItem.has_linkedin}
              useTiktok={handleCheckActivePlatform(COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK) && listItem.has_tiktok}
              usePinterest={handleCheckActivePlatform(COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST) && listItem.has_pinterest}
              useGoogle={
                handleCheckActivePlatform(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE) && 'has_google_plus' in listItem ? listItem.has_google_plus : 'has_google' in listItem ? listItem.has_google : false
              }
            />
          </div>
        </div>
        <div className="msg-wrapper">
          <Paragraph
            customText={listItem?.platform_text_copy ? listItem?.platform_text_copy : listItem.copy || ''}
            facebookTagList={listItem?.has_facebook ? listItem?.page_tag_details?.facebook || [] : []}
            actionType={AP_FRAN_SHOW_LINE_BREAK_TYPES.PREVIEW}
          />
        </div>
      </div>
      {listItem.video_url || listItem.photo_preview_url || listItem?.link_image_url ? (
        <div className="m-ast">
          {!listItem.video_url && (listItem.photo_preview_url || listItem?.link_image_url) ? (
            <ImageValidation imgUrl={listItem.photo_preview_url || listItem.link_image_url || ''} customClassname={'m-ast-itm m-ast-img'} customName={'Post Image'} />
          ) : (
            listItem.video_url && (
              <div className="video-outer-element">
                <Video videoUrl={listItem.video_url} light={listItem?.video_thumbnail_url} customClassname="m-ast-itm m-ast-video" />
              </div>
            )
          )}
        </div>
      ) : null}
    </div>
  );
};
