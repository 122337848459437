import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';

import { aiContentCreatorApiTypes, aiContentCreatorContainerTypes, aiContentCreatorReducerTypes } from 'types';
import { ISubscriptionDetailModal } from 'types/ai-content-creator/ai-content-creator-reducer';

// CREATOR - GET AI CONTENT WRITER DATA
export const getAIContentCreatorRequest = (payload: aiContentCreatorApiTypes.IAIContentCreatorRequest) => createAction(actionTypes.AI_CONTENT_CREATOR_REQUEST, payload);
export const getAIContentCreatorResponse = (data: aiContentCreatorApiTypes.IAIContentChoices) => createAction(actionTypes.AI_CONTENT_CREATOR_RESPONSE, data);
export const getAIContentCreatorFailure = (error: null | string) => createAction(actionTypes.AI_CONTENT_CREATOR_FAILURE, error);

// REPLIES - GET AI REPLY RESPONSE DATA
export const getAIReplyResponseRequest = (payload: aiContentCreatorApiTypes.IRallioAIReplyRequest) => createAction(actionTypes.AI_REPLY_RESPONSE_REQUEST, payload);
export const getAIReplyResponseResponse = (data: aiContentCreatorApiTypes.IAIContentChoices) => createAction(actionTypes.AI_REPLY_RESPONSE_RESPONSE, data);
export const getAIReplyResponseFailure = (error: null | string) => createAction(actionTypes.AI_REPLY_RESPONSE_FAILURE, error);

// CREATOR - GET AI TONE
export const getAIToneRequest = () => createAction(actionTypes.AI_TONE_REQUEST);
export const getAIToneResponse = (data: aiContentCreatorApiTypes.IAIToneResponseObj[]) => createAction(actionTypes.AI_TONE_RESPONSE, data);
export const getAIToneFailure = (error: null | string) => createAction(actionTypes.AI_TONE_FAILURE, error);

// AI POST CONTENT OPEN
export const setAIPostContentOpen = (data: boolean) => createAction(actionTypes.IS_AI_POST_CONTENT_OPEN, data);

// IS AI POST
export const setAIPost = (data: boolean) => createAction(actionTypes.IS_AI_POST, data);

// IS AI POST
export const setAIPostUsed = (data: boolean) => createAction(actionTypes.IS_AI_POST_USED, data);

// RESET STATE
export const resetAIContentCreator = (data?: Partial<aiContentCreatorReducerTypes.IAIContentCreatorReducer>) => createAction(actionTypes.RESET_AI_CONTENT_CREATOR, data);

// RESET AI IMAGE COMPLIANCE WARNING
export const resetAIImageComplianceWarning = () => createAction(actionTypes.RESET_AI_IMAGE_COMPLIANCE_WARNING);

// SET AI INPUT CONTEXT
export const setAIInputContext = (data: null | string) => createAction(actionTypes.SET_AI_INPUT_CONTEXT, data);

// POSTS - CREATE SIMILAR AI POST
export const aiCreateSimilarPostRequest = (payload: aiContentCreatorApiTypes.IAiCreateSimilarPostReq) => createAction(actionTypes.AI_CREATE_SIMILAR_POST_REQUEST, payload);
export const aiCreateSimilarPostResponse = () => createAction(actionTypes.AI_CREATE_SIMILAR_POST_RESPONSE);
export const aiCreateSimilarPostFailure = (error: null | string) => createAction(actionTypes.AI_CREATE_SIMILAR_AI_POST_FAILURE, error);

// CREATOR - CREATE AI SUBSCRIPTION
export const aiCreateSubscriptionPlanRequest = (payload: aiContentCreatorApiTypes.IAIUpdateSubscriptionPlanRequest) => createAction(actionTypes.AI_CREATE_SUBSCRIPTION_PLAN_REQUEST, payload);
export const aiCreateSubscriptionPlanResponse = (data: string) => createAction(actionTypes.AI_CREATE_SUBSCRIPTION_PLAN_RESPONSE, data);
export const aiCreateSubscriptionPlanFailure = (error: null | string) => createAction(actionTypes.AI_CREATE_SUBSCRIPTION_PLAN_FAILURE, error);

// CREATOR - GET AI SUBSCRIPTION
export const aiGetSubscriptionPlanRequest = (payload: aiContentCreatorApiTypes.IAiGetSubscriptionPlanRequest) => createAction(actionTypes.AI_GET_SUBSCRIPTION_PLAN_REQUEST, payload);
export const aiGetSubscriptionPlanResponse = (data: aiContentCreatorApiTypes.IAIGetSubscriptionPlanResponse) => createAction(actionTypes.AI_GET_SUBSCRIPTION_PLAN_RESPONSE, data);
export const aiGetSubscriptionPlanFailure = (error: null | string) => createAction(actionTypes.AI_GET_SUBSCRIPTION_PLAN_FAILURE, error);

// GET AI TAGS LIST
export const getAITagsListRequest = (payload: aiContentCreatorApiTypes.IAITagsListReq) => createAction(actionTypes.GET_AI_TAGS_LIST_REQUEST, payload);
export const getAITagsListResponse = (data: string[]) => createAction(actionTypes.GET_AI_TAGS_LIST_RESPONSE, data);
export const getAITagsListFailure = (error: null | string) => createAction(actionTypes.GET_AI_TAGS_LIST_FAILURE, error);

// SET SPECIFIC STATE
export const setAISubscriptionDetailsModal = (payload: ISubscriptionDetailModal) => createAction(actionTypes.SET_AI_SUBSCRIPTION_DETAILS_MODAL, payload);

export const setAISubscriptionType = (payload: aiContentCreatorReducerTypes.IAISubscriptionTab) => createAction(actionTypes.SET_AI_SUBSCRIPTION_TYPE, payload);

export const aiUpdateSubscriptionPlanRequest = (payload: aiContentCreatorApiTypes.IAIUpdateSubscriptionPlanRequest) => createAction(actionTypes.AI_UPDATE_SUBSCRIPTION_PLAN_REQUEST, payload);
export const aiUpdateSubscriptionPlanResponse = (data: string) => createAction(actionTypes.AI_UPDATE_SUBSCRIPTION_PLAN_RESPONSE, data);
export const aiUpdateSubscriptionPlanFailure = (error: null | string) => createAction(actionTypes.AI_UPDATE_SUBSCRIPTION_PLAN_FAILURE, error);

export const setSubscription = (payload: aiContentCreatorReducerTypes.IAISetSusbscripiton[]) => createAction(actionTypes.SET_SELECTED_SUBSCRIPTION, payload);
export const setSelectedSubscriptionTableData = (payload: { isActive: boolean; data: aiContentCreatorApiTypes.IAIContentCreatorResponse[] }) =>
  createAction(actionTypes.SET_SELECTED_SUBSCRIPTION_TABLE_DATA, payload);
export const setSubscriptionMainPageType = (payload: 'selection' | 'payment') => createAction(actionTypes.SET_SUBSCRIPTION_MAIN_PAGE_TYPE, payload);

export const setCoachMark = (payload: boolean) => createAction(actionTypes.SET_COACHMARK, payload);

export const setCreatorAICaptions = (data: aiContentCreatorReducerTypes.IAICaptions) => createAction(actionTypes.SET_CREATOR_AI_CAPTIONS, data);

// REVIEWS MODEL UPDATE
export const aiModelReviewsLearningRequest = (payload: aiContentCreatorReducerTypes.IAIModelReviewsLearning) => createAction(actionTypes.AI_MODEL_REVIEWS_LEARNING_REQUEST, payload);
export const aiModelReviewsLearningResponse = () => createAction(actionTypes.AI_MODEL_REVIEWS_LEARNING_RESPONSE);
export const aiModelReviewsLearningFailure = (error: null | string) => createAction(actionTypes.AI_MODEL_REVIEWS_LEARNING_FAILURE, error);

// CREATE CAPTIONS LIST
export const aiCreateCaptionRequest = (payload: aiContentCreatorReducerTypes.IAIUpdateCaptionsRequest) => createAction(actionTypes.AI_CREATE_CAPTION_REQUEST, payload);
export const aiCreateCaptionResponse = (data: aiContentCreatorReducerTypes.ICaptionsResponse) => createAction(actionTypes.AI_CREATE_CAPTION_RESPONSE, data);
export const aiCreateCaptionFailure = (error: null | string) => createAction(actionTypes.AI_CREATE_CAPTION_FAILURE, error);

// GET CAPTIONS LIST
export const aiGetCaptionsListRequest = (payload: aiContentCreatorReducerTypes.IAIGetCaptionsRequest) => createAction(actionTypes.AI_GET_CAPTIONS_LIST_REQUEST, payload);
export const aiGetCaptionsListResponse = (data: aiContentCreatorReducerTypes.ICaptionsResponse[]) => createAction(actionTypes.AI_GET_CAPTIONS_LIST_RESPONSE, data);
export const aiGetCaptionsListFailure = (error: null | string) => createAction(actionTypes.AI_GET_CAPTIONS_LIST_FAILURE, error);

// AI - CAPTION VALIDATION
export const aiValidationRequest = (payload: aiContentCreatorApiTypes.IValidateAICaptionReq) => createAction(actionTypes.AI_VALIDATION_REQUEST, payload);
export const aiValidationResponse = (payload: aiContentCreatorApiTypes.IValidateAICaptionRes) => createAction(actionTypes.AI_VALIDATION_RESPONSE, payload);
export const aiValidationFailure = (error: null | string) => createAction(actionTypes.AI_VALIDATION_FAILURE, error);

// AI - UPDATE LIMITATION
export const aiUpdateLimitationRequest = (payload: aiContentCreatorApiTypes.IGetAILimitationReq) => createAction(actionTypes.AI_UPDATE_LIMITATION_REQUEST, payload);
export const aiUpdateLimitationResponse = (payload: aiContentCreatorApiTypes.IAILimitRes) => createAction(actionTypes.AI_UPDATE_LIMITATION_RESPONSE, payload);
export const aiUpdateLimitationFailure = (error: null | string) => createAction(actionTypes.AI_UPDATE_LIMITATION_FAILURE, error);

// SET AI HASHTAGS AND EMOJIS
export const setAIHashtagsAndEmojis = (data: { isHashtags: boolean; isEmojis: boolean }) => createAction(actionTypes.SET_AI_HASHTAGS_AND_EMOJIS, data);

// SET AI PROMPT ACTIVE
export const setAIPromptActive = (data: boolean) => createAction(actionTypes.SET_AI_PROMPT_ACTIVE, data);

// SET AI GENERATED
export const setAIGeneratedOption = (data: aiContentCreatorContainerTypes.IAIGeneratedProps) => createAction(actionTypes.SET_AI_GENERATED_OPTION, data);
export const setAITextContent = (payload: aiContentCreatorReducerTypes.IAITextContent) => createAction(actionTypes.AI_SET_TEXT_CONTENT, payload);

// RESET CAPTION GENERATION
export const resetAICaptionHashtagGeneration = () => createAction(actionTypes.RESET_AI_CAPTION_HASHTAG_GENERATION);

// SET CONTENT SUPPLIER SELECTED PLAN
export const setContentSupplierSelectedPlan = (payload: string) => createAction(actionTypes.SET_CONTENT_SUPPLIER_SELECTED_PLAN, payload);

// ENABLE AI CAPTION VALIDATION
export const enableAICaptionValidation = (payload: boolean) => createAction(actionTypes.ENABLE_AI_CAPTION_VALIDATION, payload);

// SET CS LOCATION SUBSCRIPTION MODAL TYPE
export const setCsLocationSubscriptionModalType = (payload: 'ai_caption' | 'ai_image' | 'ai_community' | 'ai_reputation') => createAction(actionTypes.SET_CS_LOCATION_SUBSCRIPTION_MODAL_TYPE, payload);

// AI - IMAGE URL VALIDATION
export const aiValidationImageUrlsRequest = (payload: aiContentCreatorApiTypes.IAIValidationImgUrlReq) => createAction(actionTypes.AI_VALIDATION_IMAGE_URLS_REQUEST, payload);
export const aiValidationImageUrlsResponse = (payload: aiContentCreatorReducerTypes.IViolatedImages[]) => createAction(actionTypes.AI_VALIDATION_IMAGE_URLS_RESPONSE, payload);
export const aiValidationImageUrlsFailure = (error: null | string) => createAction(actionTypes.AI_VALIDATION_IMAGE_URLS_FAILURE, error);

const AI_CONTENT_CREATOR_ACTIONS = {
  getAIContentCreatorRequest,
  getAIContentCreatorResponse,
  getAIContentCreatorFailure,
  getAIReplyResponseRequest,
  getAIReplyResponseResponse,
  getAIReplyResponseFailure,
  getAIToneRequest,
  getAIToneResponse,
  getAIToneFailure,
  setAIPostContentOpen,
  setAIInputContext,
  aiCreateSimilarPostRequest,
  aiCreateSimilarPostFailure,
  aiCreateSimilarPostResponse,
  getAITagsListRequest,
  getAITagsListResponse,
  getAITagsListFailure,
  aiCreateSubscriptionPlanRequest,
  aiCreateSubscriptionPlanResponse,
  aiCreateSubscriptionPlanFailure,
  aiGetSubscriptionPlanRequest,
  aiGetSubscriptionPlanResponse,
  aiGetSubscriptionPlanFailure,
  aiUpdateSubscriptionPlanRequest,
  aiUpdateSubscriptionPlanResponse,
  aiUpdateSubscriptionPlanFailure,
  setAISubscriptionDetailsModal,
  setAISubscriptionType,
  setSubscription,
  setSelectedSubscriptionTableData,
  setSubscriptionMainPageType,
  resetAIContentCreator,
  resetAIImageComplianceWarning,
  setCoachMark,
  aiModelReviewsLearningRequest,
  aiModelReviewsLearningResponse,
  aiModelReviewsLearningFailure,
  aiCreateCaptionRequest,
  aiCreateCaptionResponse,
  aiCreateCaptionFailure,
  aiGetCaptionsListRequest,
  aiGetCaptionsListResponse,
  aiGetCaptionsListFailure,
  setAIPost,
  setAIPostUsed,
  setCreatorAICaptions,
  setAIHashtagsAndEmojis,
  setAIPromptActive,
  setAIGeneratedOption,
  setAITextContent,
  resetAICaptionHashtagGeneration,
  setContentSupplierSelectedPlan,
  aiValidationRequest,
  aiValidationResponse,
  aiValidationFailure,
  aiUpdateLimitationRequest,
  aiUpdateLimitationResponse,
  aiUpdateLimitationFailure,
  aiValidationImageUrlsRequest,
  aiValidationImageUrlsResponse,
  aiValidationImageUrlsFailure,
  enableAICaptionValidation,
  setCsLocationSubscriptionModalType
};

export type AiContentCreatorActions = ActionsUnion<typeof AI_CONTENT_CREATOR_ACTIONS>;
