import { useContext, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useOnClickOutside from 'use-onclickoutside';

import { IStore } from 'types';
import { addPostCaptionTranslateRequest, addPostGetSelectedContentObj, addPostUpdateSavedPostDetails, addPostSetCreatorOriginalText, setAISubscriptionDetailsModal } from 'actions';
import { EditorStateContext } from 'containers/Content/Creator';
import { convertEditorStateToString, convertStringToEditorState, getValidFBTagPreview, getPostedTextWithFBTagging } from 'utils/helpers';
import { useAccountSwitcherData } from 'utils/hooks';
import {
  ADD_POST_CONTENT_MAX_HASHTAGS_LENGTH,
  ADD_POST_CONTENT_MAX_TEXT_LENGTH,
  COMMON_SOCIAL_PROVIDER_TYPE,
  GET_HASHTAG_ONLY_REGEX,
  IDEAL_LENGTH_OF_SOCIAL_MEDIA_POSTS_BY_PLATFORM,
  LANG_OPTION_LABELS,
  LANG_OPTION_VALUES,
  MG_MEDIA_TYPE,
  STRING_WITH_PHONE_NUMBER,
  TRANSLATE_LANG_OPTIONS,
  USER_OWNERSHIP
} from 'utils/constants';
import { ImageValidation } from 'widgets/Media';

export const TranslateTextSec = () => {
  const dispatch = useDispatch();
  const translateWrapperRef = useRef<any | null>(null);

  const { userOwnership } = useAccountSwitcherData();

  const [textEditorState, setEditorState] = useContext(EditorStateContext);

  const contentObj = useSelector((state: IStore) => state.addPostFranchisor.contentObj);
  const selectedMediaList = useSelector((state: IStore) => state.addPostFranchisor.selectedMediaList);
  const linkData = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj.linkData);
  const savedPostDetails = useSelector((state: IStore) => state.addPostAccount.savedPostDetails);
  const isAISubscribed = useSelector((state: IStore) => state.aiContentCreator.isAISubscribed);
  const translateConfig = useSelector((state: IStore) => state.addPostFranchisorAction.translationConfig);
  const selectedFBTagList = useSelector((state: IStore) => state.addPostFranchisor.selectedFBTagList);

  const [showCountries, setShowCountries] = useState(false);

  const { customText, postStatus, currentTab: brandviewCurrentTab } = contentObj;
  const locationCurrentTab = savedPostDetails?.current_tab;
  const isFranchisor = userOwnership === USER_OWNERSHIP.FRANCHISOR;
  const brandOrLocCurrentTab = isFranchisor ? brandviewCurrentTab ?? '' : locationCurrentTab ?? '';
  const translateLangOptions = [
    { id: 1, label: LANG_OPTION_LABELS.ORIGINAL_TEXT, value: LANG_OPTION_VALUES.ORIGINAL_TEXT, imgName: '' },
    ...TRANSLATE_LANG_OPTIONS.sort((a, b) => a?.label.localeCompare(b?.label))
  ];

  useOnClickOutside(translateWrapperRef, () => setShowCountries(false));

  const handleUpdateSocialMediaText = (socialMediaText: string) => {
    const updatedSocialMediaText = brandviewCurrentTab === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? getPostedTextWithFBTagging(socialMediaText, selectedFBTagList) : socialMediaText;
    setEditorState({ ...textEditorState, [brandOrLocCurrentTab]: convertStringToEditorState(updatedSocialMediaText) });

    const editorGoogleText = new RegExp(STRING_WITH_PHONE_NUMBER).test(isFranchisor ? customText[brandviewCurrentTab] : savedPostDetails?.[`${locationCurrentTab}_text`]);
    const hashTagsLimit: string[] = updatedSocialMediaText.match(GET_HASHTAG_ONLY_REGEX) || [];
    if (userOwnership === USER_OWNERSHIP.FRANCHISOR) {
      dispatch(
        addPostGetSelectedContentObj({
          ...contentObj,
          customText: {
            ...customText,
            [brandviewCurrentTab]: updatedSocialMediaText
          },
          postStatus: {
            ...postStatus,
            [brandviewCurrentTab]:
              contentObj && !updatedSocialMediaText && !selectedMediaList.length && !linkData
                ? false
                : brandviewCurrentTab === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && (!selectedMediaList.length || hashTagsLimit?.length > ADD_POST_CONTENT_MAX_HASHTAGS_LENGTH.INSTAGRAM)
                ? false
                : brandviewCurrentTab === COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST && (!selectedMediaList.length || hashTagsLimit?.length > ADD_POST_CONTENT_MAX_HASHTAGS_LENGTH.PINTEREST)
                ? false
                : brandviewCurrentTab === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN && !updatedSocialMediaText
                ? false
                : IDEAL_LENGTH_OF_SOCIAL_MEDIA_POSTS_BY_PLATFORM.includes(brandviewCurrentTab) && updatedSocialMediaText.length > ADD_POST_CONTENT_MAX_TEXT_LENGTH[brandviewCurrentTab.toUpperCase()]
                ? false
                : brandviewCurrentTab === COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK && selectedMediaList.length && selectedMediaList[0]?.media_type !== MG_MEDIA_TYPE.VIDEO
                ? false
                : brandviewCurrentTab === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE &&
                  (editorGoogleText || contentObj.personalizedText[brandviewCurrentTab].includes('[phone_number]') || contentObj.personalizedText[brandviewCurrentTab].includes('[phone]'))
                ? false
                : updatedSocialMediaText
                ? true
                : postStatus[brandviewCurrentTab]
          }
        })
      );
    } else {
      if (savedPostDetails) {
        const socialMediaKey = locationCurrentTab || COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK;
        dispatch(
          addPostUpdateSavedPostDetails(
            savedPostDetails
              ? {
                  ...savedPostDetails,
                  [`${socialMediaKey}_text`]: updatedSocialMediaText,
                  [`use_${socialMediaKey}`]:
                    savedPostDetails && !socialMediaText && !selectedMediaList.length && !linkData
                      ? false
                      : socialMediaKey === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && (!selectedMediaList.length || hashTagsLimit.length > ADD_POST_CONTENT_MAX_HASHTAGS_LENGTH.INSTAGRAM)
                      ? false
                      : socialMediaKey === COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST && (!selectedMediaList.length || hashTagsLimit.length > ADD_POST_CONTENT_MAX_HASHTAGS_LENGTH.PINTEREST)
                      ? false
                      : socialMediaKey === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN && !updatedSocialMediaText
                      ? false
                      : IDEAL_LENGTH_OF_SOCIAL_MEDIA_POSTS_BY_PLATFORM.includes(socialMediaKey) && updatedSocialMediaText.length > ADD_POST_CONTENT_MAX_TEXT_LENGTH[socialMediaKey.toUpperCase()] // validated char limit for insta, twitter, linkedin, Google
                      ? false
                      : socialMediaKey === COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK && selectedMediaList.length && selectedMediaList[0]?.media_type !== MG_MEDIA_TYPE.VIDEO
                      ? false
                      : socialMediaKey === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE &&
                        (editorGoogleText || savedPostDetails?.[`${socialMediaKey}_text`].includes('[phone_number]') || savedPostDetails?.[`${socialMediaKey}_text`].includes('[phone]'))
                      ? false
                      : updatedSocialMediaText
                      ? true
                      : savedPostDetails[`use_${socialMediaKey}`]
                }
              : null
          )
        );
      }
    }
  };

  const handleTranslate = (lang: string) => {
    setShowCountries(false);
    if (!translateConfig[brandOrLocCurrentTab].isSocialMediaTranslated) {
      dispatch(
        addPostSetCreatorOriginalText({
          socialMedia: brandOrLocCurrentTab,
          text: isFranchisor ? contentObj?.customText[brandviewCurrentTab] : savedPostDetails?.[`${locationCurrentTab}_text`],
          toLanguage: lang,
          fromLanguage: translateConfig[brandOrLocCurrentTab].to_language ?? LANG_OPTION_VALUES.ENGLISH
        })
      );
    }
    if (lang === LANG_OPTION_VALUES.ORIGINAL_TEXT) {
      handleUpdateSocialMediaText(translateConfig[brandOrLocCurrentTab].original_text);
      dispatch(
        addPostSetCreatorOriginalText({
          socialMedia: brandOrLocCurrentTab,
          toLanguage: lang,
          fromLanguage: LANG_OPTION_VALUES.ENGLISH
        })
      );
    } else {
      const caption = convertEditorStateToString(textEditorState[brandOrLocCurrentTab]);
      dispatch(
        addPostCaptionTranslateRequest({
          payload: {
            toLanguage: lang,
            // fromLanguage: translateConfig[brandOrLocCurrentTab].to_language ?? LANG_OPTION_VALUES.ENGLISH,
            caption: brandOrLocCurrentTab === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? getValidFBTagPreview(caption, selectedFBTagList) : caption,
            socialMedia: brandOrLocCurrentTab
          },
          onSuccess: (socialMediaText) => handleUpdateSocialMediaText(socialMediaText)
        })
      );
    }
  };

  const handleShowLanguageOptions = () => {
    if (!isAISubscribed) {
      dispatch(setAISubscriptionDetailsModal({ type: 'trialPeriod', isOpen: true }));
    } else {
      setShowCountries((prev) => !prev);
    }
  };
  return (
    <div className="translateTextWrap" ref={translateWrapperRef}>
      <button onClick={handleShowLanguageOptions}>
        <div className="sign-text">
          <ImageValidation
            isImgValid
            defaultImg={TRANSLATE_LANG_OPTIONS.find((lang) => lang.value === translateConfig[brandOrLocCurrentTab]?.to_language)?.imgName || 'tranSign'}
            customName="translate"
            customClassname="tranSign"
          />
        </div>
      </button>
      <ul>
        {showCountries &&
          translateLangOptions.map((lang, index) => (
            <li
              className={lang.value === LANG_OPTION_VALUES.ORIGINAL_TEXT && !translateConfig[brandOrLocCurrentTab].isSocialMediaTranslated ? 'pointer-events-none' : ''}
              key={`languge-${index}`}
              onClick={() => {
                handleTranslate(lang.value);
              }}
            >
              {lang.imgName && <ImageValidation isImgValid defaultImg={lang.imgName} customName="translate" customClassname="tranSign" />}
              <span>{lang.label}</span>
            </li>
          ))}
      </ul>
    </div>
  );
};
