// FRANCHISORS - GET/UPDATe FRANCHISORS PERMISSION LIST REQUEST
export const FRANCHISOR_PERMISSION_LIST_REQUEST = 'franchisors/FRANCHISOR_PERMISSION_LIST_REQUEST';
export const FRANCHISOR_PERMISSION_LIST_RESPONSE = 'franchisors/FRANCHISOR_PERMISSION_LIST_RESPONSE';
export const FRANCHISOR_PERMISSION_LIST_FAILURE = 'franchisors/FRANCHISOR_PERMISSION_LIST_FAILURE';

// FRANCHISORS - GET OR UPDATE FRANCHISOR
export const FRANCHISOR_GET_OR_UPDATE_PROFILE_REQUEST = 'franchisors/FRANCHISOR_GET_OR_UPDATE_PROFILE_REQUEST';
export const FRANCHISOR_GET_OR_UPDATE_PROFILE_RESPONSE = 'franchisors/FRANCHISOR_GET_OR_UPDATE_PROFILE_RESPONSE';
export const FRANCHISOR_GET_OR_UPDATE_PROFILE_FAILURE = 'franchisors/FRANCHISOR_GET_OR_UPDATE_PROFILE_FAILURE';

export const FRANCHISOR_ERROR = 'franchisors/FRANCHISOR_ERROR';

// FRANCHISORS - SET FRANCHISOR PROFILE RESET
export const FRANCHISOR_PROFILE_RESET = 'franchisors/FRANCHISOR_PROFILE_RESET';
export const FRANCHISOR_PERMISSION_LIST_RESET = 'franchisors/FRANCHISOR_PERMISSION_LIST_RESET';

// GET FRANCHISOR DETAILS - (BASED ON RESPONSE, SHOW CALENDAR BRAND SCHEDULED POST)
export const GET_FRANCHISOR_DETAILS_REQUEST = 'franchisors/GET_FRANCHISOR_DETAILS_REQUEST';
export const GET_FRANCHISOR_DETAILS_RESPONSE = 'franchisors/GET_FRANCHISOR_DETAILS_RESPONSE';
export const GET_FRANCHISOR_DETAILS_FAILURE = 'franchisors/GET_FRANCHISOR_DETAILS_FAILURE';

// GET DIRECT LOCATIONS FOR HUB OR LOC LIST
export const GET_DIRECT_LOCS_FOR_HUB_OR_LOC_LIST_REQUEST = 'franchisors/GET_DIRECT_LOCS_FOR_HUB_OR_LOC_LIST_REQUEST';
export const GET_DIRECT_LOCS_FOR_HUB_OR_LOC_LIST_RESPONSE = 'franchisors/GET_DIRECT_LOCS_FOR_HUB_OR_LOC_LIST_RESPONSE';
export const GET_DIRECT_LOCS_FOR_HUB_OR_LOC_LIST_FAILURE = 'franchisors/GET_DIRECT_LOCS_FOR_HUB_OR_LOC_LIST_FAILURE';

export const SET_FRANCHISOR_SCHEDULE_LOC_TYPE = 'franchisors/SET_FRANCHISOR_SCHEDULE_LOC_TYPE';

export const DELETE_ITEM_IN_LOCATION_TREE = 'franchisors/DELETE_ITEM_IN_LOCATION_TREE';

export const SET_FRAN_SCHEDULE_TREE_STATS = 'franchisors/SET_FRAN_SCHEDULE_TREE_STATS';

export const FRANCHISOR_SCHEDULE_TREE_RESET = 'franchisors/FRANCHISOR_SCHEDULE_TREE_RESET';

// ADD FRANCHISOR SYNDICATION REQUEST
export const ADD_FRANCHISOR_SYNDICATION_REQUEST = 'franchisors/ADD_FRANCHISOR_SYNDICATION_REQUEST';
export const ADD_FRANCHISOR_SYNDICATION_RESPONSE = 'franchisors/ADD_FRANCHISOR_SYNDICATION_RESPONSE';
export const ADD_FRANCHISOR_SYNDICATION_FAILURE = 'franchisors/ADD_FRANCHISOR_SYNDICATION_FAILURE';

// GET FRANCHISOR SYNDICATION REQUEST
export const GET_FRANCHISOR_SYNDICATION_REQUEST = 'franchisors/GET_FRANCHISOR_SYNDICATION_REQUEST';
export const GET_FRANCHISOR_SYNDICATION_RESPONSE = 'franchisors/GET_FRANCHISOR_SYNDICATION_RESPONSE';
export const GET_FRANCHISOR_SYNDICATION_FAILURE = 'franchisors/GET_FRANCHISOR_SYNDICATION_FAILURE';

// SELECT SYNDICATE TO FRANCHISOR LOCATION
export const SELECT_SYNDICATE_TO_FRANCHISOR = 'franchisors/SELECT_SYNDICATE_TO_FRANCHISOR';

// GET DIRECT LOCATIONS FOR HUB OR LOC LIST
export const CONTENT_SUPPLIER_GET_CORP_FRANCHISORS_LIST_REQUEST = 'franchisors/CONTENT_SUPPLIER_GET_CORP_FRANCHISORS_LIST_REQUEST';
export const CONTENT_SUPPLIER_GET_CORP_FRANCHISORS_LIST_RESPONSE = 'franchisors/CONTENT_SUPPLIER_GET_CORP_FRANCHISORS_LIST_RESPONSE';
export const CONTENT_SUPPLIER_GET_CORP_FRANCHISORS_LIST_FAILURE = 'franchisors/CONTENT_SUPPLIER_GET_CORP_FRANCHISORS_LIST_FAILURE';

// GET RANDOM ADS BANNER
export const GET_PARTNER_BANNERS_REQUEST = 'franchisors/GET_PARTNER_BANNERS_REQUEST';
export const GET_PARTNER_BANNERS_RESPONSE = 'franchisors/GET_PARTNER_BANNERS_RESPONSE';
export const GET_PARTNER_BANNERS_FAILURE = 'franchisors/GET_PARTNER_BANNERS_FAILURE';

// RESET - PARTNER BANNER
export const RESET_PARTNER_BANNER = 'franchisors/RESET_PARTNER_BANNER';

// ADD FRANCHISOR MULTI SYNDICATION REQUEST
export const ADD_FRANCHISOR_MULTI_SYNDICATION_REQUEST = 'franchisors/ADD_FRANCHISOR_MULTI_SYNDICATION_REQUEST';

// ADD FRANCHISOR - TAGS MASS UPDATE
export const UPDATE_MULTI_SELECT_TAGS_REQUEST = 'add_post_franchisor/UPDATE_MULTI_SELECT_TAGS_REQUEST';
export const UPDATE_MULTI_SELECT_TAGS_RESPONSE = 'add_post_franchisor/UPDATE_MULTI_SELECT_TAGS_RESPONSE';
export const UPDATE_MULTI_SELECT_TAGS_FAILURE = 'add_post_franchisor/UPDATE_MULTI_SELECT_TAGS_FAILURE';

// ADD FRANCHISOR - GET FRANCHISOR SUBSCRIPTION TYPES
export const GET_FRAN_SUBSCRIPTION_TYPES_REQUEST = 'add_post_franchisor/GET_FRAN_SUBSCRIPTION_TYPES_REQUEST';
export const GET_FRAN_SUBSCRIPTION_TYPES_RESPONSE = 'add_post_franchisor/GET_FRAN_SUBSCRIPTION_TYPES_RESPONSE';
export const GET_FRAN_SUBSCRIPTION_TYPES_FAILURE = 'add_post_franchisor/GET_FRAN_SUBSCRIPTION_TYPES_FAILURE';

// ADD FRANCHISOR - GET SYNDICATION COUNTRIES LIST
export const GET_SYNDICATION_COUNTRIES_LIST_REQUEST = 'franchisors/GET_SYNDICATION_COUNTRIES_LIST_REQUEST';
export const GET_SYNDICATION_COUNTRIES_LIST_RESPONSE = 'franchisors/GET_SYNDICATION_COUNTRIES_LIST_RESPONSE';
export const GET_SYNDICATION_COUNTRIES_LIST_FAILURE = 'franchisors/GET_SYNDICATION_COUNTRIES_LIST_FAILURE';

// ADD FRANCHISOR - GET PREVIOUS SYNDICATION
export const GET_PREVIOUS_SYNDICATION_REQUEST = 'franchisors/GET_PREVIOUS_SYNDICATION_REQUEST';
export const GET_PREVIOUS_SYNDICATION_RESPONSE = 'franchisors/GET_PREVIOUS_SYNDICATION_RESPONSE';
export const GET_PREVIOUS_SYNDICATION_FAILURE = 'franchisors/GET_PREVIOUS_SYNDICATION_FAILURE';

// SELECT SYNDICATE TO FRANCHISOR COUNTRIES
export const SELECT_SYNDICATE_TO_COUNTRIES = 'franchisors/SELECT_SYNDICATE_TO_COUNTRIES';

export const RESET_SYNDICATION_DETAILS = 'franchisors/RESET_SYNDICATION_DETAILS';
