import { takeEvery, put, takeLatest } from 'redux-saga/effects';

import * as types from 'actions/action-types';
import {
  getReviewsListResponse,
  getReviewsListReqFailure,
  exceptionHandlerRequest,
  getReviewsStatsResponse,
  getReviewsStatsReqFailure,
  getReviewsAddReplyToPostResponse,
  getReviewsAddReplyToPostFailure,
  getReviewsCompletePostResponse,
  getReviewsCompletePostFailure,
  getReviewsInternalNotesResponse,
  getReviewsInternalNotesFailure,
  addReviewsInternalNoteResponse,
  addReviewsInternalNoteFailure,
  getReviewsInternalNotesRequest,
  reviewsHandleResponse,
  reviewsHandleFailure,
  reviewsSendForReplyApprovalResponse,
  reviewsSendForReplyApprovalFailure,
  reviewsUpdateSubmittedReplyResponse,
  reviewsUpdateSubmittedReplyFailure,
  reviewsApproveReplyResponse,
  reviewsApproveReplyFailure,
  reviewsHandleRequest,
  reviewsRejectReplyResponse,
  reviewsRejectReplyFailure,
  reviewsDeleteReplyForGoogleResponse,
  reviewsDeleteReplyForGoogleFailure,
  getReviewResPreviewResponse,
  getReviewResPreviewFailure
} from 'actions';
import { apiCall, defaultHeader, API } from 'utils/helpers';
import {
  REVIEWS_EXCEPTION_ERROR,
  REVIEWS_FETCHING_LIST_FAILED,
  REVIEWS_COMPLETE_POST_FAILED,
  USER_OWNERSHIP,
  REVIEWS_FETCHING_STATS_FAILED,
  PAGINATION_PER_PAGE,
  REVIEWS_ADD_REPLY_FAILED,
  REVIEWS_FETCHING_INTERNAL_NOTES_FAILED,
  REVIEWS_ADD_INTERNAL_NOTE_FAILED,
  REVIEWS_HANDLE_FAILED,
  CommonAction,
  REVIEWS_SEND_FOR_REPLY_APPROVAL_FAILED,
  REVIEWS_REJECT_REPLY_FAILED,
  REVIEWS_UPDATE_SUBMITTED_REPLY_FAILED,
  REVIEWS_APPROVE_REPLY_FAILED,
  REVIEWS_DELETE_REPLY_FAILED,
  AI_REVIEWS_PREVIEW_FETCHING_LIST_FAILED
} from 'utils/constants';
import { commonApiTypes, reviewsApiTypes } from 'types';

type ISagaPayload = commonApiTypes.ISagaPayload;

function* sendGetReviewsListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params: reviewsApiTypes.IReviewsSagaReqParams = {
      page: payload.page,
      per: payload?.per || PAGINATION_PER_PAGE,
      date_range: payload.dateRange,
      ...(payload.account_id
        ? { account_id: payload.account_id }
        : payload.userOwnership === USER_OWNERSHIP.FRANCHISOR && !payload.ancestor_franchisor_id
        ? { ancestor_franchisor_id: payload.id }
        : { ancestor_franchisor_id: payload.ancestor_franchisor_id }),
      ...(payload.userOwnership === USER_OWNERSHIP.ACCOUNT && !payload.account_list_id ? { account_id: payload.id } : { account_list_id: payload.account_list_id }),
      network: payload.network,
      ratings: payload.ratings,
      recommended: payload.recommended,
      has_reply: payload.has_reply,
      completed: payload.completed,
      text: payload.searchText
    };
    const response = yield apiCall({ headers, params, ...API.getReviewsList });
    if (response.status === 200) {
      if (payload?.isReviewResponder) {
        if (payload?.onSuccess) {
          payload.onSuccess(response.data?.reviews?.[0] || null);
        }
        yield put(getReviewResPreviewResponse(response.data?.reviews || []));
      } else {
        yield put(getReviewsListResponse(response.data?.reviews || []));
      }
    } else {
      yield put(getReviewsListReqFailure(REVIEWS_FETCHING_LIST_FAILED));
      yield put(getReviewResPreviewFailure(AI_REVIEWS_PREVIEW_FETCHING_LIST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(REVIEWS_EXCEPTION_ERROR));
  }
}

function* sendGetReviewsStatsRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params: reviewsApiTypes.IReviewsSagaReqParams = {
      date_range: payload.dateRange,
      ...(payload.account_id
        ? { account_id: payload.account_id }
        : payload.userOwnership === USER_OWNERSHIP.FRANCHISOR && !payload.ancestor_franchisor_id
        ? { ancestor_franchisor_id: payload.id }
        : { ancestor_franchisor_id: payload.ancestor_franchisor_id }),
      ...(payload.userOwnership === USER_OWNERSHIP.ACCOUNT && !payload.account_list_id ? { account_id: payload.id } : { account_list_id: payload.account_list_id }),
      network: payload.network,
      ratings: payload.ratings,
      recommended: payload.recommended,
      has_reply: payload.has_reply,
      completed: payload.completed,
      text: payload.searchText
    };
    const response = yield apiCall({ headers, params, ...API.getReviewStats });
    if (response.status === 200) {
      yield put(getReviewsStatsResponse(response.data));
    } else {
      yield put(getReviewsStatsReqFailure(REVIEWS_FETCHING_STATS_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(REVIEWS_EXCEPTION_ERROR));
  }
}

function* sendReviewsAddReplyToPostRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let path;
    path = API.sendReplyForPost.apiPath.replace(':id', payload.postId);
    const bodyFormData = new FormData();
    bodyFormData.append('message', payload.customText);
    const response = yield apiCall({ headers, data: bodyFormData, apiPath: path, action: API.sendReplyForPost.action });
    if (response.status === 200) {
      yield put(getReviewsAddReplyToPostResponse(response.data.review));
    } else {
      yield put(getReviewsAddReplyToPostFailure(REVIEWS_ADD_REPLY_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(REVIEWS_EXCEPTION_ERROR));
  }
}

function* sendGetReviewsCompletePostRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let path;
    path = API.completeReviewPost.apiPath.replace(':id', payload.postId);
    const bodyFormData = new FormData();
    bodyFormData.append('review[completed]', payload.isComplete);
    const response = yield apiCall({ headers, data: bodyFormData, apiPath: path, action: API.completeReviewPost.action });
    if (response.status === 200) {
      yield put(getReviewsCompletePostResponse(response.data.review));
    } else {
      yield put(getReviewsCompletePostFailure(REVIEWS_COMPLETE_POST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(REVIEWS_EXCEPTION_ERROR));
  }
}

function* sendGetReviewsInternalNotesRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let path;
    path = API.getReviewsInternalNotes.apiPath.replace(':id', payload.id);
    const response = yield apiCall({ headers, apiPath: path, action: API.getReviewsInternalNotes.action });
    if (response.status === 200) {
      yield put(getReviewsInternalNotesResponse({ ...payload, notes: response.data.review_internal_notes }));
    } else {
      yield put(getReviewsInternalNotesFailure(REVIEWS_FETCHING_INTERNAL_NOTES_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(REVIEWS_EXCEPTION_ERROR));
  }
}

function* sendAddReviewsInternalNoteRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let path;
    path = API.addReviewsInternalNote.apiPath.replace(':id', payload.reviewId);
    const bodyFormData = new FormData();
    bodyFormData.append('review_internal_note[body]', payload.note);
    bodyFormData.append('review_internal_note[review_id]', payload.reviewId);
    bodyFormData.append('review_internal_note[created_at]', payload.createdDate);
    bodyFormData.append('review_internal_note[updated_at]', payload.createdDate);
    const response = yield apiCall({ headers, data: bodyFormData, apiPath: path, action: API.addReviewsInternalNote.action });
    if (response.status === 200) {
      yield put(addReviewsInternalNoteResponse(response.data.review_internal_note));
      yield put(getReviewsInternalNotesRequest({ id: payload.reviewId, actionType: CommonAction.ADD }));
    } else {
      yield put(addReviewsInternalNoteFailure(REVIEWS_ADD_INTERNAL_NOTE_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(REVIEWS_EXCEPTION_ERROR));
  }
}

function* sendReviewsHandleRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let path;
    path = API.handleReviewPost.apiPath.replace(':id', payload.reviewId);
    const bodyFormData = new FormData();
    bodyFormData.append('review[handled]', payload.handled);
    const response = yield apiCall({ headers, data: bodyFormData, apiPath: path, action: API.handleReviewPost.action });
    if (response.status === 200) {
      yield put(reviewsHandleResponse(response.data.review));
    } else {
      yield put(reviewsHandleFailure(REVIEWS_HANDLE_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(REVIEWS_EXCEPTION_ERROR));
  }
}

function* sendReviewsSendForReplyApprovalRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let path;
    path = API.reviewsSendForReplyApproval.apiPath.replace(':id', payload.postId);
    const bodyFormData = new FormData();
    bodyFormData.append('message', payload.customText);
    const response = yield apiCall({ headers, data: bodyFormData, apiPath: path, action: API.reviewsSendForReplyApproval.action });
    if (response.status === 200) {
      yield put(reviewsSendForReplyApprovalResponse(response.data.review));
      yield put(getReviewsInternalNotesRequest({ id: payload.postId, actionType: CommonAction.GET }));
    } else {
      yield put(reviewsSendForReplyApprovalFailure(REVIEWS_SEND_FOR_REPLY_APPROVAL_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(REVIEWS_EXCEPTION_ERROR));
  }
}

function* sendUpdateSubmittedReplyRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let path;
    path = API.updateSubmittedReply.apiPath.replace(':id', payload.postId);
    const bodyFormData = new FormData();
    bodyFormData.append('message', payload.customText);
    const response = yield apiCall({ headers, data: bodyFormData, apiPath: path, action: API.updateSubmittedReply.action });
    if (response.status === 200) {
      yield put(reviewsUpdateSubmittedReplyResponse(response.data.review));
    } else {
      yield put(reviewsUpdateSubmittedReplyFailure(REVIEWS_UPDATE_SUBMITTED_REPLY_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(REVIEWS_EXCEPTION_ERROR));
  }
}

function* sendApproveReplyRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let path;
    path = API.approveReply.apiPath.replace(':id', payload.postId);
    const bodyFormData = new FormData();
    bodyFormData.append('message', payload.customText);
    bodyFormData.append('is_approval', `${true}`);
    const response = yield apiCall({ headers, data: bodyFormData, apiPath: path, action: API.approveReply.action });
    if (response.status === 200) {
      yield put(reviewsApproveReplyResponse(response.data.review));
      yield put(getReviewsInternalNotesRequest({ id: payload.postId, actionType: CommonAction.GET }));
      yield put(reviewsHandleRequest({ reviewId: payload.postId, handled: true }));
    } else {
      yield put(reviewsApproveReplyFailure(REVIEWS_APPROVE_REPLY_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(REVIEWS_EXCEPTION_ERROR));
  }
}

function* sendRejectReplyRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let path;
    path = API.rejectReply.apiPath.replace(':id', payload.postId);
    const bodyFormData = new FormData();
    bodyFormData.append('message', payload.customText);
    const response = yield apiCall({ headers, data: bodyFormData, apiPath: path, action: API.rejectReply.action });
    if (response.status === 200) {
      yield put(reviewsRejectReplyResponse(response.data.review));
      yield put(getReviewsInternalNotesRequest({ id: payload.postId, actionType: CommonAction.GET }));
    } else {
      yield put(reviewsRejectReplyFailure(REVIEWS_REJECT_REPLY_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(REVIEWS_EXCEPTION_ERROR));
  }
}

function* sendDeleteReplyForGoogleRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const path = API.deleteReply.apiPath.replace(':id', payload);
    const response = yield apiCall({ headers, apiPath: path, action: API.deleteReply.action });
    if (response.status === 200) {
      yield put(reviewsDeleteReplyForGoogleResponse(response.data.review));
    } else {
      yield put(reviewsDeleteReplyForGoogleFailure(REVIEWS_DELETE_REPLY_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(REVIEWS_EXCEPTION_ERROR));
  }
}

export function* takeReviewsRequest() {
  yield takeEvery(types.REVIEWS_GET_LIST_REQUEST, sendGetReviewsListRequest);
  yield takeEvery(types.REVIEWS_GET_STATS_REQUEST, sendGetReviewsStatsRequest);
  yield takeEvery(types.REVIEWS_ADD_REPLY_TO_A_POST_REQUEST, sendReviewsAddReplyToPostRequest);
  yield takeEvery(types.REVIEWS_COMPLETE_POST_REQUEST, sendGetReviewsCompletePostRequest);
  yield takeEvery(types.REVIEWS_GET_INTERNAL_NOTES_REQUEST, sendGetReviewsInternalNotesRequest);
  yield takeEvery(types.REVIEWS_ADD_INTERNAL_NOTE_REQUEST, sendAddReviewsInternalNoteRequest);
  yield takeLatest(types.REVIEWS_HANDLE_POST_REQUEST, sendReviewsHandleRequest);
  yield takeLatest(types.REVIEWS_SEND_FOR_REPLY_APPROVAL_REQUEST, sendReviewsSendForReplyApprovalRequest);
  yield takeLatest(types.REVIEWS_UPDATE_SUBMITTED_REPLY_REQUEST, sendUpdateSubmittedReplyRequest);
  yield takeLatest(types.REVIEWS_APPROVE_REPLY_REQUEST, sendApproveReplyRequest);
  yield takeLatest(types.REVIEWS_REJECT_REPLY_REQUEST, sendRejectReplyRequest);
  yield takeLatest(types.REVIEWS_DELETE_REPLY_FOR_GOOGLE_REQUEST, sendDeleteReplyForGoogleRequest);
}
