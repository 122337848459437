import { put, takeLatest } from '@redux-saga/core/effects';
import {
  customerCreateFailure,
  customerCreateResponse,
  customerDeleteFailure,
  customerDeleteResponse,
  customerDetailRequestFailure,
  customerDetailResponse,
  customerListRequestFailure,
  customerListResponse,
  customerMetricsRequestFailure,
  customerMetricsResponse,
  customerUpdateFailure,
  customerUpdateResponse,
  customerUploadRequestFailure,
  customerUplodadResponse
} from 'revv/actions';
import * as actionTypes from 'revv/actions/action-types';
import {
  ISagaPayload,
  ICustomerUpdateRequestParams,
  SagaYield,
  SagaValue,
  ICustomerMetrics,
  ICustomerListResponse,
  IListRequestParams,
  IUploadParams,
  ICustomerLocation,
  IIdParam,
  ICustomerDeleteRequestParams
} from 'revv/types';
import { API_ROUTES } from 'revv/utils';
import { API_TYPES } from 'utils/constants';
import { apiCall, defaultRevvHeader } from 'utils/helpers';

function* loadCustomerMetrics(action: ISagaPayload<IListRequestParams>): Generator<SagaYield, void, SagaValue> {
  try {
    const response = yield apiCall({
      apiPath: API_ROUTES.customerMetrics.apiPath,
      action: API_ROUTES.customerMetrics.method,
      headers: defaultRevvHeader(),
      type: API_TYPES.REVV,
      data: {
        rallio_franchisor_ids: action.payload?.rallioFranchisorId,
        rallio_account_ids: action.payload?.rallioLocationId,
        rallio_brand_id: action.payload?.rallioBrandId,
        location_ids: action.payload?.locationIds,
        from: action.payload?.from,
        to: action.payload?.to
      }
    });
    if (response.status === 200 || response.status === 304) {
      const result: ICustomerMetrics = {
        customersCount: response.data.data.customers_count,
        averageRating: response.data.data.average_rating,
        optInsSentCount: response.data.data.opt_in_sent,
        optInsCompletedCount: response.data.data.opt_in_completed,
        surveySentCount: response.data.data.sent_count,
        surveyCompletedCount: response.data.data.completed_count,
        surveyCreatedCount: response.data.data.total_visits_count,
        npsScore: response.data.data.nps_score
      };
      yield put(customerMetricsResponse(result));
    } else {
      yield put(customerMetricsRequestFailure('Customer metrics request failure.'));
    }
  } catch (error: any) {
    yield put(customerMetricsRequestFailure(error));
  }
}

function* loadCustomerList(action: ISagaPayload<IListRequestParams>): Generator<SagaYield, void, SagaValue> {
  try {
    const response = yield apiCall({
      apiPath: API_ROUTES.customers.apiPath,
      action: API_ROUTES.customers.method,
      params: {
        'filter[client_id]': action.payload?.clientId || '',
        'filter[keyword]': action.payload?.searchKeyword || '',
        'page[size]': action.payload?.size || 10,
        'page[number]': action.payload?.pageNumber || 1,
        sort: action.payload?.sorting || 'first_name'
      },
      data: {
        rallio_franchisor_ids: action.payload?.rallioFranchisorId,
        rallio_account_ids: action.payload?.rallioLocationId,
        rallio_brand_id: action.payload?.rallioBrandId,
        location_ids: action.payload?.locationIds,
        from: action.payload?.from,
        to: action.payload?.to
      },
      headers: defaultRevvHeader(),
      type: API_TYPES.REVV
    });
    if (response.status === 200 || response.status === 304) {
      const result: ICustomerListResponse[] = response.data.data?.map((it: any) => ({
        id: it.id,
        firstName: it.attributes.first_name || '',
        lastName: it.attributes.last_name || '',
        averageRating: it.attributes.average_rating || 0,
        nps_score: it.attributes.nps_score,
        surveysCompletedCount: it.attributes.completed_count || 0,
        surveysCreatedCount: it.attributes.created_count || 0,
        surveysSentCount: it.attributes.sent_count || 0,
        email: it.attributes.email || '',
        phoneNumber: it.attributes.phone_number || ''
      }));
      yield put(customerListResponse(result));
    } else {
      yield put(customerListRequestFailure('Customer list API failure.'));
    }
  } catch (error: any) {
    yield put(customerListRequestFailure(error));
  }
}

function* customerDetailedViewRequest(action: ISagaPayload<IIdParam>): Generator<SagaYield, void, SagaValue> {
  try {
    if (action.payload) {
      const response = yield apiCall({
        apiPath: API_ROUTES.customerDetail.apiPath.replace(':id', `${action.payload.id}`),
        action: API_ROUTES.customerDetail.method,
        headers: defaultRevvHeader(),
        type: API_TYPES.REVV
      });

      if (response.data?.data) {
        const customerSurveyList = response.data.included && response.data.included.length ? response.data.included.filter((it: any) => it.type === 'surveys') : [];
        const customerLocation: ICustomerLocation[] =
          customerSurveyList && customerSurveyList.length > 0
            ? customerSurveyList.map((it: any) => {
                return {
                  id: it?.attributes.location_id,
                  name: it?.attributes.location_name
                };
              })
            : [];

        const responseData = {
          id: response.data.data?.id,
          firstName: response.data.data?.attributes?.first_name,
          lastName: response.data.data?.attributes?.last_name,
          phoneNumber: response.data.data?.attributes?.phone_number,
          email: response.data.data?.attributes?.email,
          receiveSms: response.data.data?.attributes?.receive_sms || false,
          receiveEmail: response.data.data?.attributes?.receive_email || false,
          totalVisitsCount: response.data.data?.attributes?.surveys_created_count,
          surveysSentCount: response.data.data?.attributes?.surveys_sent_count,
          surveysCompletedCount: response.data.data?.attributes?.surveys_completed_count,
          npsValue: response.data.data?.attributes?.nps,
          avgNpsValue: response.data.data?.average_nps,
          avgSurveyRating: response.data.data?.average_survey_rating,
          locationDetail: customerLocation.filter((it, index) => customerLocation.findIndex((n) => n.id === it.id) === index)
        };
        yield put(customerDetailResponse(responseData));
      }
    }
  } catch (error: any) {
    yield put(customerDetailRequestFailure(error));
  }
}

function* customerDeleteApiRequest(action: ISagaPayload<ICustomerDeleteRequestParams>): Generator<SagaYield, void, SagaValue> {
  try {
    if (action.payload?.id) {
      const response = yield apiCall({
        apiPath: API_ROUTES.customerDelete.apiPath.replace(':id', `${action.payload?.id}`),
        action: API_ROUTES.customerDelete.method,
        headers: defaultRevvHeader(),
        type: API_TYPES.REVV
      });
      if (response.status === 204) {
        yield put(customerDeleteResponse());
        if (action.payload.callback) {
          action.payload.callback();
        }
      } else {
        yield put(customerDeleteFailure(response.data.error));
      }
    }
  } catch (error: any) {
    yield put(customerDeleteFailure(error));
  }
}

function* updateCustomerRequest(action: ISagaPayload<ICustomerUpdateRequestParams>): Generator<SagaYield, void, SagaValue> {
  try {
    const dataObj = {
      id: action.payload?.id || undefined,
      type: 'customers',
      attributes: {
        email: action.payload?.email,
        first_name: action.payload?.firstName,
        last_name: action.payload?.lastName,
        location_id: !action.payload?.id ? action.payload?.locationId : null,
        phone_number: action.payload?.phoneNumber,
        receive_sms: action.payload?.receiveSMS,
        receive_email: action.payload?.receiveEmail,
        send_survey: action.payload?.sendSurveyNow,
        surveys_completed_count: action.payload?.surveyCompletedCount || 0,
        surveys_sent_count: action.payload?.surveySentCount || 0,
        surveys_created_count: action.payload?.surveyCreatedCount || 0,
        nps: action.payload?.npsValue,
        average_nps: action.payload?.avgNpsValue || null,
        average_survey_rating: action.payload?.avgSurveyRating || 0
      },
      relationships: action.payload?.isRelationShipDisabled
        ? undefined
        : {
            locations: {
              data: [{ type: 'locations', id: action.payload?.locationId }]
            }
          }
    };
    const response = yield apiCall({
      apiPath: action.payload?.id ? API_ROUTES.updateCustomer.apiPath.replace(':id', `${action.payload?.id}`) : API_ROUTES.createCustomer.apiPath,
      action: action.payload?.id ? API_ROUTES.updateCustomer.method : API_ROUTES.createCustomer.method,
      data: { data: dataObj },
      headers: defaultRevvHeader(),
      type: API_TYPES.REVV
    });
    if (response.status === 201 || response.status === 200) {
      if (action.payload?.id) {
        yield put(customerUpdateResponse('The Survey  was successfully sent.'));
      } else {
        yield put(customerCreateResponse());
      }
      if (action.payload?.callback) {
        action.payload.callback({ id: response.data.data.id });
      }
    } else {
      if (action.payload?.id) {
        yield put(customerUpdateFailure(response.data.errors ? `${response.data.errors[0].title || ''} ${response.data.errors[0].detail || ''}` : 'Error Occured'));
      } else {
        yield put(customerCreateFailure(response.data.errors ? `${response.data.errors[0].title || ''} ${response.data.errors[0].detail || ''}` : 'Error Occured'));
      }
    }
  } catch (error: any) {
    if (action.payload?.id) {
      yield put(customerUpdateFailure(error));
    } else {
      yield put(customerCreateFailure(error));
    }
  }
}

function* uploadCustomers(action: ISagaPayload<IUploadParams>): Generator<SagaYield, void, SagaValue> {
  try {
    if (action.payload) {
      const fileData = new FormData();
      fileData.append('file', action.payload?.data);
      if (action.payload?.data) {
        const response = yield apiCall({
          apiPath: API_ROUTES.uploadCustomers.apiPath,
          action: API_ROUTES.uploadCustomers.method,
          headers: defaultRevvHeader(),
          type: API_TYPES.REVV,
          data: fileData,
          fileUpload: true
        });
        if (response.status === 204) {
          yield put(customerUplodadResponse('You will receive an email once your request has been processed.'));
        } else {
          yield put(customerUploadRequestFailure('There was a problem uploading your file'));
        }
      }
    } else {
      yield put(customerUploadRequestFailure('File not selected.'));
    }
  } catch (error: any) {
    yield put(customerUploadRequestFailure(error));
  }
}

export function* takeCustomersRequest() {
  yield takeLatest(actionTypes.CUSTOMER_METRICS_REQUEST, loadCustomerMetrics);
  yield takeLatest(actionTypes.CUSTOMER_LIST_REQUEST, loadCustomerList);
  yield takeLatest(actionTypes.CUSTOMER_DETAIL_REQUEST, customerDetailedViewRequest);
  yield takeLatest(actionTypes.CUSTOMER_DELETE_REQUEST, customerDeleteApiRequest);
  yield takeLatest(actionTypes.CUSTOMER_CREATE_REQUEST, updateCustomerRequest);
  yield takeLatest(actionTypes.CUSTOMER_DETAILS_UPDATE_REQUEST, updateCustomerRequest);
  yield takeLatest(actionTypes.CUSTOMER_UPLOAD_REQUEST, uploadCustomers);
}
