import { connectionApiTypes } from 'types';

export const CN_STATS_SUMMARY_INIT_OBJ: connectionApiTypes.IConnectionStatsSummary = {
  total_count: 0,
  total_count_with_platform: 0,
  total_count_with_problem: 0,
  total_count_with_no_platform: 0,
  facebook_connected_count: 0,
  facebook_connected_count_with_problem: 0,
  facebook_not_connected_count: 0,
  twitter_connected_count: 0,
  twitter_connected_count_with_problem: 0,
  twitter_not_connected_count: 0,
  linkedin_connected_count: 0,
  linkedin_connected_count_with_problem: 0,
  linkedin_not_connected_count: 0,
  google_plus_connected_count: 0,
  google_plus_connected_count_with_problem: 0,
  google_plus_not_connected_count: 0,
  google_places_connected_count: 0,
  google_places_connected_count_with_problem: 0,
  google_places_not_connected_count: 0,
  google_connected_count: 0,
  google_connected_count_with_problem: 0,
  google_not_connected_count: 0,
  yelp_connected_count: 0,
  yelp_connected_count_with_problem: 0,
  yelp_not_connected_count: 0,
  instagram_connected_count: 0,
  instagram_connected_count_with_problem: 0,
  instagram_not_connected_count: 0,
  instagram_business_page_connected_count: 0,
  instagram_business_page_connected_count_with_problem: 0,
  instagram_business_page_not_connected_count: 0,
  tiktok_connected_count: 0,
  tiktok_connected_count_with_problem: 0,
  tiktok_not_connected_count: 0,
  pinterest_connected_count: 0,
  pinterest_connected_count_with_problem: 0,
  pinterest_not_connected_count: 0
};
