import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { IStore } from 'types';
import { getDiscountedDaysFromToday } from 'utils/helpers';
import { AI_SUBSCRIPTION_PLAN_NAMES, DISCOUNT_PRICE_OFFER_MSG, RIPPLE_COLOR, USER_OWNERSHIP } from 'utils/constants';
import { useAccountSwitcherData } from 'utils/hooks';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { ImageValidation } from 'widgets/Media';
import { setAISubscriptionDetailsModal, setCsLocationSubscriptionModalType } from 'actions';

export const CSBanner = () => {
  const dispatch = useDispatch();

  const { id, userOwnership, currentRoute } = useAccountSwitcherData();

  const menuAccess = useSelector((state: IStore) => state.accountSwitcher.menu_access);
  const accSubscriptionDetails = useSelector(
    (state: IStore) => state.accounts.accountDetails?.account?.active_subscription_details?.[0] || state.accounts.accountDetails?.account?.subscription_details?.[0]
  );

  const isFreeTier = [AI_SUBSCRIPTION_PLAN_NAMES.RC_FREE].includes(accSubscriptionDetails?.franchisor_subscription_type?.plan_name);

  const discountDays = getDiscountedDaysFromToday(accSubscriptionDetails?.created_at || '');
  const isCSLocationOfferAvailable = isFreeTier && discountDays >= 1 && discountDays <= 7;

  const [openBanner, setOpenBanner] = useState(false);

  const showBanner = useMemo(() => {
    if (id && userOwnership && menuAccess) {
      const restrictions = menuAccess?.site_restrictions;
      if (userOwnership === USER_OWNERSHIP.FRANCHISOR && restrictions?.hub_user) {
        return restrictions.hub_user.includes('show_banner');
      } else if (userOwnership === USER_OWNERSHIP.ACCOUNT && restrictions?.location_user) {
        return restrictions.location_user.includes('show_banner') || isFreeTier || isCSLocationOfferAvailable;
      }
    }
    return false;
  }, [id, userOwnership, menuAccess, isFreeTier, isCSLocationOfferAvailable]);

  useEffect(() => {
    setOpenBanner(showBanner);
  }, [showBanner]);

  return openBanner && isFreeTier && isCSLocationOfferAvailable && !['community/inbox', 'reputation/reviews'].includes(currentRoute) ? (
    <div className={`ap-alert-main rc sdUpgrades ${!isFreeTier ? '' : 'freeTier-alert-on'}`}>
      <div className="api-alert-box alert-facebook">
        <div className="w-100 bg-white d-flex justify-end align-items-center">
          <p>
            {DISCOUNT_PRICE_OFFER_MSG.MSG1} <b>{DISCOUNT_PRICE_OFFER_MSG.MSG2}</b> {DISCOUNT_PRICE_OFFER_MSG.MSG3}
          </p>
          <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
            <button
              className={`ac-btn ac-primary ac-block kc-save`}
              type="submit"
              onClick={() => {
                dispatch(setAISubscriptionDetailsModal({ type: 'CSLocationAISubscription', isOpen: true }));
                dispatch(setCsLocationSubscriptionModalType('ai_community'));
              }}
            >
              Upgrade
            </button>
          </CustomRippleButton>
        </div>
        {!isFreeTier && (
          <div className="alert-icon ai-x">
            <ImageValidation isImgValid defaultImg="alert-xmark" customClassname="close" customName="close" onClick={() => setOpenBanner(false)} />
          </div>
        )}
      </div>
    </div>
  ) : null;
};
