import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { menuAccessApiTypes, operationsListsApiTypes, operationsListsReducerTypes } from 'types';

export const setViewMode = (payload: { viewMode: string }) => createAction(actionTypes.SET_VIEW_MODE, payload);

export const getLocationsHubListRequest = (payload: operationsListsApiTypes.IGetLocationHubListReq) => createAction(actionTypes.GET_LOCATIONS_HUBS_LIST_REQUEST, payload);
export const getLocationsHubListResponse = (payload: operationsListsApiTypes.IOperationsLocationHubListResponse) => createAction(actionTypes.GET_LOCATIONS_HUBS_LIST_RESPONSE, payload);
export const getLocationsHubListFailure = (error: null | string) => createAction(actionTypes.GET_LOCATIONS_HUBS_LIST_FAILURE, error);

export const updateLocationsHubListRequest = (payload: operationsListsApiTypes.IDataResponse) => createAction(actionTypes.UPDATE_LOCATIONS_HUBS_LIST_REQUEST, payload);
export const updateLocationsHubListResponse = (payload: operationsListsApiTypes.IDataResponse) => createAction(actionTypes.UPDATE_LOCATIONS_HUBS_LIST_RESPONSE, payload);
export const updateLocationsHubListFailure = (error: null | string) => createAction(actionTypes.UPDATE_LOCATIONS_HUBS_LIST_FAILURE, error);

export const subscriptionsLocationToListRequest = (payload: operationsListsApiTypes.ISubscriptionLocationListReq) => createAction(actionTypes.SUBSCRIPTIONS_LOCATION_TO_LIST_REQUEST, payload);
export const subscriptionsLocationToListResponse = (payload: operationsListsApiTypes.IDataResponse) => createAction(actionTypes.SUBSCRIPTIONS_LOCATION_TO_LIST_RESPONSE, payload);
export const subscriptionsLocationToListFailure = (error: null | string) => createAction(actionTypes.SUBSCRIPTIONS_LOCATION_TO_LIST_FAILURE, error);

export const reloadList = () => createAction(actionTypes.RELOAD_LIST);

export const getLocationsTreeRequest = (payload: { franchisor_id: null | number }) => createAction(actionTypes.GET_LOCATIONS_TREE_REQUEST, payload);
export const getLocationsTreeResponse = (payload: operationsListsApiTypes.ILocationHubTreeDateResponse) => createAction(actionTypes.GET_LOCATIONS_TREE_RESPONSE, payload);
export const getLocationsTreeFailure = (error: null | string) => createAction(actionTypes.GET_LOCATIONS_TREE_FAILURE, error);

export const getLocationHubDetailRequest = (payload: { selectedObj: operationsListsReducerTypes.ILocationHub }) => createAction(actionTypes.GET_LOCATION_HUB_DETAIL_REQUEST, payload);
export const getLocationHubDetailResponse = (payload: { data: operationsListsReducerTypes.ILocationHubDetail | null }) => createAction(actionTypes.GET_LOCATION_HUB_DETAIL_RESPONSE, payload);
export const getLocationHubDetailFailure = (error: null | string) => createAction(actionTypes.GET_LOCATION_HUB_DETAIL_FAILURE, error);

export const onChangeList = (payload: { key: string; value: any }) => createAction(actionTypes.ON_CHANGE_LIST, payload);

export const saveLocationGroupRequest = (payload: operationsListsApiTypes.ISaveLocationGroupReqObj) => createAction(actionTypes.SAVE_LOCATION_LIST_REQUEST, payload);
export const saveLocationGroupResponse = () => createAction(actionTypes.SAVE_LOCATION_LIST_RESPONSE);
export const saveLocationGroupFailure = (error: null | string) => createAction(actionTypes.SAVE_LOCATION_LIST_FAILURE, error);

export const deleteLocationListRequest = (payload: { id: number }) => createAction(actionTypes.DELETE_LOCATION_LIST_REQUEST, payload);
export const deleteLocationListResponse = () => createAction(actionTypes.DELETE_LOCATION_LIST_RESPONSE);
export const deleteLocationListFailure = (error: null | string) => createAction(actionTypes.DELETE_LOCATION_LIST_FAILURE, error);

export const operationListsDetailViewError = (error: null | string) => createAction(actionTypes.OPERATION_LISTS_DETAIL_VIEW_ERROR, error);

export const operationListsResetAll = () => createAction(actionTypes.OPERATION_LISTS_RESET_ALL);

// OPERATION LIST  - RESET MODIFIED OBJ
export const operationListsResetModifiedObj = () => createAction(actionTypes.OPERATION_LISTS_RESET_MODIFIED_OBJ);

// CONTENT FEED - GET CS FRANCHISOR LIST
export const contentFeedGetCSFranchisorsListRequest = (payload: { id: number }) => createAction(actionTypes.CONTENT_FEED_GET_CS_FRANCHISORS_LIST_REQUEST, payload);
export const contentFeedGetCSFranchisorsListResponse = (response: menuAccessApiTypes.IFranchisorSyndication[]) => createAction(actionTypes.CONTENT_FEED_GET_CS_FRANCHISORS_LIST_RESPONSE, response);
export const contentFeedGetCSFranchisorsListFailure = (error: null | string) => createAction(actionTypes.CONTENT_FEED_GET_CS_FRANCHISORS_LIST_FAILURE, error);

// CONTENT FEED -  GET HUB LIST
export const contentFeedGetHubListResponse = (payload: operationsListsReducerTypes.ISchedulerContentSupplier[]) => createAction(actionTypes.CONTENT_FEED_GET_HUB_LIST_RESPONSE, payload);

// CONTENT FEED - GET TAGS LIST
export const contentFeedAddTags = (payload: string[]) => createAction(actionTypes.CONTENT_FEED_ADD_TAGS, payload);
export const contentFeedResetTags = () => createAction(actionTypes.CONTENT_FEED_RESET_TAGS);

// CONTENT SUPPLIER - GET ELIGIBLE CONTENTS COUNT
export const getEligibleContentsCountRequest = (payload: { account_list: operationsListsApiTypes.IGetEligibleContentsCountReq }) =>
  createAction(actionTypes.GET_ELIGIBLE_CONTENT_COUNT_REQUEST, payload);
export const getEligibleContentsCountResponse = (payload: operationsListsReducerTypes.IEligibleContentCounts) => createAction(actionTypes.GET_ELIGIBLE_CONTENT_COUNT_RESPONSE, payload);
export const getEligibleContentsCountFailure = (error: null | string) => createAction(actionTypes.GET_ELIGIBLE_CONTENT_COUNT_FAILURE, error);

export const modifyLocationsHubListResponse = (payload: operationsListsApiTypes.IDataResponse[]) => createAction(actionTypes.MODIFY_LOCATIONS_HUBS_LIST_RESPONSE, payload);

const TEAM_MANAGEMENT_LISTS_ACTIONS = {
  setViewMode,
  getLocationsHubListRequest,
  getLocationsHubListResponse,
  getLocationsHubListFailure,
  updateLocationsHubListRequest,
  updateLocationsHubListResponse,
  updateLocationsHubListFailure,
  subscriptionsLocationToListRequest,
  subscriptionsLocationToListResponse,
  subscriptionsLocationToListFailure,
  reloadList,
  getLocationsTreeRequest,
  getLocationsTreeResponse,
  getLocationsTreeFailure,
  getLocationHubDetailRequest,
  getLocationHubDetailResponse,
  getLocationHubDetailFailure,
  onChangeList,
  saveLocationGroupRequest,
  saveLocationGroupResponse,
  saveLocationGroupFailure,
  deleteLocationListRequest,
  deleteLocationListResponse,
  deleteLocationListFailure,
  operationListsDetailViewError,
  operationListsResetAll,
  contentFeedGetCSFranchisorsListRequest,
  contentFeedGetCSFranchisorsListResponse,
  contentFeedGetCSFranchisorsListFailure,
  contentFeedGetHubListResponse,
  contentFeedAddTags,
  contentFeedResetTags,
  operationListsResetModifiedObj,
  getEligibleContentsCountRequest,
  getEligibleContentsCountResponse,
  getEligibleContentsCountFailure,
  modifyLocationsHubListResponse
};

export type OperationsListsAction = ActionsUnion<typeof TEAM_MANAGEMENT_LISTS_ACTIONS>;
