import { Paragraph } from 'widgets/Text';
import { apFranContainerTypes } from 'types';
import { SocialMediaPreviewCommentSec, SocialMediaPreviewFooterIcons } from 'containers/Content/Creator/CommonModal';
import { CREATOR_INSTA_LEFT_PREVIEW_FOOTER_ICONS, CREATOR_INSTA_RIGHT_PREVIEW_FOOTER_ICONS, MG_MEDIA_TYPE, SettingsHashtagsActionsVal } from 'utils/constants';
import { CustomSwiper, ImageValidation, ReactVideoPlayer } from 'widgets/Media';

export const InstagramPostPreview = ({
  socialMedia,
  hashtagState,
  accSocialMedia,
  imagesLimitBasedOnPlatform,
  selectedMediaList,
  playVideo,
  setPlayVideo,
  isSocialMediaPostEnabled,
  caption,
  userName,
  profilePic
}: apFranContainerTypes.ISocialMediaInstagramPostPreview) => {
  return (
    <div className="insta-pn-preview">
      <div className="p-header">
        <div className="p-header-title">
          <div className="p-avatar">
            <ImageValidation isImgValid customName={'Location Profile Item'} imgUrl={profilePic} defaultImg="user-avatar-common.png" isNotSvgFormat />
          </div>
          <div className="p-title">
            <div className="insta-un-nts">
              <h5>{userName}</h5>
              {/* <ImageValidation isImgValid defaultImg={'insta-dot'} customClassname="insta-n-dot" customName={''} /> */}
              <span>Just Now</span>
            </div>
            {/* <span className="insta-tag">Original audio</span> */}
          </div>
        </div>
        <div className="p-header-menu">
          <div className="insta-dot">
            <ImageValidation isImgValid defaultImg="insta-more" customName="More" />
          </div>
        </div>
      </div>
      <div className="p-body previewtLinks p-0">
        <div className="am-media-preview pmg">
          {selectedMediaList.length ? (
            selectedMediaList[0]?.media_type === MG_MEDIA_TYPE.PHOTO ? (
              imagesLimitBasedOnPlatform?.length > 1 ? (
                <CustomSwiper imagesList={imagesLimitBasedOnPlatform} isVirtualSlider />
              ) : (
                <div
                  className="cpp-wrap cpp-img doWrap do-img"
                  data-orientation="loading"
                  ref={(el) => {
                    if (el && imagesLimitBasedOnPlatform[0]) {
                      const img = new Image();
                      img.src = imagesLimitBasedOnPlatform[0];
                      img.onload = () => el.setAttribute('data-orientation', img.naturalHeight > img.naturalWidth ? 'portrait' : 'landscape');
                    }
                  }}
                >
                  <ImageValidation imgUrl={imagesLimitBasedOnPlatform[0]} customName={'Image asset'} />
                </div>
              )
            ) : (
              <div
                className="video-outer-element doWrap do-video"
                data-orientation="loading"
                ref={(el) => {
                  if (el && selectedMediaList[0]?.medium_size_url) {
                    const video = document.createElement('video');
                    video.src = selectedMediaList[0]?.medium_size_url || '';
                    video.onloadedmetadata = () => {
                      el.setAttribute('data-orientation', video.videoHeight > video.videoWidth ? 'portrait' : 'landscape');
                    };
                  }
                }}
              >
                <ReactVideoPlayer
                  videoUrl={selectedMediaList[0]?.medium_size_url || ''}
                  customClassname={'video'}
                  isPlayControl={playVideo.includes(socialMedia)}
                  controls={false}
                  onClick={() => {
                    if (isSocialMediaPostEnabled) {
                      setPlayVideo([...playVideo, socialMedia]);
                    }
                  }}
                  light={selectedMediaList[0]?.thumbnail_url}
                />
              </div>
            )
          ) : null}
        </div>
      </div>

      <div className="insta-social-sec-wrp">
        <div className="lefticon">
          <SocialMediaPreviewFooterIcons socialMedia={`${socialMedia}-left`} footerIconsList={CREATOR_INSTA_LEFT_PREVIEW_FOOTER_ICONS} />
        </div>

        <div className="righticon">
          <SocialMediaPreviewFooterIcons socialMedia={`${socialMedia}-right`} footerIconsList={CREATOR_INSTA_RIGHT_PREVIEW_FOOTER_ICONS} />
        </div>
      </div>

      <div className="pre-footer">
        <span className="pf-like-counts">
          Be the first to <strong>like this</strong>
        </span>
        <div className="msg-wrapper insta-i-description">
          <Paragraph customText={`${userName ? `${userName}: ` : ''}${caption || ''}`} customClassname="insta-text-main bold-username" data-username={userName} />
        </div>
        {/* <span className="insta-vc">View 1 comment</span> */}
      </div>

      {hashtagState && hashtagState.action_status === SettingsHashtagsActionsVal.IN_COMMENT && hashtagState.saved_hashtags?.length ? (
        <SocialMediaPreviewCommentSec socialMedia={socialMedia} hashtagState={hashtagState} accSocialMedia={accSocialMedia ?? []} />
      ) : null}
    </div>
  );
};
