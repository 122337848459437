import { useSelector } from 'react-redux';

import { addPostSetSelectedMediaList, getAITagsListRequest, selectedMediaListSort, videoCompatibilityReset } from 'actions';
import { IStore, apFranContainerTypes, commonWidgetTypes } from 'types';
import { creatorPhotosCount, getBrandMediaCloudinaryIdsForAI, getBrandMediaTypeForAI, handleLinkNavigation } from 'utils/helpers';
import { AP_ADD_TO_POST_TAB_NAMES, COMMON_SOCIAL_PROVIDER_TYPE, IReactDNDItemTypes, MG_MEDIA_TYPE, POST_IMAGES_LIMITATION, TOOLTIP_PLACEMENT } from 'utils/constants';
import { ImageValidation, Video } from 'widgets/Media';
import { CustomTooltip } from 'widgets/Tooltip';
import { ReactDND } from 'widgets/DragAndDrop';

export const LeftCreatorMediaList = ({ socialMedia, contentObj, setModalName, isShowAiImageTab, dispatch }: apFranContainerTypes.ILeftCreatorMediaListProps) => {
  const selectedMediaList = useSelector((state: IStore) => state.addPostFranchisor.selectedMediaList);
  const splitSelectedMediaList = selectedMediaList.slice(
    0,
    socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER
      ? POST_IMAGES_LIMITATION.TWITTER
      : socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN
      ? POST_IMAGES_LIMITATION.LINKEDIN
      : socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE
      ? POST_IMAGES_LIMITATION.GOOGLE
      : socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST
      ? POST_IMAGES_LIMITATION.PINTEREST
      : selectedMediaList.length
  );
  const handleDeleteUploadedMedia = (mediaUrl: string, mediaType: string) => {
    if (mediaUrl) {
      const totalMediaUrls = selectedMediaList.filter((datum) => datum.url !== mediaUrl);
      dispatch(addPostSetSelectedMediaList(totalMediaUrls));
      if (isShowAiImageTab) {
        if (totalMediaUrls?.length) dispatch(getAITagsListRequest({ cloudinaryId: getBrandMediaCloudinaryIdsForAI(totalMediaUrls), type: getBrandMediaTypeForAI(totalMediaUrls) }));
      }
      if (mediaType === MG_MEDIA_TYPE.VIDEO) {
        dispatch(videoCompatibilityReset());
      }
    }
  };

  const handleSelectedMedialistPosition = (data: commonWidgetTypes.IReactDNDSortData) => {
    dispatch(selectedMediaListSort(data));
  };

  const renderImage = (mediumSizeUrl: null | string, url: null | string) => {
    return (
      <div className="img-thumb">
        <ImageValidation imgUrl={mediumSizeUrl} customName={'creator-media'} onClick={() => handleLinkNavigation(url)} />
      </div>
    );
  };

  const renderVideo = (socialMedia: string, thumbnailUrl: string, mediumSizeUrl: string) => {
    return (
      <div className={`video-outer-element-left-side${contentObj && !contentObj.postStatus[`${socialMedia}`] ? ` button-opacity` : ``}`}>
        <Video videoUrl={mediumSizeUrl} customClassname={'video'} light={thumbnailUrl} />
      </div>
    );
  };

  return selectedMediaList.length && (socialMedia !== COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM || selectedMediaList[0]?.media_type === MG_MEDIA_TYPE.VIDEO) ? (
    <>
      {splitSelectedMediaList?.length > 1 && socialMedia !== COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK && <p className="d-block  mb-3">Drag and drop to rearrange the order</p>}
      <div className={`image-roll-holder ctmb ct-main`}>
        {splitSelectedMediaList.map((mediaDatum, index) => {
          return mediaDatum.media_type === MG_MEDIA_TYPE.PHOTO && socialMedia !== COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK ? (
            <ReactDND key={`selected-medias-list-dnd-${index}`} index={index} type={IReactDNDItemTypes.CARD} data={mediaDatum} onSortData={(data) => handleSelectedMedialistPosition(data)}>
              <div className={`ctvm ct-img${contentObj && !contentObj.postStatus[`${socialMedia}`] ? ` button-opacity` : ``}`} key={`creator-img-media-${index}`}>
                {mediaDatum.comments ? (
                  <CustomTooltip
                    customPlacement={TOOLTIP_PLACEMENT.TOP}
                    customTooltipText={mediaDatum.comments}
                    customClassname={'custom-tooltip-long-text'}
                    customInput={() => renderImage(mediaDatum.medium_size_url, mediaDatum.url)}
                  />
                ) : (
                  renderImage(mediaDatum.medium_size_url, mediaDatum.url)
                )}
                {!contentObj?.isPostLock ? (
                  <div className="ctm-controls">
                    <div className="ctmc-item ctmc-delete" onClick={() => handleDeleteUploadedMedia(mediaDatum?.url, MG_MEDIA_TYPE.PHOTO)}>
                      <ImageValidation isImgValid defaultImg="delete-bright-red" customName="Delete" />
                    </div>
                  </div>
                ) : null}
              </div>
            </ReactDND>
          ) : (
            <div className="ctvm ct-video" key={`creator-brand-video-media-${index}`}>
              {mediaDatum.comments && mediaDatum.media_type === MG_MEDIA_TYPE.VIDEO ? (
                <CustomTooltip
                  customPlacement={TOOLTIP_PLACEMENT.TOP}
                  customTooltipText={mediaDatum.comments}
                  customClassname={'custom-tooltip-long-text'}
                  customInput={() => renderVideo(socialMedia, mediaDatum.thumbnail_url || '', mediaDatum.medium_size_url || '')}
                />
              ) : mediaDatum.media_type === MG_MEDIA_TYPE.VIDEO ? (
                renderVideo(socialMedia, mediaDatum.thumbnail_url || '', mediaDatum.medium_size_url || '')
              ) : null}
              {!contentObj?.isPostLock ? (
                <div className="ctm-controls">
                  <div className="ctmc-item ctmc-delete" onClick={() => handleDeleteUploadedMedia(mediaDatum?.url, MG_MEDIA_TYPE.VIDEO)}>
                    <ImageValidation isImgValid defaultImg="delete-bright-red" customName="Delete" />
                  </div>
                </div>
              ) : null}
            </div>
          );
        })}
        {selectedMediaList[selectedMediaList.length - 1].media_type === MG_MEDIA_TYPE.PHOTO && socialMedia !== COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK && !contentObj?.isPostLock && (
          <div className={`add-img-btn${creatorPhotosCount(selectedMediaList) >= 500 ? ` pointer-events-none` : ''}`} onClick={() => setModalName(AP_ADD_TO_POST_TAB_NAMES.PHOTOS)}>
            <div>
              <ImageValidation isImgValid defaultImg="add-images" customName={'Add photos'} />
            </div>
            <span>Add Images</span>
          </div>
        )}
      </div>
    </>
  ) : null;
};
