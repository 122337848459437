import { Modal, ModalBody, ModalFooter } from 'react-bootstrap';

import { commonModalPopup, mgApiTypes } from 'types';
import { endDateChecked } from 'utils/helpers';
import { RIPPLE_COLOR } from 'utils/constants';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { ImageValidation } from 'widgets/Media';

export const ViolatedImagesWarningModal = ({
  isShowModal,
  onModalClose,
  selectedMediaList,
  summaryUpdatedAt
}: commonModalPopup.ICommonModalProps & { selectedMediaList: mgApiTypes.IMGListResponseObj[]; summaryUpdatedAt: string }) => {
  const violatedImages = selectedMediaList?.length
    ? selectedMediaList.filter((media) => media.ai_compliance_warning && summaryUpdatedAt && media.ai_compliance_validated_at && endDateChecked(summaryUpdatedAt, media.ai_compliance_validated_at))
    : [];
  return (
    <Modal show={isShowModal} className="cnfrm__delete--modal glbl__delete--modal glbl__unsave--modal icw-main" onHide={onModalClose}>
      <div className="mod__close--icon">
        <ImageValidation isImgValid defaultImg="mpcIcon" customClassname="close-icon close mod__close--img wh-30" customName={'close'} onClick={onModalClose} />
      </div>
      <ModalBody>
        <div className="ic-wrap">
          <h2 className="ic-head">Image Compliance</h2>
          {violatedImages?.length ? (
            <div className="icg-grid img-comp-grid">
              {violatedImages.map((image, index: number) => {
                return (
                  <div key={`img-compliance-item-${index}`} className={`iComp-item`}>
                    <div className="icg-img-holder">
                      <ImageValidation isImgValid defaultImg="warning" customClassname="icg-warning" customName={'Image Compliance Warning'} />
                      <div className="icg-wrap">
                        <ImageValidation isNotSvgFormat imgUrl={image.medium_size_url || image.url} customName="media-assets" customClassname="m-ast-itm m-ast-img" />
                      </div>
                    </div>
                    <p>{image.ai_compliance_warning}</p>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="delete__btn--wrp">
          <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
            <button className={`ac-btn ac-primary ac-block`} onClick={onModalClose}>
              Done
            </button>
          </CustomRippleButton>
        </div>
      </ModalFooter>
    </Modal>
  );
};
