import React, { useCallback, useMemo, useState } from 'react';

import { VirtualTable } from 'components';
import { ITableHeader, postsContainerTypes } from 'types';
import { dateFormatterForPostDetail, generatePostSyndicationTableData } from 'utils/helpers';
import { POST_SYNDICATE_TABLE_HEADER, POST_SYNDICATE_TABLE_IDENTIFIER } from 'utils/constants';
import { SyndicationDetailModal } from '../Modals';

export const PostSyndicationDetails = React.memo(({ syndicationDetails, lastSyndicateDate }: postsContainerTypes.IPostSyndicationDetailsProps) => {
  const [selectedSyndication, setSelectedSyndication] = useState<{ selectedSyndication: postsContainerTypes.IPostSyndicationTableData | null; isCountryOpen: boolean }>({
    selectedSyndication: null,
    isCountryOpen: false
  });
  const [syndicationDetailModalOpen, setSyndicationDetailModalOpen] = useState(false);

  const syndicationTableData = generatePostSyndicationTableData(syndicationDetails, dateFormatterForPostDetail(lastSyndicateDate));

  const manageSyndicationDetailModalAndData = (isOpen: boolean, selectedSyndication: postsContainerTypes.IPostSyndicationTableData | null, isCountryOpen: boolean) => {
    setSelectedSyndication({ selectedSyndication, isCountryOpen });
    setSyndicationDetailModalOpen(isOpen);
  };

  const renderHeader = useCallback((headerObj: ITableHeader<postsContainerTypes.IPostSyndicationTableData>) => {
    switch (headerObj.identifier) {
      default:
        return <div>{headerObj.labelString}</div>;
    }
  }, []);

  const renderColumn = useCallback((rowObj: postsContainerTypes.IPostSyndicationTableData, header: ITableHeader<postsContainerTypes.IPostSyndicationTableData>) => {
    switch (header.identifier) {
      case POST_SYNDICATE_TABLE_IDENTIFIER.WHO:
        return (
          <div className="pWho">
            {rowObj?.syndicated_brands?.length ? rowObj?.syndicated_brands[0] : 'All'}{' '}
            {rowObj?.syndicated_brands?.length > 1 ? (
              <span className="pBlue" onClick={() => manageSyndicationDetailModalAndData(true, rowObj, false)}>{`${rowObj?.syndicated_brands.length - 1} More`}</span>
            ) : (
              ''
            )}
          </div>
        );
      case POST_SYNDICATE_TABLE_IDENTIFIER.WHERE:
        // return <div>{rowObj?.country_codes?.length ? rowObj?.country_codes.join(', ') : 'All'}</div>;
        return (
          <div className="pWho">
            {rowObj?.country_codes?.length ? rowObj?.country_codes[0] : 'All'}{' '}
            {rowObj?.country_codes?.length > 1 ? (
              <span className="pBlue" onClick={() => manageSyndicationDetailModalAndData(true, rowObj, true)}>{`${rowObj?.country_codes.length - 1} More`}</span>
            ) : (
              ''
            )}
          </div>
        );
      default:
        return <div>{rowObj[header.identifier as keyof postsContainerTypes.IPostSyndicationTableData]}</div>;
    }
  }, []);

  const tableHeader = useMemo(() => {
    return POST_SYNDICATE_TABLE_HEADER.map(
      (it): ITableHeader<postsContainerTypes.IPostSyndicationTableData> => ({
        identifier: `${it.identifier}`,
        labelString: `${it.labelString}`,
        headerClassName: `${it.headerClassName}`,
        label: <div>{it.labelString}</div>,
        renderHeader,
        renderColumn
      })
    );
  }, [renderColumn, renderHeader]);

  return (
    <div className="st-tc-item pd-fields pd-items pdi-postEdit pst-td">
      <div className="wb-top post__white--box bradius-lt-rt-none">
        <div className="pd-field-group">
          <div className="wb-label">
            <div className="wbl-head">Syndicate to</div>
          </div>
          <div className="wb-f-main">
            <div className="wb-fields">
              <div className="wb-fields bExp smExpiration pa-se-datesWrap">
                <div className="wbf-item">
                  <div id="postSyndicateTableTarget">
                    <VirtualTable<postsContainerTypes.IPostSyndicationTableData>
                      data={[syndicationTableData]}
                      headers={tableHeader}
                      tableClassName="responsiveTable table-cmn--skeleton"
                      ipadBoundary={767}
                      ipadColCount={2}
                      mobileColCount={2}
                      pinColumnsRight={[4]}
                      isFetching={false}
                      scrollableTargetString={'postSyndicateTableTarget'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {selectedSyndication && (
            <SyndicationDetailModal isShowModal={syndicationDetailModalOpen} data={selectedSyndication} onModalClose={() => manageSyndicationDetailModalAndData(false, null, false)} />
          )}
        </div>
      </div>
    </div>
  );
});
