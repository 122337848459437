import { Accordion, Button, Form } from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import Ripples from 'react-ripples';
import { useEffect, useState } from 'react';

import { ModalPopup } from 'revv/components';
import { config } from 'config';
import { IHubLocationList } from 'revv/types';
import { ILocationOptions } from 'analytics/types';
import { INITIAL_LOCATION_FILTER_DATA } from 'utils';

interface ILocationFilterModal {
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  handleChange: any;
  isModalOpen: boolean;
  initialObj: ILocationOptions;
  filter: { [key: string]: any };
}

export const RevvLocationFilterModal = ({ setModalState, handleChange, initialObj, isModalOpen, filter }: ILocationFilterModal) => {
  const [searchValue, setSearchValue] = useState('');
  const [clickedLocations, setClickedLocations] = useState<number[]>([]);

  useEffect(() => {
    setClickedLocations(filter?.selectedLocation);
  }, [filter?.selectedLocation]);

  const [options, setOptions] = useState(initialObj);
  const locationFilterHandler = () => {
    setModalState(false);
    const optionId = options?.id ? +options?.id : 0;
    // console.log('intial obj', initialObj, options, clickedLocations, id);
    // if (initialObj !== options && id) {
    handleChange({
      locationFilterObj: options,
      multipleLocationList: clickedLocations,
      ...{ ancestorFranchisorId: 0, locationId: optionId, locationListId: 0 }
    });
    // }
  };

  const handleModalClose = () => {
    setModalState((prevState) => !prevState);
    setClickedLocations(filter?.selectedLocation);
  };

  return (
    <ModalPopup
      show={isModalOpen}
      centered
      className="location-filter-dialog"
      onHide={handleModalClose}
      renderHeader={() => (
        <div className="non-psp-loc-popup">
          <h3>Location Selector </h3>
          <div className="non-psp-loc-search">
            <Form.Control size="lg" type="text" placeholder="Search" className="non-psp-search-input w-100" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
            <img src={`${config.cdnRevvImgUrl}/filter/search-icon.svg`} alt="search" />
          </div>
        </div>
      )}
      renderBody={() => (
        <div>
          <div
            className={`${clickedLocations.length === 0 ? 'active' : ''} cur-pointer all-selection`}
            onClick={() => {
              setOptions(INITIAL_LOCATION_FILTER_DATA);
              setClickedLocations([]);
            }}
          >
            All Locations
          </div>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>{`Locations  ${clickedLocations.length > 0 ? `(${clickedLocations.length} Location Selected)` : ''}`}</Accordion.Header>
              <Accordion.Body className="overflow-hidden">
                <Scrollbars autoHeight>
                  <ul>
                    {filter.locations && filter.locations?.length > 0 ? (
                      filter.locations
                        ?.filter((it: IHubLocationList) => it.name.toLowerCase().indexOf(searchValue.toLowerCase().trim()) > -1)
                        .map((it: IHubLocationList, index: number) => (
                          <li
                            key={`locationsOption${index}`}
                            className={`cur-pointer${clickedLocations.includes(it.id) ? ' active' : ''}`}
                            onClick={() => {
                              // let changedLocations = [...clickedLocations];
                              // if (changedLocations.includes(it.id)) {
                              //   changedLocations = changedLocations.filter((cl) => cl !== it.id);
                              // } else {
                              //   changedLocations.push(it.id);
                              // }
                              // console.log('clicked', changedLocations);
                              setClickedLocations([it.id]);
                            }}
                          >
                            <img
                              src={clickedLocations.includes(it.id) ? `${config.cdnRevvImgUrl}/filter/location-blue-active.svg` : `${config.cdnRevvImgUrl}/filter/location-grey.svg`}
                              alt="location Icon"
                            />
                            <span className={`lbl ${clickedLocations.includes(it.id) ? 'active' : ''}`}>{it.name}</span>
                          </li>
                        ))
                    ) : (
                      <li>
                        <span>No Locations found.</span>
                      </li>
                    )}
                  </ul>
                </Scrollbars>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )}
      renderFooter={() => (
        <div className="non-psp-foot-btn">
          <Ripples>
            <Button
              variant="outline-primary"
              size="lg"
              className="non-psp-ac-btn non-psp-ac-cancel"
              onClick={() => {
                setModalState((prevState) => !prevState);
                setClickedLocations(filter?.selectedLocation);
              }}
            >
              Cancel
            </Button>
          </Ripples>
          <Ripples>
            <Button size="lg" className="non-psp-ac-btn non-psp-ac-ok " onClick={locationFilterHandler}>
              Ok
            </Button>
          </Ripples>
        </div>
      )}
    />
  );
};
