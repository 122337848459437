import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';

import { IStore, settingsSocialProfilContainerTypes } from 'types';
import { COMMON_SOCIAL_PROVIDER_TYPE, CONNECTION_TABS, RIPPLE_COLOR, USER_OWNERSHIP } from 'utils/constants';
import { useAccountSwitcherData } from 'utils/hooks';
import { handleLinkNavigation, disconnectSocialMedia } from 'utils/helpers';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { ImageValidation } from 'widgets/Media';
import { useSocialMedia } from './useSocialMedia';
import { config } from 'config';

export const SocialProfilePinterest = ({ renderLabel, openPopup, pinterestReconnect }: settingsSocialProfilContainerTypes.ISettingsSocialProfilePinterest) => {
  const { id, userOwnership, optionalParams } = useAccountSwitcherData();
  const dispatch = useDispatch();

  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails);
  const apiToken = useSelector((state: IStore) => state.login.activeUser?.token);

  const pinterestFilterData = useSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST);

  const [showModal, setShowModal] = useState(false);

  const accountId = id && userOwnership === USER_OWNERSHIP.ACCOUNT ? id : accountDetails?.account?.id || 0;

  return (
    <>
      {optionalParams[2] === CONNECTION_TABS.SOCIAL_PROFILES || accountDetails?.account_profiles[0]?.access.platform_pinterest_access ? (
        <div className="csp-item d-flex justify-content-between cspTiktok">
          <aside className="d-flex align-items-center social-media__wrp">
            <div className="smn">
              <div className="sp-img">
                <ImageValidation isImgValid defaultImg="pinterest" customName="social logo" />
              </div>
              <span className="sp-txt">Pinterest</span>
            </div>
            <div className="sm-added-details" />
          </aside>
          {pinterestFilterData ? (
            <>
              {showModal ? (
                <div className="disconnect-notification-wrp">
                  <div className="left-item">
                    <div>
                      <ImageValidation isImgValid defaultImg="connection-social-profile" customName="more" />
                    </div>
                    <span className="are-u-sure-text">Are you sure?</span>
                  </div>
                  <div className="right-item">
                    <div className="csp-action">
                      <CustomRippleButton rippleClass="ripple-unset ac-secondary-box clr" custColor={RIPPLE_COLOR.whiteGrey}>
                        <button className="ac-btn ac-secondary-white  size-xs cl" onClick={() => setShowModal(false)}>
                          Cancel
                        </button>
                      </CustomRippleButton>
                      <CustomRippleButton rippleClass={`ripple-unset ac-danger-box`} custColor={RIPPLE_COLOR.danger}>
                        <button
                          className={`ac-btn ac-danger`}
                          onClick={() => {
                            setShowModal(false);
                            disconnectSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST, pinterestFilterData?.id, COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST, accountId, dispatch);
                          }}
                        >
                          {pinterestFilterData?.needs_reconnection ? 'Remove' : 'Disconnect Now'}
                        </button>
                      </CustomRippleButton>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="csp-action g-15">
                  <Button
                    className="ac-btn ac-link-btn primeblueaction"
                    variant="link"
                    onClick={() => {
                      handleLinkNavigation(pinterestFilterData.profile_url);
                    }}
                  >
                    View page
                  </Button>
                  {pinterestFilterData?.needs_reconnection ? (
                    <CustomRippleButton rippleClass={`ripple-unset ac-danger-box r-mr2`} custColor={RIPPLE_COLOR.danger}>
                      <button
                        className="ac-btn ac-icon-btn ac-danger h-default r-flx r-flx-jc r-flx-ac"
                        onClick={() => {
                          pinterestReconnect.current = true;
                          openPopup(COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST);
                        }}
                      >
                        <ImageValidation isImgValid defaultImg="reconnect" customClassname="reconnect" customName="Reconnect" />
                        <span>{renderLabel(COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST, 'light', 'Reconnect')}</span>
                      </button>
                    </CustomRippleButton>
                  ) : pinterestFilterData?.needs_updated_credentials ? (
                    <CustomRippleButton rippleClass={`ripple-unset ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
                      <button className="ac-btn ac-primary h-default r-flx r-flx-jc r-flx-ac" onClick={() => openPopup(COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST)}>
                        {renderLabel(COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST, 'light', 'Update Credentials')}
                      </button>
                    </CustomRippleButton>
                  ) : null}
                  <CustomRippleButton rippleClass={`ripple-unset ac-secondary-box`} custColor={RIPPLE_COLOR.whiteGrey}>
                    <button className="ac-btn ac-secondary-grey h-default r-flx r-flx-jc r-flx-ac" onClick={() => setShowModal(true)}>
                      {renderLabel(COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST, 'primary', pinterestFilterData?.needs_reconnection ? 'Remove' : 'Disconnect')}
                    </button>
                  </CustomRippleButton>
                </div>
              )}
            </>
          ) : (
            <div className="csp-action">
              <CustomRippleButton rippleClass={`ripple-unset ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
                {/* <form action={`${config.apiBaseURL}users/auth/pinterest`} method="post">
                  <input value={apiToken} type="hidden" name="access_token" />
                  <input value="new_rallio_account_social_profiles" type="hidden" name="redirect_controller" />
                  <input value={accountId} type="hidden" name="account_id" />
                  <input type="submit" name="commit" value="Pinterest" />
                </form> */}
                <a
                  className="ac-btn ac-primary h-default r-flx r-flx-jc r-flx-ac"
                  href={`${config.apiBaseURL}users/auth/pinterest?redirect_controller=new_rallio_account_social_profiles&account_id=${accountId}&access_token=${apiToken}`}
                >
                  Connect Now
                </a>
              </CustomRippleButton>
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};
