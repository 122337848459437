import { takeEvery, put, delay, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

import * as types from 'actions/action-types';
import {
  MG_EXCEPTION_ERROR,
  MG_FETCHING_ASSETS_STATS_FAILED,
  USER_OWNERSHIP,
  MG_FETCHING_FAV_TAGS_FAILED,
  MG_FETCHING_ASSETS_LIST_FAILED,
  MG_UPLOAD_MEDIA_IN_CLOUDINARY_FAILED,
  MG_SOURCE_TYPE,
  MG_ADD_MEDIA_ASSET_FAILURE_MSG,
  MG_DELETE_ASSET_SUCCESS_MSG,
  MG_DELETE_ASSET_FAILURE_MSG,
  SAGAS_DELAY_SECONDS,
  MG_LIKE_FAILED,
  MG_DISLIKE_FAILED,
  MG_DETAIL_DATA_FAILED,
  MG_UPDATE_DETAIL_DATA_FAILED,
  MG_MEDIA_TYPE,
  MgMediaType,
  MG_CLONE_ASSET_FAILED_MSG,
  MAX_CHUNK_SIZE,
  ALERT_BOX_TYPES,
  MG_PHOTO_VIDEO,
  UNICODE_REGEX,
  MediaDropPages,
  MG_UPLOAD_MEDIA_FAILED,
  API_TYPES
} from 'utils/constants';
import {
  logoutRequest,
  exceptionHandlerRequest,
  getMGListResponse,
  getMGListRequestFailure,
  mgTagsResponse,
  mgTagsRequestFailure,
  mgUploadCloudMediaResponse,
  mgUploadCloudMediaReqFailure,
  mgSaveAssetResponse,
  mgSaveAssetFailure,
  mgDeleteAssetResponse,
  mgDeleteAssetFailure,
  mgMediaReleaseResponse,
  mgMediaReleaseRequestFailure,
  mgLikeResponse,
  mgLikeRequestFailure,
  mgDislikeResponse,
  mgDislikeRequestFailure,
  mgUpdateFavTagsResponse,
  mgUpdateFavTagsRequestFailure,
  mgGetFavTagsResponse,
  mgGetFavTagsRequestFailure,
  mgDetailDataResponse,
  mgDetailDataRequestFailure,
  mgUpdateDetailDataResponse,
  mgUpdateDetailDataRequestFailure,
  getMGFilterStatsResponse,
  getMGFilterStatsFailure,
  uploadWatermarkImageToCloudResponse,
  getWatermarkImageResponse,
  getWatermarkImageRequest,
  saveWatermarkImageResponse,
  addPostSetSelectedMediaList,
  mgCloneAssetResponse,
  mgCloneAssetFailure,
  mgDetailDataRequest,
  mgValidUploadedCloudMediaList,
  mgAddedAssetDetailReset,
  getMGListRequest,
  mgAIImageUploadingRequest,
  mgAddCustomizeVideoThumbnailResponse,
  getMultiSelectMGListResponse
} from 'actions';
import { apiCall, defaultHeader, API, cloudinaryHeader, CLOUDINARY_API, getMGFavTagsList, awsHeader, s3Response } from 'utils/helpers';
import { ISagaPayload } from 'types/common/api';
import { mgApiTypes, accountSwitcherApiTypes } from 'types';
import { store } from 'store';
import { config } from 'config';
import { alertBoxCall } from 'components';

function* sendGetMGFilterStatsRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params: mgApiTypes.IMGListRequest = payload;
    let response;

    if (payload.source === MG_SOURCE_TYPE.BRAND) params.copy_to_descendants = 1;

    const { media_type, ...newParams } = params;
    const APIObj = payload.media_type === MgMediaType.PHOTOS ? API.mgGetPhotosAssetsStats : payload.media_type === MgMediaType.VIDEOS ? API.mgGetVideosAssetsStats : API.mgGetDocumentsAssetsStats;
    response = yield apiCall({ headers, params: newParams, ...APIObj });

    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(
        getMGFilterStatsResponse(
          payload.media_type === MgMediaType.PHOTOS ? response.data.photos_stats : payload.media_type === MgMediaType.VIDEOS ? response.data.videos_stats : response.data.documents_stats
        )
      );
    } else {
      yield put(getMGFilterStatsFailure(MG_FETCHING_ASSETS_STATS_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(MG_EXCEPTION_ERROR));
  }
}

function* sendGetMGListsRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params: mgApiTypes.IMGListRequest = payload;

    const { media_type, isMultiSelect, ...newParams } = params;
    const APIObj = payload.media_type === MgMediaType.PHOTOS ? API.mgGetPhotosAssetsList : payload.media_type === MgMediaType.VIDEOS ? API.mgGetVideosAssetsList : API.getAIPlaybookDocumentsList;
    const response = yield apiCall({ headers, params: newParams, ...APIObj });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      if (payload?.isMultiSelect) {
        yield put(
          getMultiSelectMGListResponse({
            page: params.page || 0,
            media_type: payload.media_type === MgMediaType.PHOTOS ? MG_MEDIA_TYPE.PHOTO : payload.media_type === MgMediaType.VIDEOS ? MG_MEDIA_TYPE.VIDEO : MG_MEDIA_TYPE.RAW,
            assets: response && response.data && response.data.photos ? response.data.photos : response.data.videos ? response.data.videos : response.data.documents
          })
        );
      } else {
        yield put(
          getMGListResponse({
            page: params.page || 0,
            media_type: payload.media_type === MgMediaType.PHOTOS ? MG_MEDIA_TYPE.PHOTO : payload.media_type === MgMediaType.VIDEOS ? MG_MEDIA_TYPE.VIDEO : MG_MEDIA_TYPE.RAW,
            assets: response && response.data && response.data.photos ? response.data.photos : response.data.videos ? response.data.videos : response.data.documents
          })
        );
      }
    } else {
      yield put(getMGListRequestFailure(MG_FETCHING_ASSETS_LIST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(MG_EXCEPTION_ERROR));
  }
}

function* sendMGUploadCloudMediaRequest({ payload }: ISagaPayload): any {
  const { file, signature, apiKey, timestamp, eager, fileName, uploadProgressCallback, successCallback, order, from, isMediaRelease, videoCloudinaryId } = payload;

  try {
    const resourceType = '/auto';
    const headers = cloudinaryHeader();
    const url = `${config.cloudinary.baseUrl}${config.cloudinary.name}${resourceType}${CLOUDINARY_API.cloudinaryImageUpload.apiPath}`;

    if (typeof file !== 'string' && file?.type.includes('video')) {
      const noOfChunks = Math.ceil(file.size / MAX_CHUNK_SIZE);
      headers['X-Unique-Upload-Id'] = `${fileName.replace(UNICODE_REGEX, '')}${new Date().getTime()}`;

      for (const [index] of [...Array(noOfChunks)].entries()) {
        const start = MAX_CHUNK_SIZE * Number(index);
        const end = MAX_CHUNK_SIZE * Number(index) + MAX_CHUNK_SIZE;

        const chunk = file.slice(start, end);
        headers['Content-Range'] = `bytes ${start}-${end > file.size ? file.size - 1 : end - 1}/${file.size}`;

        const bodyFormData = new FormData();
        bodyFormData.append('api_key', apiKey);
        bodyFormData.append('file', chunk);
        bodyFormData.append('signature', signature);
        bodyFormData.append('timestamp', timestamp);
        bodyFormData.append('eager', eager);

        const requestConfig: any = {
          url,
          method: CLOUDINARY_API.cloudinaryImageUpload.action,
          data: bodyFormData,
          headers,
          timeout: 0,
          validateStatus(status: number) {
            return status >= 200 && status <= 500;
          },
          onUploadProgress: (progressEvent: ProgressEvent) => {
            if (uploadProgressCallback) {
              uploadProgressCallback(progressEvent, Number(index), noOfChunks);
            }
          }
        };
        const response = yield axios(requestConfig);

        if (response.data.public_id) {
          if (successCallback) {
            successCallback(response.data);
          }
          if (!isMediaRelease) {
            yield put(mgUploadCloudMediaResponse({ cloudinaryResponse: response.data, fileName, order }));
          }
        }
      }
    } else {
      const bodyFormData = new FormData();
      bodyFormData.append('api_key', apiKey);
      bodyFormData.append('file', file);
      bodyFormData.append('signature', signature);
      bodyFormData.append('timestamp', timestamp);
      bodyFormData.append('eager', eager);

      const requestConfig: any = {
        url,
        method: CLOUDINARY_API.cloudinaryImageUpload.action,
        data: bodyFormData,
        headers,
        timeout: 0,
        validateStatus(status: number) {
          return status >= 200 && status <= 500;
        },
        onUploadProgress: (progressEvent: ProgressEvent) => {
          if (uploadProgressCallback) {
            uploadProgressCallback(progressEvent);
          }
        }
      };
      const response = yield axios(requestConfig);

      if (successCallback) {
        successCallback(response.data);
      }

      if (response.status === 401) {
        yield put(logoutRequest());
      } else if (response.status === 200) {
        if (from === MediaDropPages.CUSTOMIZE_THUMBNAIL) {
          yield put(mgAddCustomizeVideoThumbnailResponse({ cloudinaryResponse: response.data, fileName, order, videoCloudinaryId }));
        } else if (!isMediaRelease) {
          yield put(mgUploadCloudMediaResponse({ cloudinaryResponse: response.data, fileName, order }));
        }
      } else {
        if (response.data?.error?.message) alertBoxCall(ALERT_BOX_TYPES.ERROR, response.data.error.message);
        else alertBoxCall(ALERT_BOX_TYPES.ERROR, MG_UPLOAD_MEDIA_IN_CLOUDINARY_FAILED);
        yield put(mgValidUploadedCloudMediaList(fileName));
      }
    }
  } catch (error) {
    yield put(mgUploadCloudMediaReqFailure(MG_UPLOAD_MEDIA_IN_CLOUDINARY_FAILED));
    yield put(exceptionHandlerRequest(MG_EXCEPTION_ERROR));
  }
}

function* getWatermarkImage({ payload }: any): any {
  try {
    const response = yield apiCall({ headers: defaultHeader(), params: { franchisor_id: payload.brandId }, ...API.loadWatermarkImage });
    if (response.data.watermark_photos?.length > 0) {
      yield put(getWatermarkImageResponse(response.data.watermark_photos));
    }
  } catch (error) {
    yield put(getWatermarkImageResponse(null));
  }
}

function* sendCancelWatermarkImageToCloud({ type, payload }: any): any {
  const axiosSource = axios.CancelToken.source();
  try {
    if (type === types.UPLOAD_WATERMARK_IMAGE_CLOUDINARY_REQUEST) {
      const { file, signature, apiKey, timestamp, eager, fileName, uploadProgressCallback } = payload;
      const resourceType = '/auto';
      const headers = cloudinaryHeader();
      const bodyFormData = new FormData();
      bodyFormData.append('api_key', apiKey);
      bodyFormData.append('file', file);
      bodyFormData.append('signature', signature);
      bodyFormData.append('timestamp', timestamp);
      bodyFormData.append('eager', eager);

      const url = `${config.cloudinary.baseUrl}${config.cloudinary.name}${resourceType}${CLOUDINARY_API.cloudinaryImageUpload.apiPath}`;

      const requestConfig: any = {
        url,
        method: CLOUDINARY_API.cloudinaryImageUpload.action,
        data: bodyFormData,
        headers,
        timeout: 0,
        validateStatus(status: number) {
          return status >= 200 && status <= 500;
        },
        onUploadProgress: (progressEvent: ProgressEvent) => uploadProgressCallback(progressEvent),
        cancelToken: axiosSource.token
      };
      const response = yield axios(requestConfig);
      if (response.status === 200) {
        yield put(uploadWatermarkImageToCloudResponse({ response: { ...response.data, imageName: fileName } }));
      } else {
        if (response.data?.error?.message) alertBoxCall(ALERT_BOX_TYPES.ERROR, response.data.error.message);
        else alertBoxCall(ALERT_BOX_TYPES.ERROR, 'Upload cancelled!');
        yield put(uploadWatermarkImageToCloudResponse({ response: null }));
      }
    } else {
      axiosSource.cancel('Upload cancelled!');
    }
  } catch (error) {}
}

function* sendWatermarkImagesToCloud({ payload }: ISagaPayload): any {
  try {
    const { signature, apiKey, timestamp, eager, imageList, franchisorId, userId } = payload;
    const resourceType = '/auto';
    const headers = cloudinaryHeader();
    const url = `${config.cloudinary.baseUrl}${config.cloudinary.name}${resourceType}${CLOUDINARY_API.cloudinaryImageUpload.apiPath}`;
    const requestArr = imageList.map((it: any) => {
      const bodyFormData = new FormData();
      bodyFormData.append('api_key', apiKey);
      bodyFormData.append('file', it.file);
      bodyFormData.append('signature', signature);
      bodyFormData.append('timestamp', timestamp);
      bodyFormData.append('eager', eager);
      const requestConfig = {
        url,
        method: CLOUDINARY_API.cloudinaryImageUpload.action,
        data: bodyFormData,
        headers,
        timeout: 0
      };
      return axios(requestConfig);
    });
    const uploadResponse = (yield axios.all(requestArr)).map((it: any) => it.data);
    const dbRequestArr = uploadResponse.map((it: any) => {
      const requestConfig = {
        url: `${config.apiBaseURL}${config.apiVersionPath}${API.createWatermarkImage.apiPath}`,
        method: API.createWatermarkImage.action,
        data: {
          cloudinary_id: it.public_id,
          franchisor_id: franchisorId,
          user_id: userId,
          photo_url: it.secure_url
        },
        headers: defaultHeader(),
        timeout: 0
      };
      return axios(requestConfig);
    });
    const isSuccess = (yield axios.all(dbRequestArr)).filter((it: any) => it.status === 200)?.length === dbRequestArr.length;
    if (isSuccess) {
      yield put(getWatermarkImageRequest({ brandId: franchisorId }));
    }
  } catch (error) {}
}

function* sendSaveWatermarkImageRequest({ payload }: any): any {
  try {
    let response;
    if (payload.isUpdate) {
      response = yield apiCall({
        headers: defaultHeader(),
        data: { watermark_photo: payload.watermark_photo },
        apiPath: API.updateWatermarkImage.apiPath.replace(':id', payload.id),
        action: API.updateWatermarkImage.action
      });
    } else {
      response = yield apiCall({ headers: defaultHeader(), data: { watermark_photo: payload.watermark_photo }, ...API.createWatermarkImage });
    }
    if (response.status === 200) {
      yield put(getWatermarkImageRequest({ brandId: payload.watermark_photo.franchisor_id }));
      yield put(saveWatermarkImageResponse());
    }
  } catch (error) {}
}

function* sendGetTagsRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params = {
      ...(payload.searchValue && { search: payload.searchValue })
    };
    const urlPathObj = {
      ...(payload.userOwnership === USER_OWNERSHIP.ACCOUNT ? API.mgGetAccoutsTag : API.mgGetFavTags)
    };
    const replacedPath: string = urlPathObj.apiPath.replace(':id', payload.id);
    urlPathObj.apiPath = replacedPath;

    const response = yield apiCall({ headers, params, ...urlPathObj });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      const tagsList = getMGFavTagsList(response.data.favorite_tags);
      yield put(mgTagsResponse(tagsList));
    } else {
      yield put(mgTagsRequestFailure(MG_FETCHING_FAV_TAGS_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(MG_EXCEPTION_ERROR));
  }
}

function* sendGetFavTagsRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const urlPathObj: { apiPath: string; action: any } = payload.userOwnership === USER_OWNERSHIP.FRANCHISOR ? { ...API.mgGetFavTags } : { ...API.mgGetAccoutsTag };

    const replacedPath: string = urlPathObj.apiPath.replace(':id', payload.id);
    urlPathObj.apiPath = replacedPath;

    const response = yield apiCall({ headers, ...urlPathObj });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      const tagsList = getMGFavTagsList(response.data.favorite_tags);
      yield put(mgGetFavTagsResponse(tagsList));
    } else {
      yield put(mgGetFavTagsRequestFailure(MG_FETCHING_FAV_TAGS_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(MG_EXCEPTION_ERROR));
  }
}

function* sendUpdateFavTagsRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const data = {
      ...(payload.tagItem && { favorite_tag: { favorite: payload.tagItem.favourite, tag_name: payload.tagItem.name } })
    };
    const urlPathObj = { ...API.mgUpdateFavFrachisorTag };

    const replacedPath: string = urlPathObj.apiPath.replace(':id', payload.id);
    urlPathObj.apiPath = replacedPath;

    const response = yield apiCall({ headers, data, ...urlPathObj });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(mgUpdateFavTagsResponse());
    } else {
      yield put(mgUpdateFavTagsRequestFailure());
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(MG_EXCEPTION_ERROR));
  }
}

function* sendMGAddMediaAssetRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const mediaType = payload.mediaType === MG_MEDIA_TYPE.RAW ? MG_MEDIA_TYPE.DOCUMENT : payload.mediaType;
    const data = {
      [mediaType]: payload.dataParams
    };
    let response;
    if (payload.mediaType === MG_MEDIA_TYPE.PHOTO) {
      response = yield apiCall({ headers, data, ...API.mgAddPhotoAsset });
    } else if (payload.mediaType === MG_MEDIA_TYPE.VIDEO) {
      response = yield apiCall({ headers, data, ...API.mgAddVideoAsset });
    } else {
      delete data[mediaType].tags_list;
      response = yield apiCall({ headers, data, ...API.createAIPlaybookDocument });
    }
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      const selectedMediaList = store.getState().addPostFranchisor.selectedMediaList;
      if (payload.subPageName === 'creator') {
        yield put(mgAIImageUploadingRequest(false));
        yield put(addPostSetSelectedMediaList([...selectedMediaList, payload.mediaType === MG_MEDIA_TYPE.PHOTO ? response.data.photo : response.data.video]));
        if (payload.isFromCreator) {
          yield put(getMGListRequest(payload?.mgListReqPayload));
          yield put(mgAddedAssetDetailReset());
        }
      } else {
        yield put(
          mgSaveAssetResponse({
            mediaType: payload.mediaType === MG_MEDIA_TYPE.RAW ? MG_MEDIA_TYPE.DOCUMENT : payload.mediaType,
            mediaId: 'photo' in response.data ? response.data?.photo?.id || 0 : 'video' in response.data ? response.data?.video?.id || 0 : response.data?.document?.id,
            isMediaClone: payload.isMediaClone
          })
        );
        if (typeof payload.onNavigate === 'function') payload.onNavigate();
      }
    } else {
      if (payload.subPageName === 'creator') {
        const selectedMediaList = store.getState().addPostFranchisor.selectedMediaList;
        yield put(addPostSetSelectedMediaList(selectedMediaList));
      } else {
        const assetsList = store.getState().mediaGallery.addMediaAssets.assetsList;
        const totalAssetsCount = store.getState().mediaGallery.uploadCloudinaryMedia.addedAssetsCount;
        if (totalAssetsCount && assetsList.length !== totalAssetsCount) {
          yield put(mgSaveAssetFailure(MG_ADD_MEDIA_ASSET_FAILURE_MSG));
        }
      }
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(MG_EXCEPTION_ERROR));
  }
}

function* sendDeleteAssetRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const APIObj = payload.mediaType === MgMediaType.PHOTOS ? API.mgDeletePhoto : payload.mediaType === MgMediaType.VIDEOS ? API.mgDeleteVideo : API.deleteAIPlaybookDocument;
    const action = MG_PHOTO_VIDEO.includes(payload.mediaType) ? API.mgDeletePhoto.action : API.deleteAIPlaybookDocument.action;
    const path = APIObj.apiPath.replace(':id', payload.assetId);
    const mediaType = payload.mediaType === MgMediaType.PHOTOS ? MG_MEDIA_TYPE.PHOTO : payload.mediaType === MgMediaType.VIDEOS ? MG_MEDIA_TYPE.VIDEO : MG_MEDIA_TYPE.DOCUMENT;
    let response;
    const data = {
      [mediaType]: payload.reqPayload
    };
    response = yield apiCall({ headers, ...(MG_PHOTO_VIDEO.includes(payload.mediaType) && { data }), apiPath: path, action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(
        mgDeleteAssetResponse({
          message: MG_DELETE_ASSET_SUCCESS_MSG,
          mediaType: payload.mediaType,
          id: payload.assetId
        })
      );
      yield delay(SAGAS_DELAY_SECONDS);
    } else {
      yield put(mgDeleteAssetFailure(MG_DELETE_ASSET_FAILURE_MSG));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(MG_EXCEPTION_ERROR));
  }
}

function* sendMediaReleasesRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let path;
    let response;
    if (payload.mediaType === MgMediaType.PHOTOS) {
      path = API.mgMediaReleasePhoto.apiPath.replace(':id', payload.assetId);
    } else {
      path = API.mgMediaReleaseVideo.apiPath.replace(':id', payload.assetId);
    }
    response = yield apiCall({ headers, apiPath: path, action: API.mgMediaReleasePhoto.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(mgMediaReleaseResponse(response.data.media_releases));
      yield delay(SAGAS_DELAY_SECONDS);
      yield put(mgMediaReleaseResponse([]));
    } else {
      yield put(mgMediaReleaseRequestFailure(MG_DELETE_ASSET_FAILURE_MSG));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(MG_EXCEPTION_ERROR));
  }
}

function* sendLikeRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const APIObj = payload.mediaType === MgMediaType.PHOTOS ? API.mgLikePhoto : payload.mediaType === MgMediaType.VIDEOS ? API.mgLikeVideo : API.mgLikeDocument;
    const path = APIObj.apiPath.replace(':id', payload.assetId);
    const response = yield apiCall({ headers, apiPath: path, action: APIObj.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(
        mgLikeResponse({
          assets: response && response.data && response.data.photo ? response.data.photo : response.data.video ? response.data.video : response.data.document
        })
      );
    } else {
      yield put(mgLikeRequestFailure(MG_LIKE_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(MG_EXCEPTION_ERROR));
  }
}

function* sendDislikeRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const APIObj = payload.mediaType === MgMediaType.PHOTOS ? API.mgDislikePhoto : payload.mediaType === MgMediaType.VIDEOS ? API.mgDislikeVideo : API.mgDislikeDocuments;
    const path = APIObj.apiPath.replace(':id', payload.assetId);
    const response = yield apiCall({ headers, apiPath: path, action: APIObj.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(
        mgDislikeResponse({
          assets: response && response.data && response.data.photo ? response.data.photo : response.data.video ? response.data.video : response.data.document
        })
      );
    } else {
      yield put(mgDislikeRequestFailure(MG_DISLIKE_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(MG_EXCEPTION_ERROR));
  }
}

function* sendMGDetailDataRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let path;
    const params: accountSwitcherApiTypes.IAccountSwitcherReqParams = {
      [`${payload.userOwnership}_id`]: payload.id
    };
    const APIObj = payload.mediaType === MgMediaType.PHOTOS ? API.mgGetPhotoAssetDetail : payload.mediaType === MgMediaType.VIDEOS ? API.mgGetVideoAssetDetail : API.getAIPlaybookDocumentDetail;
    path = APIObj.apiPath.replace(':id', payload.assetId);
    const response = yield apiCall({ headers, params, apiPath: path, action: APIObj.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(mgDetailDataResponse(payload.mediaType === MgMediaType.PHOTOS ? response.data.photo : payload.mediaType === MgMediaType.VIDEOS ? response.data.video : response.data.document));
    } else {
      yield put(mgDetailDataRequestFailure(MG_DETAIL_DATA_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(MG_EXCEPTION_ERROR));
  }
}

function* sendMGUpdateDetailDataRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const APIObj = payload.mediaType === MgMediaType.PHOTOS ? API.mgUpdatePhotoAssetDetail : payload.mediaType === MgMediaType.VIDEOS ? API.mgUpdateVideoAssetDetail : API.updateDocumentDetail;
    const path = APIObj.apiPath.replace(':id', payload.editedContent.id);
    const data = {
      ...(payload.mediaType === MgMediaType.PHOTOS && { photo: payload.editedContent }),
      ...(payload.mediaType === MgMediaType.VIDEOS && { video: payload.editedContent }),
      ...(payload.mediaType === MgMediaType.DOCUMENTS && { document: payload.editedContent })
    };
    const response = yield apiCall({ headers, data, apiPath: path, action: APIObj.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      if (payload.successCallback) {
        payload.successCallback();
      }
      yield put(mgUpdateDetailDataResponse(payload.mediaType === MgMediaType.PHOTOS ? response.data.photo : payload.mediaType === MgMediaType.VIDEOS ? response.data.video : response.data.document));
    } else {
      yield put(mgUpdateDetailDataRequestFailure(MG_UPDATE_DETAIL_DATA_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(MG_EXCEPTION_ERROR));
  }
}

function* sendMGCloneAssetRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let path;
    const params = payload.params;
    if (payload.mediaType === MgMediaType.PHOTOS) {
      path = API.mgClonePhoto.apiPath.replace(':assetId', payload.assetId);
    } else {
      path = API.mgCloneVideo.apiPath.replace(':assetId', payload.assetId);
    }
    const response = yield apiCall({ headers, params, apiPath: path, action: API.mgClonePhoto.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(
        mgCloneAssetResponse({
          data: payload.mediaType === MgMediaType.PHOTOS ? response.data.photo : response.data.video,
          ...(payload.id && { id: payload.id }),
          ...(payload.userOwnership && { userOwnership: payload.userOwnership })
        })
      );
      if (payload.id && payload.userOwnership) {
        yield put(mgDetailDataRequest({ mediaType: payload.mediaType, assetId: payload.assetId, id: payload.id, userOwnership: payload.userOwnership, isDataRetain: true }));
      }
    } else {
      yield put(
        mgCloneAssetFailure({
          errorMsg: MG_CLONE_ASSET_FAILED_MSG,
          ...(payload.id && { id: payload.id }),
          ...(payload.userOwnership && { userOwnership: payload.userOwnership })
        })
      );
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(MG_EXCEPTION_ERROR));
  }
}
function* sendS3CloudMediaUploadRequest({ payload }: ISagaPayload): any {
  try {
    const headers = awsHeader();
    const response = yield apiCall({
      headers,
      data: payload.payload,
      ...API.mgS3FileUpload,
      onUploadProgress: (evt) => (payload?.onUploadProgress ? payload?.onUploadProgress(evt) : null),
      type: API_TYPES.NODE
    });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      const data = response.data.data;
      if (!payload?.isRevv) {
        yield put(mgUploadCloudMediaResponse({ cloudinaryResponse: s3Response(data.Key, data.Location, data.ETag), fileName: data.Key, order: [] }));
      } else {
        payload?.onUploadSuccess(data);
      }
    } else {
      if (!payload?.isRevv) {
        alertBoxCall(ALERT_BOX_TYPES.ERROR, MG_UPLOAD_MEDIA_FAILED);
      } else {
        payload?.onUploadFailure();
      }
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(MG_EXCEPTION_ERROR));
  }
}

export function* takeMediaGalleryRequest() {
  yield takeEvery(types.MG_FILTER_STATS_REQUEST, sendGetMGFilterStatsRequest);
  yield takeEvery(types.MG_TAGS_REQUEST, sendGetTagsRequest);
  yield takeLatest(types.MG_GET_FAV_TAGS_REQUEST, sendGetFavTagsRequest);
  yield takeEvery(types.MG_UPDATE_FAV_TAGS_REQUEST, sendUpdateFavTagsRequest);
  yield takeEvery(types.MG_LIST_REQUEST, sendGetMGListsRequest);
  yield takeEvery(types.GET_MULTISELECT_MG_LIST_REQUEST, sendGetMGListsRequest);
  yield takeEvery(types.MG_CLOUD_MEDIA_UPLOAD_REQUEST, sendMGUploadCloudMediaRequest);
  yield takeEvery(types.MG_ADD_MEDIA_ASSET_REQUEST, sendMGAddMediaAssetRequest);
  yield takeEvery(types.MG_DELETE_ASSET_REQUEST, sendDeleteAssetRequest);
  yield takeEvery(types.MG_MEDIA_RELEASE_REQUEST, sendMediaReleasesRequest);
  yield takeEvery(types.MG_LIKE_REQUEST, sendLikeRequest);
  yield takeEvery(types.MG_DISLIKE_REQUEST, sendDislikeRequest);
  yield takeEvery(types.MG_DETAIL_DATA_REQUEST, sendMGDetailDataRequest);
  yield takeEvery(types.MG_UPDATE_DETAIL_DATA_REQUEST, sendMGUpdateDetailDataRequest);
  yield takeLatest(types.UPLOAD_WATERMARK_IMAGE_CLOUDINARY_REQUEST, sendWatermarkImagesToCloud);
  yield takeLatest(types.CANCEL_UPLOAD, sendCancelWatermarkImageToCloud);
  yield takeLatest(types.GET_WATERMARK_IMAGE, getWatermarkImage);
  yield takeLatest(types.SAVE_WATERMARK_IMAGE_REQUEST, sendSaveWatermarkImageRequest);
  yield takeLatest(types.MG_CLONE_ASSET_REQUEST, sendMGCloneAssetRequest);
  yield takeEvery(types.MG_S3_CLOUD_MEDIA_UPLOAD_REQUEST, sendS3CloudMediaUploadRequest);
}
