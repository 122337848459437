import { useState, useEffect, useContext, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EditorState, Modifier } from 'draft-js';
import { BaseEmoji } from 'emoji-mart';

import { ImageValidation } from 'widgets/Media';
import {
  COMMON_SOCIAL_PROVIDER_TYPE,
  COMMON_SOCIAL_MEDIA_NAME,
  ADD_POST_CONTENT_MAX_TEXT_LENGTH,
  MG_MEDIA_TYPE,
  AP_ADD_TO_POST_TAB_NAMES,
  AP_PREVIEW_TYPES,
  SIDE_NAV_NAME,
  PERSONALIZED_TAG_REQEX,
  CREATOR_SOCIAL_MEDIAS,
  IDEAL_LENGTH_OF_SOCIAL_MEDIA_POSTS_BY_PLATFORM,
  CREATOR_TEXT_EDITOR_ALERT_MSG,
  GET_HASHTAG_ONLY_REGEX,
  STRING_WITH_PHONE_NUMBER,
  SOCIAL_MEDIA_POST_TYPE,
  SOCIAL_MEDIA_POST_TYPE_VALUES,
  SettingsHashtagsActionsVal,
  HASHTAG_BLOCK_DEFAULT_SPACING,
  AP_ACTION_TYPES,
  ADD_POST_CONTENT_MAX_HASHTAGS_LENGTH
} from 'utils/constants';
import { apAccountContainerTypes, /* apFranActionReducerTypes, */ apFranReducerTypes, IStore } from 'types';
import {
  addPostGetSelectedContentObj,
  addPostGetSelectedFBTagList,
  addPostFranSelectedMediaReset,
  addPostSetLinkData,
  addPostGetFBTagsListRequest,
  addPostIsEmojiOpen,
  setAIPostContentOpen,
  setCreatorModalName,
  setAISubscriptionDetailsModal,
  getVideoCompatibilityRequest,
  setCreatorTextAlertMsg,
  mgGetCloudResponseList,
  mgSetAddedAssetsCount,
  setCustomizeLinkEnable,
  addCustomizeLink,
  enableAICaptionValidation,
  addPostUpdateCustomTextEditor,
  addLocalizeLinkURL,
  addLocalizeLink,
  addPostResetPreviewLinks
} from 'actions';
import {
  convertEditorStateToString,
  removeTagListBasedOnDataAvail,
  createEmptyEditorState,
  handleHashtagsInCreator,
  getPreviewBrandHubCreatorActions,
  convertStringToEditorState
} from 'utils/helpers';
import { useAccountSwitcherData, useNavBarData, useSiteRestriction } from 'utils/hooks';
import { CouponOrLinkPreview } from 'widgets/Preview';
import { AddToYourPostSection } from './AddToYourPost';
import { CreatePersonalizedVersion } from './CreatePersonalizedVersion';
import { Loading, TranslateTextSec } from 'components';
import { CreatorInstagramPhotos, CreatorTextCount, CreatorTextEditor, SocialMediaAlertMessage, LimitExceedAlertMessage } from '../CommonModal';
import { EditorStateContext } from '..';
import { LeftCreatorMediaList } from './LeftCreatorMediaList';
import { RenderAddContentList } from './RenderAddContentList';
import { regexes } from 'widgets/DraftjsHashtagsPlugin/hashtagRegex';
import Picker from 'widgets/ReactEmojiPicker';
import { CustomDropdown } from 'widgets/CustomDropdown';
import { ToggleSwitch } from 'widgets/Switch';

export const AddTextSection = (props: apAccountContainerTypes.IAddPostTextSectionProps) => {
  const { socialMediaList, addContentList } = props;

  const dispatch = useDispatch();

  const { id, userOwnership } = useAccountSwitcherData();
  const { subNavPageName } = useNavBarData();
  const { aiRestriction } = useSiteRestriction();

  const contentObj = useSelector((state: IStore) => state.addPostFranchisor.contentObj);
  const facebookTags = useSelector((state: IStore) => state.addPostAccount.fbTagsData.fbTagsList);
  const selectedFBTagList = useSelector((state: IStore) => state.addPostFranchisor.selectedFBTagList);
  const selectedMediaList = useSelector((state: IStore) => state.addPostFranchisor.selectedMediaList);
  const isLinkFetching = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj.isLinkFetching);
  const linkData = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj.linkData);
  const { customText, postStatus, isThumbnailShow, currentTab, isPostLock } = contentObj;
  const isFBPageTaggingEnabled = useSelector((state: IStore) => state.franchisors.franchisorDetails?.facebook_page_tagging_feature || false);
  const isEmojiOpen = useSelector((state: IStore) => state.addPostFranchisorAction.isEmojiOpen);
  const isAiContentOpen = useSelector((state: IStore) => state.aiContentCreator.isAiContentOpen);
  const isShowAiImageTab = useSelector((state: IStore) => state.addPostFranchisorAction.isShowAiImageTab);
  const isAISubscribed = useSelector((state: IStore) => state.aiContentCreator.isAISubscribed);
  const isRallioai = useSelector((state: IStore) => state.accountSwitcher?.rallio_ai || false);
  const videoCompatibility = useSelector((state: IStore) => state.addPostFranchisor.videoCompatibility);
  const creatorTextAlertMsg = useSelector((state: IStore) => state.common.creatorTextAlertMsg);
  const { hashtagsList } = useSelector((state: IStore) => state.addPostFranchisorAction.hashtagsListObj);
  const createPostHashtagSuggestions = useSelector((state: IStore) => state.aiContentCreator.createPostHashtagSuggestions);
  const captionState = useSelector((state: IStore) => state.aiContentCreator.caption);
  const isCustomizeLinkAdded = useSelector((state: IStore) => state.addPostFranchisorAction.isCustomizeLinkAdded || false);
  const isAICaptionValidationEnabled = useSelector((state: IStore) => state.aiContentCreator.isAICaptionValidationEnabled || false);
  const hashTagsState = useSelector((state: IStore) => state.settingsHashTags.hashTagsState);
  const pageType = useSelector((state: IStore) => state.addPostFranchisor.pageType);

  const [editableSocialMedia, setEditableSocialMedia] = useState({ name: '', isEdit: false });
  const [isPersonalizedDDOpen, setIsPersonalizedDDOpen] = useState(false);
  const [textEditorState, setEditorState] = useContext(EditorStateContext);

  const isStoriesAdded =
    (currentTab === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && contentObj?.facebook_story) || (currentTab === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && contentObj?.instagram_story) || false;

  const socialMediaPostMemo = useMemo(() => {
    const socialMediaPostType =
      currentTab === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? SOCIAL_MEDIA_POST_TYPE : SOCIAL_MEDIA_POST_TYPE.filter((type) => type.value !== SOCIAL_MEDIA_POST_TYPE_VALUES.REELS);
    return socialMediaPostType;
  }, [currentTab]);

  useEffect(() => {
    if (currentTab && addContentList.length && !customText[currentTab]) {
      setEditableSocialMedia({
        name: currentTab,
        isEdit: false
      });
    }
  }, [addContentList.length, currentTab, customText[currentTab]]); // eslint-disable-line

  useEffect(() => {
    if (selectedFBTagList.length && currentTab === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK) {
      dispatch(addPostGetSelectedFBTagList(removeTagListBasedOnDataAvail(selectedFBTagList, customText?.facebook || '')));
    }
  }, [currentTab, customText?.facebook, selectedFBTagList.length]); // eslint-disable-line

  // Google phone RAS-4964 validation Prevent users from creating Google posts with phone numbers in the post body
  useEffect(() => {
    if (contentObj.personalizedText?.google || customText?.google) {
      const regexData = new RegExp(STRING_WITH_PHONE_NUMBER);
      const editorGoogleText = regexData.test(customText[currentTab]);
      dispatch(
        addPostGetSelectedContentObj({
          ...contentObj,
          postStatus: {
            ...postStatus,
            [currentTab]:
              contentObj && !currentTab
                ? false
                : IDEAL_LENGTH_OF_SOCIAL_MEDIA_POSTS_BY_PLATFORM.includes(currentTab) && customText[currentTab].length > ADD_POST_CONTENT_MAX_TEXT_LENGTH[currentTab.toUpperCase()]
                ? false
                : currentTab === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE &&
                  (editorGoogleText || contentObj.personalizedText[currentTab].includes('[phone_number]') || contentObj.personalizedText[currentTab].includes('[phone]'))
                ? false
                : true
          }
        })
      );
    }
  }, [contentObj.personalizedText?.google, customText, dispatch]); // eslint-disable-line

  useEffect(() => {
    if (id && userOwnership) {
      dispatch(addPostFranSelectedMediaReset());
    }
  }, [id, userOwnership]); // eslint-disable-line

  // If the post is locked, close the emoji list
  useEffect(() => {
    if (isPostLock && isEmojiOpen) dispatch(addPostIsEmojiOpen(false));
  }, [isPostLock, isEmojiOpen, dispatch]);

  // If the post description is more than the length, close the emoji list
  useEffect(() => {
    const isDisableEmoji =
      IDEAL_LENGTH_OF_SOCIAL_MEDIA_POSTS_BY_PLATFORM.includes(currentTab) && customText[currentTab]?.length > ADD_POST_CONTENT_MAX_TEXT_LENGTH[currentTab.toUpperCase()] - 3 ? true : false;
    if (isEmojiOpen && isDisableEmoji) {
      dispatch(addPostIsEmojiOpen(false));
    }
  }, [customText, currentTab, contentObj, isEmojiOpen, dispatch]);

  useEffect(() => {
    if (selectedMediaList.length && selectedMediaList[0].media_type === MG_MEDIA_TYPE.VIDEO) {
      dispatch(getVideoCompatibilityRequest(selectedMediaList[0].cloudinary_id));
    }
  }, [selectedMediaList, dispatch]);

  useEffect(() => {
    const hashTagsLimit: string[] = customText[currentTab].match(GET_HASHTAG_ONLY_REGEX) || [];
    handleHashtagsInCreator(hashtagsList, hashTagsLimit, dispatch, createPostHashtagSuggestions, captionState);
  }, [customText[currentTab]]); // eslint-disable-line

  useEffect(() => {
    if (videoCompatibility?.warnings[`${contentObj?.currentTab}_reel` as keyof apFranReducerTypes.IVideoCompatibilityWarnings]?.length) {
      dispatch(
        addPostGetSelectedContentObj({
          ...contentObj,
          postStatus: {
            ...postStatus,
            ...(contentObj?.[`${[`${contentObj?.currentTab}_reel`]}`] && { [contentObj?.currentTab]: false })
          }
        })
      );
    }
  }, [videoCompatibility?.warnings[`${contentObj?.currentTab}_reel` as keyof apFranReducerTypes.IVideoCompatibilityWarnings], contentObj?.facebook_reel, dispatch]); // eslint-disable-line

  useEffect(() => {
    if (hashTagsState.action_status === SettingsHashtagsActionsVal.IN_POST && hashTagsState.saved_hashtags?.length && pageType === AP_ACTION_TYPES.ADD) {
      handleUpdateSocialMediaText(currentTab, convertStringToEditorState(`${HASHTAG_BLOCK_DEFAULT_SPACING}${hashTagsState.saved_hashtags.join(' ')}`));
    }
  }, [hashTagsState, dispatch]); // eslint-disable-line

  const handleSocialMediaSelect = (selectedTab: string | null) => {
    if (isEmojiOpen) dispatch(addPostIsEmojiOpen(false));
    if (creatorTextAlertMsg) dispatch(setCreatorTextAlertMsg(null));
    dispatch(addPostGetSelectedContentObj({ ...contentObj, currentTab: selectedTab || socialMediaList[0] }));
    setIsPersonalizedDDOpen(false);
  };

  const handleUpdateSocialMediaText = (socialMediaKey: string, socialMediaValue: EditorState) => {
    // need to validate caption before save
    if (!isAICaptionValidationEnabled) dispatch(enableAICaptionValidation(true));
    dispatch(addPostUpdateCustomTextEditor({ key: socialMediaKey, value: socialMediaValue }));
    setEditorState({ ...textEditorState, [socialMediaKey]: socialMediaValue });
    const socialMediaText = convertEditorStateToString(socialMediaValue) || '';
    const hashTagsLimit: string[] = socialMediaText.match(GET_HASHTAG_ONLY_REGEX) || [];
    const regexData = new RegExp(STRING_WITH_PHONE_NUMBER);
    const editorGoogleText = regexData.test(customText[socialMediaKey]);
    // old cond - const socialMediaText = getContentTextWithMaximumLimit(convertEditorStateToString(socialMediaValue) || '', socialMediaKey);
    dispatch(
      addPostGetSelectedContentObj({
        ...contentObj,
        customText: {
          ...customText,
          [socialMediaKey]: socialMediaText
        },
        postStatus: {
          ...postStatus,
          [socialMediaKey]:
            contentObj && !socialMediaText && !selectedMediaList.length && !linkData
              ? false
              : socialMediaKey === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && (!selectedMediaList.length || hashTagsLimit?.length > ADD_POST_CONTENT_MAX_HASHTAGS_LENGTH.INSTAGRAM)
              ? false
              : socialMediaKey === COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST && (!selectedMediaList.length || hashTagsLimit?.length > ADD_POST_CONTENT_MAX_HASHTAGS_LENGTH.PINTEREST)
              ? false
              : socialMediaKey === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN && !socialMediaText
              ? false
              : IDEAL_LENGTH_OF_SOCIAL_MEDIA_POSTS_BY_PLATFORM.includes(socialMediaKey) && socialMediaText.length > ADD_POST_CONTENT_MAX_TEXT_LENGTH[socialMediaKey.toUpperCase()]
              ? false
              : socialMediaKey === COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK && (!selectedMediaList.length || (selectedMediaList.length && selectedMediaList[0]?.media_type !== MG_MEDIA_TYPE.VIDEO))
              ? false
              : socialMediaKey === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE &&
                (editorGoogleText || contentObj.personalizedText[socialMediaKey].includes('[phone_number]') || contentObj.personalizedText[currentTab].includes('[phone]'))
              ? false
              : socialMediaKey === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
              ? contentObj.facebook_story
                ? selectedMediaList.length
                  ? true
                  : false
                : contentObj?.facebook_reel
                ? selectedMediaList.length && selectedMediaList?.[0].media_type === MG_MEDIA_TYPE.VIDEO
                  ? true
                  : false
                : selectedMediaList.length
                ? true
                : Boolean(socialMediaText)
              : Boolean(socialMediaText)
              ? true
              : postStatus[socialMediaKey]
        }
      })
    );
    if (socialMediaText.length && socialMediaKey === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && creatorTextAlertMsg) dispatch(setCreatorTextAlertMsg(null));
  };

  const handleUpdateSocialMediaCheckbox = (socialMediaKey: string, socialMediaValue: boolean) => {
    dispatch(
      addPostGetSelectedContentObj({
        ...contentObj,
        postStatus: {
          ...postStatus,
          [socialMediaKey]: socialMediaValue
        }
      })
    );
  };

  const handleActiveSocialMedia = (socialMedia: string) => {
    return !customText[socialMedia].length && !selectedMediaList.length ? false : true;
  };

  const handleDeletePreview = () => {
    dispatch(addPostSetLinkData(null));
    dispatch(
      addPostGetSelectedContentObj({
        ...contentObj,
        postStatus: {
          ...postStatus,
          facebook: contentObj?.facebook_story
            ? Boolean(selectedMediaList?.length)
            : contentObj?.facebook_reel
            ? selectedMediaList?.[0]?.media_type === MG_MEDIA_TYPE.VIDEO
            : handleActiveSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK),
          instagram: selectedMediaList.length ? handleActiveSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM) : false,
          twitter: handleActiveSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.TWITTER),
          linkedin: handleActiveSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN),
          // tiktok: handleActiveSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK),
          tiktok: selectedMediaList?.[0]?.media_type === MG_MEDIA_TYPE.VIDEO,
          google: handleActiveSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE),
          pinterest: selectedMediaList.length ? handleActiveSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST) : false
        },
        isThumbnailShow: true,
        linkIndex: 0,
        facebook_call_to_action_type: null,
        personalized_facebook_call_to_action_link: '',
        google_call_to_action_type: null,
        google_call_to_action_url: ''
      })
    );
    dispatch(mgGetCloudResponseList([]));
    dispatch(mgSetAddedAssetsCount(0));
    dispatch(setCustomizeLinkEnable(false));
    if (isCustomizeLinkAdded) {
      dispatch(addCustomizeLink(false));
    }
    dispatch(addLocalizeLinkURL(''));
    dispatch(addLocalizeLink(false));
    dispatch(addPostResetPreviewLinks());
  };

  const handleTagSearch = (searchValue: string) => {
    dispatch(addPostGetFBTagsListRequest(searchValue));
  };

  const handleAiClick = () => {
    if (!isAISubscribed) {
      dispatch(setAISubscriptionDetailsModal({ type: 'trialPeriod', isOpen: true }));
    } else {
      dispatch(setAIPostContentOpen(!isAiContentOpen));
    }
    // navigate(ROUTES.CONTENT_CREATOR.replace(':type', userOwnership).replace(':id', id.toString()).replace('*', COMMON_PAGE_TYPE.NEW));
    // if (!isAiContentOpen)
  };

  const handleUpdateSettingsCheckbox = (socialMediaKey: string, socialMediaValue: boolean, socialMedia: string) => {
    const unusedPostType: Record<string, boolean> = {};
    [SOCIAL_MEDIA_POST_TYPE_VALUES.REELS, SOCIAL_MEDIA_POST_TYPE_VALUES.STORY]
      .filter((option) => `${socialMedia}_${option}` !== socialMediaKey)
      .forEach((key) => {
        unusedPostType[`${socialMedia}_${key}`] = false;
      });

    dispatch(
      addPostGetSelectedContentObj({
        ...contentObj,
        postStatus: {
          ...postStatus,
          [socialMedia]:
            socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
              ? socialMediaKey === `${socialMedia}_${SOCIAL_MEDIA_POST_TYPE_VALUES.STORY}`
                ? selectedMediaList?.length && !linkData
                  ? true
                  : false
                : socialMediaKey === `${socialMedia}_${SOCIAL_MEDIA_POST_TYPE_VALUES.REELS}`
                ? selectedMediaList?.[0]?.media_type === MG_MEDIA_TYPE.VIDEO && !linkData
                  ? true
                  : false
                : Boolean(contentObj?.customText?.[socialMedia] || selectedMediaList.length || linkData)
              : socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
              ? Boolean(selectedMediaList.length)
              : Boolean(contentObj?.customText?.[socialMedia] || selectedMediaList.length || linkData)
        },

        ...(socialMediaKey !== `${socialMedia}_${SOCIAL_MEDIA_POST_TYPE_VALUES.POST}` && { [socialMediaKey]: socialMediaValue }),
        ...unusedPostType
      })
    );
  };

  const setModalName = (data: string | null) => dispatch(setCreatorModalName(data));

  const renderSocialMediaTabDetails = (socialMedia: string, socialMediaLabel: string) => {
    const hashTagsLimit = customText[socialMedia].match(regexes.validHashtag) || [];
    const regexData = new RegExp(STRING_WITH_PHONE_NUMBER);
    const editorGoogleText = regexData.test(customText[socialMedia]);
    const isPhoneNumber =
      COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE === currentTab &&
      (editorGoogleText || contentObj.personalizedText[socialMedia].includes('[phone_number]') || contentObj.personalizedText[socialMedia].includes('[phone]'));

    return (
      <div className="tw-content flexiAlign cr-prt-wrap">
        <div className="pm-options">
          <div className={`form-check${contentObj?.isPostLock ? ` pointer-events-none` : ``}`}>
            <p className="label-txt">
              Publish <span className="mandatory cursor-help">*</span>
            </p>
            <ToggleSwitch
              id={'material-switch-21'}
              handleOnChange={() => handleUpdateSocialMediaCheckbox(socialMedia, !contentObj?.postStatus?.[socialMedia])}
              checkedStatus={
                socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
                  ? contentObj.postStatus.facebook
                  : socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
                  ? contentObj.postStatus.instagram
                  : socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER
                  ? contentObj.postStatus.twitter
                  : socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN
                  ? contentObj.postStatus.linkedin
                  : socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK
                  ? contentObj.postStatus.tiktok
                  : socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST
                  ? contentObj.postStatus.pinterest
                  : contentObj.postStatus.google
              }
              disabled={
                contentObj?.isPostLock
                  ? true
                  : COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM === socialMedia
                  ? !selectedMediaList?.length
                  : COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK === socialMedia
                  ? contentObj?.facebook_story || contentObj?.facebook_reel
                    ? !selectedMediaList.length
                      ? true
                      : false
                    : selectedMediaList.length || contentObj?.customText?.[socialMedia] || linkData
                    ? false
                    : true
                  : COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK === socialMedia
                  ? selectedMediaList?.[0]?.media_type !== MG_MEDIA_TYPE.VIDEO
                  : !(contentObj?.customText?.[socialMedia] || selectedMediaList.length || linkData)
              }
            />
          </div>
          {[COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM].includes(socialMedia) ? (
            <div className={`prsMain${contentObj?.isPostLock ? ' pointer-events-none' : ''}`}>
              {/* <div className="label-txt">
                Publish as <span>*</span>
              </div> */}
              <CustomDropdown
                id="post-type-dropdown"
                classNamePrefix={'prsData'}
                // menuIsOpen={true}
                placeholder={'Select a day'}
                options={socialMediaPostMemo}
                value={socialMediaPostMemo.find((datum) => contentObj?.[`${socialMedia}_${datum.value}`] === true) || SOCIAL_MEDIA_POST_TYPE[0]}
                onChange={(eventObj: any) => handleUpdateSettingsCheckbox(`${socialMedia}_${eventObj?.value}`, true, socialMedia)}
              />
            </div>
          ) : null}

          {isRallioai && isAISubscribed && !aiRestriction.button && (
            <div className={`translateMain ${!getPreviewBrandHubCreatorActions(postStatus).length || contentObj?.isPostLock ? 'pointer-events-none' : ''}`}>
              <TranslateTextSec />
            </div>
          )}
        </div>
        {creatorTextAlertMsg ? <SocialMediaAlertMessage message={creatorTextAlertMsg} /> : null}
        {customText?.[currentTab] && COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK === currentTab && (!selectedMediaList.length || selectedMediaList[0]?.media_type === MG_MEDIA_TYPE.PHOTO) ? (
          <SocialMediaAlertMessage message={CREATOR_TEXT_EDITOR_ALERT_MSG.TIKTOK} />
        ) : null}
        {COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK === contentObj.currentTab && (contentObj?.facebook_story || contentObj?.facebook_reel) ? (
          !selectedMediaList?.length ? (
            <SocialMediaAlertMessage
              message={
                linkData
                  ? CREATOR_TEXT_EDITOR_ALERT_MSG.LINK_ALERT_MSG_WITH_STORY_OR_REEL.replaceAll(`/post-type/`, 'story or reel')
                  : contentObj?.facebook_reel
                  ? CREATOR_TEXT_EDITOR_ALERT_MSG.FACEBOOK_REEL
                  : CREATOR_TEXT_EDITOR_ALERT_MSG.FACEBOOK_STORY
              }
            />
          ) : contentObj?.facebook_reel && selectedMediaList?.[0]?.media_type !== MG_MEDIA_TYPE.VIDEO ? (
            <SocialMediaAlertMessage message={CREATOR_TEXT_EDITOR_ALERT_MSG.FACEBOOK_REEL} />
          ) : null
        ) : null}
        {/* reel video compatibility warning for fb and instagram */}
        {contentObj?.[`${socialMedia}_reel`] && videoCompatibility?.warnings[`${socialMedia}_reel` as keyof apFranReducerTypes.IVideoCompatibilityWarnings]?.length ? (
          <SocialMediaAlertMessage message={videoCompatibility?.warnings[`${socialMedia}_reel` as keyof apFranReducerTypes.IVideoCompatibilityWarnings]?.[0]} />
        ) : null}
        {/* When context, media and link are not added, when instagram has no media or contain coupon/link,
            when linkedin or google has video media, the status checkbox should not be shown */}
        {
          (customText?.[currentTab] || contentObj?.instagram_story) && socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && (!selectedMediaList.length || hashTagsLimit.length > 30) ? (
            <>
              {!selectedMediaList.length ? <SocialMediaAlertMessage message={CREATOR_TEXT_EDITOR_ALERT_MSG.INSTAGRAM} /> : null}
              {customText[socialMedia].length > ADD_POST_CONTENT_MAX_TEXT_LENGTH.INSTAGRAM ? <LimitExceedAlertMessage socialMedia={socialMedia} /> : null}
              {hashTagsLimit.length > 30 ? <LimitExceedAlertMessage socialMedia={socialMedia} isHashTagLimit /> : null}
            </>
          ) : socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST && !selectedMediaList.length ? (
            <SocialMediaAlertMessage message={CREATOR_TEXT_EDITOR_ALERT_MSG.PINTEREST} />
          ) : IDEAL_LENGTH_OF_SOCIAL_MEDIA_POSTS_BY_PLATFORM.includes(socialMedia) && customText[socialMedia].length > ADD_POST_CONTENT_MAX_TEXT_LENGTH[socialMedia.toUpperCase()] ? (
            <LimitExceedAlertMessage socialMedia={socialMedia} />
          ) : isPhoneNumber ? (
            <SocialMediaAlertMessage message={CREATOR_TEXT_EDITOR_ALERT_MSG.GOOGLE} />
          ) : null /* contentObj &&
          !customText[socialMedia] &&
          selectedMediaList.length &&
          [COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN, COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE].includes(socialMedia) &&
          selectedMediaList[0]?.media_type === MG_MEDIA_TYPE.VIDEO ? null : [COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK].includes(socialMedia) &&
          selectedMediaList[0]?.media_type !== MG_MEDIA_TYPE.VIDEO ? null : */
        }
        <CreatorTextEditor
          className="at-textarea"
          emojiClassName="roundedbtn ap-emoji-btn"
          emojiKey="brand-creator"
          isShowAiButton={isRallioai}
          currentSocialMediaTab={socialMedia}
          disableEmoji={
            socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER && customText[socialMedia]?.length > ADD_POST_CONTENT_MAX_TEXT_LENGTH.TWITTER - 3
              ? true
              : socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && customText[socialMedia]?.length > ADD_POST_CONTENT_MAX_TEXT_LENGTH.INSTAGRAM - 3
              ? true
              : socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN && customText[socialMedia]?.length > ADD_POST_CONTENT_MAX_TEXT_LENGTH.LINKEDIN - 3
              ? true
              : socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE && customText[socialMedia]?.length > ADD_POST_CONTENT_MAX_TEXT_LENGTH.GOOGLE - 3
              ? true
              : false
          }
          disableEditor={contentObj?.isPostLock || isStoriesAdded || false}
          editorState={textEditorState[socialMedia] || createEmptyEditorState()}
          onEditorChange={(currentValue) => handleUpdateSocialMediaText(socialMedia, currentValue)}
          isEmojiListOpen={isEmojiOpen}
          onEmojiClick={() => {
            dispatch(addPostIsEmojiOpen(!isEmojiOpen));
          }}
          isCoachMark={[SIDE_NAV_NAME.CONTENT_CAMPAIGNS, SIDE_NAV_NAME.CONTENT_CALENDAR].includes(subNavPageName) ? false : convertEditorStateToString(textEditorState[socialMedia]) ? false : true}
          isMentionEnabled={socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && isFBPageTaggingEnabled}
          suggestionList={facebookTags}
          onAddSuggestionTag={(suggestionData) => {
            dispatch(addPostGetSelectedFBTagList([...selectedFBTagList, suggestionData]));
          }}
          isAiButtonDisable={isAiContentOpen}
          handleAiClick={handleAiClick}
          onSuggestionSearchChange={handleTagSearch}
          renderExtra={() => (
            <div>
              <div className={`${isStoriesAdded ? 'd-none' : ''}`}>
                <RenderAddContentList
                  socialMedia={socialMedia}
                  contentObj={contentObj}
                  addContentList={addContentList}
                  setEditorState={setEditorState}
                  textEditorState={textEditorState}
                  editableSocialMedia={editableSocialMedia}
                  setEditableSocialMedia={setEditableSocialMedia}
                  selectedMediaList={selectedMediaList}
                  linkData={linkData}
                  dispatch={dispatch}
                />
              </div>
              <CreatorTextCount socialMedia={socialMedia} currentTextCount={customText[socialMedia]?.length || 0} />
            </div>
          )}
        />

        <CreatorInstagramPhotos
          socialMedia={socialMedia}
          isActionEnabled={!contentObj?.isPostLock}
          isOpacityEnabled={Boolean(contentObj && !contentObj.postStatus[`${socialMedia}`])}
          handleAddImages={() => setModalName(AP_ADD_TO_POST_TAB_NAMES.PHOTOS)}
          currentTab={currentTab}
        />
        <LeftCreatorMediaList socialMedia={socialMedia} contentObj={contentObj} setModalName={setModalName} isShowAiImageTab={isShowAiImageTab} dispatch={dispatch} />
        {!selectedMediaList.length && (isLinkFetching || linkData?.url) && (
          <div className={`creator-addcoupon-active-wrp${!contentObj.postStatus[socialMedia] ? ` button-opacity` : ``}`}>
            {isLinkFetching ? (
              <Loading isSmall />
            ) : (
              <CouponOrLinkPreview
                type={AP_PREVIEW_TYPES.LINK}
                // index={contentObj?.linkIndex || 0}
                // imagesList={linkData?.link_preview_image_url_choices || []}
                previewTitle={linkData?.link_preview_title || ''}
                linkUrl={linkData?.url || ''}
                thumbnailShow={isThumbnailShow || false}
                description={linkData?.link_preview_description || ''}
                // handleUpdateIndex={(indexValue) =>
                //   dispatch(
                //     addPostGetSelectedContentObj({
                //       ...contentObj,
                //       linkIndex: indexValue
                //     })
                //   )
                // }
                handleUpdateThumbnail={() =>
                  dispatch(
                    addPostGetSelectedContentObj({
                      ...contentObj,
                      isThumbnailShow: !isThumbnailShow
                    })
                  )
                }
                handleDeletePreview={() => handleDeletePreview()}
                activeImgUrl={linkData?.link_preview_image_url || ''}
              />
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="icon-tabs">
        <ul className="nav nav-posts nav-tabs" role="tablist">
          {socialMediaList.map((mediaDatum) => {
            const socialMediaLabel = CREATOR_SOCIAL_MEDIAS.includes(mediaDatum) ? COMMON_SOCIAL_MEDIA_NAME[mediaDatum.toUpperCase()] : COMMON_SOCIAL_MEDIA_NAME.GOOGLE;
            return COMMON_SOCIAL_PROVIDER_TYPE.YELP !== mediaDatum ? (
              <li key={mediaDatum} className="nav-item">
                <button
                  type="button"
                  onClick={() => handleSocialMediaSelect(mediaDatum)}
                  data-rr-ui-event-key={mediaDatum}
                  className={`${contentObj.postStatus[mediaDatum] ? 'content-added' : 'selected-media'} nav-link ${currentTab === mediaDatum ? 'active' : ''}  ${
                    contentObj?.isPostLock ? 'pointer-events-none' : ''
                  }`}
                >
                  {socialMediaLabel}
                </button>
              </li>
            ) : null;
          })}
        </ul>
        <div className="tab-content">
          {renderSocialMediaTabDetails(
            currentTab || COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK,
            CREATOR_SOCIAL_MEDIAS.includes(currentTab)
              ? currentTab === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER
                ? COMMON_SOCIAL_MEDIA_NAME.X
                : COMMON_SOCIAL_MEDIA_NAME[currentTab.toUpperCase()]
              : COMMON_SOCIAL_MEDIA_NAME.GOOGLE
          )}
          <div className="add__post--main">
            {/* <div className="add__post__title">Add to Your Post</div> */}
            <div className="post__icon--wrp">
              <AddToYourPostSection />
            </div>
          </div>
          <div className="ap-emoji cp-emoji creator-emoji">
            {isEmojiOpen && (
              <Picker
                previewPosition="none"
                onEmojiSelect={(emo: BaseEmoji) => {
                  let contentState = (textEditorState[currentTab] || createEmptyEditorState()).getCurrentContent();
                  const currentSelection = (textEditorState[currentTab] || createEmptyEditorState()).getSelection();

                  contentState = contentState.createEntity('EMOJI', 'IMMUTABLE', `${emo.native}\u200A`);
                  const emoEntityKey = contentState.getLastCreatedEntityKey();

                  const newContentState = Modifier.insertText(contentState, currentSelection, `${emo.native}\u200A`, undefined, emoEntityKey);
                  let newEditorState = EditorState.push(textEditorState[currentTab] || createEmptyEditorState(), newContentState, 'insert-characters');

                  newEditorState = EditorState.moveFocusToEnd(newEditorState);
                  newEditorState = EditorState.forceSelection(newEditorState, newContentState.getSelectionAfter());

                  handleUpdateSocialMediaText(currentTab, newEditorState);
                }}
              />
            )}
          </div>
        </div>
        {Boolean(customText[currentTab].match(PERSONALIZED_TAG_REQEX)) && (
          <div className="personalizedTag">
            <ImageValidation isImgValid defaultImg="i-orange" customName={'personalizedTag'} />
            <span>It appears you are attempting to insert a local field into this post. To successfully localize a post, check the "Localize Post" box and insert the field below.</span>
          </div>
        )}
        <CreatePersonalizedVersion activeSocialMedia={currentTab} isPersonalizedDDOpen={isPersonalizedDDOpen} setIsPersonalizedDDOpen={setIsPersonalizedDDOpen} />
      </div>
    </>
  );
};
