import { useEffect, useCallback, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { appRoutes } from './app-routes/app-routes';
import { PublicRoute } from './app-routes/public-route';
import { InteractiveIcons } from 'widgets/Icons';
import { PrivateRoute } from './app-routes/private-route';
import { commonRouterTypes, IStore } from 'types';
import { ADMIN_AREA_ROUTES } from 'utils/constants';
import { loadConfigurationRequest, getLoginUserDataRequest, resetPartnerBanner } from 'actions';
import { EnvConfigs } from 'config';
import { clearStorage, isInIFrame } from 'utils/helpers';
import { Filter, Header, NavBar, PartnerBanners } from 'layouts';
import { Loading, PageNotFound } from 'components';
import { useMenuAccessibility } from './app-routes/use-menu-accessibility';
import { ExportHandler } from 'revv/containers/export-handler';
import { loginRequest, settingsRequest } from 'revv/actions';
import { activeModal } from 'revv/utils';
import { ErrorRouteModal } from 'components/Common/Modal/ErrorRouteModal';
import { useCheckInvalidUrl } from 'utils/hooks';

export const Router = () => {
  const dispatch = useDispatch();
  const { isShowModal } = useCheckInvalidUrl();
  const { publicRoutes, privateRoutes, superAdminRoutes }: commonRouterTypes.IAppRoutes = appRoutes;
  const { menuAvailablity } = useSelector((state: IStore) => state.menuAccessiblity);
  const isAuthenticated = useSelector((state: IStore) => state.login.isAuthenticated);
  const isRevvAuthenticated = useSelector((state: IStore) => state.revv.login?.isAuthenticated);
  const isAdmin = useSelector((state: IStore) => state.login.activeUser?.admin);

  const activeUserId = useSelector((state: IStore) => state.login.activeUser?.id);
  const isAdminUser = useSelector((state: IStore) => state.login.activeUser?.admin) || false;
  const isContentWriter = useSelector((state: IStore) => state.login.activeUser?.content_writer) || false;
  const isFranchisor = useSelector((state: IStore) => state.login.activeUser?.franchisor) || false;
  const mobileFilter = useSelector((state: IStore) => state.common.mobileFilter);
  const isSSO = useSelector((state: IStore) => state.login.isSSO);
  const leftNav = useSelector((state: IStore) => state.common.leftNav);
  const error = useSelector((state: IStore) => state.errorBoundary.error);
  const isDataLoading = useSelector((state: IStore) => state.common.isDataLoading);
  const revvReference = useSelector((state: IStore) => state.login.activeUser?.revv_reference);
  const activeFilteOption = useSelector((state: IStore) => state.revv.filter?.activeFilterOptions);
  const isCoachMark = useSelector((state: IStore) => state.aiContentCreator.isCoachMark);
  const isContentSupplier = useSelector((state: IStore) => state.franchisors.franchisorDetails?.content_supplier);
  const isPartnerBanner = useSelector((state: IStore) => state.franchisors.franchisorDetails?.banner_partner);

  const isAdminScreenEnabledFunc = () => {
    const pathArray = window.location.pathname.split('/');
    return isAdmin && ADMIN_AREA_ROUTES.includes(`/${pathArray[1]}/${pathArray[2]}`);
  };

  // const isFilterEnabledFunc = () => {
  //   const pathArray = window.location.pathname.split('/');
  //   return !['/ai_engine/chat'].includes(`/${pathArray[3]}/${pathArray[4]}`);
  // };

  // const isFilterMenuEnabled = isFilterEnabledFunc();
  const isAdminScreenEnabled = isAdminScreenEnabledFunc();
  const renderRoutes = useCallback(
    () => (
      <>
        {publicRoutes.map((it: any, index: any) => {
          return <Route key={index} path={it.route} element={<PublicRoute routeObj={it} />} />;
        })}

        {superAdminRoutes.map((it: any, index: number) => {
          const isMenuAccess = it.isMenuAccessible;
          // Object.keys(SUPER_ADMIN_MENU_AVAILABLITY).forEach((menuItem: string) => {
          //   if (menuItem === it.menuAccessibleName) isMenuAccess = SUPER_ADMIN_MENU_AVAILABLITY[menuItem];
          // });
          return (
            <Route key={index} path={it.route} element={<PrivateRoute routeObj={it} isMenuAccessible={isMenuAccess} />}>
              {it.children?.map((child: any) => {
                const Component = child.component;
                return <Route key={index} path={child.route} element={<Component />} />;
              })}
            </Route>
          );
        })}

        {privateRoutes.map((it: any, index: number) => {
          let isMenuAccess = it.isMenuAccessible;
          Object.keys(menuAvailablity).forEach((menuItem: any) => {
            if (menuItem === it.menuAccessibleName) isMenuAccess = menuAvailablity[menuItem];
          });
          return (
            <Route key={index} path={it.route} element={<PrivateRoute routeObj={it} isMenuAccessible={isMenuAccess} />}>
              {it.children?.map((child: any) => {
                const Component = child.component;
                return (
                  <Route
                    key={index}
                    path={child.route}
                    element={
                      <Suspense fallback={<Loading />}>
                        <Component />
                      </Suspense>
                    }
                  />
                );
              })}
            </Route>
          );
        })}

        <Route
          path="*"
          element={
            <Suspense fallback={<Loading />}>
              <PageNotFound />
            </Suspense>
          }
        />
      </>
    ),
    [menuAvailablity, publicRoutes, privateRoutes, superAdminRoutes]
  );

  useEffect(() => {
    if (isAuthenticated && activeUserId) {
      dispatch(getLoginUserDataRequest(activeUserId));
    }
  }, [isAuthenticated, activeUserId, dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(loadConfigurationRequest());
    } else {
      indexedDB.deleteDatabase('localforage');
    }
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    if (isInIFrame()) {
      window.self.onbeforeunload = () => {
        clearStorage();
      };
    }
    return () => {
      dispatch(resetPartnerBanner());
    };
  }, [dispatch]);

  useMenuAccessibility();

  // Revv login and settings request
  useEffect(() => {
    if (revvReference?.sso_token && !isRevvAuthenticated) {
      dispatch(loginRequest({ token: revvReference.sso_token }));
    }
  }, [revvReference, isRevvAuthenticated, dispatch]);

  useEffect(() => {
    if (isRevvAuthenticated) {
      dispatch(settingsRequest());
    }
  }, [dispatch, isRevvAuthenticated]);

  return (
    <>
      <EnvConfigs />
      <BrowserRouter>
        {
          /* isLoginFetching || isUserDataFetching || isEnvFetching ? (
          <Loading />
        ) : */ isAuthenticated ? (
            <>
              <div
                className={`ra-page filter-open ${mobileFilter ? 'mob-fltr-open' : ''}${leftNav ? 'main-nav-open' : ''}
              ${isAdminScreenEnabled ? 'ad-scrn' : 'usr-scrn'} ${isAdminUser || isContentWriter || isFranchisor ? 'ac-franchisor' : 'ac-location'}
              ${isSSO ? 'nt-wrap' : ''} ${activeModal(activeFilteOption) ? 'revv-layout__modal--open' : ''}
              `}
              >
                <Header />
                <ExportHandler />
                {isShowModal && !isInIFrame() ? <ErrorRouteModal /> : null}
                {isDataLoading ? (
                  <Loading />
                ) : (
                  <main className={`global ${isAdminScreenEnabled ? 'admin-page' : 'userSelect'} ${isCoachMark ? 'backdrop-cntnr' : ''}`} id="app-main-container">
                    {!error ? <NavBar /> : null}
                    <div className="g-centre">
                      <Routes>{renderRoutes()}</Routes>
                    </div>
                    {!error /* && isFilterMenuEnabled */ ? (
                      <section className={`item-g filter mainFilterWrap`} id="filterScrollTarget">
                        {Boolean(!isContentSupplier && !isPartnerBanner) ? <PartnerBanners /> : null}
                        {<Filter />}
                      </section>
                    ) : null}
                  </main>
                )}
              </div>
            </>
          ) : (
            <Routes>{renderRoutes()}</Routes>
          )
        }
        <InteractiveIcons customClassname="img-effect" />
      </BrowserRouter>
    </>
  );
};
