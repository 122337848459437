import { useCallback, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router';

import { IStore, mgReducerTypes } from 'types';
import { setAISubscriptionDetailsModal, setAIPostContentOpen, setCreatorAITabName, setCsLocationSubscriptionModalType } from 'actions';
import {
  COMMON_PAGE_TYPE,
  RIPPLE_COLOR,
  MG_FILTER_INITIAL_VALUE,
  IMAGE_SUPPORTED_FORMAT,
  VIDEO_SUPPORTED_FORMAT,
  UTC_TIMESTAMP_FORMAT,
  AI_ICON_BG_IMG,
  AI_IMAGE_GENERATION_TAB_NAMES,
  MG_FILE_SUPPORTED_FORMAT,
  MediaDropPages,
  IAISugesstionTabs,
  IAIResultsTab
} from 'utils/constants';
import { debounceMethod, dateFormatByRequestedFormat, getCurrentDate, uploadFileToCloud } from 'utils/helpers';
import { useParamsDeconstructor, useAccountSwitcherData, useAIFeatureAccess, useSSOIdentifiersForAccount, useSiteRestriction } from 'utils/hooks';
import { ImageValidation } from 'widgets/Media';
import { WatermarkUploadModal } from 'containers/Content/MediaGallery/SubPages/WatermarkUploadModal';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { AIContents } from 'containers/Content/Creator/AIContent';

export const MediaGalleryStats = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { queryParams } = useParamsDeconstructor(MG_FILTER_INITIAL_VALUE);
  const { aiImageAccess } = useAIFeatureAccess();
  const { aiRestriction } = useSiteRestriction();

  const { id, userOwnership, currentRoute, optionalParams } = useAccountSwitcherData();
  const { isCSLocation } = useSSOIdentifiersForAccount();

  const isListFetching = useSelector((state: IStore) => state.mediaGallery.isListFetching);
  const addedAssetsCount = useSelector((state: IStore) => state.mediaGallery.uploadCloudinaryMedia.addedAssetsCount);
  const response = useSelector((state: IStore) => state.mediaGallery.uploadCloudinaryMedia.response);
  const showWatermarkModal = useSelector((state: IStore) => state.mediaGallery.watermarkUpload.showModal);
  const isAISubscribed = useSelector((state: IStore) => state.aiContentCreator.isAISubscribed);
  const isRallioai = useSelector((state: IStore) => state.accountSwitcher?.rallio_ai || false);
  const aiLimitation = useSelector((state: IStore) => state.aiContentCreator.aiLimitation);
  const isAiContentOpen = useSelector((state: IStore) => state.aiContentCreator.isAiContentOpen);

  const [searchText, setSearchText] = useState(queryParams?.text || '');

  const mgListApiTimeout = useRef<null | NodeJS.Timeout>(null);

  const mediaType = optionalParams[0];

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const currentUnixTimestamp = dateFormatByRequestedFormat(getCurrentDate(), UTC_TIMESTAMP_FORMAT);
      if (currentUnixTimestamp) {
        uploadFileToCloud({
          acceptedFiles,
          response,
          addedAssetsCount,
          from: MediaDropPages.MEDIA_GALLERY,
          dispatch,
          aiPlaybookDocType: null,
          id,
          userOwnership,
          currentRoute,
          queryParams,
          optionalParams,
          navigate
        });
      }
    },
    [addedAssetsCount, response, optionalParams[1]] // eslint-disable-line
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: [...IMAGE_SUPPORTED_FORMAT, ...VIDEO_SUPPORTED_FORMAT, ...MG_FILE_SUPPORTED_FORMAT],
    noClick: false,
    onDrop,
    disabled: isListFetching
  });

  const handleInputSearch = (value: string) => {
    handleChange({ search: value });
  };

  const handleChange = (data: Partial<mgReducerTypes.IMgFiltersVal>) => {
    if (data.search) queryParams.text = data.search;
    else delete queryParams.text;
    navigate({ search: `?${new URLSearchParams(queryParams).toString()}` });
  };

  const routePush = (path: string, searchParams: { [key: string]: string } = {}) => {
    navigate({ pathname: path, search: searchParams ? `?${new URLSearchParams(searchParams).toString()}` : `` });
  };

  const handleCSSubscription = () => {
    dispatch(setAISubscriptionDetailsModal({ type: 'CSLocationAISubscription', isOpen: true }));
    dispatch(setCsLocationSubscriptionModalType('ai_caption'));
  };

  const handleRoute = () => {
    if (!isAISubscribed) {
      if (!isCSLocation) {
        dispatch(setAISubscriptionDetailsModal({ type: 'trialPeriod', isOpen: true }));
      } else {
        handleCSSubscription();
      }
    } else if (Boolean(aiLimitation && +aiLimitation?.ai_caption_limit <= 0)) {
      handleCSSubscription();
    } else {
      dispatch(setAIPostContentOpen(!isAiContentOpen));
      routePush(`/${userOwnership}/${id.toString()}/${currentRoute}/${mediaType}/${AI_IMAGE_GENERATION_TAB_NAMES.CREATE_AI_IMAGE}`, queryParams);
      dispatch(setCreatorAITabName({ parent: IAISugesstionTabs.IMAGES, child: IAIResultsTab.IMAGES }));
    }
  };

  return (
    <>
      <input {...getInputProps()} />
      <div className="lpx vpy-20">
        <div className="cont__media--head r-flx r-flx-ac g-20">
          <div
            // className="upload-dotted-box m-0 scl-controls drag__drop--box"
            className="drag__drop--box flex-fill"
            {...getRootProps({
              onClick: (event) => event
            })}
          >
            <div className="drag__drop--txt">
              <div className="single-icon">
                <ImageValidation isImgValid defaultImg={'upload-blue'} customName="Upload Asset" />
              </div>
              <span>Drag &amp; Drop your files here to upload (Select video up to 500 MB)</span>
            </div>
            <CustomRippleButton rippleClass={`ripple-unset ac-primary-box${isListFetching ? `pointer-events-none` : ``}`} custColor={RIPPLE_COLOR.primary}>
              {/* <button className={`ac-btn ac-primary ${isListFetching ? `disabled` : ``}`} disabled={isListFetching} onClick={open}> */}
              <button className={`drag__drop--btn ${isListFetching ? `disabled` : ``}`} disabled={isListFetching} onClick={open}>
                Browse
              </button>
            </CustomRippleButton>
            {/* {userOwnership === USER_OWNERSHIP.FRANCHISOR && !parentFranchisorId ? (
          <Button
            className='primeblueaction cmn-blue-btn upload-wm'
            disabled={isListFetching}
            variant='primary'
            onClick={() => dispatch(showHideWatermarkModal({ show: true }))}
          >
            {`${watermarkObj ? 'Change' : 'Upload'} Watermark Image`}
          </Button>
        ) : null} */}
          </div>

          {isRallioai && aiImageAccess && !aiRestriction.button ? (
            <div onClick={() => handleRoute()} className={`aiG-wrap r-flx r-flx-ac ${isAiContentOpen ? 'pointer-events-none' : ''}`}>
              <div className="aiGBtn">
                <ImageValidation isImgValid defaultImg={AI_ICON_BG_IMG.DARK_BLUE} isNotSvgFormat customName="AI" customClassname="" />
              </div>
              <div className="aiGTxt">
                <h5>
                  Generate<span>AI Images</span>
                </h5>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {(optionalParams.length === 1 || optionalParams[1] === COMMON_PAGE_TYPE.FAVORITE_TAGS) && (
        <div className={`search-tk lpx r-mt0`}>
          <div className="react-tags">
            <div className="react-tags__search">
              <div className="react-tags__search-input r-mt0">
                <input
                  type="text"
                  placeholder="Search text"
                  autoComplete="off"
                  value={searchText}
                  onChange={(event) => {
                    setSearchText(event.target.value);
                  }}
                  onKeyUp={() => (mgListApiTimeout.current = debounceMethod(searchText.trim(), mgListApiTimeout.current, handleInputSearch))}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {showWatermarkModal ? <WatermarkUploadModal /> : null}
      {optionalParams[1] === AI_IMAGE_GENERATION_TAB_NAMES.CREATE_AI_IMAGE && <AIContents editedContent={''} />}
    </>
  );
};
