export const imageFileDownload = (fileId: string) => {
  return `https://app-aiengineapi.rall.io/file/download/${fileId}`;
};

export const modifiedMessages = (content: any, assistantResponse?: string) => {
  let newResponse = assistantResponse || '';

  if (content.type === 'text') {
    if (content.text.value !== null) {
      newResponse += content.text.value;
    } else if (content.text.annotations?.length >= 1) {
      for (const annotationData of content.text.annotations) {
        if (newResponse?.includes(annotationData.text)) {
          const base64 = imageFileDownload(annotationData.file_path.file_id);
          const escapedText = annotationData.text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
          const markdownImagePattern = new RegExp(`!\\[.*?\\]\\(${escapedText}\\)`, 'g');

          if (markdownImagePattern.test(newResponse)) {
            // newResponse = newResponse.replace(tempData, "");
            // setTempData('')
          }
          newResponse = newResponse.replace(annotationData.text, `${base64}`);
        }
      }
    }
  } else if (content.type === 'image_file') {
    const base64 = imageFileDownload(content.image_file.file_id);
    // tempData = (`![Alt Text](${base64})\n\n`);
    newResponse += `![Alt Text](${base64})\n\n`;
  }

  return newResponse || '';
};
