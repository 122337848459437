import { useRef } from 'react';
import { ReactTags, ReactTagsAPI, TagSuggestion } from 'react-tag-autocomplete';

import { commonWidgetTypes } from 'types';
import { REACT_TAG_DEFAULT_CLASSES } from 'utils/constants';

export const CustomReactTags = ({
  tags,
  suggestions,
  handleAddition,
  handleDelete,
  inputAttributes,
  handleInputChange,
  placeholder,
  autofocus = false,
  classNames,
  allowNew,
  maxSuggestionsLength,
  minQueryLength,
  disabled,
  delimiterKeys
}: commonWidgetTypes.ICustomReactTags) => {
  const tagRef = useRef<ReactTagsAPI>(null);
  return (
    <ReactTags
      ref={tagRef}
      selected={tags || []}
      suggestions={suggestions || []}
      onAdd={(tag: TagSuggestion) => handleAddition(tag)}
      onDelete={(index: number) => {
        if (handleDelete) {
          handleDelete(index);
        }
      }}
      renderInput={({ classNames, inputWidth, ...inputProps }) => {
        return <input {...inputProps} {...(inputAttributes && { ...inputAttributes })} className={REACT_TAG_DEFAULT_CLASSES.input} autoFocus={autofocus} style={{ width: inputWidth }} />;
      }}
      onInput={(search: string) => handleInputChange(search)}
      placeholderText={placeholder || ''}
      classNames={{
        ...REACT_TAG_DEFAULT_CLASSES,
        ...(!allowNew && !suggestions?.length && { listBox: `${REACT_TAG_DEFAULT_CLASSES.listBox} d-none` }),
        ...(classNames && { ...classNames })
      }}
      suggestionsTransform={(value, suggestions) => {
        if (value.length >= (minQueryLength || 1) && suggestions.length) {
          return suggestions.slice(0, maxSuggestionsLength);
        }
        return [];
      }}
      allowNew={allowNew && !suggestions?.find((it) => it.label === tagRef?.current?.input?.value)}
      labelText=""
      isDisabled={disabled}
      noOptionsText=""
      onShouldCollapse={() => false}
      newOptionPosition="first"
      activateFirstOption
      delimiterKeys={delimiterKeys}
    />
  );
};
