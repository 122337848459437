import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Button, Modal } from 'react-bootstrap';
import Masonry from 'react-masonry-css';
import { Tag } from 'react-tag-autocomplete';

import { commonModalPopup, IStore } from 'types';
import { CustomReactTags } from 'widgets/CustomReactTags';
import { Loading, NoDataFound } from 'components';
import { BREAK_POINT_COLUMNS_OBJ, NoDataFoundProps, SCROLL_THRESHOLD, MgMediaType, MG_SOURCE_TYPE, MEDIA_PAGINATION_PER_PAGE } from 'utils/constants';
import { ImageValidation } from 'widgets/Media';
import { getMGListRequest, couponsSetNewCouponObj, postsTagsRequest, addPostSetTags } from 'actions';
import { formNewInternalTag } from 'utils/helpers';
import { useAccountSwitcherData } from 'utils/hooks';

export const CouponBrowseImagesList = ({ isModalShow, handleModalClose }: commonModalPopup.IULAddNewUserModalPopup) => {
  const dispatch = useDispatch();
  const { id, userOwnership } = useAccountSwitcherData();

  const internalTagsList = useSelector((state: IStore) => state.posts.postsTagsList);
  const selectedTags = useSelector((state: IStore) => state.addPostAccount.selectedTags);
  const isListFetching = useSelector((state: IStore) => state.mediaGallery.isListFetching);
  const assetsList = useSelector((state: IStore) => state.mediaGallery.assetsList);
  const newCouponObj = useSelector((state: IStore) => state.coupons.newCouponObj);

  const [searchValue, setSearchValue] = useState<null | string>(null);
  const [currentPageCount, setCurrentPageCount] = useState(1);
  const [selectedImageId, setSelectedImageId] = useState(0);

  const tagsList = formNewInternalTag(internalTagsList, searchValue);

  useEffect(() => {
    if (isModalShow) setSelectedImageId(0);
  }, [isModalShow]); // eslint-disable-line

  useEffect(() => {
    if (id && userOwnership && isModalShow && selectedTags.length) {
      dispatch(addPostSetTags([]));
    }
  }, [id, userOwnership, isModalShow]); // eslint-disable-line

  useEffect(() => {
    if (id && userOwnership && isModalShow) {
      handleMediaRequest(currentPageCount);
    }
  }, [id, userOwnership, isModalShow, selectedTags.length]); // eslint-disable-line

  useEffect(() => {
    if (currentPageCount > 1) setCurrentPageCount(1);
  }, [selectedTags.length]); // eslint-disable-line

  const handleSearchChange = () => {
    if (currentPageCount > 1) setCurrentPageCount(1);
    handleMediaRequest(1);
  };

  const handleLoadMoreItems = () => {
    setCurrentPageCount(currentPageCount + 1);
    handleMediaRequest(currentPageCount + 1);
  };

  const handleMediaRequest = (currentPage: number) => {
    if (id && userOwnership && isModalShow) {
      dispatch(
        getMGListRequest({
          media_type: MgMediaType.PHOTOS,
          franchisor_id: id,
          page: currentPage,
          per: MEDIA_PAGINATION_PER_PAGE,
          source: MG_SOURCE_TYPE.ALL,
          ...(searchValue && { search: searchValue }),
          ...(selectedTags.length && { tags: selectedTags.map((datum) => datum.label) })
        })
      );
    }
  };

  const handleSelectCouponImage = () => {
    const selectedImg = assetsList.find((datum) => datum.id === selectedImageId)?.medium_size_url || '';
    if (selectedImg) {
      dispatch(couponsSetNewCouponObj(newCouponObj ? { ...newCouponObj, imageUrl: selectedImg } : null));
      handleModalClose();
    }
  };

  const handleAddition = (tag: Tag) => {
    tag.value = !internalTagsList.length ? Math.floor(Math.random() * 100) : tag.value;
    tag.label = !internalTagsList.length ? tag.label.replace('Add ', '') : tag.label;
    const addedTagsArray: Tag[] = [...selectedTags, tag];
    dispatch(addPostSetTags(addedTagsArray));
    setSearchValue(null);
  };

  const handleDeleteTag = (tagName: string) => {
    const retainedTagsArray = selectedTags.filter((datum) => datum.label !== tagName);
    dispatch(addPostSetTags(retainedTagsArray));
  };

  const handleInputChange = (search: string) => {
    setSearchValue(search || null);
    if (search) {
      dispatch(postsTagsRequest({ searchTag: search }));
    }
  };

  const handleCloseBrowseImgModal = () => () => {
    setCurrentPageCount(1);
    handleModalClose();
  };

  const renderMediaList = () => {
    return (
      <div id="scrollTarget-coupon" className={`masonry-list-view-group`}>
        <Masonry breakpointCols={BREAK_POINT_COLUMNS_OBJ} className="masonry-grid" columnClassName="masonry-grid_column">
          {assetsList.map((assetsItem, index: number) => {
            return (
              <div key={`coupons-assets-item-${index}`} className={`m-item${selectedImageId === assetsItem.id ? ` active` : ``}`} onClick={() => setSelectedImageId(assetsItem.id)}>
                <div className="m-ast">
                  <ImageValidation imgUrl={assetsItem.medium_size_url} customName="media-asset" customClassname="m-ast-itm m-ast-img" />
                </div>
                <div className="m-ast-dtls">
                  <div className="mastg-main">
                    <div className="mast-prime-dtls">
                      <div className="mast-prof-txt">
                        <span className="sm-txt-top"> {assetsItem.name}</span>
                        <span className="lg-txt-base">
                          <span className="mlg-label">Uses</span>
                          <span className="mlg-count"> {assetsItem.used_count || 0}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Masonry>
      </div>
    );
  };

  return (
    <Modal
      className="add-assets profile-imagery-br__modal coupon-cil coupons-tab"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
      show={isModalShow}
      animation={false}
      onHide={handleCloseBrowseImgModal}
    >
      <Modal.Header>
        <div className="brow-text-wrp">
          <div className="mp-back" onClick={handleCloseBrowseImgModal}>
            <ImageValidation isImgValid defaultImg="arw-full-left" customName="back" />
          </div>
          <div className="bro-img-text">Images Library</div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="addTabs">
          <div className="at-cnt coupon-browe-scroll-wrp">
            <div className="search-tk">
              <CustomReactTags
                key={`${id}${userOwnership}`}
                suggestions={tagsList}
                inputAttributes={{
                  onDoubleClick: () => handleSearchChange()
                  // onKeyUp: (evt: React.KeyboardEvent) => {
                  //   if (evt.keyCode === 13) handleSearchChange();
                  // }
                }}
                handleInputChange={(search: string) => handleInputChange(search)}
                maxSuggestionsLength={tagsList.length}
                handleAddition={(tag: Tag) => handleAddition(tag)}
                handleDelete={() => false}
                placeholder={'Search Tags and Keywords'}
                autofocus={false}
                allowNew={false}
              />
            </div>

            {selectedTags.length ? (
              <div className="favoutite-tag-wrp-secction">
                <div className="filter-item">
                  <div className="imtc">
                    <h3>Tags</h3>
                  </div>
                  <div className="fts">
                    {selectedTags.map((tagDatum, index) => {
                      return (
                        <span key={`coupon-tags-${index}`} className={'fav-tags active'}>
                          {tagDatum.label}
                          <span className="remove-btn" onClick={() => handleDeleteTag(tagDatum.label)} />
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : null}

            {isListFetching && currentPageCount === 1 ? (
              <Loading />
            ) : assetsList.length ? (
              <InfiniteScroll
                scrollThreshold={SCROLL_THRESHOLD}
                next={handleLoadMoreItems}
                hasMore
                dataLength={assetsList.length}
                loader={isListFetching ? <Loading /> : null}
                className="local-ini"
                scrollableTarget="scrollTarget-coupon"
                height={350}
              >
                {renderMediaList()}
              </InfiniteScroll>
            ) : !isListFetching && !assetsList.length ? (
              <NoDataFound size={NoDataFoundProps.FULL} />
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" disabled={!selectedImageId} onClick={() => setSelectedImageId(0)}>
          Clear
        </Button>
        <Button variant="primary" disabled={!selectedImageId} onClick={() => handleSelectCouponImage()}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
