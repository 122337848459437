import { settingsRallioProfileContainerTypes } from 'types';

export const RALLIO_PROFILE_TAB_FILEDS: settingsRallioProfileContainerTypes.IRPTabFeilds = {
  NAME: 'name',
  ADDRESS: 'street',
  STORE_CODE: 'short_name',
  CITY: 'city',
  PHONE_NUMBER: 'phone',
  ZIP_OR_POSTAL_CODE: 'zip',
  BUSINESS_NAME: 'business_name',
  BUSINESS_PHONE: 'phone_number',
  BUSINESS_MAILING: 'mailing_address',
  BUSINESS_EMAIL: 'business_email',
  WEBSITE_URL: 'url',
  STATE_OR_PROVINCE: 'state',
  COUNTRY: 'country_code',
  TIME_ZONE: 'time_zone',
  JOB_PAGE_URL: 'job_page_url',
  MANAGER: 'person_to_reach',
  REQUIRED_FIELD: 'required_field',
  CUSTOM_FIELDS: 'custom_fields',
  PREFERRED_LANGUAGE: 'preferred_language',
  ENABLE_AUTOMATIC_POST_TRANSLATIONS: 'enable_automatic_post_translations',
  ACTION_STATUS: 'action_status',
  SAVED_HASHTAGS: 'saved_hashtags'
};
export const RALLIO_PROFILE_PERSONALIZED_FIELD_LABELS: Record<string, string> = {
  NAME: 'Name',
  PHONE_NUMBER: 'Phone Number',
  BUSINESS_NAME: 'Business Name',
  MAILING_ADDRESS: 'Mailing Address',
  BUSINESS_EMAIL: 'Business Email',
  URL: 'Website URL',
  JOB_PAGE_URL: 'Job Page URL',
  PERSON_TO_REACH: 'Manager Name',
  CUSTOM_FIELD_1: 'Custom Field 1',
  CUSTOM_FIELD_2: 'Custom Field 2',
  CUSTOM_FIELD_3: 'Custom Field 3',
  CUSTOM_FIELD_4: 'Custom Field 4',
  CUSTOM_FIELD_5: 'Custom Field 5',
  CUSTOM_FIELD_6: 'Custom Field 6',
  CUSTOM_FIELD_7: 'Custom Field 7'
};

export const RALLIO_PROFILE_OBJ_TYPE = {
  // PROFILE_INFO: 'profileInfo',
  ADDRESS: 'address',
  // BUSINESS_INFO: 'businessinfo',
  URL: 'url',
  CUSTOM_FIELDS: 'customFields',
  CTA_FIELDS: 'ctaFields',
  PREFERRED_LANGUAGES: 'preferredLanguages',
  HASHTAGS: 'hashtags'
};

export const SETTINGS_RALLIO_PROFILE_CHECKFILEDS = {
  /*  [RALLIO_PROFILE_OBJ_TYPE.PROFILE_INFO]: {
    [RALLIO_PROFILE_TAB_FILEDS.NAME]: false,
    [RALLIO_PROFILE_TAB_FILEDS.STORE_CODE]: false
  }, */
  [RALLIO_PROFILE_OBJ_TYPE.ADDRESS]: {
    [RALLIO_PROFILE_TAB_FILEDS.NAME]: false,
    [RALLIO_PROFILE_TAB_FILEDS.STORE_CODE]: false,
    [RALLIO_PROFILE_TAB_FILEDS.ADDRESS]: false,
    [RALLIO_PROFILE_TAB_FILEDS.CITY]: false,
    [RALLIO_PROFILE_TAB_FILEDS.STATE_OR_PROVINCE]: false,
    [RALLIO_PROFILE_TAB_FILEDS.ZIP_OR_POSTAL_CODE]: false,
    [RALLIO_PROFILE_TAB_FILEDS.COUNTRY]: false,
    [RALLIO_PROFILE_TAB_FILEDS.PHONE_NUMBER]: false,
    [RALLIO_PROFILE_TAB_FILEDS.TIME_ZONE]: false
  },
  /* [RALLIO_PROFILE_OBJ_TYPE.BUSINESS_INFO]: {
    [RALLIO_PROFILE_TAB_FILEDS.BUSINESS_NAME]: false,
    [RALLIO_PROFILE_TAB_FILEDS.BUSINESS_PHONE]: false,
    [RALLIO_PROFILE_TAB_FILEDS.BUSINESS_MAILING]: false,
    [RALLIO_PROFILE_TAB_FILEDS.BUSINESS_EMAIL]: false,
    [RALLIO_PROFILE_TAB_FILEDS.MANAGER]: false
  }, */
  [RALLIO_PROFILE_OBJ_TYPE.URL]: {
    [RALLIO_PROFILE_TAB_FILEDS.BUSINESS_NAME]: false,
    [RALLIO_PROFILE_TAB_FILEDS.BUSINESS_PHONE]: false,
    [RALLIO_PROFILE_TAB_FILEDS.BUSINESS_MAILING]: false,
    [RALLIO_PROFILE_TAB_FILEDS.BUSINESS_EMAIL]: false,
    [RALLIO_PROFILE_TAB_FILEDS.MANAGER]: false,
    [RALLIO_PROFILE_TAB_FILEDS.JOB_PAGE_URL]: false,
    [RALLIO_PROFILE_TAB_FILEDS.WEBSITE_URL]: false
  },
  [RALLIO_PROFILE_OBJ_TYPE.CUSTOM_FIELDS]: {
    [RALLIO_PROFILE_TAB_FILEDS.CUSTOM_FIELDS]: false
  },
  [RALLIO_PROFILE_OBJ_TYPE.PREFERRED_LANGUAGES]: {
    [RALLIO_PROFILE_TAB_FILEDS.PREFERRED_LANGUAGES]: false,
    [RALLIO_PROFILE_TAB_FILEDS.ENABLE_AUTOMATIC_POST_TRANSLATIONS]: false
  },
  [RALLIO_PROFILE_OBJ_TYPE.HASHTAGS]: {
    [RALLIO_PROFILE_TAB_FILEDS.ACTION_STATUS]: false,
    [RALLIO_PROFILE_TAB_FILEDS.SAVED_HASHTAGS]: false
  }
};

export const RALLIO_PROFIILE_CUSTOM_FIELDS = ['custom_field_1', 'custom_field_2', 'custom_field_3', 'custom_field_4', 'custom_field_5', 'custom_field_6', 'custom_field_7'];
