// AI CONTENT CREATOR
export const AI_CONTENT_CREATOR_REQUEST = 'ai_content/AI_CONTENT_CREATOR_REQUEST';
export const AI_CONTENT_CREATOR_RESPONSE = 'ai_content/AI_CONTENT_CREATOR_RESPONSE';
export const AI_CONTENT_CREATOR_FAILURE = 'ai_content/AI_CONTENT_CREATOR_FAILURE';

// AI REPLY RESPONSE
export const AI_REPLY_RESPONSE_REQUEST = 'ai_content/AI_REPLY_RESPONSE_REQUEST';
export const AI_REPLY_RESPONSE_RESPONSE = 'ai_content/AI_REPLY_RESPONSE_RESPONSE';
export const AI_REPLY_RESPONSE_FAILURE = 'ai_content/AI_REPLY_RESPONSE_FAILURE';

// AI TONE CREATOR
export const AI_TONE_REQUEST = 'ai_content/AI_TONE_REQUEST';
export const AI_TONE_RESPONSE = 'ai_content/AI_TONE_RESPONSE';
export const AI_TONE_FAILURE = 'ai_content/AI_TONE_FAILURE';

// AI POST CONTENT OPEN
export const IS_AI_POST_CONTENT_OPEN = 'ai_content/IS_AI_POST_CONTENT_OPEN';

// IS AI POST OR NOT
export const IS_AI_POST = 'ai_content/IS_AI_POST';

// IS AI POST USED OR NOT
export const IS_AI_POST_USED = 'ai_content/IS_AI_POST_USED';

// RESET STATE
export const RESET_AI_CONTENT_CREATOR = 'ai_content/RESET_AI_CONTENT_CREATOR';

// RESET AI IMAGE COMPLIANCE WARNING
export const RESET_AI_IMAGE_COMPLIANCE_WARNING = 'ai_content/RESET_AI_IMAGE_COMPLIANCE_WARNING';

// SET AI INPUT CONTEXT
export const SET_AI_INPUT_CONTEXT = 'ai_content/SET_AI_INPUT_CONTEXT';

// POSTS - CREATE SIMILAR AI POST
export const AI_CREATE_SIMILAR_POST_REQUEST = 'ai_content/AI_CREATE_SIMILAR_POST_REQUEST';
export const AI_CREATE_SIMILAR_POST_RESPONSE = 'ai_content/AI_CREATE_SIMILAR_POST_RESPONSE';
export const AI_CREATE_SIMILAR_AI_POST_FAILURE = 'ai_content/AI_CREATE_SIMILAR_AI_POST_FAILURE';

// GET AI TAGS LIST
export const GET_AI_TAGS_LIST_REQUEST = 'ai_content/GET_AI_TAGS_LIST_REQUEST';
export const GET_AI_TAGS_LIST_RESPONSE = 'ai_content/GET_AI_TAGS_LIST_RESPONSE';
export const GET_AI_TAGS_LIST_FAILURE = 'ai_content/GET_AI_TAGS_LIST_FAILURE';

// CREATE SIMILAR AI SUBSCRIPTION PLAN
export const AI_CREATE_SUBSCRIPTION_PLAN_REQUEST = 'ai_content/AI_CREATE_SUBSCRIPTION_PLAN_REQUEST';
export const AI_CREATE_SUBSCRIPTION_PLAN_RESPONSE = 'ai_content/AI_CREATE_SUBSCRIPTION_PLAN_RESPONSE';
export const AI_CREATE_SUBSCRIPTION_PLAN_FAILURE = 'ai_content/AI_CREATE_SUBSCRIPTION_PLAN_FAILURE';

// GET SIMILAR AI SUBSCRIPTION PLAN
export const AI_GET_SUBSCRIPTION_PLAN_REQUEST = 'ai_content/AI_GET_SUBSCRIPTION_PLAN_REQUEST';
export const AI_GET_SUBSCRIPTION_PLAN_RESPONSE = 'ai_content/AI_GET_SUBSCRIPTION_PLAN_RESPONSE';
export const AI_GET_SUBSCRIPTION_PLAN_FAILURE = 'ai_content/AI_GET_SUBSCRIPTION_PLAN_FAILURE';

// SET SPECIFIC STATE
export const SET_AI_SUBSCRIPTION_DETAILS_MODAL = 'ai_content/SET_AI_SUBSCRIPTION_DETAILS_MODAL';

// SET SPECIFIC SUBSCRIPTION TPE
export const SET_AI_SUBSCRIPTION_TYPE = 'ai_content/SET_AI_SUBSCRIPTION_TYPE';
// RESET SPECIFIC STATE
// export const SET_AI_CONTENT_CREATOR = 'ai_content/SET_AI_CONTENT_CREATOR';

// CANCEL AI SUBSCRIPTION PLAN
export const AI_UPDATE_SUBSCRIPTION_PLAN_REQUEST = 'ai_content/AI_UPDATE_SUBSCRIPTION_PLAN_REQUEST';
export const AI_UPDATE_SUBSCRIPTION_PLAN_RESPONSE = 'ai_content/AI_UPDATE_SUBSCRIPTION_PLAN_RESPONSE';
export const AI_UPDATE_SUBSCRIPTION_PLAN_FAILURE = 'ai_content/AI_UPDATE_SUBSCRIPTION_PLAN_FAILURE';

// SET_SELECTED_SUBSCRIPTION
export const SET_SELECTED_SUBSCRIPTION = 'ai_content/SET_SELECTED_SUBSCRIPTION';

// SET_SELECTED_SUBSCRIPTION_TABLE_DATA
export const SET_SELECTED_SUBSCRIPTION_TABLE_DATA = 'ai_content/SET_SELECTED_SUBSCRIPTION_TABLE_DATA';

// SET_SELECTED_SUBSCRIPTION_TABLE_DATA
export const SET_SUBSCRIPTION_MAIN_PAGE_TYPE = 'ai_content/SET_SUBSCRIPTION_MAIN_PAGE_TYPE';

// SET_COACHMARK
export const SET_COACHMARK = 'ai_content/SET_COACHMARK';

export const SET_CREATOR_AI_CAPTIONS = 'ai_content/SET_CREATOR_AI_CAPTIONS';

// REVIEW UPDATE MODEL
export const AI_MODEL_REVIEWS_LEARNING_REQUEST = 'ai_content/AI_MODEL_REVIEWS_LEARNING_REQUEST';
export const AI_MODEL_REVIEWS_LEARNING_RESPONSE = 'ai_content/AI_MODEL_REVIEWS_LEARNING_RESPONSE';
export const AI_MODEL_REVIEWS_LEARNING_FAILURE = 'ai_content/AI_MODEL_REVIEWS_LEARNING_FAILURE';

// CREATE CAPTIONS LIST
export const AI_CREATE_CAPTION_REQUEST = 'ai_content/AI_CREATE_CAPTION_REQUEST';
export const AI_CREATE_CAPTION_RESPONSE = 'ai_content/AI_CREATE_CAPTION_RESPONSE';
export const AI_CREATE_CAPTION_FAILURE = 'ai_content/AI_CREATE_CAPTION_FAILURE';

// GET CAPTIONS LIST
export const AI_GET_CAPTIONS_LIST_REQUEST = 'ai_content/AI_GET_CAPTIONS_LIST_REQUEST';
export const AI_GET_CAPTIONS_LIST_RESPONSE = 'ai_content/AI_GET_CAPTIONS_LIST_RESPONSE';
export const AI_GET_CAPTIONS_LIST_FAILURE = 'ai_content/AI_GET_CAPTIONS_LIST_FAILURE';

// AI CAPTION VALIDATION
export const AI_VALIDATION_REQUEST = 'ai_content/AI_VALIDATION_REQUEST';
export const AI_VALIDATION_RESPONSE = 'ai_content/AI_VALIDATION_RESPONSE';
export const AI_VALIDATION_FAILURE = 'ai_content/AI_VALIDATION_FAILURE';

// UPDATE AI LIMITATION
export const AI_UPDATE_LIMITATION_REQUEST = 'ai_content/AI_UPDATE_LIMITATION_REQUEST';
export const AI_UPDATE_LIMITATION_RESPONSE = 'ai_content/AI_UPDATE_LIMITATION_RESPONSE';
export const AI_UPDATE_LIMITATION_FAILURE = 'ai_content/AI_UPDATE_LIMITATION_FAILURE';

// ENABLE AI CAPTION VALIDATION
export const ENABLE_AI_CAPTION_VALIDATION = 'ai_content/ENABLE_AI_CAPTION_VALIDATION';

// SET AI HASHTAGS AND EMOJIS
export const SET_AI_HASHTAGS_AND_EMOJIS = 'ai_content/SET_AI_HASHTAGS_AND_EMOJIS';

// SET AI PROMPT ACTIVE
export const SET_AI_PROMPT_ACTIVE = 'ai_content/SET_AI_PROMPT_ACTIVE';

// SET AI GENERATED POST OPTION
export const SET_AI_GENERATED_OPTION = 'ai_content/SET_AI_GENERATED_OPTION';

// SET AI TEXT CONTENT
export const AI_SET_TEXT_CONTENT = 'ai_content/AI_SET_TEXT_CONTENT';

// RESET CAPTION GENERATION
export const RESET_AI_CAPTION_HASHTAG_GENERATION = 'ai_content/RESET_AI_CAPTION_HASHTAG_GENERATION';

// SEt CONTENT SUPPLIER SELECTED PLAN
export const SET_CONTENT_SUPPLIER_SELECTED_PLAN = 'ai_content/SET_CONTENT_SUPPLIER_SELECTED_PLAN';

// SET SPECIFIC STATE
export const SET_CS_LOCATION_SUBSCRIPTION_MODAL_TYPE = 'ai_content/SET_CS_LOCATION_SUBSCRIPTION_MODAL_TYPE';

// AI CAPTION VALIDATION
export const AI_VALIDATION_IMAGE_URLS_REQUEST = 'ai_content/AI_VALIDATION_IMAGE_URLS_REQUEST';
export const AI_VALIDATION_IMAGE_URLS_RESPONSE = 'ai_content/AI_VALIDATION_IMAGE_URLS_RESPONSE';
export const AI_VALIDATION_IMAGE_URLS_FAILURE = 'ai_content/AI_VALIDATION_IMAGE_URLS_FAILURE';
