import { config } from 'config';
import { commonWidgetTypes } from 'types';

export const SocialIcons = (props: commonWidgetTypes.ISocialIconsProps) => (
  <div className="fltr-imc selectsocial">
    {props.useFacebook ? (
      <button type="button" className={`roundedbtn btn btn-link`}>
        <img src={`${config.cdnImgUrl}filter/fb-platform.svg`} alt="Facebook" />{' '}
      </button>
    ) : null}
    {props.useInstagram ? (
      <button type="button" className={`roundedbtn btn btn-link`}>
        <img src={`${config.cdnImgUrl}filter/instagram-platform.svg`} alt="Instagram" />{' '}
      </button>
    ) : null}
    {props.useTwitter ? (
      <button type="button" className={`roundedbtn btn btn-link`}>
        <img src={`${config.cdnImgUrl}filter/twitter-platform.svg`} alt="Twitter" />{' '}
      </button>
    ) : null}
    {props.useLinkedIn ? (
      <button type="button" className={`roundedbtn btn btn-link`}>
        <img src={`${config.cdnImgUrl}filter/linkedin-platform.svg`} alt="LinkedIn" />{' '}
      </button>
    ) : null}
    {props.useTiktok ? (
      <button type="button" className={`roundedbtn btn btn-link`}>
        <img src={`${config.cdnImgUrl}filter/tiktok-platform.svg`} alt="Tiktok" />{' '}
      </button>
    ) : null}
    {props.useGoogle ? (
      <button type="button" className={`roundedbtn btn btn-link`}>
        <img src={`${config.cdnImgUrl}filter/google-platform.svg`} alt="GooglePlus" />{' '}
      </button>
    ) : null}
    {props.usePinterest ? (
      <button type="button" className={`roundedbtn btn btn-link`}>
        <img src={`${config.cdnImgUrl}filter/pinterest-platform.svg`} alt="Pinterest" />{' '}
      </button>
    ) : null}
  </div>
);
