import { Reducer } from 'redux';

import * as types from 'actions/action-types';

import { aiReviewResponderReducerTypes } from 'types';
import { AIReviewResponderActions } from 'actions';
import { AI_REVIEW_RESPONSER_INITIAL_OBJ, AI_REVIEW_SETTINGS_STATS, AUTO_REPLY_ACC_PERMISSION_INIT_OBJ } from 'utils/constants';
import { convertMsHourMinsSecFormat } from 'analytics/utils/helpers';

type IAIReviewResponderReducer = aiReviewResponderReducerTypes.IAIReviewResponderReducer;

const initialState: IAIReviewResponderReducer = {
  error: null,
  isFetching: false,
  isUpdating: false,
  reviewResponder: AI_REVIEW_RESPONSER_INITIAL_OBJ,
  reviewResponsePreview: [],
  isReviewFetching: true,
  isAutoAIResGenerating: false,
  isAutoReplyAccPermissionFetching: false,
  accPermissions: AUTO_REPLY_ACC_PERMISSION_INIT_OBJ,
  aiReviewStats: AI_REVIEW_SETTINGS_STATS,
  isStatsFetching: false
};

const aiReviewResponderReducer: Reducer<IAIReviewResponderReducer, AIReviewResponderActions> = (
  state: IAIReviewResponderReducer = initialState,
  action: AIReviewResponderActions
): IAIReviewResponderReducer => {
  switch (action.type) {
    case types.GET_AI_REVIEW_RESPONDER_REQUEST:
      return {
        ...state,
        isFetching: true,
        reviewResponder: AI_REVIEW_RESPONSER_INITIAL_OBJ,
        error: null
      };
    case types.GET_AI_REVIEW_RESPONDER_RESPONSE:
      return {
        ...state,
        isFetching: false,
        reviewResponder: action.payload
      };
    case types.GET_AI_REVIEW_RESPONDER_FAILURE:
      return {
        ...state,
        isFetching: false,
        reviewResponder: AI_REVIEW_RESPONSER_INITIAL_OBJ,
        error: action.payload
      };
    case types.UPDATE_AI_REVIEW_RESPONDER_REQUEST:
      return {
        ...state,
        isUpdating: true,
        error: null
      };
    case types.UPDATE_AI_REVIEW_RESPONDER_RESPONSE:
      return {
        ...state,
        isUpdating: false,
        reviewResponder: action.payload
      };
    case types.UPDATE_AI_REVIEW_RESPONDER_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: action.payload
      };
    case types.GET_REVIEW_RES_PREVIEW_REQUEST:
      return {
        ...state,
        isReviewFetching: true
      };
    case types.GET_REVIEW_RES_PREVIEW_RESPONSE:
      return {
        ...state,
        isReviewFetching: false,
        reviewResponsePreview: [...state.reviewResponsePreview, ...action.payload]
      };
    case types.GET_REVIEW_RES_PREVIEW_FAILURE:
      return {
        ...state,
        isReviewFetching: false,
        error: action.payload
      };
    case types.RESET_REVIEW_RESPONSE_PREVIEW:
      return {
        ...state,
        reviewResponsePreview: []
      };
    case types.GENERATE_AI_AUTO_REVIEWS_REQUEST:
      return {
        ...state,
        isAutoAIResGenerating: true
      };
    case types.GENERATE_AI_AUTO_REVIEWS_RESPONSE:
      let reviewResPreview = [...state.reviewResponsePreview];
      const selectedReviewResPreviewIndex = state.reviewResponsePreview.findIndex((res) => res.rating === action.payload.rating && res.network === action.payload.network);
      if (selectedReviewResPreviewIndex >= 0) {
        const updatedReviewRes = { ...state.reviewResponsePreview[selectedReviewResPreviewIndex] };
        updatedReviewRes.review_reply = action.payload.responseText;
        reviewResPreview[selectedReviewResPreviewIndex] = updatedReviewRes;
        reviewResPreview = reviewResPreview.sort((a, b) => {
          if (a.network < b.network) return -1;
          if (a.network > b.network) return 1;
          // If the 'network' values are the same, sort by 'rating'
          return a.rating - b.rating;
        });
      }
      return {
        ...state,
        isAutoAIResGenerating: false,
        reviewResponsePreview: reviewResPreview
      };
    case types.GENERATE_AI_AUTO_REVIEWS_FAILURE:
      return {
        ...state,
        isAutoAIResGenerating: false,
        error: action.payload
      };
    case types.GET_AUTO_REPLY_ACCOUNT_PERMISSION_REQUEST:
      return {
        ...state,
        isAutoReplyAccPermissionFetching: true,
        accPermissions: initialState.accPermissions
      };
    case types.GET_AUTO_REPLY_ACCOUNT_PERMISSION_RESPONSE:
      return {
        ...state,
        isAutoReplyAccPermissionFetching: false,
        accPermissions: action.payload
      };
    case types.GET_AUTO_REPLY_ACCOUNT_PERMISSION_FAILURE:
      return {
        ...state,
        isAutoReplyAccPermissionFetching: false,
        error: action.payload
      };
    case types.GET_AI_REVIEW_SETTINGS_STATS_REQUEST:
      return {
        ...state,
        isStatsFetching: true,
        aiReviewStats: initialState.aiReviewStats
      };
    case types.GET_AI_REVIEW_SETTINGS_STATS_RESPONSE:
      const updatedStats = { ...action.payload, hours_saved: convertMsHourMinsSecFormat(action.payload?.replies_count * 60 || 0, true, false) };
      return {
        ...state,
        isStatsFetching: false,
        aiReviewStats: updatedStats
      };
    case types.GET_AI_REVIEW_SETTINGS_STATS_FAILURE:
      return {
        ...state,
        isStatsFetching: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export { aiReviewResponderReducer };
