import { Reducer } from 'redux';

import produce from 'immer';
import * as types from 'revv/actions/action-types';
import { IDashboard } from 'revv/types';
import { CommonActions, DashboardAction } from 'revv/actions';

const initialState: IDashboard = {
  commentsError: '',
  statsErrorMsg: '',
  surveyStatsResponse: null,
  isStatsFetching: true,
  isReviewStatsFetching: true,
  reviewStatsResponse: null,
  isReviewStatsErrorMsg: '',
  commentsErrorMsg: '',
  isCommentsFetching: true,
  recentCommentsResponse: null,
  questionList: null,
  isQuestionsFetching: true,
  questionFetchErrorMsg: '',
  isFocusAreaDayWiseFetching: true,
  focusAreaDayWiseRequestError: '',
  isFocusAreaHourlyFetching: false,
  focusAreaHourlyResponse: null,
  focusAreaHourlyRequestError: '',
  surveyAnswersHubTypeError: '',
  isSurveyAnswersHubFetching: true,
  surveyAnswerErrorMsg: '',
  isSurveyAnswerFetching: true,
  surveyAnswersHubTypeResponse: null,
  focusAreaDayWiseResponse: null,
  surveyAnswerResponse: null,
  focusAreaPageNum: 1,
  surveyTablePageNumber: 1,
  activeDeliveryMethod: '1',
  surveyNotificationsError: '',
  surveyResolvedNotificationsError: '',
  surveyNotificationsResponse: null,
  surveyResolvedNotificationsResponse: null,
  isSurveyNotificationsFetching: true,
  isSurveyResolvedNotificationsFetching: true,
  resolveNotificationError: '',
  isResolveNotificationFetching: true,
  locationDetailsRequestError: '',
  isLocationDetailsFetching: false,
  locationDetails: null
};

export const dashboardReducer: Reducer<IDashboard, DashboardAction> = (prevState: IDashboard = initialState, action: DashboardAction | CommonActions) => {
  switch (action.type) {
    case types.DASHBOARD_SURVEY_STATS_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.statsErrorMsg = '';
        nextState.isStatsFetching = true;
      });
    case types.DASHBOARD_SURVEY_STATS_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.statsErrorMsg = action.payload;
        nextState.isStatsFetching = false;
      });
    case types.DASHBOARD_SURVEY_STATS_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.statsErrorMsg = '';
        nextState.isStatsFetching = false;
        nextState.surveyStatsResponse = action.payload;
      });

    case types.DASHBOARD_REVIEW_STATS_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isReviewStatsFetching = true;
        nextState.isReviewStatsErrorMsg = '';
      });
    case types.DASHBOARD_REVIEW_STATS_REQUEST_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.statsErrorMsg = action.payload;
        nextState.isReviewStatsFetching = false;
      });
    case types.DASHBOARD_REVIEW_STATS_RESPONES:
      return produce(prevState, (nextState) => {
        nextState.isReviewStatsErrorMsg = '';
        nextState.isReviewStatsFetching = false;
        nextState.reviewStatsResponse = action.payload;
      });
    case types.DASHBOARD_SURVEY_RECENT_COMMENTS_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.commentsError = '';
        nextState.isCommentsFetching = action.payload.isPolling ? prevState.isCommentsFetching : true;
      });
    case types.DASHBOARD_SURVEY_RECENT_COMMENTS_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.commentsError = action.payload;
        nextState.isCommentsFetching = false;
      });
    case types.DASHBOARD_SURVEY_RECENT_COMMENTS_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.commentsError = '';
        nextState.isCommentsFetching = false;
        nextState.recentCommentsResponse = action.payload;
      });
    case types.DASHBOARD_SURVEY_DELIVERY_METHOD:
      return produce(prevState, (nextState) => {
        nextState.focusAreaPageNum = 1;
        nextState.activeDeliveryMethod = action.payload;
        if (prevState.activeDeliveryMethod !== action.payload) {
          nextState.questionList = initialState.questionList;
        }
      });
    case types.DASHBOARD_SURVEY_QUESTION_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.questionFetchErrorMsg = '';
        nextState.isQuestionsFetching = true;
      });
    case types.DASHBOARD_SURVEY_QUESTION_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.questionFetchErrorMsg = action.payload;
        nextState.isQuestionsFetching = false;
      });
    case types.DASHBOARD_SURVEY_QUESTION_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.questionFetchErrorMsg = '';
        nextState.isQuestionsFetching = false;
        nextState.questionList = action.payload;
      });

    case types.DASHBOARD_SURVEY_ANSWER_REQUEST:
      return produce(prevState, (nextState) => {
        if (action.payload.isDayWise) {
          nextState.isFocusAreaDayWiseFetching = true;
          nextState.focusAreaDayWiseRequestError = '';
        } else if (action.payload.isHourly) {
          nextState.isFocusAreaHourlyFetching = true;
          nextState.focusAreaHourlyRequestError = '';
        } else if (action.payload.hubTypeId !== undefined) {
          nextState.surveyAnswersHubTypeError = '';
          nextState.isSurveyAnswersHubFetching = true;
        } else {
          nextState.surveyAnswerErrorMsg = '';
          nextState.isSurveyAnswerFetching = true;
        }
      });
    case types.DASHBOARD_SURVEY_ANSWER_FAILURE:
      return produce(prevState, (nextState) => {
        if (action.payload.surveyTable) {
          nextState.surveyAnswersHubTypeError = action.payload.surveyTable;
          nextState.isSurveyAnswersHubFetching = false;
        } else if (action.payload.focusAreaDayWise) {
          nextState.focusAreaDayWiseRequestError = action.payload.focusAreaDayWise;
          nextState.isFocusAreaDayWiseFetching = false;
        } else if (action.payload.focusAreaHourly) {
          nextState.focusAreaHourlyRequestError = action.payload.focusAreaHourly;
          nextState.isFocusAreaHourlyFetching = false;
        } else if (action.payload.surveyAnswer) {
          nextState.surveyAnswerErrorMsg = action.payload.surveyAnswer;
          nextState.isSurveyAnswerFetching = false;
        }
      });
    case types.DASHBOARD_SURVEY_ANSWER_RESPONSE:
      return produce(prevState, (nextState) => {
        if (action.payload.surveyTable) {
          nextState.surveyAnswersHubTypeError = '';
          nextState.isSurveyAnswersHubFetching = false;
          nextState.surveyAnswersHubTypeResponse = action.payload.surveyTable;
        } else if (action.payload.focusAreaDayWise) {
          nextState.focusAreaDayWiseRequestError = '';
          nextState.isFocusAreaDayWiseFetching = false;
          nextState.focusAreaDayWiseResponse = action.payload.focusAreaDayWise;
        } else if (action.payload.focusAreaHourly) {
          nextState.focusAreaHourlyRequestError = '';
          nextState.isFocusAreaHourlyFetching = false;
          nextState.focusAreaHourlyResponse = action.payload.focusAreaHourly;
        } else if (action.payload.surveyAnswer) {
          nextState.surveyAnswerErrorMsg = '';
          nextState.surveyAnswerResponse = action.payload.surveyAnswer;
          nextState.isSurveyAnswerFetching = false;
        }
      });
    case types.DASHBOARD_SURVEY_NOTIFICATION_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.surveyNotificationsError = '';
        nextState.isSurveyNotificationsFetching = action.payload.params.isPolling ? prevState.isSurveyNotificationsFetching : true;
      });
    case types.DASHBOARD_SURVEY_NOTIFICATION_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.surveyNotificationsError = action.payload;
        nextState.isSurveyNotificationsFetching = false;
      });
    case types.DASHBOARD_SURVEY_NOTIFICATION_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.surveyNotificationsError = '';
        nextState.isSurveyNotificationsFetching = false;
        nextState.surveyNotificationsResponse = action.payload;
      });
    case types.DASHBOARD_SURVEY_RESOLVED_NOTIFICATION_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.surveyResolvedNotificationsError = '';
        nextState.isSurveyResolvedNotificationsFetching = action.payload.params.isPolling ? prevState.isSurveyResolvedNotificationsFetching : true;
      });
    case types.DASHBOARD_SURVEY_RESOLVED_NOTIFICATION_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.surveyResolvedNotificationsError = action.payload;
        nextState.isSurveyResolvedNotificationsFetching = false;
      });
    case types.DASHBOARD_SURVEY_RESOLVED_NOTIFICATION_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.surveyResolvedNotificationsError = '';
        nextState.isSurveyResolvedNotificationsFetching = false;
        nextState.surveyResolvedNotificationsResponse = action.payload;
      });
    case types.DASHBOARD_LOCATION_DETAILED_VIEW_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.locationDetailsRequestError = '';
        nextState.isLocationDetailsFetching = true;
      });
    case types.DASHBOARD_LOCATION_DETAILED_VIEW_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.locationDetailsRequestError = action.payload;
        nextState.isLocationDetailsFetching = false;
      });
    case types.DASHBOARD_LOCATION_DETAILED_VIEW_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.locationDetailsRequestError = '';
        nextState.isLocationDetailsFetching = false;
        nextState.locationDetails = action.payload;
      });
    case types.DASHBOARD_FOCUS_AREA_PAGE_NUM:
      return produce(prevState, (nextState) => {
        nextState.focusAreaPageNum = action.payload;
      });
    case types.RESET_PAGE_STATE:
      return produce(prevState, (nextState) => {
        if (action.payload === 'revv_dashboard') {
          nextState.commentsError = initialState.commentsError;
          nextState.statsErrorMsg = initialState.statsErrorMsg;
          nextState.surveyStatsResponse = initialState.surveyStatsResponse;
          nextState.isStatsFetching = initialState.isStatsFetching;
          nextState.isReviewStatsFetching = initialState.isReviewStatsFetching;
          nextState.reviewStatsResponse = initialState.reviewStatsResponse;
          nextState.isReviewStatsErrorMsg = initialState.isReviewStatsErrorMsg;
          nextState.commentsErrorMsg = initialState.commentsErrorMsg;
          nextState.isCommentsFetching = initialState.isCommentsFetching;
          nextState.recentCommentsResponse = initialState.recentCommentsResponse;
          // nextState.questionList = initialState.questionList;
          // nextState.isQuestionsFetching = initialState.isQuestionsFetching;
          nextState.questionFetchErrorMsg = initialState.questionFetchErrorMsg;
          nextState.isFocusAreaDayWiseFetching = initialState.isFocusAreaDayWiseFetching;
          nextState.focusAreaDayWiseRequestError = initialState.focusAreaDayWiseRequestError;
          nextState.isFocusAreaHourlyFetching = initialState.isFocusAreaHourlyFetching;
          nextState.focusAreaHourlyResponse = initialState.focusAreaHourlyResponse;
          nextState.focusAreaHourlyRequestError = initialState.focusAreaHourlyRequestError;
          nextState.surveyAnswersHubTypeError = initialState.surveyAnswersHubTypeError;
          nextState.isSurveyAnswersHubFetching = initialState.isSurveyAnswersHubFetching;
          nextState.surveyAnswerErrorMsg = initialState.surveyAnswerErrorMsg;
          nextState.isSurveyAnswerFetching = initialState.isSurveyAnswerFetching;
          nextState.surveyAnswersHubTypeResponse = initialState.surveyAnswersHubTypeResponse;
          nextState.focusAreaDayWiseResponse = initialState.focusAreaDayWiseResponse;
          nextState.surveyAnswerResponse = initialState.surveyAnswerResponse;
          nextState.focusAreaPageNum = initialState.focusAreaPageNum;
          nextState.surveyTablePageNumber = initialState.surveyTablePageNumber;
          nextState.activeDeliveryMethod = initialState.activeDeliveryMethod;
          nextState.surveyNotificationsError = initialState.surveyNotificationsError;
          nextState.surveyResolvedNotificationsError = initialState.surveyResolvedNotificationsError;
          nextState.surveyNotificationsResponse = initialState.surveyNotificationsResponse;
          nextState.surveyResolvedNotificationsResponse = initialState.surveyResolvedNotificationsResponse;
          nextState.isSurveyNotificationsFetching = initialState.isSurveyNotificationsFetching;
          nextState.isSurveyResolvedNotificationsFetching = initialState.isSurveyResolvedNotificationsFetching;
          nextState.resolveNotificationError = initialState.resolveNotificationError;
          nextState.isResolveNotificationFetching = initialState.isResolveNotificationFetching;
          nextState.locationDetailsRequestError = initialState.locationDetailsRequestError;
          nextState.isLocationDetailsFetching = initialState.isLocationDetailsFetching;
          nextState.locationDetails = initialState.locationDetails;
        }
      });
    default:
      return prevState;
  }
};
