import { takeLatest, put } from 'redux-saga/effects';

import * as actionTypes from 'revv/actions/action-types';
import {
  locationsMetricsFailure,
  locationsMetricsResponse,
  locationsListFailure,
  locationsListResponse,
  locationDeleteResponse,
  locationDeleteFailure,
  locationUpdateResponse,
  locationCreateResponse,
  locationUpdateFailure,
  locationCreateFailure,
  locationDetailResponse,
  locationDetailFailure,
  locationUploadResponse,
  locationUploadRequestFailure
} from 'revv/actions';
import {
  ILocationsMetricsResponse,
  IListRequestParams,
  ISagaPayload,
  ILocationsList,
  ILocationsListResponse,
  ILocationDetailResponse,
  IUploadParams,
  IIdParam,
  SagaYield,
  SagaValue,
  ILoationMetrics
} from 'revv/types';
import { API_ROUTES } from 'revv/utils';
import { API_TYPES } from 'utils/constants';
import { apiCall, defaultRevvHeader } from 'utils/helpers';

function* getLocationsMetrics(action: ISagaPayload<ILoationMetrics>): Generator<SagaYield, void, SagaValue> {
  try {
    const isRallioLocation = action.payload?.rallioLocationId || action.payload?.rallioFranchisorId;
    const response = yield apiCall({
      apiPath: isRallioLocation ? API_ROUTES.locationsMetricsFiltered.apiPath : API_ROUTES.locationsMetrics.apiPath,
      action: isRallioLocation ? API_ROUTES.locationsMetricsFiltered.method : API_ROUTES.locationsMetrics.method,
      type: API_TYPES.REVV,
      headers: defaultRevvHeader(),
      data: {
        from: action.payload?.fromDate,
        to: action.payload?.toDate,
        rallio_account_ids: action.payload?.rallioLocationId,
        rallio_franchisor_ids: action.payload?.rallioFranchisorId,
        location_ids: action.payload?.locationIds
      }
    });
    if (response.status !== 200) {
      yield put(locationsMetricsFailure(response.data.error || 'OOPS! Something went wrong.'));
    } else {
      const LocationsMetricsResponseData: ILocationsMetricsResponse = {
        locationCount: response.data.data.locations_count,
        customersCount: response.data.data.customers_count,
        averageRating: response.data.data.average_rating,
        npsScore: response.data.data.nps_score,
        sentCount: response.data.data.sent_count,
        completedCount: response.data.data.completed_count,
        agreeedReviewCount: response.data.data.agreed_to_review_count
      };
      yield put(locationsMetricsResponse(LocationsMetricsResponseData));
    }
  } catch (error: any) {
    yield put(locationsMetricsFailure(error || 'OOPS! Something went wrong.'));
  }
}

function* getLocationsList(action: ISagaPayload<IListRequestParams>): Generator<SagaYield, void, SagaValue> {
  try {
    const isRallioLocation = action.payload?.rallioLocationId || action.payload?.rallioFranchisorId;
    const response = yield apiCall({
      apiPath: isRallioLocation ? API_ROUTES.locationsListFiltered.apiPath : API_ROUTES.locationsList.apiPath,
      action: isRallioLocation ? API_ROUTES.locationsListFiltered.method : API_ROUTES.locationsList.method,
      type: API_TYPES.REVV,
      headers: defaultRevvHeader(),
      params: {
        'filter[client_id]': action.payload?.clientId || '',
        'filter[keyword]': action.payload?.searchKeyword,
        'page[size]': action.payload?.size,
        'page[number]': action.payload?.pageNumber,
        leaderboard: action.payload?.isLeaderBoard,
        per: action.payload?.maxLocationCount,
        sort: action.payload?.sorting
      },
      data: {
        location_ids: action.payload?.locationIds,
        rallio_account_ids: action.payload?.rallioLocationId,
        rallio_franchisor_ids: action.payload?.rallioFranchisorId,
        from: action.payload?.fromDate,
        to: action.payload?.toDate
      }
    });
    if (response.status !== 200) {
      yield put(locationsListFailure(response.data.errors[0].detail || 'OOPS! Something went wrong.'));
    } else {
      if (response.data) {
        const locationsListResponseData: ILocationsListResponse[] =
          response.data?.data && response.data?.data?.length > 0
            ? response.data.data.map((it: ILocationsList) => {
                return {
                  id: it.id,
                  name: `${it?.attributes?.name || ''}`,
                  surveySentCount: it?.attributes?.sent_count || 0,
                  customerCount: it?.attributes?.customers_count || 0,
                  score: it?.attributes?.score || 0,
                  averageRating: it?.attributes?.average_rating || 0,
                  npsScore: it?.attributes?.nps_score,
                  surveyCompletedCount: it?.attributes?.completed_count || 0,
                  surveyCancelledCount: it?.attributes?.cancelled_count || 0,
                  surveyAgreedToReviewCount: it?.attributes?.agreed_to_review_count || 0
                };
              })
            : [];
        if (action.payload?.callback && locationsListResponseData[0]) {
          action.payload.callback([locationsListResponseData[0]]);
        }

        yield put(locationsListResponse(locationsListResponseData));
      }
    }
  } catch (error: any) {
    yield put(locationsListFailure(error || 'OOPS! Something went wrong.'));
  }
}

function* deletelocationRequest(action: ISagaPayload<IIdParam>): Generator<SagaYield, void, SagaValue> {
  try {
    if (action.payload?.id) {
      const response = yield apiCall({
        apiPath: API_ROUTES.locationDelete.apiPath.replace(':id', `${action.payload?.id}`),
        action: API_ROUTES.locationDelete.method,
        type: API_TYPES.REVV,
        headers: defaultRevvHeader()
      });
      if (response.status === 204) {
        yield put(locationDeleteResponse());
        if (action.payload.callback) {
          action.payload.callback();
        }
      } else {
        yield put(locationDeleteFailure(response.data.error));
      }
    }
  } catch (error: any) {
    yield put(locationDeleteFailure(error));
  }
}

function* createLocationRequest(action: ISagaPayload<ILocationDetailResponse>): Generator<SagaYield, void, SagaValue> {
  try {
    if (action.payload) {
      const dataObj = {
        data: {
          id: action.payload.id || undefined,
          attributes: {
            name: action.payload.locationName,
            store_id: action.payload.storeId,
            phone_number: action.payload.phoneNumber,
            email: action.payload.email,
            website_url: action.payload.websiteUrl || null,
            address: action.payload.address,
            city: action.payload.city,
            region: action.payload.region,
            postal_code: action.payload.postalCode,
            country_code: action.payload.countryCode,
            time_zone: action.payload.timeZone,
            facebook_page_url: action.payload.facebookPageUrl || null,
            google_page_url: action.payload.googlePageUrl || null,
            yelp_page_url: action.payload.yelpPageUrl || null,
            custom_social_media_page_url: action.payload.customPageUrl, // custome page url
            preferred_review_site: action.payload.preferredReviewSite !== 'Keep Client Settings' ? action.payload.preferredReviewSite : null,
            tags: action.payload.selectedTags?.toString(),
            anonymous_survey_url: action.payload.staticLink ? action.payload.staticLink : null, // static link
            sms_message: action.payload.smsMessage,
            survey_email_message: action.payload.emailMessage,
            survey_email_subject: action.payload.emailSubject,
            other_platform_url: action.payload.otherPlatformUrl,
            other_platform_logo: action.payload.otherPlatformLogo
          },
          relationships: {
            client: {
              data: {
                type: 'clients',
                id: action.payload.clientId
              }
            }
          },
          types: 'locations'
        }
      };
      const response = yield apiCall({
        apiPath: action.payload.id ? API_ROUTES.locationUpdate.apiPath.replace(':id', `${action.payload.id}`) : API_ROUTES.locationCreate.apiPath,
        action: action.payload.id ? API_ROUTES.locationUpdate.method : API_ROUTES.locationCreate.method,
        type: API_TYPES.REVV,
        headers: defaultRevvHeader(),
        data: dataObj
      });
      if (response.status === 200 || response.status === 201) {
        if (action.payload.id) {
          yield put(locationUpdateResponse());
        } else {
          yield put(locationCreateResponse());
        }
        if (action.payload.callback) {
          action.payload.callback();
        }
      } else {
        if (action.payload.id) {
          const errorMessage = `${response.data.errors[0].title} ${response.data.errors[0].detail}`;
          yield put(locationUpdateFailure(errorMessage.replace('base', '') || 'OOPS! Something went wrong.'));
        } else {
          yield put(locationCreateFailure(response.data.errors[0].detail));
        }
      }
    } else {
      yield put(locationUpdateFailure('Location update or create payload not found.'));
    }
  } catch (error: any) {
    yield put(locationUpdateFailure('OOPS! Something went wrong.'));
  }
}

function* locationDetailRequestSaga(action: ISagaPayload<IIdParam>): Generator<SagaYield, void, SagaValue> {
  try {
    if (action.payload) {
      const response = yield apiCall({
        apiPath: API_ROUTES.locationDetail.apiPath.replace(':id', `${action.payload.id}`),
        action: API_ROUTES.locationDetail.method,
        type: API_TYPES.REVV,
        headers: defaultRevvHeader()
      });
      if (response.data?.data) {
        const responseData = {
          id: response.data?.data?.id,
          clientId: response.data.data.relationships.client.id,
          locationName: response.data?.data?.attributes.name,
          storeId: response.data?.data?.attributes.store_id,
          phoneNumber: response.data?.data?.attributes.phone_number,
          email: response.data?.data?.attributes.email,
          websiteUrl: response.data?.data?.attributes.website_url,
          address: response.data?.data?.attributes.address,
          city: response.data?.data?.attributes.city,
          region: response.data?.data?.attributes.region,
          postalCode: response.data?.data?.attributes.postal_code,
          countryCode: response.data?.data?.attributes.country_code,
          timeZone: response.data?.data?.attributes.time_zone,
          facebookPageUrl: response.data?.data?.attributes.facebook_page_url,
          googlePageUrl: response.data?.data?.attributes.google_page_url,
          yelpPageUrl: response.data?.data?.attributes.yelp_page_url,
          customPageUrl: response.data?.data?.attributes.custom_social_media_page_url,
          preferredReviewSite: response.data?.data?.attributes.preferred_review_site,
          tags: response.data?.data?.attributes.tags,
          staticLink: response.data?.data?.attributes.anonymous_survey_url,
          smsMessage: response.data?.data?.attributes.sms_message,
          emailMessage: response.data?.data?.attributes.survey_email_message,
          emailSubject: response.data?.data?.attributes.survey_email_subject,
          otherPlatformUrl: response.data?.data?.attributes.other_platform_url,
          otherPlatformLogo: response.data?.data?.attributes.other_platform_logo
        };
        yield put(locationDetailResponse(responseData));
      }
    }
  } catch (error: any) {
    yield put(locationDetailFailure(error));
  }
}

function* uploadLocations(action: ISagaPayload<IUploadParams>): Generator<SagaYield, void, SagaValue> {
  try {
    if (action.payload) {
      const fileData = new FormData();
      fileData.append('file', action.payload?.data);
      if (action.payload?.data) {
        const response = yield apiCall({
          apiPath: API_ROUTES.uploadLocations.apiPath,
          action: API_ROUTES.uploadLocations.method,
          type: API_TYPES.REVV,
          headers: defaultRevvHeader(),
          data: fileData,
          fileUpload: true
        });
        if (response.status === 204) {
          yield put(locationUploadResponse('You will receive an email once your request has been processed.'));
        } else {
          yield put(locationUploadRequestFailure('There was a problem uploading your file'));
        }
      }
    } else {
      yield put(locationUploadRequestFailure('File not selected.'));
    }
  } catch (error: any) {
    yield put(locationUploadRequestFailure(error || 'OOPS! Something went wrong.'));
  }
}

export function* takeLocationsRequest() {
  yield takeLatest(actionTypes.LOCATIONS_METRICS_REQUEST, getLocationsMetrics);
  yield takeLatest(actionTypes.LOCATIONS_LIST_REQUEST, getLocationsList);
  yield takeLatest(actionTypes.LOCATION_DELETE_REQUEST, deletelocationRequest);
  yield takeLatest(actionTypes.LOCATION_CREATE_REQUEST, createLocationRequest);
  yield takeLatest(actionTypes.LOCATION_UDPATE_REQUEST, createLocationRequest);
  yield takeLatest(actionTypes.LOCATION_DETAIL_REQUEST, locationDetailRequestSaga);
  yield takeLatest(actionTypes.LOCATION_UPLOAD_REQUEST, uploadLocations);
}
