import moment from 'moment-timezone';

import { dateFormatter } from 'analytics/utils';
import { ALL_HUBS, CALENDAR_YR_MN_DATE_FORMAT, COMMON_SOCIAL_PROVIDER_TYPE, FRANCHISORS_SELECTIONS, ICommonDateTypes, USER_OWNERSHIP, UTC_TIMESTAMP_FORMAT } from 'utils/constants';
import { ILocationSelectorAccountList } from 'types/common/account-switcher';
import { IFiterHandleChangeData, IPlatformFilter } from 'types/common/filter';
import { menuAccessApiTypes } from 'types';

export const filterObjConversion = (
  queryParams: any,
  intialValue: any,
  accountSelectionList: ILocationSelectorAccountList,
  id: any,
  userOwnership: any,
  contentSupplier: menuAccessApiTypes.IFranchisorSyndication[]
) => {
  const dates = queryParams.start_date
    ? {
        dateRange: {
          [ICommonDateTypes.FROM_DATE]: {
            selected: moment(queryParams.start_date).startOf('day').toDate(),
            timeStamp: moment(queryParams.start_date).startOf('day').format(UTC_TIMESTAMP_FORMAT)
          },
          [ICommonDateTypes.TO_DATE]: {
            selected: queryParams.end_date ? moment(queryParams.end_date).endOf('day').toDate() : null,
            timeStamp: queryParams.end_date ? moment(queryParams.end_date).endOf('day').format(UTC_TIMESTAMP_FORMAT) : null
          }
        }
      }
    : intialValue;

  const country = '';

  const returnObj = {
    ...intialValue,
    platform: queryParams?.platform || COMMON_SOCIAL_PROVIDER_TYPE.ALL
  };

  returnObj.dateRange = dates.dateRange;
  returnObj.country = country;

  let hubObj: any = {};
  if (queryParams.hub_id) {
    returnObj.ancestorFranchisorId = +queryParams.hub_id;
    returnObj.locationId = 0;
    const hubList = accountSelectionList?.hubList;
    hubObj = hubList?.find((it) => it.id === +queryParams.hub_id);
    returnObj.locationFilterObj = hubObj || intialValue.locationFilterObj;
  } else if (queryParams.location_list_id) {
    returnObj.ancestorFranchisorId = 0;
    returnObj.locationId = +queryParams.location_list_id;
    returnObj.locationListId = 0;
    const locationList = accountSelectionList?.locationList;
    hubObj = locationList?.find((it) => it.id === +queryParams.location_list_id);
    returnObj.locationFilterObj = hubObj || intialValue.locationFilterObj;
  } else if (queryParams.location_id) {
    returnObj.ancestorFranchisorId = 0;
    returnObj.locationId = 0;
    returnObj.locationListId = +queryParams.location_id;
    const onlyLocationList = accountSelectionList?.onlyLocationList;
    hubObj = onlyLocationList?.find((it) => it.id === +queryParams.location_id);
    returnObj.locationFilterObj = hubObj || intialValue.locationFilterObj;
  } else if (queryParams.all_hubs === '1') {
    returnObj.ancestorFranchisorId = userOwnership === USER_OWNERSHIP.FRANCHISOR ? id : 0;
    returnObj.locationId = userOwnership === USER_OWNERSHIP.ACCOUNT ? id : 0;
    returnObj.locationFilterObj = { id: 0.5, value: ALL_HUBS, label: ALL_HUBS, type: FRANCHISORS_SELECTIONS };
  } else if (!Object.keys(hubObj).length) {
    returnObj.ancestorFranchisorId = userOwnership === USER_OWNERSHIP.FRANCHISOR ? id : 0;
    returnObj.locationId = userOwnership === USER_OWNERSHIP.ACCOUNT ? id : 0;
    returnObj.locationFilterObj = intialValue.locationFilterObj;
  }

  // condition to check contentsupplier id
  if (queryParams.content_supplier_id) {
    const selectedContentSupplier = contentSupplier?.find((corpHub) => corpHub.id === +queryParams.content_supplier_id);
    returnObj.contentSupplierFilterObj = selectedContentSupplier;
  }
  return returnObj;
};

export const handleFilterChange = (data: IFiterHandleChangeData, isClear = false, id: number | null, queryParams: { [k: string]: string }, availablePlatforms?: IPlatformFilter[]) => {
  if (isClear) {
    const quearyObj: { [key: string]: any } = {
      ...(queryParams.sort_by ? { sort_by: queryParams.sort_by } : {}),
      ...(queryParams.ascending ? { ascending: queryParams.ascending } : {}),
      ...(queryParams.selected_tile ? { selected_tile: queryParams.selected_tile } : {}),
      ...(availablePlatforms?.length === 1 ? { platform: availablePlatforms[0]?.value || '' } : {})
    };
    return quearyObj;
  } else {
    if (data.platform) {
      if (data.platform === COMMON_SOCIAL_PROVIDER_TYPE.ALL) delete queryParams.platform;
      else queryParams.platform = data.platform.toString();
    } else if (data.dateRange) {
      if (data.dateRange?.fromDate?.selected) {
        queryParams.start_date = dateFormatter(data.dateRange.fromDate.selected, CALENDAR_YR_MN_DATE_FORMAT) || '';
      }
      if (data.dateRange?.toDate?.selected) {
        queryParams.end_date = dateFormatter(data.dateRange.toDate.selected, CALENDAR_YR_MN_DATE_FORMAT) || '';
      }
      if (!data.dateRange?.fromDate?.selected) {
        delete queryParams.start_date;
      }
      if (!data.dateRange?.toDate?.selected) {
        delete queryParams.end_date;
      }
    } else if (data.locationId) {
      queryParams.location_id = data.locationId.toString() || '';
      delete queryParams.hub_id;
      delete queryParams.location_list_id;
    } else if (data.multipleLocationList) {
      queryParams.location_id = data.multipleLocationList.toString() || '';
      delete queryParams.hub_id;
      delete queryParams.location_list_id;
      if (data.multipleLocationList?.length === 0) {
        delete queryParams.location_id;
      }
    } else if (data.locationListId) {
      queryParams.location_list_id = data.locationListId.toString() || '';
      delete queryParams.hub_id;
      delete queryParams.location_id;
    } else if (data.ancestorFranchisorId && data.ancestorFranchisorId !== id) {
      queryParams.hub_id = data.ancestorFranchisorId.toString() || '';
      delete queryParams.location_list_id;
      delete queryParams.location_id;
    } else if (data.ancestorFranchisorId && data.ancestorFranchisorId === id) {
      delete queryParams.location_list_id;
      delete queryParams.location_id;
      delete queryParams.hub_id;
    } else if (data.content_supplier_id) {
      if (data.content_supplier_id === 'all') {
        delete queryParams.content_supplier_id;
      } else {
        queryParams.content_supplier_id = data.content_supplier_id.toString() || '';
      }
    } else if (data?.content_type) {
      if (data.content_type === 'all') {
        delete queryParams.content_type;
      } else {
        queryParams.content_type = data.content_type;
      }
    }
    return queryParams;
  }
};
