import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';

import { CommonFilter } from 'components';
import { IStore } from 'types';
import { BUTTON_PROPS, FeedValue, USER_OWNERSHIP } from 'utils/constants';
import { weekOrMonthBeforeTodayFilter } from 'utils/helpers';
import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';
import { REVV_SURVEYS_RATING_FILTER, REVV_SURVEYS_OPTION_FILTER, REVV_SURVEYS_NPS_RATING_FILTER } from 'revv/utils';
import { activeFilterOptions, onChangeDateRange, onChangeLocation, resetPageState } from 'revv/actions';
import { IFiterHandleChangeData } from 'types/common/filter';
import { dateHelperForWeek, handleFilterChange } from 'analytics/utils';
import { downloadRequest, downloadSurveysFilteredRequest } from 'revv/actions/download';

export const SurveysFilter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, userOwnership } = useAccountSwitcherData();

  const selectedLocation = useSelector((state: IStore) => state.revv.filter.selectedLocation);
  const locations = useSelector((state: IStore) => state.revv.filter.hubLocation);
  const defaultSelectedLocation = useSelector((state: IStore) => state.revv.filter.defaultSelectedLocation);
  const isSendSurveyFetching = useSelector((state: IStore) => state.revv.surveys.sendSurveyMsg.isFetching);
  const npsSurvey = useSelector((state: IStore) => state.revv.settings.settingsDetails?.attributes?.survey_type);

  const { queryParams, filter: basicQueryFilter } = useParamsDeconstructor({
    dateRange: weekOrMonthBeforeTodayFilter(FeedValue.INBOX)
  });

  const statusValue = useMemo(() => {
    return queryParams.status || 'completed';
  }, [queryParams]);

  const urlRatingValue = useMemo(() => {
    return queryParams.survey_rating?.split(',') || [];
  }, [queryParams]);

  const isFilterDisabled = useMemo(() => {
    if (Object.keys(queryParams).length || selectedLocation.length) {
      return false;
    } else {
      return true;
    }
  }, [queryParams, selectedLocation]);

  useEffect(() => {
    return () => {
      dispatch(resetPageState('revv_surveys'));
    };
  }, [dispatch]);

  const revvSurveysFilter = {
    ...basicQueryFilter,
    status: queryParams.status || 'completed',
    survey_rating: queryParams.survey_rating?.split(',') || [],
    locations,
    selectedLocation
  };

  const handleChange = (data: IFiterHandleChangeData, isClear = false) => {
    if (data.multipleLocationList) {
      dispatch(
        onChangeLocation({
          selectedLocations: data.multipleLocationList,
          highlightHubIdsGroup: {},
          highlightedLocations: [],
          selectedFilterMode: [],
          selectedHubGroup: {},
          validHubs: {},
          validLocations: defaultSelectedLocation
        })
      );
    }
    if (isClear) {
      if (selectedLocation.length) {
        dispatch(
          onChangeLocation({
            selectedLocations: [],
            highlightHubIdsGroup: {},
            highlightedLocations: [],
            selectedFilterMode: [],
            selectedHubGroup: {},
            validHubs: {},
            validLocations: defaultSelectedLocation
          })
        );
      }
      dispatch(
        onChangeDateRange({
          fiscalStartDate: dateHelperForWeek(true).fromDate.selected || new Date(),
          fiscalEndDate: dateHelperForWeek(true).toDate.selected || new Date()
        })
      );
    }
    const queryParamsClone = handleFilterChange(data, isClear, id, queryParams);
    if (data.status) {
      if (data.status === REVV_SURVEYS_OPTION_FILTER[3].value && Array.isArray(data.status)) {
        delete queryParamsClone.status;
      } else {
        queryParamsClone.status = data.status;
      }
    }
    if (data.survey_rating && Array.isArray(data.survey_rating)) {
      if (data.survey_rating.length === 0) {
        delete queryParamsClone.survey_rating;
      } else {
        queryParamsClone.survey_rating = data.survey_rating.join(',');
      }
    }
    navigate({ search: `?${new URLSearchParams(queryParamsClone).toString()}` });
  };

  return (
    <>
      <CommonFilter
        sectionVariant="revv-survey__filter"
        filter={revvSurveysFilter}
        handleChange={handleChange}
        checkBoxFilter={[
          {
            title: 'Status',
            filter: REVV_SURVEYS_OPTION_FILTER,
            value: 'status'
          }
        ]}
        primaryButtons={[
          {
            ...BUTTON_PROPS.CLEAR_FILTER,
            isDisable: isFilterDisabled
          }
        ]}
        multiSelectCheckBoxFilter={[
          {
            title: 'Ratings',
            filter: npsSurvey === 'nps' ? REVV_SURVEYS_NPS_RATING_FILTER : REVV_SURVEYS_RATING_FILTER,
            value: 'survey_rating'
          }
        ]}
        filterActionButtons={[
          {
            ...BUTTON_PROPS.PRIMARY_BUTTON,
            label: 'Send Survey',
            parentVariant: 'filter-item connection-report border-0',
            value: 'sendsurvey',
            onClick: () => {
              dispatch(activeFilterOptions('sendsurvey'));
            },
            isFetching: isSendSurveyFetching
          },
          {
            ...BUTTON_PROPS.SECONDARY_BUTTON,
            label: 'Send Queued Survey',
            parentVariant: 'connection-report border-0',
            value: 'sendqueuedsurvey',
            onClick: () => {
              dispatch(activeFilterOptions('sendqueuedsurvey'));
            }
          },
          {
            ...BUTTON_PROPS.SECONDARY_BUTTON,
            label: 'Download All-Time',
            customImage: 'csv-export.svg',
            parentVariant: 'connection-report border-0',
            buttonVariant: 'ac-btn ac-icon-btn ac-secondary ac-block csv',
            onClick: () => {
              dispatch(downloadRequest({ type: 'surveys', locationIds: userOwnership === USER_OWNERSHIP.ACCOUNT ? [`${id}`] : [] }));
            }
          },
          {
            ...BUTTON_PROPS.SECONDARY_BUTTON,
            label: 'Download Filtered',
            customImage: 'csv-export.svg',
            parentVariant: 'connection-report border-0',
            buttonVariant: 'ac-btn ac-icon-btn ac-secondary ac-block csv',
            onClick: () => {
              dispatch(
                downloadSurveysFilteredRequest({
                  from_date: queryParams.start_date || moment(weekOrMonthBeforeTodayFilter(FeedValue.INBOX).fromDate.selected).format('YYYY-MM-DD'),
                  to_date: queryParams.end_date || moment(weekOrMonthBeforeTodayFilter(FeedValue.INBOX).toDate.selected).format('YYYY-MM-DD'),
                  survey_status: statusValue === 'any' ? '' : statusValue,
                  survey_rating: urlRatingValue?.join(',') === 'all' ? '' : urlRatingValue?.join(',') || '',
                  from_transaction_date: '',
                  to_transaction_date: '',
                  retail_type: '',
                  locationIds: userOwnership === USER_OWNERSHIP.ACCOUNT ? [`${id}`] : []
                })
              );
            }
          }
        ]}
        isLocation={userOwnership === USER_OWNERSHIP.FRANCHISOR}
        isRevvLocation
      />
    </>
  );
};
