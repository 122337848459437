import { APP_TITLE } from '../common';

export const YELP_PROFILE_REGEX = /^(https?:\/\/)?(www\.)?yelp\.(ca|com)\/biz\/([a-z0-9-]+)/;

export const FB_REQUIRED_FIELDS = 'name,email,picture';

export const FB_GRAPH_URL = 'https://graph.facebook.com/';
export const YELP_BASE_URL = 'https://www.yelp.com/biz/';
export const FB_SCOPE = `email,read_insights,pages_messaging,instagram_basic,instagram_manage_comments,
instagram_manage_insights,instagram_content_publish,
pages_read_engagement,pages_manage_metadata,pages_read_user_content,pages_manage_ads,pages_manage_posts,pages_manage_engagement,business_management`;
export const FB_SCOPE_ADS = `${FB_SCOPE},ads_management`;
export const INSTA_BUSINESS_ACCOUNT_TEXT = `A Business Instagram account tells Instagram you’re using their platform for marketing which offers you robust analytics and features not available to non-business accounts. Be sure your Business Instagram is linked to your Facebook business page to take advantage of ${APP_TITLE}’s Instagram direct publishing feature.`;
export const INSTA_PERSONAL_ACCOUNT_TEXT = `Enabling this feature will allow you to post content manually to your personal Instagram profile via ${APP_TITLE} mobile app push notifications. Convert your personal Instagram profile to a Business Account to automatically publish content and view engagement analytics on ${APP_TITLE}`;

export const YELP_UPDATE_TOOLTIP_TEXT = 'Need to make updates? Please reach out to support@rallio.com';
