import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';

import {
  getLocationHubDetailRequest,
  getLocationsHubListRequest,
  getLocationsTreeRequest,
  operationListsDetailViewError,
  reloadList,
  operationListsResetAll,
  contentFeedGetCSFranchisorsListRequest,
  rallioProfileResetAll,
  setFranchisorIds,
  socialProfileResetAll,
  accountProfileReset,
  subscriptionsLocationToListRequest,
  modifyLocationsHubListResponse,
  commonSetContentModifiedStatus,
  accountActivateRequest,
  operationListsResetModifiedObj
} from 'actions';
import { IStore, commonProps } from 'types';
import { TMLists, CreateEditFeedList } from './SubPages';
import { useAccountSwitcherData, useActivePlatform, useParamsDeconstructor, useSSOIdentifiersForAccount } from 'utils/hooks';
import { COMMON_SOCIAL_PROVIDER_TYPE, CONNECTION_TABS, OPERATION_LISTS_ERROR_MSG, PRIMARY_APP_SOURCE, RIPPLE_COLOR, USER_OWNERSHIP } from 'utils/constants';
import { scrollToTop } from 'utils/helpers';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { Loading } from 'components';

export const Lists = () => {
  const navigate = useNavigate();
  const dispatch = useCallback(useDispatch(), []); // eslint-disable-line

  const { id, userOwnership, isValidId, optionalParams, isAdminScreenEnabled, currentRoute } = useAccountSwitcherData();
  const { isCSLocation } = useSSOIdentifiersForAccount();
  const { queryParams } = useParamsDeconstructor();
  const activePlatforms = useActivePlatform();

  const param = optionalParams[0];

  const locationsHubList = useSelector((state: IStore) => state.operationsList.locationsGroupList);
  const loadListAgain = useSelector((state: IStore) => state.operationsList.loadListAgain);
  const modifiedObj = useSelector((state: IStore) => state.operationsList.modifiedObj);
  const activateAccount = useSelector((state: IStore) => state.accounts.activateAccount);
  const isAdmin = useSelector((state: IStore) => state.login.activeUser?.admin);
  const isAccountActivateFetching = useSelector((state: IStore) => state.accounts.isAccountActivateFetching);
  const selectedFranchisorId = useSelector((state: IStore) => state.adminArea.selectedFranchisorId);
  const isFetching = useSelector((state: IStore) => state.operationsList.isListFetching);
  const isTreeFetching = useSelector((state: IStore) => state.operationsList.isTreeFetching);
  const isSaveRequest = useSelector((state: IStore) => state.operationsList.isSaveRequest);
  const modifyLocationGroups = useSelector((state: IStore) => state.operationsList.modifiedLocationGroupList);
  const isSSO = useSelector((state: IStore) => state.login.isSSO);
  const isContentSupplierFranchisor = useSelector((state: IStore) => state.accountSwitcher?.content_supplier_franchisor || false);

  const isFromSetupAccountProfile = optionalParams[2] === CONNECTION_TABS.FEED;

  useEffect(() => {
    scrollToTop();
    return () => {
      if (userOwnership === USER_OWNERSHIP.FRANCHISOR) dispatch(operationListsResetAll());
    };
  }, [userOwnership, dispatch]);

  useEffect(() => {
    if (userOwnership === USER_OWNERSHIP.FRANCHISOR && isValidId && id) dispatch(getLocationsTreeRequest({ franchisor_id: id }));
    return () => {
      dispatch(reloadList());
    };
  }, [userOwnership, id, isValidId, dispatch]);

  useEffect(() => {
    if (id && isValidId) {
      dispatch(
        getLocationsHubListRequest({
          [`${userOwnership}_id`]: id,
          ...(queryParams.feed_type && { feed_type: queryParams.feed_type }),
          ...(isFromSetupAccountProfile &&
            (isSSO || isContentSupplierFranchisor) && {
              from: 'account_wizard',
              platforms: {
                use_facebook: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK),
                use_instagram: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM),
                use_twitter: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.TWITTER),
                use_linkedin: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN),
                use_google: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE),
                use_tiktok: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK),
                use_pinterest: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST)
              }
            })
        })
      );
    }
  }, [userOwnership, id, isValidId, dispatch, queryParams.feed_type, loadListAgain]); // eslint-disable-line

  useEffect(() => {
    if (userOwnership === USER_OWNERSHIP.FRANCHISOR && param === 'new' && id && isValidId) {
      dispatch(
        getLocationHubDetailRequest({
          selectedObj: {
            id: 0,
            franchisor_id: id,
            created_at: '',
            created_user: {
              id: 0,
              name: ''
            },
            name: modifiedObj?.name || '',
            updated_at: '',
            account_included: false,
            content_suppliers: modifiedObj?.content_suppliers || [],
            feed_type: false,
            content_type: 0
          }
        })
      );
    }
  }, [userOwnership, param, id, isValidId, dispatch]); // eslint-disable-line

  useEffect(() => {
    if (userOwnership === USER_OWNERSHIP.FRANCHISOR) {
      if (locationsHubList.length && param) {
        const selectedObj = locationsHubList.find((it) => it?.id === +param);
        if (selectedObj && isValidId) {
          dispatch(getLocationHubDetailRequest({ selectedObj }));
        } else if (param !== 'new' && param !== 'account_wizard') {
          dispatch(operationListsDetailViewError(OPERATION_LISTS_ERROR_MSG));
        }
      }
    }
  }, [userOwnership, param, locationsHubList, isValidId, dispatch]);

  useEffect(() => {
    if (userOwnership === USER_OWNERSHIP.FRANCHISOR && loadListAgain) {
      dispatch(contentFeedGetCSFranchisorsListRequest({ id }));
    }
  }, [id, userOwnership, loadListAgain, dispatch]);

  useEffect(() => {
    if (userOwnership === USER_OWNERSHIP.FRANCHISOR && optionalParams.length && param !== 'new' && param !== 'account_wizard' && !+param) {
      dispatch(operationListsDetailViewError(OPERATION_LISTS_ERROR_MSG));
    }
  }, [userOwnership, optionalParams, param, dispatch]);

  const handleCancel = () => {
    dispatch(rallioProfileResetAll());
    if (isAdminScreenEnabled) {
      if (selectedFranchisorId) dispatch(setFranchisorIds(null));
      dispatch(accountProfileReset());
      dispatch(socialProfileResetAll());
    }
    handleURLPathname(`/${currentRoute}`, { ...queryParams });
  };

  const handleNavigateToSchedulerPage = () => {
    if (modifyLocationGroups?.length) {
      modifyLocationGroups.forEach((feed) => {
        dispatch(
          subscriptionsLocationToListRequest({ account_id: id, account_list_id: feed?.id, account_included: feed?.account_included, isFromAccountWizard: true, platforms: feed?.platforms || null })
        );
      });
    }
    /* if (isCSLocation) { */
    if ((PRIMARY_APP_SOURCE === 'rallio' && isSSO && isCSLocation) || isContentSupplierFranchisor) {
      navigate({ pathname: `/${userOwnership}/${id?.toString()}/${currentRoute}/account_wizard/${id?.toString()}/${CONNECTION_TABS.VIDEO_CONCLUSION}`, search: '' });
    } else {
      dispatch(
        accountActivateRequest({
          id,
          onNavigate: (currentAccountId) =>
            navigate({
              pathname: `/${USER_OWNERSHIP.ACCOUNT}/${currentAccountId.toString()}/content/calendar`,
              search: `?${new URLSearchParams({ ...queryParams, ...{ view: 'calendar' } }).toString()}`
            })
        })
      );
    }
    /* } else {
      if (PRIMARY_APP_SOURCE === 'rallio') {
        navigate({ pathname: `/${userOwnership}/${id?.toString()}/${currentRoute}/account_wizard/${id?.toString()}/${CONNECTION_TABS.VIDEO_CONCLUSION}`, search: '' });
      } else {
        dispatch(
          accountActivateRequest({
            id,
            onNavigate: (currentAccountId) =>
              navigate({
                pathname: `/${USER_OWNERSHIP.ACCOUNT}/${currentAccountId.toString()}/content/calendar`,
                search: `?${new URLSearchParams({ ...queryParams, ...{ view: 'calendar' } }).toString()}`
              })
          })
        );
      }
    } */
    dispatch(modifyLocationsHubListResponse([]));
    dispatch(commonSetContentModifiedStatus(false));
    dispatch(operationListsResetModifiedObj());
  };

  const handleURLPathname = (route: string, search?: commonProps.ICommonObj<string>) => {
    if (isAdmin && isAdminScreenEnabled) navigate({ pathname: route, search: `?${new URLSearchParams(search ? { ...queryParams, ...search } : queryParams).toString()}` });
    else navigate({ pathname: `/${userOwnership}/${id?.toString()}${route}`, search: `?${new URLSearchParams(search ? { ...queryParams, ...search } : queryParams).toString()}` });
  };

  return (
    <>
      {isFetching || isTreeFetching || isSaveRequest ? (
        <Loading />
      ) : (
        <>
          {!param || param === 'account_wizard' || optionalParams?.[1] === 'manage_accounts' ? <TMLists /> : <CreateEditFeedList />}
          {isFromSetupAccountProfile && (
            <div className="stg-item form-configure r-mr0">
              <div className="modal-btn-grp-wraps csp-action ap-actions cn-social-pr">
                {activateAccount ? null : (
                  <CustomRippleButton rippleClass={`ripple-unset ac-secondary-box edit-ripple__wrp`} custColor={RIPPLE_COLOR.whiteGrey}>
                    <button className="ac-btn ac-secondary-white ac-outline size-sm border-0" disabled={isAccountActivateFetching} onClick={handleCancel}>
                      Cancel
                    </button>
                  </CustomRippleButton>
                )}
                <CustomRippleButton rippleClass={`ripple-unset ac-primary-box r-ml2`} custColor={RIPPLE_COLOR.primary}>
                  <button
                    className="ac-btn ac-primary-white ac-outline size-sm"
                    disabled={isAccountActivateFetching}
                    onClick={() => {
                      handleURLPathname(`/${currentRoute}/account_wizard/${id}/${CONNECTION_TABS.SOCIAL_PROFILES}`);
                    }}
                  >
                    Back
                  </button>
                </CustomRippleButton>
                <CustomRippleButton rippleClass={`ripple-unset ac-primary-box r-ml2`} custColor={RIPPLE_COLOR.primary}>
                  <button className="ac-btn ac-primary size-sm" disabled={isAccountActivateFetching} onClick={handleNavigateToSchedulerPage}>
                    {isAccountActivateFetching ? <Spinner animation="border" variant="light" /> : 'Next'}
                  </button>
                </CustomRippleButton>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
