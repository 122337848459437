import ReactPlayer from 'react-player';

import { commonWidgetTypes } from 'types';

export const ReactVideoPlayer = (props: commonWidgetTypes.IVideoProps) => (
  <ReactPlayer
    className={props.customClassname}
    url={props.videoUrl}
    light={props.light}
    playing={props.isPlayControl ? true : false}
    controls={Object(props).hasOwnProperty('controls') ? props.controls : true}
  />
);
