import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { Container } from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import moment from 'moment-timezone';
import { useEffect, useMemo } from 'react';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { useSelector, useDispatch } from 'react-redux';

import { ModalPopup, NameCircle } from 'revv/components';
import { IDashboardSurveyQuestionList } from 'revv/types';
import { config } from 'config';
import { Loading } from 'components';
import { findSmiley } from 'revv/utils';
import { IStore } from 'types';
import { dashboardLocationDetailedViewRequest } from 'revv/actions';

interface IDetailedViewData {
  data: {
    locationId: string;
    locationName: string;
    date: string;
    datetime: string;
    retailType: string;
    customerId: number;
    customerName: string;
    customerMail: string;
    customerComment: string;
    mobileNumber: number | null | undefined;
    isDataAvailable: boolean;
    ratingValue?: number;
  };
  questionList: IDashboardSurveyQuestionList[];
}
interface ILocationListDetailedViewProps {
  isEnabled: boolean;
  currentPage: number;
  totalPage?: number;
  updatePageNum?: (pageNum: number) => void;
  onHide: () => void;
  classVariant?: string;
  getSurveyBasedOnUser?: (customerId: number) => void;
  activeSurveyId: number | null;
}

export const SurveyDetailedView = ({ activeSurveyId, classVariant, isEnabled, currentPage, totalPage, updatePageNum, getSurveyBasedOnUser, onHide }: ILocationListDetailedViewProps) => {
  const dispatch = useDispatch();

  const isFetching = useSelector((state: IStore) => state.revv.dashboard.isLocationDetailsFetching);
  const locationDetails = useSelector((state: IStore) => state.revv.dashboard.locationDetails);
  // const settingsDetails = useSelector((state: IStore) => state.revv.settings.settingsDetails?.attributes);
  // const surveyType = settingsDetails?.survey_type || '';

  const detailedViewData = useMemo<IDetailedViewData | null>(() => {
    if (locationDetails) {
      return {
        data: {
          locationId: locationDetails.store,
          locationName: locationDetails.locationName,
          date: locationDetails?.completedAt ? moment(`${locationDetails?.completedAt}`).format('MMM DD YYYY, hh:mm A') : '',
          datetime: locationDetails?.datetime ? moment(`${locationDetails?.datetime}`.substring(0, 19) || '').format('MMM DD YYYY, hh:mm A') : '',
          retailType: locationDetails.retailType,
          customerId: locationDetails.customerId,
          customerName: locationDetails.customerName,
          customerMail: locationDetails.emailAddr,
          customerComment: locationDetails.comments,
          mobileNumber: locationDetails.mobNumber,
          isDataAvailable: locationDetails.isDataAvailable,
          rating: locationDetails.overallExp
        },
        questionList: locationDetails.questions || []
      };
    }
    return null;
  }, [locationDetails]);

  useEffect(() => {
    if (activeSurveyId) {
      dispatch(dashboardLocationDetailedViewRequest(activeSurveyId));
    }
  }, [activeSurveyId, dispatch]);

  return (
    <ModalPopup
      className={`non-notification__modal-container non-psp__modal notifResolvingView${classVariant ? classVariant : ''}`}
      show={isEnabled}
      centered
      fullView
      bodyClass="p-0"
      backdropClasses="non-psp__modal-backdrop"
      onHide={onHide}
      renderBody={() => (
        <Container className="p-0" fluid>
          <img
            src={`${config.cdnRevvImgUrl}/modal-prev.svg`}
            alt="navigation"
            className={`nav-prev__icon ${currentPage <= 1 ? 'd-none' : 'cur-pointer'}`}
            onClick={() => {
              if (updatePageNum) {
                updatePageNum(currentPage - 1);
              }
            }}
          />
          <img
            src={`${config.cdnRevvImgUrl}/modal-next.svg`}
            alt="navigation"
            className={`nav-next__icon  ${currentPage === totalPage || currentPage === 0 ? 'd-none' : 'cur-pointer'}`}
            onClick={() => {
              if (updatePageNum) {
                updatePageNum(currentPage + 1);
              }
            }}
          />
          <div className="mod-close__icon cur-pointer" onClick={onHide}>
            <img src={`${config.cdnRevvImgUrl}/mpcIcon.svg`} alt="close" className="non-psp-mod-close" />
          </div>
          {isFetching ? <Loading /> : null}
          <div className="dashboard-detailed__modal--scroll">
            {detailedViewData?.data.isDataAvailable === false ? (
              <div className="h-100 notification__modal-inner--list with-resolved-textarea d-flex align-items-center justify-content-center">No location details found</div>
            ) : (
              <div className="h-100 notification__modal-inner--list with-resolved-textarea">
                <div className="notification-detailed-view">
                  <div className="resolve-btn--wrp d-flex align-items-center justify-content-between flex-column flex-md-row">
                    <div className="loc-name__hldr">
                      <div className="d-flex align-items-center flex-column flex-md-row notification__loc-wrp">
                        <span className="notification__loc-lbl text-dotted" title={detailedViewData?.data.locationName}>
                          {detailedViewData?.data.locationName}
                        </span>
                      </div>
                      <div className="notification__date fs-secondary">{detailedViewData?.data.date || detailedViewData?.data.datetime || ''}</div>
                    </div>
                  </div>
                  <div className="note-name-mail--wrp d-flex align-items-center">
                    <NameCircle
                      name={detailedViewData?.data?.customerName || ''}
                      onClick={() => {
                        if (detailedViewData?.data.customerId && getSurveyBasedOnUser) {
                          getSurveyBasedOnUser(detailedViewData.data.customerId);
                        }
                      }}
                    />
                    {detailedViewData?.data?.customerMail || detailedViewData?.data?.mobileNumber ? (
                      <span className="text-dotted email">{detailedViewData?.data?.customerMail || detailedViewData?.data?.mobileNumber || '-'}</span>
                    ) : null}
                  </div>
                  <article className="notification__description mt-1 mb-2 fs-secondary text-break overflow-auto">
                    <Scrollbars autoHide>{detailedViewData?.data?.customerComment || '-'}</Scrollbars>
                  </article>
                </div>
                <div className="notification__table-container ">
                  <Scrollbars autoHide>
                    <Table className="revv-dboard">
                      <Thead>
                        <Tr>
                          <Th>Questions</Th>
                          <Th>Rating</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {detailedViewData &&
                          detailedViewData?.questionList?.map((question: IDashboardSurveyQuestionList, index) => {
                            return (
                              <Tr key={index}>
                                <Td>
                                  <div className="d-flex align-items-center question-wrp">
                                    <span className="q-no">{`Q${index + 1}`}</span>
                                    <div className="question1">{question.question}</div>
                                  </div>
                                </Td>
                                <Td>
                                  {/* <div className="d-flex align-items-center">
                                    {findSmiley(question?.answer) ? <img className="smiley-icon" src={findSmiley(question?.answer)} alt="smiley" /> : null}
                                    <div className="score-lbl">{surveyType === 'nps' ? '' : question?.answer ? question?.answer : 'NA'}</div>
                                  </div> */}
                                  <div className="d-flex align-items-center">
                                    {detailedViewData.data?.ratingValue && detailedViewData.data.ratingValue >= 0 ? (
                                      <img src={`${config.cdnRevvImgUrl}/${detailedViewData.data.ratingValue > 0 ? 'star' : 'star-grey'}.svg`} alt="review-status" />
                                    ) : findSmiley(question?.answer?.toLocaleLowerCase() || '') ? (
                                      <img className="smiley-icon" src={findSmiley(question?.answer?.toLocaleLowerCase() || '')} alt="smiley" />
                                    ) : null}
                                    <div className="score-lbl">
                                      {detailedViewData.data.ratingValue && detailedViewData.data.ratingValue >= 0 ? detailedViewData.data.ratingValue : question?.answer}
                                    </div>
                                  </div>
                                </Td>
                              </Tr>
                            );
                          })}
                      </Tbody>
                    </Table>
                  </Scrollbars>
                </div>
              </div>
            )}
          </div>
        </Container>
      )}
    />
  );
};
