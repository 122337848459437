import { Modal, ModalBody, ModalFooter } from 'react-bootstrap';

import { commonModalPopup } from 'types';
import { RIPPLE_COLOR } from 'utils/constants';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { ImageValidation } from 'widgets/Media';

export const AIImageValidationMsgModal = ({ isShowModal, onSubmit, onCancel, violatedImages }: commonModalPopup.IAIImageComplianceWarningModalProps) => {
  return (
    <Modal show={isShowModal} className="cnfrm__delete--modal glbl__delete--modal glbl__unsave--modal icw-main" onHide={onCancel}>
      <div className="mod__close--icon">
        <ImageValidation isImgValid defaultImg="mpcIcon" customClassname="close-icon close mod__close--img wh-30" customName={'close'} onClick={onCancel} />
      </div>
      <ModalBody>
        <div className="ic-wrap">
          <h2 className="ic-head">Image Compliance</h2>
          {violatedImages?.length ? (
            <div className="icg-grid img-comp-grid">
              {violatedImages.map((image, index: number) => {
                return (
                  <div key={`img-compliance-item-${index}`} className={`iComp-item`}>
                    <div className="icg-img-holder">
                      <ImageValidation isImgValid defaultImg="warning" customClassname="icg-warning" customName={'Image Compliance Warning'} />
                      <div className="icg-wrap">
                        <ImageValidation isNotSvgFormat imgUrl={image.image_url} customName="media-assets" customClassname="m-ast-itm m-ast-img" />
                      </div>
                    </div>
                    <p>{image.explanation}</p>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="delete__btn--wrp">
          <CustomRippleButton rippleClass="ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
            <button className="ac-btn ac-secondary-white ac-outline ac-block border-0" onClick={onCancel}>
              Cancel
            </button>
          </CustomRippleButton>
          <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
            <button className={`ac-btn ac-primary ac-block`} onClick={onSubmit}>
              Replace Image
            </button>
          </CustomRippleButton>
        </div>
      </ModalFooter>
    </Modal>
  );
};
