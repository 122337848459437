import { useDispatch, useSelector } from 'react-redux';

import { IStore } from 'types';
import { ImageValidation } from 'widgets/Media';
import { AI_SUBSCRIPTION_PLAN_NAMES, DISCOUNT_PRICE_OFFER_MSG, RIPPLE_COLOR } from 'utils/constants';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { setAISubscriptionDetailsModal, setCsLocationSubscriptionModalType } from 'actions';
import { getDiscountedDaysFromToday } from 'utils/helpers';
import { useSSOIdentifiersForAccount } from 'utils/hooks';

export const SalesFilter = () => {
  const dispatch = useDispatch();

  const { isCSLocation } = useSSOIdentifiersForAccount();

  const accSubscriptionDetails = useSelector(
    (state: IStore) => state.accounts.accountDetails?.account?.active_subscription_details?.[0] || state.accounts.accountDetails?.account?.subscription_details?.[0]
  );

  const isFreeTier = [AI_SUBSCRIPTION_PLAN_NAMES.RC_FREE].includes(accSubscriptionDetails?.franchisor_subscription_type?.plan_name);

  const discountDays = getDiscountedDaysFromToday(accSubscriptionDetails?.created_at || '');
  const isCSLocationOfferAvailable = isFreeTier && discountDays >= 1 && discountDays <= 7;

  return (
    <div className="ralSalesWrap sdUpgrades">
      <div className={`ap-alert-main freeTier-alert-on`}>
        {isCSLocation && isCSLocationOfferAvailable ? (
          <div className="api-alert-box alert-facebook">
            <div className="w-100 bg-white d-flex justify-end align-items-center">
              <p>
                {DISCOUNT_PRICE_OFFER_MSG.MSG1} <h2>{DISCOUNT_PRICE_OFFER_MSG.MSG2}</h2> {DISCOUNT_PRICE_OFFER_MSG.MSG3}
              </p>
              <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
                <button
                  className={`ac-btn ac-primary ac-block kc-save`}
                  type="submit"
                  onClick={() => {
                    dispatch(setAISubscriptionDetailsModal({ type: 'CSLocationAISubscription', isOpen: true }));
                    dispatch(setCsLocationSubscriptionModalType('ai_community'));
                  }}
                >
                  Upgrade
                </button>
              </CustomRippleButton>
            </div>
          </div>
        ) : null}
      </div>
      <section className="item-g tm-permission-notify notification-cnt revv__sales--page ralAgency">
        <ImageValidation isImgValid defaultImg="eBrands" customClassname="agencyThumb" customName={'Rallio Subscription'} />
      </section>
    </div>
  );
};
