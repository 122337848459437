import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { /* Fragment, */ useCallback } from 'react';

import { IStore } from 'types';
import { capitalizeName, dateFormatterForPostDetail, addPadStart } from 'utils/helpers';
import { ImageValidation } from 'widgets/Media';
import { COMMA_SPLITTER, ADD_POST_STATUS_NAME, USER_OWNERSHIP, APPROVED_STATUS, PostSourceType, COMMON_SOCIAL_PROVIDER_TYPE } from 'utils/constants';
import { postDetailDataReset } from 'actions';
import { CampaignPostsDetailViewSection } from './CampaignPostsDetailViewSection';
import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';
import { PostDetailAvailability } from './PostDetailAvailability';
import { PostDetailEditing } from './PostDetailEditing';
import { PostSyndicationDetails } from './PostSyndicationDetails';

export const PostsDetailViewDetails = ({ activeSocialMediaTab }: { activeSocialMediaTab: string }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userOwnership, currentRoute } = useAccountSwitcherData();
  const { queryParams } = useParamsDeconstructor();

  const postsDetails = useSelector((state: IStore) => state.posts.postsDetails);
  const postSyndicationData = useSelector((state: IStore) => state.addPostFranchisor.postSyndicationDetails);
  const isContentSupplierFranchisor = useSelector((state: IStore) => state.accountSwitcher?.content_supplier_franchisor || false);
  const editedPostDetails = useSelector((state: IStore) => state.posts.editedPostDetails);
  const topLevelFranchisorId = useSelector((state: IStore) => state.accountSwitcher.topLevelFranchisorId);
  // const isContentSupplier = useSelector((state: IStore) => state.franchisors.franchisorDetails?.content_supplier ?? false);

  const handleNavigateLocation = () => {
    if (postsDetails?.id && postsDetails?.account?.id) {
      dispatch(postDetailDataReset());
      navigate({ pathname: `/${USER_OWNERSHIP.ACCOUNT}/${postsDetails?.account.id.toString()}/${currentRoute}` });
    }
  };

  const getPostType = useCallback(
    (platform: string) => {
      switch (platform) {
        case COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK:
          return postsDetails?.facebook_reel ? 'Reel' : postsDetails?.facebook_story ? 'Story' : 'Post';
        case COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM:
          return postsDetails?.instagram_reel ? 'Reel' : postsDetails?.instagram_story ? 'Story' : 'Post';
        default: {
          return postsDetails?.pinterest_board_name || 'My Pins';
        }
      }
    },
    [postsDetails]
  );

  const renderPostStatus = () => {
    return (
      postsDetails && (
        <>
          <div className="wb-fields">
            <div className="wbf-item">
              <div className="wbf-label-main">
                <span className="wbf-label">Status</span>
              </div>
              <div className="wbf-inputs">
                <div className="wbfi-items g-10">
                  <div className="details__appr--icon">
                    <ImageValidation
                      isImgValid
                      defaultImg={
                        postsDetails?.rejected ? 'stats/rejected' : postsDetails.status_id === 1 ? 'draft-filled' : [2, 5].includes(postsDetails.status_id) ? 'pending-filled' : 'stats/approved'
                      }
                      customName={'Status'}
                    />
                  </div>
                  <span className="wbfi-txt">
                    {postsDetails?.rejected
                      ? ADD_POST_STATUS_NAME.REJECTED
                      : postsDetails.status_id === 1
                      ? ADD_POST_STATUS_NAME.DRAFT
                      : userOwnership === USER_OWNERSHIP.FRANCHISOR && postsDetails.status_id === 2
                      ? ADD_POST_STATUS_NAME.AWAITING_APPROVAL
                      : userOwnership === USER_OWNERSHIP.ACCOUNT && postsDetails.status_id === 2
                      ? ADD_POST_STATUS_NAME.PENDING_APPROVAL
                      : postsDetails?.status_id === 3
                      ? `${ADD_POST_STATUS_NAME.APPROVED}${postsDetails?.scheduled_anywhere ? ' & Used' : ' & Unused'}`
                      : ADD_POST_STATUS_NAME.AWAITING_APPROVAL_AI_GENERATED}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      )
    );
  };

  const renderPostCreatedDate = () => {
    return (
      postsDetails && (
        <div className="wb-fields">
          <div className="wbf-item">
            <div className="wbf-label-main">
              <span className="wbf-label">Created on</span>
            </div>
            <div className="wbf-inputs">
              <div className="wbfi-items">
                <span className="wbfi-txt">{dateFormatterForPostDetail(postsDetails.created_at)}</span>
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  const renderPostCreatedBy = () => {
    return (
      !postsDetails?.content_supplier?.is_content_supplier && (
        <div className="wb-fields">
          <div className="wbf-item">
            <div className="wbf-label-main">
              <span className="wbf-label">Created by</span>
            </div>
            <div className="wbf-inputs">
              <div className="wbfi-items">
                <span
                  className="wbfi-txt blue-text cur-pointer"
                  onClick={() => {
                    navigate({ search: `?${new URLSearchParams({ ...queryParams, user_id: String(postsDetails?.created_user?.id) }).toString()}` });
                  }}
                >
                  {capitalizeName(postsDetails?.created_user?.name || '')}
                </span>
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  const renderPostTags = () => {
    return (
      postsDetails && (
        <div className="wb-fields">
          <div className="wbf-item">
            <div className="wbf-label-main">
              <span className="wbf-label">Tags</span>
            </div>
            <div className="wbf-inputs">
              <div className="wbfi-items">
                <div className="wbf-tags fts">
                  {postsDetails.tags_list
                    ? postsDetails.tags_list.split(COMMA_SPLITTER).map((tagDatum, index) => {
                        return (
                          <span key={`posts-tag-${index}`} className="wbft-txt fav-tags">
                            {tagDatum}
                          </span>
                        );
                      })
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  /* const renderSyndicatedRegions = useCallback(() => {
    return postsDetails ? (
      <div className="wb-fields">
        <div className="wbf-item">
          <div className="wbf-label-main">
            <span className="wbf-label">Where</span>
          </div>
          <div className="wbf-inputs">
            <div className="wbfi-items flex-wrap">
              {postsDetails?.country_codes?.length ? (
                postsDetails?.country_codes.map((countryCode: string, index: number) => {
                  return (
                    <Fragment key={index}>
                      <span className={`wbfi-txt`} key={`syndication-region-${index}`}>
                        {countryCode}
                      </span>
                      {index + 1 === postsDetails?.country_codes.length ? null : index === postsDetails?.country_codes?.length - 2 ? (
                        <span className="mx-2">{'and'} </span>
                      ) : (
                        <span className="me-2">,</span>
                      )}
                    </Fragment>
                  );
                })
              ) : (
                <span className={`wbfi-txt`}>All</span>
              )}
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }, [postsDetails]); */

  const renderPostCreatedBrand = useCallback(() => {
    return (
      <div className="wb-fields">
        <div className="wbf-item">
          <div className="wbf-label-main">
            <span className="wbf-label">{capitalizeName(postsDetails?.content_supplier?.id === topLevelFranchisorId ? PostSourceType.BRAND : PostSourceType.HUB)}</span>
          </div>
          <div className="wbf-inputs">
            <div className="wbfi-items">
              <span className="wbfi-txt">{capitalizeName(postsDetails?.content_supplier?.name)}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }, [postsDetails?.content_supplier, topLevelFranchisorId]);

  const renderPostType = useCallback(() => {
    return (
      <div className="wb-fields">
        <div className="wbf-item">
          <div className="wbf-label-main">
            <span className="wbf-label">{[COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM].includes(activeSocialMediaTab) ? 'Type' : 'Board'}</span>
          </div>
          <div className="wbf-inputs">
            <div className="wbfi-items">
              <span className="wbfi-txt">{getPostType(activeSocialMediaTab)}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }, [activeSocialMediaTab, getPostType]);

  return (
    postsDetails && (
      <>
        <div className="st-tc-item pd-fields pd-items pdi-postDetails">
          <div className="wb-top post__white--box bradius-lb-rb-none">
            <div className="pd-field-group">
              <div className="wb-label">
                <div className="wbl-head">Post Details</div>
              </div>
              <div className="wb-f-main">
                <div className="wb-fields">
                  <div className="wbf-item">
                    <div className="wbf-label-main">
                      <span className="wbf-label">Package ID</span>
                    </div>
                    <div className="wbf-inputs">
                      <div className="wbfi-items">
                        <span className="wbfi-txt">{!postsDetails ? '---' : userOwnership === USER_OWNERSHIP.ACCOUNT ? addPadStart(postsDetails.id, 'E') : postsDetails.id}</span>
                      </div>
                    </div>
                  </div>
                </div>
                {isContentSupplierFranchisor && postsDetails?.content_supplier?.is_content_supplier && (
                  <div className="wb-fields">
                    <div className="wbf-item">
                      <div className="wbf-label-main">
                        <span className="wbf-label">Source</span>
                      </div>
                      <div className="wbf-inputs">
                        <div className="wbfi-items">
                          <span className="wbfi-txt">
                            {postsDetails?.content_supplier?.name || ''}
                            {/*   <div className="sh-bLogo">
                            <ImageValidation isImgValid defaultImg={'rIcon'} imgUrl={postsDetails?.content_supplier?.square_logo_url} customName={'Asset status'} />
                          </div> */}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {userOwnership === USER_OWNERSHIP.FRANCHISOR ? (
                  <>
                    {renderPostCreatedDate()}
                    {!postsDetails?.content_origins && renderPostCreatedBy()}
                    {renderPostCreatedBrand()}
                    {postsDetails?.status_id === 2 && postsDetails?.account?.name && (
                      <div className="wb-fields">
                        <div className="wbf-item">
                          <div className="wbf-label-main">
                            <span className="wbf-label">Location</span>
                          </div>
                          <div className="wbf-inputs">
                            <div className="wbfi-items">
                              <span className="wbfi-txt blue-text cur-pointer" onClick={() => handleNavigateLocation()}>
                                {postsDetails.account.name}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="wb-fields">
                      <div className="wbf-item">
                        <div className="wbf-label-main">
                          <span className="wbf-label">Scheduled</span>
                        </div>
                        <div className="wbf-inputs">
                          <div className="wbfi-items">
                            <span className="wbfi-txt">{postsDetails.post_published_count ? APPROVED_STATUS.YES : APPROVED_STATUS.NO}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {renderPostStatus()}
                    {renderPostTags()}
                    {[COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM].includes(activeSocialMediaTab) && renderPostType()}
                    {/* {renderSyndicatedDetails()} */}
                    {/* {(isContentSupplier || isContentSupplierFranchisor) && postsDetails?.content_supplier?.is_content_supplier && renderSyndicatedRegions()} */}
                  </>
                ) : (
                  <>
                    {postsDetails?.syndicated_from_content_id && renderPostCreatedBrand()}
                    {renderPostCreatedDate()}
                    {!postsDetails?.syndicated_from_content_id && renderPostCreatedBy()}
                    {renderPostStatus()}
                    {renderPostTags()}
                    {[COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM, COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST].includes(activeSocialMediaTab) && renderPostType()}
                  </>
                )}
              </div>
            </div>
            <CampaignPostsDetailViewSection />
          </div>
        </div>
        {postsDetails && postSyndicationData?.length ? <PostSyndicationDetails syndicationDetails={postSyndicationData} lastSyndicateDate={postsDetails.updated_at} /> : null}
        <PostDetailAvailability
          postsDetails={postsDetails}
          editedPostDetails={editedPostDetails}
          isContentSupplierFranchisor={isContentSupplierFranchisor}
          userOwnership={userOwnership}
          dispatch={dispatch}
        />
        <PostDetailEditing postsDetails={postsDetails} editedPostDetails={editedPostDetails} dispatch={dispatch} userOwnership={userOwnership} />
      </>
    )
  );
};
