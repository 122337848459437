import { put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  deleteLocationListFailure,
  deleteLocationListResponse,
  exceptionHandlerRequest,
  getLocationHubDetailFailure,
  getLocationHubDetailResponse,
  getLocationsHubListFailure,
  getLocationsHubListResponse,
  getLocationsTreeFailure,
  getLocationsTreeResponse,
  logoutRequest,
  saveLocationGroupFailure,
  saveLocationGroupResponse,
  subscriptionsLocationToListFailure,
  subscriptionsLocationToListResponse,
  updateLocationsHubListFailure,
  updateLocationsHubListResponse,
  contentFeedGetCSFranchisorsListFailure,
  contentFeedGetCSFranchisorsListResponse,
  contentFeedGetHubListResponse,
  getEligibleContentsCountResponse,
  getEligibleContentsCountFailure
} from 'actions';
import { ISagaPayload } from 'types/common/api';
import { API, apiCall, defaultHeader, formLocationTreeData, HttpMethods, updateFranchisorSyndicateWithLabel } from 'utils/helpers';
import {
  DL_UPDATED_SUCCESSFULLY,
  ALERT_BOX_TYPES,
  DELETE_COUPON_SUCCESS,
  OPERATION_LISTS_ERROR_MSG,
  OPERATION_LISTS_LOC_HUB_LIST_ERROR,
  OPERATION_LISTS_LOC_TREE_ERROR,
  OPERATION_LISTS_LOC_HUB_DETAIL_ERROR,
  OPERATION_LISTS_SAVE_LOC_GROUP_ERROR,
  DL_UPDATED_FAILED,
  OPERATION_LISTS_DELETE_LOC_LIST_ERROR,
  SUBSCRIPTIONS_LOCATION_TO_LIST_ERROR,
  SUBSCRIPTIONS_LISTS_ERROR_MSG,
  CONTENT_SUPPLIER_EXCEPTION_ERROR,
  CAMPAIGN_WEEKLY_SCHEDULE_FROM,
  SETTINGS_SUCCESS_MSG,
  SETTINGS_FAILURE_MSG
} from 'utils/constants';
import * as types from '../../actions/action-types/operations-lists';
import { alertBoxCall } from 'components';

function* loadLocationsHubsList(action: ISagaPayload): any {
  try {
    const updatedParams = { ...action.payload };
    if (updatedParams.from) delete updatedParams.from;
    const response = yield apiCall({ headers: defaultHeader(), ...API.getAccountList, params: { ...updatedParams, named_only: 1 } });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      if (action.payload?.from === CAMPAIGN_WEEKLY_SCHEDULE_FROM.SCHEDULER) {
        yield put(contentFeedGetHubListResponse(response.data.account_lists));
      }
      yield put(
        getLocationsHubListResponse({
          data: response.data.account_lists,
          isDefaultEnroll: action.payload?.from === 'account_wizard',
          account_included: action.payload?.account_included || false,
          platforms: action.payload?.platforms
        })
      );
    } else {
      yield put(getLocationsHubListFailure(OPERATION_LISTS_LOC_HUB_LIST_ERROR));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(OPERATION_LISTS_ERROR_MSG));
  }
}

function* updateLocationsHubsList(action: ISagaPayload): any {
  try {
    const apiPath = API.updateAccountList.apiPath.replace(':id', action.payload.id);
    const response = yield apiCall({ headers: defaultHeader(), apiPath, action: API.updateAccountList.action, data: action.payload });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(updateLocationsHubListResponse(response.data.account_list));
    } else {
      yield put(updateLocationsHubListFailure(OPERATION_LISTS_LOC_HUB_LIST_ERROR));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(OPERATION_LISTS_ERROR_MSG));
  }
}

function* subscriptionsLocationToList(action: ISagaPayload): any {
  try {
    const data = {
      account_id: action.payload.account_id,
      ...(action.payload?.account_included && {
        use_facebook: action.payload?.platforms?.use_facebook,
        use_instagram: action.payload?.platforms?.use_instagram,
        use_twitter: action.payload?.platforms?.use_twitter,
        use_linkedin: action.payload?.platforms?.use_linkedin,
        use_google: action.payload?.platforms?.use_google,
        use_tiktok: action.payload?.platforms?.use_tiktok,
        use_pinterest: action.payload?.platforms?.use_pinterest
      })
    };
    const apiPath = action.payload?.account_included
      ? API.subscriptionAccountToList.apiPath.replace(':account_list_id', action.payload.account_list_id)
      : API.deleteSubscriptionAccountToList.apiPath.replace(':account_list_id', action.payload.account_list_id);
    const response = yield apiCall({
      headers: defaultHeader(),
      apiPath,
      action: action.payload?.account_included ? API.subscriptionAccountToList.action : API.deleteSubscriptionAccountToList.action,
      data
    });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      if (!action.payload?.isFromAccountWizard) alertBoxCall(ALERT_BOX_TYPES.SUCCESS, SETTINGS_SUCCESS_MSG);
      yield put(subscriptionsLocationToListResponse(response.data.account_list));
    } else {
      alertBoxCall(ALERT_BOX_TYPES.ERROR, SETTINGS_FAILURE_MSG);
      yield put(subscriptionsLocationToListFailure(SUBSCRIPTIONS_LOCATION_TO_LIST_ERROR));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(SUBSCRIPTIONS_LISTS_ERROR_MSG));
  }
}

function* loadHubLocationsTree(action: ISagaPayload): any {
  try {
    const params = { franchisor_id: action.payload.franchisor_id };
    const response = yield apiCall({ headers: defaultHeader(), ...API.getTreeData, params });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      const parsedData = yield formLocationTreeData(response.data.brand_hubs_locations.franchisors);
      yield put(getLocationsTreeResponse({ data: parsedData }));
    } else {
      yield put(getLocationsTreeFailure(OPERATION_LISTS_LOC_TREE_ERROR));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(OPERATION_LISTS_ERROR_MSG));
  }
}

function* loadLocationHubDetailById(action: ISagaPayload): any {
  try {
    let url = '';
    let urlAction: HttpMethods;
    let params = {};
    if (action.payload.selectedObj.id === 0) {
      url = API.getDefaultLocList.apiPath;
      urlAction = API.getDefaultLocList.action;
      params = {
        franchisor_id: action.payload.selectedObj.franchisor_id
      };
    } else {
      url = API.getSelectedLocList.apiPath.replace(':id', action.payload.selectedObj.id);
      urlAction = API.getSelectedLocList.action;
    }
    const response = yield apiCall({ headers: defaultHeader(), apiPath: url, action: urlAction, params });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getLocationHubDetailResponse({ data: response.data.account_list_detail }));
    } else {
      yield put(getLocationHubDetailFailure(OPERATION_LISTS_LOC_HUB_DETAIL_ERROR));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(OPERATION_LISTS_ERROR_MSG));
  }
}

function* saveLocationGroup(action: ISagaPayload): any {
  try {
    let url = '';
    let urlAction: HttpMethods;
    const data = {
      account_list_detail: action.payload.reqPayload
    };
    if (action.payload.id) {
      url = API.updateCustomLocList.apiPath.replace(':id', action.payload.id);
      urlAction = API.updateCustomLocList.action;
    } else {
      url = API.addCustomLocList.apiPath;
      urlAction = API.addCustomLocList.action;
    }
    const response = yield apiCall({ headers: defaultHeader(), apiPath: url, action: urlAction, data });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(saveLocationGroupResponse());
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, DL_UPDATED_SUCCESSFULLY);
    } else {
      yield put(saveLocationGroupFailure(OPERATION_LISTS_SAVE_LOC_GROUP_ERROR));
      alertBoxCall(ALERT_BOX_TYPES.ERROR, DL_UPDATED_FAILED);
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(OPERATION_LISTS_ERROR_MSG));
  }
}

function* deleteLocationList(action: ISagaPayload): any {
  try {
    const apiPath = API.deleteCustomLoc.apiPath.replace(':id', action.payload.id);
    const response = yield apiCall({ headers: defaultHeader(), apiPath, action: API.deleteCustomLoc.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(deleteLocationListResponse());
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, DELETE_COUPON_SUCCESS);
    } else {
      yield put(deleteLocationListFailure(OPERATION_LISTS_DELETE_LOC_LIST_ERROR));
      alertBoxCall(ALERT_BOX_TYPES.ERROR, DL_UPDATED_FAILED);
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(OPERATION_LISTS_ERROR_MSG));
  }
}

function* sendContentSupplierGetFranchisorsListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const urlPathObj: { apiPath: string; action: any } = { ...API.getContentSupplierFranchisor };
    const replacedPath: string = urlPathObj.apiPath.replace(':id', payload.id);
    urlPathObj.apiPath = replacedPath;
    const response = yield apiCall({ headers, ...urlPathObj });
    if (response.status === 200) {
      yield put(contentFeedGetCSFranchisorsListResponse(updateFranchisorSyndicateWithLabel(response.data.franchisors)));
    } else {
      yield put(contentFeedGetCSFranchisorsListFailure(response.data.error));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(CONTENT_SUPPLIER_EXCEPTION_ERROR));
  }
}

function* sendGetEligibleContentsCountRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const response = yield apiCall({ headers, data: payload, ...API.getEligibleContentsCount });
    if (response.status === 200) {
      yield put(getEligibleContentsCountResponse(response.data?.eligible_contents_counts));
    } else {
      yield put(getEligibleContentsCountFailure(response.data.error));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(CONTENT_SUPPLIER_EXCEPTION_ERROR));
  }
}

export function* takeOperationsListRequest() {
  yield takeEvery(types.GET_LOCATIONS_HUBS_LIST_REQUEST, loadLocationsHubsList);
  yield takeLatest(types.UPDATE_LOCATIONS_HUBS_LIST_REQUEST, updateLocationsHubsList);
  yield takeEvery(types.SUBSCRIPTIONS_LOCATION_TO_LIST_REQUEST, subscriptionsLocationToList);
  yield takeLatest(types.GET_LOCATIONS_TREE_REQUEST, loadHubLocationsTree);
  yield takeLatest(types.GET_LOCATION_HUB_DETAIL_REQUEST, loadLocationHubDetailById);
  yield takeLatest(types.SAVE_LOCATION_LIST_REQUEST, saveLocationGroup);
  yield takeLatest(types.DELETE_LOCATION_LIST_REQUEST, deleteLocationList);
  yield takeLatest(types.CONTENT_FEED_GET_CS_FRANCHISORS_LIST_REQUEST, sendContentSupplierGetFranchisorsListRequest);
  yield takeLatest(types.GET_ELIGIBLE_CONTENT_COUNT_REQUEST, sendGetEligibleContentsCountRequest);
}
