import moment from 'moment-timezone';

import { config } from 'config';
import { IHub, IIdGroup, ISortingProps, ISurveyAnswer, ISurveyAnswerFormated, ISurveyAnswerMultipleQuestion, ISurveyList } from 'revv/types';
import { commonLocationTree } from 'types';

export const surveyAnswerModifier = (data: { [key: string]: ISurveyAnswer[] | any }, activeClientName: string) => {
  const questionStatistics: ISurveyAnswerFormated[] = [];
  Object.keys(data).forEach((key) => {
    if (data[key]) {
      data[key].forEach((it: ISurveyAnswer) => {
        const utcToLocalTime = moment(`${key} 23:59:00`).local().valueOf();
        if (!it?.data) {
          let excellentCountData = 0;
          if (activeClientName === 'Pet Supplies Plus') {
            excellentCountData = Number(it.count);
          } else {
            excellentCountData = Number(it.score);
          }
          const questionObject = {
            id: it.id,
            question: it.question,
            scorePercentage: Number(it.score),
            scoreSeries: [[utcToLocalTime, Number(it.score)]],
            totalExcellentCount: excellentCountData,
            totalCount: Number(it.total_count),
            isMultipleQuestion: false,
            multiQuestionData: [],
            indexCount: key
          };
          const presentObject = questionStatistics.find((q) => q.id === it.id);
          if (!presentObject) {
            questionStatistics.push(questionObject);
          } else {
            presentObject.totalExcellentCount += questionObject.totalExcellentCount;
            presentObject.totalCount += questionObject.totalCount;
            presentObject.indexCount += `?${questionObject.indexCount}`;
            if (activeClientName === 'Pet Supplies Plus') {
              presentObject.scorePercentage = Math.round(Number(((presentObject.totalExcellentCount / presentObject.totalCount) * 100).toFixed(1)));
            } else {
              presentObject.scorePercentage = presentObject?.indexCount
                ? Number(parseFloat(Number(presentObject.totalExcellentCount / presentObject?.indexCount?.split('?').length).toFixed(1)))
                : Number(0);
            }
            presentObject.scoreSeries.push([new Date(utcToLocalTime).getTime(), Number(it.score)]);
          }
        } else if (it?.data) {
          const questionObject = {
            id: it.id,
            question: it.question,
            isMultipleQuestion: true,
            scoreSeries: [],
            scorePercentage: 0,
            totalExcellentCount: 0,
            totalCount: 0,
            multiQuestionData: [...it.data].map((datum) => ({
              id: datum.id,
              answer: datum.answer,
              score: Number(datum.score),
              scorePercentage: Number(datum.score),
              scoreSeries: [[utcToLocalTime, Number(datum.score)]],
              totalExcellentCount: Number(datum.count),
              totalCount: Number(datum.total_count)
            }))
          };
          const presentObject = questionStatistics.find((q) => q.id === it.id);
          if (!presentObject) {
            questionStatistics.push(questionObject);
          } else {
            const isPresent = presentObject.multiQuestionData?.length < questionObject.multiQuestionData?.length;
            const newData = questionObject.multiQuestionData.filter((question) => {
              return presentObject?.multiQuestionData?.find((it) => it.answer !== question.answer);
            });
            const data = presentObject?.multiQuestionData?.concat(newData).reduce((acc: ISurveyAnswerMultipleQuestion[], it) => {
              const datum = questionObject.multiQuestionData.find((present) => present.answer === it.answer);
              if (datum) {
                const totalExcellentCount = it.totalExcellentCount + Number(datum.totalExcellentCount);
                const totalCount = it.totalCount + datum.totalCount;
                const scoreSeries = [...(!isPresent ? it.scoreSeries : datum.scoreSeries), [new Date(utcToLocalTime).getTime(), Number(isPresent ? it.score : datum.score)]];
                acc.push({
                  answer: it.answer,
                  id: it.id,
                  totalExcellentCount,
                  isMultipleQuestion: true,
                  score: it.score,
                  totalCount,
                  scorePercentage: Math.round(Number(((totalExcellentCount / totalCount) * 100).toFixed(1))),
                  scoreSeries
                });
              } else {
                acc.push({
                  ...it,
                  isMultipleQuestion: true
                });
              }
              return acc;
            }, []);
            presentObject.multiQuestionData = data;
          }
        }
      });
    }
  });
  return questionStatistics;
};

export const surveyAnswerModifierForNPS = (data: { [key: string]: ISurveyAnswer[] | any }, activeClientName: string) => {
  // console.log('log 2');
  const questionStatistics: ISurveyAnswerFormated[] = [];
  // promoters_count / total_count - detractors_count / total_count * 100
  Object.keys(data).forEach((key) => {
    // console.log('else data', data);
    if (data[key]) {
      data[key].forEach((it: ISurveyAnswer) => {
        // console.log('else c1', it.promoters_count, it.total_count, it.detractors_count);
        const utcToLocalTime = moment(`${key} 23:59:00`).local().valueOf();
        if (!it?.data) {
          let excellentCountData = 0;
          // if (activeClientName === 'Pet Supplies Plus') {
          //   excellentCountData = Number(it.count);
          // } else {
          //   excellentCountData = Number(it.score);
          // }
          if (it.promoters_count !== undefined && it.total_count !== undefined && it.detractors_count !== undefined) {
            excellentCountData = Number((it.promoters_count / it.total_count - it.detractors_count / it.total_count) * 100);
          } else {
            excellentCountData = 0;
          }
          // console.log('else c2', excellentCountData);
          const questionObject = {
            id: it.id,
            question: it.question,
            scorePercentage: !Number.isNaN(excellentCountData) ? excellentCountData : 0, // Number(it.score),
            scoreSeries: [[utcToLocalTime, Number(it.score)]],
            totalExcellentCount: !Number.isNaN(excellentCountData) ? excellentCountData : 0,
            totalCount: Number(it.total_count),
            isMultipleQuestion: false,
            multiQuestionData: [],
            promotersCount: it.promoters_count || 0,
            detractorsCount: it.detractors_count || 0,
            indexCount: key
          };
          const presentObject = questionStatistics.find((q) => q.id === it.id);
          if (!presentObject) {
            questionStatistics.push(questionObject);
          } else {
            presentObject.totalExcellentCount += questionObject.totalExcellentCount;
            presentObject.totalCount += questionObject.totalCount;
            presentObject.indexCount += `?${questionObject.indexCount}`;
            if (presentObject.promotersCount !== undefined) {
              presentObject.promotersCount += questionObject.promotersCount ? questionObject.promotersCount : 0;
            }
            if (presentObject.detractorsCount !== undefined) {
              presentObject.detractorsCount += questionObject.detractorsCount ? questionObject.detractorsCount : 0;
            }
            // console.log('else ', presentObject.indexCount, presentObject.totalExcellentCount, presentObject.totalCount)
            if (activeClientName === 'Pet Supplies Plus') {
              presentObject.scorePercentage = Math.round(Number(((presentObject.totalExcellentCount / presentObject.totalCount) * 100).toFixed(1)));
            } else {
              presentObject.scorePercentage =
                presentObject?.promotersCount && presentObject?.detractorsCount
                  ? Math.ceil(Number((presentObject.promotersCount / presentObject.totalCount - presentObject.detractorsCount / presentObject.totalCount) * 100))
                  : // Number(parseFloat(Number(presentObject.totalExcellentCount / presentObject?.indexCount?.split('?').length).toFixed(1)))
                    Number(0);
            }
            presentObject.scoreSeries.push([new Date(utcToLocalTime).getTime(), Number(it.score)]);
          }
        } else if (it?.data) {
          const questionObject = {
            id: it.id,
            question: it.question,
            isMultipleQuestion: true,
            scoreSeries: [],
            scorePercentage: 0,
            totalExcellentCount: 0,
            totalCount: 0,
            promotersCount: it.promoters_count,
            detractorsCount: it.detractors_count,
            multiQuestionData: [...it.data].map((datum) => ({
              id: datum.id,
              answer: datum.answer,
              score: Number(datum.score),
              scorePercentage: Number(datum.score),
              scoreSeries: [[utcToLocalTime, Number(datum.score)]],
              totalExcellentCount: Number(datum.count),
              totalCount: Number(datum.total_count)
            }))
          };
          const presentObject = questionStatistics.find((q) => q.id === it.id);
          if (!presentObject) {
            questionStatistics.push(questionObject);
          } else {
            const isPresent = presentObject.multiQuestionData?.length < questionObject.multiQuestionData?.length;
            const newData = questionObject.multiQuestionData.filter((question) => {
              return presentObject?.multiQuestionData?.find((it) => it.answer !== question.answer);
            });
            const data = presentObject?.multiQuestionData?.concat(newData).reduce((acc: ISurveyAnswerMultipleQuestion[], it) => {
              const datum = questionObject.multiQuestionData.find((present) => present.answer === it.answer);
              if (datum) {
                const totalExcellentCount = it.totalExcellentCount + Number(datum.totalExcellentCount);
                const totalCount = it.totalCount + datum.totalCount;
                const scoreSeries = [...(!isPresent ? it.scoreSeries : datum.scoreSeries), [new Date(utcToLocalTime).getTime(), Number(isPresent ? it.score : datum.score)]];
                acc.push({
                  answer: it.answer,
                  id: it.id,
                  totalExcellentCount,
                  isMultipleQuestion: true,
                  score: it.score,
                  totalCount,
                  scorePercentage: Math.round(Number(((totalExcellentCount / totalCount) * 100).toFixed(1))),
                  scoreSeries
                });
              } else {
                acc.push({
                  ...it,
                  isMultipleQuestion: true
                });
              }
              return acc;
            }, []);
            presentObject.multiQuestionData = data;
          }
        }
      });
    }
  });
  return questionStatistics;
};

export const calculateQuaterDifferenceInPercentage = (value1: number, value2: number) => {
  const difference = value1 >= value2 ? '+' : '-';
  const differenceInPercentage = difference === '-' ? (value2 ? value2 : 0) - (value1 ? value1 : 0) : (value1 ? value1 : 0) - (value2 ? value2 : 0);
  return {
    difference,
    differenceInPercentage
  };
};

export const groupHubIdByTypeId = (hubList: IHub[], index = 0, result: IIdGroup = {}): IIdGroup => {
  if (hubList.length === index) {
    return result;
  }
  if (!result[hubList[index].type_id]) {
    result[hubList[index].type_id] = [];
  }
  result[hubList[index].type_id].push(hubList[index].hub_id);
  return groupHubIdByTypeId(hubList, index + 1, result);
};

export const numberFormatter = (num: number, digits: number) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((it) => {
      return num >= it.value;
    });
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
};

export const showSmileyIconNonPSP = (percentage: number, isNps?: boolean) => {
  if (isNps) {
    // return { icon: '', class: 'na-variant', color: '#d6d6d6', modalColor: '#d6d6d6' };
    return percentage < 0
      ? { icon: `${config.cdnRevvImgUrl}/smiley-na.svg`, class: 'na-variant', color: '#d6d6d6', modalColor: '#d6d6d6' }
      : percentage >= 0 && percentage <= 9
      ? { icon: `${config.cdnRevvImgUrl}/smiley-normal.svg`, class: 'pink-variant-one', color: '#f75454', modalColor: '#f75454' }
      : percentage >= 10 && percentage <= 29
      ? { icon: `${config.cdnRevvImgUrl}/smiley-normal.svg`, class: 'yellow-variant-two', color: '#F6BE00', modalColor: '#F6BE00' }
      : percentage >= 30 && percentage <= 49
      ? { icon: `${config.cdnRevvImgUrl}/smiley-bigeyes.svg`, class: 'green-variant-two', color: '#578d59', modalColor: '#2DC8A7' }
      : percentage >= 50
      ? { icon: `${config.cdnRevvImgUrl}/smiley-star-eyes.png`, class: 'green-variant-two', color: '#00AF41', modalColor: '#2DC8A7' }
      : { icon: `${config.cdnRevvImgUrl}/smiley-na.svg`, class: 'na-variant', color: '#d6d6d6', modalColor: '#d6d6d6' };
  } else {
    return percentage >= 0 && percentage <= 1.4
      ? { icon: `${config.cdnRevvImgUrl}/smiley-angry.svg`, class: 'pink-variant-one', color: '#E31C21', modalColor: '#FF5C5C' }
      : percentage >= 1.5 && percentage <= 2.4
      ? { icon: `${config.cdnRevvImgUrl}/smiley-dull.svg`, class: 'pink-variant-one', color: '#E31C21', modalColor: '#FF5C5C' }
      : percentage >= 2.5 && percentage <= 3.4
      ? { icon: `${config.cdnRevvImgUrl}/smiley-normal.svg`, class: 'yellow-variant-two', color: '#F6BE00', modalColor: '#F6BE00' }
      : percentage >= 3.5 && percentage <= 4.4
      ? { icon: `${config.cdnRevvImgUrl}/smiley-bigeyes.svg`, class: 'green-variant-two', color: '#00AF41', modalColor: '#2DC8A7' }
      : percentage >= 4.5 && percentage <= 10
      ? { icon: `${config.cdnRevvImgUrl}/smiley-star-eyes.png`, class: 'green-variant-two', color: '#00AF41', modalColor: '#2DC8A7' }
      : { icon: `${config.cdnRevvImgUrl}/smiley-na.svg`, class: 'na-variant', color: '#d6d6d6', modalColor: '#d6d6d6' };
  }
};

export const findSmiley = (answer: string) => {
  const qAnswer = typeof answer === 'string' ? answer?.toLowerCase() : answer;
  return qAnswer === 'excellent'
    ? `${config.cdnRevvImgUrl}/smiley-star-eyes.png`
    : qAnswer === 'good'
    ? `${config.cdnRevvImgUrl}/smiley-bigeyes.svg`
    : qAnswer === 'ok'
    ? `${config.cdnRevvImgUrl}/smiley-normal.svg`
    : qAnswer === 'disappointed' || qAnswer === 'disappointing' || qAnswer === 'disappoint'
    ? `${config.cdnRevvImgUrl}/smiley-dull.svg`
    : qAnswer === 'terrible'
    ? `${config.cdnRevvImgUrl}/smiley-angry.svg`
    : '';
};

export const defaultHubSurveyDropdownOption = { value: 'companyScore', label: 'Company Score', id: 0 };

export const resetScroll = () => {
  const scrollElement = document.getElementById('scrollTarget');
  if (scrollElement) {
    scrollElement.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }
};

export const getHubTreeKeys = (filteredArray: commonLocationTree.ILocationTree[], filterKeys: string[] = []) => {
  if (filteredArray && filteredArray.length) {
    filteredArray.forEach((datum) => {
      if (datum.type === 'hub') {
        filterKeys.push(datum.key);
      }
      if (datum.children && datum.children.length) {
        getHubTreeKeys(datum.children, filterKeys);
      }
    });
  }
  return filterKeys;
};

export const getInitialName = (name: string) => {
  // const initName = name.split(' ').length > 1 ? name.split(' ').map((ele: string) => ele.slice(0, 1)).join().replaceAll(',', '') : name.slice(0, 1);
  const initName = name.split(' ')[0];
  return initName[0] || '';
};

export const handleSort = <ISortData extends { [key: string]: any }>({ data, sortState, isDescending }: ISortingProps<ISortData>) => {
  return data?.slice().sort((a, b) => {
    if (isDescending) {
      return b[sortState] > a[sortState] ? 1 : -1;
    } else if (!isDescending) {
      return a[sortState] > b[sortState] ? 1 : -1;
    } else {
      return 0;
    }
  });
};

export const rankHandler = (arrValue: number[], medals: string[]) => {
  const sortArray = arrValue?.slice();
  sortArray?.sort((a, b) => b - a);
  return arrValue?.map((_1, index) => {
    if (arrValue[index] === -1) {
      return medals[3];
    } else {
      const medalPosition = arrValue[index] !== 0 ? sortArray.indexOf(arrValue[index]) : 3;
      return medalPosition <= 2 ? medals[medalPosition] : medals[3];
    }
  });
};

export const getInitials = (str1?: string, str2?: string) => (str1 ? str1.charAt(0).toUpperCase() : '') + (str2 ? str2.charAt(0).toUpperCase() : '');

export const activeModal = (val: string) => {
  const modalList = ['newlocation', 'editLocation'];
  return modalList.find((it) => it === val);
};

export const debounceUpdate = (searchValue: string | undefined, debounceTimeout: null | NodeJS.Timeout, handleSearch: (searchValue: string) => void) => {
  if (debounceTimeout) {
    clearTimeout(debounceTimeout);
  }
  debounceTimeout = setTimeout(() => handleSearch(searchValue || ''), 700);
  return debounceTimeout;
};

export const handleDownload = <ItotalData, ITableData, IHeading>(
  totalData: ItotalData,
  tableData: ITableData[],
  filename: string,
  tableHeading: IHeading[],
  totalKeys: (string | undefined)[],
  tableDataKeys: (string | undefined)[],
  totalLocKeys?: number
) => {
  let data = '';
  data = `${tableHeading.map((it: any) => it.label).toString()}
  Totals${totalKeys.length > 0 && totalKeys?.map((it, index) => (index !== 0 ? totalData[it as keyof typeof totalData] : totalLocKeys && totalLocKeys > 0 ? `(${totalLocKeys})` : 0))}\n`;
  data =
    tableData?.reduce((previous: string, current: any) => {
      return previous.concat(`${tableDataKeys.map((it: any) => current[it])}\n`);
    }, data) || '';
  const uri = encodeURI(`data:text/csv;charset=utf-8,${data}`);
  const downloadAnchor = document.createElement('a');
  downloadAnchor.href = uri;
  downloadAnchor.download = filename;
  downloadAnchor.click();
};

export const handleRoundOfValue = (value: number) => {
  const fixedVal = value.toFixed(1);
  const splitedVal = fixedVal.split('.');
  return splitedVal[1] !== '0' ? fixedVal : splitedVal[0];
};

export const surveyResposneHandler = (responseData: ISurveyList[]) => {
  const surveyListResponseData =
    responseData && responseData?.length > 0
      ? responseData.map((it: ISurveyList) => {
          return {
            id: it.id,
            name: it?.attributes?.customer_name || '',
            isAgreedToReview: it?.attributes?.agreed_to_review,
            isLeftAComment: it?.attributes?.left_a_comment,
            isContactRequested: it?.attributes?.customer_preferred_phone_number || it?.attributes?.customer_preferred_email ? true : false,
            status: it?.attributes?.user_friendly_status,
            rating: it?.attributes?.rating,
            dateCompleted: it?.attributes?.completed_at || '',
            transactionDate: it?.attributes?.visit_created_at_local || '',
            email: it?.attributes?.customer_preferred_email || '',
            phoneNumber: it?.attributes?.customer_preferred_phone_number || '',
            customerId: it.attributes.customer_id,
            customerEmail: it?.attributes?.customer_email || '',
            customerPhoneNumber: it?.attributes?.customer_phone_number || '',
            surveySentDate: it.attributes?.sent_at || '',
            isCustomerEnabled: it.attributes?.customer
          };
        })
      : [];

  return surveyListResponseData;
};

export const decimalValuehandler = (val: number, decimalPlaces?: number) => {
  if (val === 0) {
    return 0;
  } else {
    if (val && val.toString().indexOf('.') === 1) {
      return val.toFixed(decimalPlaces || 2);
    } else {
      return val;
    }
  }
};
