import { put, takeLatest } from 'redux-saga/effects';

import * as types from 'actions/action-types';
import { ISagaPayload } from 'types/common/api';
import { API, apiCall, defaultHeader } from 'utils/helpers';
import {
  createOrUpdateHashTagsListFailure,
  createOrUpdateHashTagsListResponse,
  exceptionHandlerRequest,
  logoutRequest,
  settingsGetHashTagsListFailure,
  settingsGetHashTagsListResponse
} from 'actions';
import { ALERT_BOX_TYPES, HASHTAGS_EXCEPTION_ERROR, SETTINGS_SUCCESS_MSG, USER_OWNERSHIP } from 'utils/constants';
import { alertBoxCall } from 'components';

function* sendGetSettingsHashTagsListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const apiObj = payload.userOwnership === USER_OWNERSHIP.FRANCHISOR ? { ...API.getFranchisorHashtagsList } : { ...API.getAccountHashtagsList };
    apiObj.apiPath = apiObj.apiPath.replace(':id', payload.id);
    const response = yield apiCall({ headers, params: payload?.params, ...apiObj });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      const updatedPayload = { ...response.data, action_status: response.data.action_status || payload?.action_status };
      yield put(settingsGetHashTagsListResponse(updatedPayload));
    } else {
      yield put(settingsGetHashTagsListFailure(response.data.message));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(HASHTAGS_EXCEPTION_ERROR));
  }
}
function* sendCreateOrUpdateSettingsHashTagsListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const apiObj = payload.userOwnership === USER_OWNERSHIP.FRANCHISOR ? { ...API.updateFranchisorHashtagsList } : { ...API.updateAccountHashtagsList };
    apiObj.apiPath = apiObj.apiPath.replace(':id', payload.id);
    const response = yield apiCall({ headers, data: payload.reqPayload, ...apiObj });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 201) {
      yield put(createOrUpdateHashTagsListResponse(response.data));
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, SETTINGS_SUCCESS_MSG);
    } else {
      yield put(createOrUpdateHashTagsListFailure(response.data.message));
      alertBoxCall(ALERT_BOX_TYPES.ERROR, response.data.message);
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(HASHTAGS_EXCEPTION_ERROR));
  }
}

export function* takeSettingsHashTagsRequest() {
  yield takeLatest(types.GET_HASHTAGS_LIST_REQUEST, sendGetSettingsHashTagsListRequest);
  yield takeLatest(types.CREATE_OR_UPDATE_HASHTAGS_REQUEST, sendCreateOrUpdateSettingsHashTagsListRequest);
}
