import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { TagSuggestion } from 'react-tag-autocomplete';

import {
  POSTS_FILTERS_POST_TYPE_ARRAY,
  POSTS_INIT_FILTER_DATA,
  COMMON_SOCIAL_PROVIDER_TYPE,
  USER_OWNERSHIP,
  POSTS_FILTERS_ACC_STATUS_ARRAY,
  POSTS_FILTERS_FRAN_STATUS_ARRAY,
  POSTS_DATE_RANGE_FILTER,
  POSTS_FILTER_USAGE_TYPE,
  PostsValidQueryParams,
  BUTTON_PROPS,
  PostType,
  CommonValidQueryParams,
  COMMON_VALID_QUERY_PARAMS_OBJ,
  SIDE_NAV_NAME,
  PostDateType,
  AI_POSTS_FILTER_TYPE,
  POSTS_STATUS_TYPE,
  MAIN_NAV_BAR_NAME,
  CONNECTION_SORT_TYPES,
  ConnectionsValidQueryParams,
  MgSourceType,
  MG_POST_SOURCE_TYPE_FILTER_OPTION,
  FranchisorSyndicationMediaType,
  MG_SYNDICATED_FILTER_TYPE,
  MediaGalleryValidQueryParams
} from 'utils/constants';
import { IStore, mgReducerTypes, settingsRallioProfileReducerTypes } from 'types';
import {
  checkSiteRestriction,
  getFormattedNumber,
  getPostMediaTypeFromQueryParams,
  getPostFavTagsFromQueryParams,
  debounceMethod,
  navigateToValidRoute,
  generateOptionsForReactTag
} from 'utils/helpers';
import { useAccountSwitcherData, useParamsDeconstructor, useParamsValidator, useAvailablePlatforms, useNavBarData } from 'utils/hooks';
import { ImageValidation } from 'widgets/Media';
import { handleFilterChange } from 'analytics/utils/helpers';
import { CommonFilter, MultiSelectMediaOrPostModal } from 'components';
import { PLATFORM_FILTER } from 'analytics/utils';
import { CustomReactTags } from 'widgets/CustomReactTags';
import { campaignSelectedPostsReset, postsTagsRequest } from 'actions';
import { IFiterHandleChangeData } from 'types/common/filter';
import { ICommonObj } from 'types/common/common-props';
import { MultiSelectPostInfinityScrollList } from 'containers/Content/Posts/SubPages';

export const PostFilter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, userOwnership, currentRoute, optionalParams } = useAccountSwitcherData();
  const { queryParams, filter } = useParamsDeconstructor(POSTS_INIT_FILTER_DATA);
  const platformFilter = useAvailablePlatforms(PLATFORM_FILTER, userOwnership, null, false, userOwnership === USER_OWNERSHIP.ACCOUNT ? true : false).filter((it) => it.value !== 'yelp');

  const { subNavPageName, activeMainPages } = useNavBarData();

  const franchisorsPermissionList = useSelector((state: IStore) => state.franchisors.franchisorsPermissionList);
  const publishedPosts = useSelector((state: IStore) => state.posts.postsStats.publishedPosts);
  const notPublishedPosts = useSelector((state: IStore) => state.posts.postsStats.notPublishedPosts);
  const postsTagsList = useSelector((state: IStore) => state.posts.postsTagsList);
  const isTagFetching = useSelector((state: IStore) => state.mediaGallery.isTagFetching);
  const favTagsList = useSelector((state: IStore) => state.mediaGallery.favTagsList);
  const menuAccess = useSelector((state: IStore) => state.accountSwitcher.menu_access);
  const permissions = useSelector((state: IStore) => state.accounts.permissions);
  const isAISubscribed = useSelector((state: IStore) => state.aiContentCreator.isAISubscribed);
  const isValidContentPosts = useSelector((state: IStore) => state.menuAccessiblity.menuAvailablity?.content?.posts);
  const isAdmin = useSelector((state: IStore) => state.login.activeUser?.admin || false);
  const menuAvailablity = useSelector((state: IStore) => state.menuAccessiblity.menuAvailablity);
  const isContentSupplier = useSelector((state: IStore) => state.franchisors.franchisorDetails?.content_supplier);
  const isContentSupplierFranchisor = useSelector((state: IStore) => state.accountSwitcher?.content_supplier_franchisor || false);
  const isAccountActivated = useSelector((state: IStore) => state.accounts.accountDetails?.account?.activated || false) || (userOwnership === USER_OWNERSHIP.FRANCHISOR && true);
  const postsStats = useSelector((state: IStore) => state.posts.postsStats);
  const isBrand = useSelector((state: IStore) => state.accountSwitcher.isBrand);
  const accountSwitcherId = useSelector((state: IStore) => state.accountSwitcher.id);
  const aiPlayBook = useSelector((state: IStore) => state.aiPlayBook.ai_playbook);

  const [isMultiSelectModalOpen, setIsMultiSelectModalOpen] = useState(false);

  const overriddenCustomPostsLocked = permissions?.franchiseePermissions?.overridden_custom_posts_locked;
  const postSourceFilter = MG_POST_SOURCE_TYPE_FILTER_OPTION.filter((it) => (userOwnership === USER_OWNERSHIP.FRANCHISOR ? it.value !== MgSourceType.ACCOUNT : true));

  const isDonotShow = localStorage?.getItem('doNotShowAICoachMark') === 'true';

  const startDateTimeStamp = filter?.dateRange?.fromDate?.timeStamp || null;
  const endDateTimeStamp = filter?.dateRange?.toDate?.timeStamp || null;
  const isCallApi = Boolean((startDateTimeStamp && endDateTimeStamp) || (!startDateTimeStamp && !endDateTimeStamp));
  const franCustomPostsLocked = !franchisorsPermissionList
    ? false
    : Boolean(franchisorsPermissionList?.custom_posts_locked)
    ? franchisorsPermissionList?.custom_posts_locked
    : franchisorsPermissionList?.franchisee_permissions.some((datum) => datum.overridden_custom_posts_locked || datum.custom_posts_locked);

  const copiedParentContentsAutoApproved = franchisorsPermissionList?.copied_parent_contents_auto_approved;
  const updatedFavoriteList = generateOptionsForReactTag(favTagsList.filter((item) => item.favourite));
  let postsTagsApiTimeout: null | NodeJS.Timeout = null;

  const postFilter = {
    ...filter,
    type: queryParams?.type || 'all',
    platform: platformFilter.length === 1 ? platformFilter[0].value : queryParams?.platform || 'all',
    status: queryParams?.status || 'all',
    ai_post: queryParams?.ai_post || 'all',
    usage: queryParams?.usage || 'all',
    search: queryParams?.text || '',
    media_type: queryParams ? getPostMediaTypeFromQueryParams(queryParams) : [],
    tags_list: queryParams?.tags_list ? getPostFavTagsFromQueryParams(favTagsList, queryParams?.tags_list || '') : [],
    sort_by: '',
    ascending: '0',
    content_supplier_id: queryParams?.content_supplier_id || 'all',
    location: queryParams?.location || '',
    source: queryParams?.source || MgSourceType.ALL,
    syndicated_to_any_franchisor: queryParams?.syndicated_to_any_franchisor || 'all',
    syndicated_to_any_account: queryParams?.syndicated_to_any_account || 'all'
  };

  const tagsList = postFilter.tags_list.filter((it: mgReducerTypes.IMediaGalleryTagsData) => !it.favourite);

  // For current route validation with default query params
  const isContentMenuActive = useMemo(() => {
    return activeMainPages.map((it) => it.name).includes(MAIN_NAV_BAR_NAME.CONTENT);
  }, [activeMainPages]);

  const getFilteredStatusOptions = (statusOptionList: settingsRallioProfileReducerTypes.ITimeZoneResponse[], filteredValue: string[]) =>
    statusOptionList.filter((it) => !filteredValue.includes(it.value));

  const statusOptions = useMemo(() => {
    if (userOwnership === USER_OWNERSHIP.FRANCHISOR) {
      let franStatusFilter = POSTS_FILTERS_FRAN_STATUS_ARRAY;
      if (menuAccess && checkSiteRestriction(userOwnership, menuAccess, 'posts/awaiting_approval')) {
        franStatusFilter = getFilteredStatusOptions(franStatusFilter, ['aa_my_content', 'aa_location_content']);
      }
      if (isContentSupplier) franStatusFilter = getFilteredStatusOptions(franStatusFilter, ['aa_my_content']);
      if (!franCustomPostsLocked) franStatusFilter = getFilteredStatusOptions(franStatusFilter, ['aa_location_content']);
      if (!isAISubscribed || !aiPlayBook?.auto_generate_post) franStatusFilter = getFilteredStatusOptions(franStatusFilter, ['ai_generated']);
      if (copiedParentContentsAutoApproved && !isContentSupplier) franStatusFilter = getFilteredStatusOptions(franStatusFilter, ['aa_my_content']);
      return franStatusFilter;
    } else {
      let accStatusFilter = POSTS_FILTERS_ACC_STATUS_ARRAY;
      if (menuAccess && checkSiteRestriction(userOwnership, menuAccess, 'posts/pending')) {
        accStatusFilter = getFilteredStatusOptions(accStatusFilter, ['awaiting_approval']);
      }
      if (!isAISubscribed || !aiPlayBook?.auto_generate_post) accStatusFilter = getFilteredStatusOptions(accStatusFilter, ['ai_generated']);
      return accStatusFilter;
    }
  }, [userOwnership, menuAccess, franCustomPostsLocked, isAISubscribed, isContentSupplier, copiedParentContentsAutoApproved, aiPlayBook?.auto_generate_post]);

  const isValidate = userOwnership === USER_OWNERSHIP.FRANCHISOR ? Boolean(franchisorsPermissionList) : true;

  useParamsValidator(
    {
      ...CommonValidQueryParams,
      ...PostsValidQueryParams,
      ...ConnectionsValidQueryParams,
      source: postSourceFilter.map((it) => it.value),
      sort_by: Object.values(CONNECTION_SORT_TYPES).slice(1),
      ascending: ['0']
    },
    {
      ...COMMON_VALID_QUERY_PARAMS_OBJ,
      type: [PostDateType.SPECIFIC_DATE_RANGE],
      platform: platformFilter.map((it) => it.value || ''),
      ...(subNavPageName === SIDE_NAV_NAME.CONTENT_POSTS && {
        status:
          userOwnership === USER_OWNERSHIP.FRANCHISOR || overriddenCustomPostsLocked
            ? statusOptions.map((it) => it.value)
            : statusOptions.filter((it) => !['awaiting_approval', 'rejected'].includes(it.value)).map((it) => it.value)
      }),
      ai_post: AI_POSTS_FILTER_TYPE.map((it) => it.value),
      usage: POSTS_FILTER_USAGE_TYPE.map((it) => it.value),
      has_photo: ['1'],
      has_link: ['1'],
      has_copy: ['1'],
      content_supplier_id: ['1'],
      syndicated_to_any_franchisor: MG_SYNDICATED_FILTER_TYPE.map((filter) => filter.value),
      syndicated_to_any_account: MG_SYNDICATED_FILTER_TYPE.map((filter) => filter.value)
    },
    isValidate
  );

  useEffect(() => {
    if (subNavPageName === SIDE_NAV_NAME.CONTENT_POSTS && platformFilter.length === 1 && !queryParams?.platform && isContentMenuActive && isAccountActivated) {
      const params = { platform: platformFilter[0]?.value || '', ...queryParams };
      navigate({ search: `?${new URLSearchParams(params).toString()}` }, { state: {}, replace: true });
    }
  }, [navigate, subNavPageName, platformFilter, isContentMenuActive, isAccountActivated]); // eslint-disable-line

  useEffect(() => {
    if (isValidContentPosts && platformFilter.length === 1 && !queryParams?.platform && isContentMenuActive && isAccountActivated) {
      if (optionalParams.length && optionalParams[0] === 'account_wizard') {
        navigate({
          pathname: `/${USER_OWNERSHIP.ACCOUNT}/${id.toString()}/content/${isContentSupplierFranchisor ? 'calendar' : 'posts'}`,
          search: `?${new URLSearchParams({ ...queryParams, ...(isContentSupplierFranchisor && { view: 'calendar' }) }).toString()}`
        });
      } else {
        const params: { [key: string]: string } = { platform: platformFilter[0]?.value || '', ...queryParams };
        if (!isDonotShow) {
          params.status = 'approved';
        }
        navigate({ search: `?${new URLSearchParams(params).toString()}` }, { state: {}, replace: true });
      }
    }
  }, [navigate, isValidContentPosts, isDonotShow, platformFilter, isContentMenuActive, isAccountActivated]); // eslint-disable-line

  useEffect(() => {
    if (isValidContentPosts && isContentMenuActive && isAccountActivated) {
      const params = { status: 'approved', ...queryParams };
      navigate({ search: `?${new URLSearchParams(params).toString()}` }, { state: {}, replace: true });
    } else {
      navigateToValidRoute(id, userOwnership, isAdmin, subNavPageName, menuAvailablity, navigate);
    }
  }, [navigate, isValidContentPosts, isContentMenuActive]); // eslint-disable-line

  useEffect(() => {
    if (!queryParams?.source && isContentSupplierFranchisor && accountSwitcherId === id && isAccountActivated) {
      const params: ICommonObj<string> = { ...queryParams, source: 'all' };
      if (isBrand) {
        params.source = 'brand';
      }
      if (isValidContentPosts && isContentMenuActive) {
        params.status = 'approved';
      }
      navigate({ search: `?${new URLSearchParams(params).toString()}` }, { state: {}, replace: true });
    } else if (!isContentSupplierFranchisor && isAccountActivated) {
      const queryParamObj = { status: 'approved', ...queryParams };
      // delete queryParamObj?.source;
      navigate({ search: `?${new URLSearchParams(queryParamObj).toString()}` }, { state: {}, replace: true });
    }
  }, [navigate, isBrand, isContentSupplierFranchisor, accountSwitcherId, id]); // eslint-disable-line

  const handleChange = (data: IFiterHandleChangeData, isClear = false) => {
    const queryParamsObj = handleFilterChange(data, isClear, id, queryParams);
    if (isClear) {
      if (queryParams?.text) queryParamsObj.text = queryParams?.text || '';
      if (queryParams?.add_posts) queryParamsObj.add_posts = queryParams?.add_posts || '1';
    }
    if (data.type) {
      delete queryParamsObj.start_date;
      delete queryParamsObj.end_date;
      if (data.type === COMMON_SOCIAL_PROVIDER_TYPE.ALL) delete queryParamsObj.type;
      else queryParamsObj.type = data.type;
    }
    if (subNavPageName === SIDE_NAV_NAME.CONTENT_CAMPAIGNS && data.favorite_tags) {
      if (data.favorite_tags === '0') delete queryParamsObj.favorite_tags;
      else queryParamsObj.favorite_tags = data.favorite_tags;
    }
    if (data.tags_list) {
      if (data.tags_list?.length) queryParamsObj.tags_list = data.tags_list.map((it) => it.label).join(',');
      else delete queryParamsObj.tags_list;
    }
    if (data.status) {
      if (data.status === COMMON_SOCIAL_PROVIDER_TYPE.ALL) delete queryParamsObj.status;
      else queryParamsObj.status = data.status;
    }
    if (data.ai_post) {
      if (data.ai_post === COMMON_SOCIAL_PROVIDER_TYPE.ALL) delete queryParamsObj.ai_post;
      else queryParamsObj.ai_post = data.ai_post;
    }
    if (data.usage) {
      if (data.usage === COMMON_SOCIAL_PROVIDER_TYPE.ALL) delete queryParamsObj.usage;
      else queryParamsObj.usage = data.usage;
    }
    if (data.media_type) {
      data.media_type.includes(PostType.HAS_PHOTO) ? (queryParamsObj.has_photo = '1') : delete queryParamsObj.has_photo;
      data.media_type.includes(PostType.HAS_LINK) ? (queryParamsObj.has_link = '1') : delete queryParamsObj.has_link;
      data.media_type.includes(PostType.HAS_COPY) ? (queryParamsObj.has_copy = '1') : delete queryParamsObj.has_copy;
    }
    if (data.content_supplier_id) {
      if (data.content_supplier_id === COMMON_SOCIAL_PROVIDER_TYPE.ALL) delete queryParamsObj.content_supplier_id;
      else {
        queryParamsObj.content_supplier_id = data.content_supplier_id;
        delete queryParamsObj.status;
      }
    }
    if (data.source) {
      if (data.source !== MgSourceType.CONTENT_SUPPLIER) delete queryParamsObj.content_supplier_id;
      /* if (data.source === MgSourceType.ALL) delete queryParamsObj.source;
      else */ queryParamsObj.source = data.source;
    }
    if (data.syndicated_to_any_franchisor) {
      if (data.syndicated_to_any_franchisor === 'all') delete queryParamsObj.syndicated_to_any_franchisor;
      else queryParamsObj.syndicated_to_any_franchisor = data.syndicated_to_any_franchisor;
    }
    if (data.syndicated_to_any_account) {
      if (data.syndicated_to_any_account === 'all') delete queryParamsObj.syndicated_to_any_account;
      else queryParamsObj.syndicated_to_any_account = data.syndicated_to_any_account;
    }
    if (data?.multi_select) {
      setIsMultiSelectModalOpen(true);
    }
    navigate({ search: `?${new URLSearchParams(queryParamsObj).toString()}` });
  };

  const handleTagSelection = (tagObj: TagSuggestion) => {
    handleChange({
      tags_list:
        postFilter.tags_list && !postFilter.tags_list.map((it: TagSuggestion) => it.label).includes(tagObj.label)
          ? [...postFilter.tags_list, tagObj]
          : postFilter.tags_list.filter((it: TagSuggestion) => it.label !== tagObj.label)
    });
  };

  const handleSearchTags = (searchValue: string) => {
    dispatch(postsTagsRequest({ searchTag: searchValue }));
  };

  const handleURLPathname = (pathname: string) => {
    navigate({ pathname, search: `?${new URLSearchParams(queryParams).toString()}` });
  };

  const queryParamsClone = { ...queryParams };
  delete queryParamsClone?.text;
  delete queryParamsClone?.add_posts;

  const isClearFilterDisabled = platformFilter.length === 1 ? (Object.keys(queryParamsClone)?.length === 1 && queryParamsClone?.platform ? true : false) : !Object.keys(queryParamsClone)?.length;

  const checkBoxFilter =
    subNavPageName === SIDE_NAV_NAME.CONTENT_CAMPAIGNS
      ? [
          {
            title: 'Usage',
            filter: POSTS_FILTER_USAGE_TYPE.map((it, index) => ({
              value: it.value,
              label:
                publishedPosts || notPublishedPosts
                  ? `${it.label} (${
                      index === 0 ? getFormattedNumber(publishedPosts + notPublishedPosts || 0) : index === 1 ? getFormattedNumber(publishedPosts || 0) : getFormattedNumber(notPublishedPosts || 0)
                    })`
                  : it.label
            })),
            value: 'usage'
          }
        ]
      : [
          {
            title: 'Source',
            filter: postSourceFilter.map((it, index) => ({
              value: it.value,
              label: `${it.label} (${
                it.value === MgSourceType.ALL
                  ? getFormattedNumber(postsStats.totalPosts || 0)
                  : it.value === MgSourceType.BRAND
                  ? getFormattedNumber(postsStats.brandPosts || 0)
                  : it.value === MgSourceType.CONTENT_SUPPLIER
                  ? getFormattedNumber(postsStats.syndicatedPosts || 0)
                  : it.value === MgSourceType.ACCOUNT
                  ? getFormattedNumber(postsStats.locationPosts || 0)
                  : 0
              })`
            })),
            isShowCSSelector: isContentSupplierFranchisor && queryParams.source === MgSourceType.CONTENT_SUPPLIER,
            value: 'source'
          },
          {
            title: 'Status',
            filter:
              userOwnership === USER_OWNERSHIP.FRANCHISOR
                ? statusOptions
                : overriddenCustomPostsLocked
                ? statusOptions
                : statusOptions.filter((it) => !['awaiting_approval', 'rejected'].includes(it.value)),
            value: 'status'
          },
          {
            title: 'AI Type',
            filter: AI_POSTS_FILTER_TYPE.map((it) => ({
              value: it.value,
              label: it.label
            })),
            value: 'ai_post'
          },
          {
            title: 'Usage',
            filter: POSTS_FILTER_USAGE_TYPE.map((it, index) => ({
              value: it.value,
              label:
                publishedPosts || notPublishedPosts
                  ? `${it.label} (${
                      index === 0 ? getFormattedNumber(publishedPosts + notPublishedPosts || 0) : index === 1 ? getFormattedNumber(publishedPosts || 0) : getFormattedNumber(notPublishedPosts || 0)
                    })`
                  : it.label
            })),
            value: 'usage'
          },
          {
            title: 'Syndication',
            filter: MG_SYNDICATED_FILTER_TYPE.map((it, index) => ({
              value: it.value,
              label: it.label
              // Hided because unavailability of counts
              // usedMediaCount || unusedMediaCount || newlyAddedMediaCount
              //   ? `${it.label} (${
              //       index === 0
              //         ? getFormattedNumber(usedMediaCount + unusedMediaCount || 0)
              //         : index === 1
              //         ? getFormattedNumber(usedMediaCount || 0)
              //         : index === 2
              //         ? getFormattedNumber(unusedMediaCount || 0)
              //         : getFormattedNumber(newlyAddedMediaCount || 0)
              //     })`
              // : it.label
            })),
            value: isContentSupplier ? MediaGalleryValidQueryParams.SYNDICATED_TO_ANY_FRANCHISOR : MediaGalleryValidQueryParams.SYNDICATED_TO_ANY_ACCOUNT
          }
        ];

  const renderCheckboxFilter = () => {
    let updatedCheckboxFilter = [...checkBoxFilter];

    if (!isAISubscribed) {
      updatedCheckboxFilter = updatedCheckboxFilter.filter((filterOption) => filterOption.value !== PostsValidQueryParams.AI_POST);
    }
    if (isContentSupplier) {
      updatedCheckboxFilter = updatedCheckboxFilter.filter((filterOption) => !([PostsValidQueryParams.SOURCE, PostsValidQueryParams.USAGE] as string[]).includes(filterOption.value));
    }
    if (!isContentSupplierFranchisor) {
      updatedCheckboxFilter = updatedCheckboxFilter.filter((filterOption) => filterOption.value !== PostsValidQueryParams.SOURCE);
    }
    if (userOwnership === USER_OWNERSHIP.ACCOUNT) {
      updatedCheckboxFilter = updatedCheckboxFilter.filter((filterOption) => filterOption.value !== MediaGalleryValidQueryParams.SYNDICATED_TO_ANY_ACCOUNT);
    }
    return updatedCheckboxFilter;
  };

  const listPrimaryButtons = useCallback(() => {
    const primaryButtons = [
      {
        ...BUTTON_PROPS.CLEAR_FILTER,
        isDisable: isClearFilterDisabled
      }
    ];
    if (userOwnership === USER_OWNERSHIP.FRANCHISOR && subNavPageName !== SIDE_NAV_NAME.CONTENT_CAMPAIGNS) {
      primaryButtons.unshift({
        ...BUTTON_PROPS.PRIMARY_BUTTON,
        label: 'Multi Select',
        value: 'multi_select',
        parentVariant: 'filter-item'
      });
    }
    return primaryButtons;
  }, [userOwnership, isClearFilterDisabled, subNavPageName]);
  return (
    <>
      <CommonFilter
        filter={postFilter}
        handleChange={handleChange}
        isDateRangeFilterEnable={queryParams?.type === PostDateType.SPECIFIC_DATE_RANGE}
        isSecondaryDateRangePlaceholder
        isClearDateEnabled={Boolean(queryParams?.start_date && queryParams?.end_date)}
        analyticsPlatformFilter={platformFilter}
        secondaryCheckBoxFilter={[
          {
            title: '',
            filter: POSTS_DATE_RANGE_FILTER,
            value: 'type'
          }
        ]}
        checkBoxFilter={renderCheckboxFilter()}
        multiSelectCheckBoxFilter={[
          {
            title: 'Post Type',
            filter: POSTS_FILTERS_POST_TYPE_ARRAY,
            value: 'media_type'
          }
        ]}
        primaryButtons={listPrimaryButtons()}
        sectionVariant={`post--filter post-filter__main${subNavPageName === SIDE_NAV_NAME.CONTENT_POSTS ? (!optionalParams.length ? '' : ` events-none pointer-events-none`) : ''}`}
        renderPrefixData={() => (
          <>
            <div className="filter-item">
              <h3>Tags</h3>
              <div className="search-tk post-search post-list__search">
                <CustomReactTags
                  key={`posts-tag-${id}-${userOwnership}`}
                  tags={tagsList}
                  suggestions={postsTagsList}
                  maxSuggestionsLength={postsTagsList.length}
                  handleInputChange={(search: string) => {
                    postsTagsApiTimeout = debounceMethod(search.trim(), postsTagsApiTimeout, handleSearchTags);
                  }}
                  handleDelete={(postIdx) => postIdx > -1 && handleTagSelection(tagsList[postIdx])}
                  handleAddition={(tag) => handleTagSelection(tag)}
                  placeholder={'Search tags'}
                  autofocus={false}
                />
              </div>
            </div>
            {!isTagFetching && favTagsList.length ? (
              <div className="filter-item filter-item-tag__item status-list tagnew-margin">
                <div className="imtc ef-edit">
                  <h3>Favorite Tags</h3>
                  {!isTagFetching && userOwnership === USER_OWNERSHIP.FRANCHISOR && queryParams?.status !== POSTS_STATUS_TYPE.AA_LOCATION_CONTENT && (
                    <>
                      <div
                        className="edit-icon-post cur-pointer"
                        onClick={() =>
                          subNavPageName === SIDE_NAV_NAME.CONTENT_CAMPAIGNS
                            ? handleChange({ favorite_tags: '1' })
                            : handleURLPathname(`/${userOwnership}/${id.toString()}/${currentRoute}/favorite_tags`)
                        }
                      >
                        <ImageValidation isImgValid defaultImg="create_post-edit" customName="Edit" />
                        <span className="cf-values">Edit</span>
                      </div>
                    </>
                  )}
                </div>
                <div className="fts">
                  {updatedFavoriteList?.length
                    ? updatedFavoriteList.map((tagItem, index) => {
                        return (
                          <span
                            key={`posts-fav-tags-${index}`}
                            className={`fav-tags${postFilter.tags_list.find((datum: TagSuggestion & { favourite: boolean }) => datum.favourite && datum.label === tagItem.label) ? ` active` : ``}`}
                            onClick={() => handleTagSelection(tagItem)}
                          >
                            {tagItem.label}
                          </span>
                        );
                      })
                    : null}
                </div>
              </div>
            ) : null}
          </>
        )}
      />
      {isMultiSelectModalOpen ? (
        <MultiSelectMediaOrPostModal
          isShowModal={isMultiSelectModalOpen}
          onModalClose={() => {
            setIsMultiSelectModalOpen(false);
            // reset selected post ids
            dispatch(campaignSelectedPostsReset());
            if (queryParams?.multi_syndicated_to_any_franchisor || queryParams?.multi_syndicated_to_any_account) {
              const queryParamObj = { ...queryParams };
              delete queryParamObj?.multi_syndicated_to_any_franchisor;
              delete queryParamObj?.multi_syndicated_to_any_account;
              navigate({ search: `?${new URLSearchParams(queryParamObj).toString()}` });
            }
          }}
          type={FranchisorSyndicationMediaType.CONTENT}
        >
          <MultiSelectPostInfinityScrollList isCallApi={isCallApi} startDateTimeStamp={startDateTimeStamp} endDateTimeStamp={endDateTimeStamp} type={SIDE_NAV_NAME.CONTENT_CAMPAIGNS} height={785} />
        </MultiSelectMediaOrPostModal>
      ) : null}
    </>
  );
};
