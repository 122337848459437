import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { AiEngineActions } from 'actions';
import { aiEngineReducerTypes } from 'types';
import { modifiedMessages } from 'utils/helpers/ai-engine-helpers';

type IAIEngineReducer = aiEngineReducerTypes.IAIEngineReducerType;

const initialState: IAIEngineReducer = {
  error: null,
  isChatHistoryFetching: false,
  chatHistory: [],
  cardDetails: {
    cardContent: [],
    isCardLoading: false
  },
  threadId: '',
  threadList: []
};

const aiEngineReducer: Reducer<IAIEngineReducer, AiEngineActions> = (state: IAIEngineReducer = initialState, action: AiEngineActions): IAIEngineReducer => {
  switch (action.type) {
    case types.AI_ENGINE_SEND_NEW_CHAT_REQUEST:
      return {
        ...state,
        chatHistory: state.chatHistory
      };
    case types.AI_ENGINE_SEND_NEW_CHAT_RESPONSE:
      return {
        ...state,
        chatHistory: state.chatHistory
      };
    case types.AI_ENGINE_SEND_NEW_CHAT_FAILURE:
      return {
        ...state,
        chatHistory: []
      };
    case types.UPDATE_CHAT_HISTORY:
      return {
        ...state,
        chatHistory:
          action.payload.assistantIndex >= 0
            ? state.chatHistory.map((msg, index) => (index === action.payload.assistantIndex ? { ...msg, message: action.payload.message || msg.message, status: action.payload.status || '' } : msg))
            : action.payload
      };
    case types.AI_ENGINE_GET_CHAT_HISTORY_REQUEST:
      return {
        ...state,
        chatHistory: [],
        isChatHistoryFetching: true
      };
    case types.AI_ENGINE_GET_CHAT_HISTORY_RESPONSE:
      return {
        ...state,
        chatHistory: action.payload?.data.reverse().map((it: any) => ({
          role: it.role,
          message: it.content.map((item: any) => modifiedMessages(item)).join(' ')
        })),
        isChatHistoryFetching: false
      };
    case types.AI_ENGINE_GET_CHAT_HISTORY_FAILURE:
      return {
        ...state,
        chatHistory: [],
        isChatHistoryFetching: false,
        error: action.payload
      };
    case types.AI_ENGINE_CREATE_THREAD_REQUEST:
      return {
        ...state
        // threadId: action.payload
      };
    case types.AI_ENGINE_CREATE_THREAD_RESPONSE:
      return {
        ...state
        // threadId: action.payload
      };
    case types.AI_ENGINE_CREATE_THREAD_FAILURE:
      return {
        ...state
        // threadId: ""
      };
    case types.AI_ENGINE_LIST_THREAD_REQUEST:
      return {
        ...state,
        threadList: state.threadList
      };
    case types.AI_ENGINE_LIST_THREAD_RESPONSE:
      return {
        ...state,
        threadList: action.payload
      };
    case types.AI_ENGINE_LIST_THREAD_FAILURE:
      return {
        ...state,
        threadList: []
      };
    case types.SET_THREAD_ID:
      return {
        ...state,
        threadId: action.payload
      };

    case types.UPDATE_CARDS_DETAILS:
      return {
        ...state,
        cardDetails: action.payload
      };
    default:
      return state;
  }
};

export { aiEngineReducer };
