import { apAccountReducerTypes } from 'types';

export const AP_SAVED_POST_INIT_DETAILS: apAccountReducerTypes.IAPSavedPostDetails = {
  current_tab: 'facebook',
  use_facebook: false,
  use_instagram: false,
  use_twitter: false,
  use_linkedin: false,
  use_google: false,
  use_tiktok: false,
  use_pinterest: false,
  facebook_text: '',
  twitter_text: '',
  linkedin_text: '',
  instagram_text: '',
  google_text: '',
  tiktok_text: '',
  pinterest_text: '',
  mobile_app_only_enabled: true,
  locked: false,
  link_index: 0,
  is_thumbnail_show: true,
  status_name: null,
  schedule_date_time: { selected: null, utcFormat: null },
  account_schedule_date_time: { selected: null, utcFormat: null },
  campaigns: [],
  instagram_reel: false,
  facebook_reel: false,
  google_call_to_action_type: null,
  google_call_to_action_url: null,
  facebook_call_to_action_type: null,
  facebook_call_to_action_link: null,
  link_preview_customized: false,
  video_cover_cloudinary_id: null,
  facebook_story: false,
  instagram_story: false,
  pinterest_board_name: ''
};

export const AP_INSTA_PHOTO_PREVIEW_INIT_OBJ: apAccountReducerTypes.IAddPostInstaPreviewData = {
  actionType: 'pad',
  photoUrl: {
    originalUrls: [],
    previewUrls: []
  }
};

export const AP_ADD_BOOST_FOR_SCH_POST_INIT_OBJ: apAccountReducerTypes.IAddPostBoostForSchPost = {
  boostSuccessMsg: null,
  boostFailureMsg: null,
  scheduledPostId: 0
};

export const AP_EDIT_CONFIRM_INIT_OBJ: apAccountReducerTypes.IAddPostEditConfirmObj = {
  isPostEditable: false,
  isShowModal: false,
  isEditConfirmed: false
};
