import { IContentAnalyticsFilter, ICATableData, IContentAnalyticsModalDataObj, ICAModalIDResponseContent } from 'analytics/types';
import { ContentAnalyticsTitle, CONTENT_ENGAGEMENT_FILTER, CONTENT_IMPRESSION_FILTER, ContentImpression, ContentEngagement } from 'analytics/utils/constants';
import { apAccountReducerTypes, fbAdsReducerTypes, mgApiTypes, apAccountApiTypes, calendarApiTypes } from 'types';
import { COMMON_SOCIAL_PROVIDER_TYPE, NOT_APPLICABLE, MG_MEDIA_TYPE, FbAdsConnectionsType } from 'utils/constants';
import { imageExists } from 'utils/helpers';

export const getContentAnalyticsData = (data: any, impressions: string, engagements: string, isIndividualPost?: boolean) => {
  return data?.map((it: any) => {
    const postClickArray = [
      it.stats?.engagement_clicks_link || it?.engagement_clicks_link,
      it.stats?.engagement_clicks_other || it?.engagement_clicks_other,
      it.stats?.engagement_clicks_photo || it?.engagement_clicks_photo
    ];
    const isPostCilckAvailable = postClickArray.some((it) => Number.isInteger(it));
    const postCliksTotal = isPostCilckAvailable
      ? (it.stats?.engagement_clicks_link || it?.engagement_clicks_link || null) +
        (it.stats?.engagement_clicks_other || it?.engagement_clicks_other || null) +
        (it.stats?.engagement_clicks_photo || it?.engagement_clicks_photo || null)
      : null;
    const impressionsUnpaid = it.stats?.impressions_unpaid || it?.impressions_unpaid || it.stats?.impressions_unique || it?.impressions_unique || null;
    const impressionArray = [it.stats?.impressions_paid || it?.impressions_paid, impressionsUnpaid];
    const isImpressionAvailable = impressionArray.some((it) => Number.isInteger(it));
    const impressionTotal = isImpressionAvailable
      ? impressions === 'all'
        ? (it.stats?.impressions_paid || it?.impressions_paid || null) + impressionsUnpaid
        : impressions === 'paid'
        ? it.stats?.impressions_paid || it?.impressions_paid
        : impressionsUnpaid
      : null;
    const engagementTotArr = [
      it.stats?.engagement_comments || it?.engagement_comments,
      it.stats?.engagement_likes || it?.engagement_likes,
      it.stats?.engagement_shares || it?.engagement_shares,
      it.stats?.engagement_clicks_link || it?.engagement_clicks_link,
      it.stats?.engagement_clicks_other || it?.engagement_clicks_other,
      it.stats?.engagement_clicks_photo || it?.engagement_clicks_photo
    ];
    const isEngagementAvailable = engagementTotArr.some((it) => Number.isInteger(it));
    const engagementTotal = isEngagementAvailable
      ? engagements === 'all'
        ? (it.stats?.engagement_comments || it?.engagement_comments || null) +
          (it.stats?.engagement_likes || it?.engagement_likes || null) +
          (it.stats?.engagement_shares || it?.engagement_shares || null) +
          (it.stats?.engagement_clicks_link || it?.engagement_clicks_link || null) +
          (it.stats?.engagement_clicks_other || it?.engagement_clicks_other || null) +
          (it.stats?.engagement_clicks_photo || it?.engagement_clicks_photo || null)
        : engagements === 'likes'
        ? it.stats?.engagement_likes || it?.engagement_likes
        : engagements === 'comments'
        ? it.stats?.engagement_comments || it?.engagement_comments
        : engagements === 'clicks'
        ? postCliksTotal
        : it.stats?.engagement_shares || it?.engagement_shares
      : null;
    return {
      content: it?.body || it?.text || '',
      imageURL: it?.image_url || it?.photo_url || '',
      id: it?.id,
      contentId: it?.content_id,
      childPostCount: it.child_posts_count,
      account_id: it.account_id,
      account_name: it.account_name || '',
      fbPostId: it.facebook_post_id || '',
      providerName: it?.provider_name || '',
      postedAt: it?.posted_at || it?.timestamp,
      timeZone: it?.time_zone,
      postURL: it?.post_url,
      remotePostId: it?.remote_post_id || it?.linkedin_activity_urn,
      impressionPaid: it.stats?.impressions_paid || it?.impressions_paid || 0,
      impressionUnpaid: impressionsUnpaid || 0,
      impressionUnique: it.stats?.impressions_unique || it.impressions_unique || 0,
      engagementLikes: it.stats?.engagement_likes || it?.engagement_likes || 0,
      engagementComment: it.stats?.engagement_comments || it?.engagement_comments || 0,
      engagementShares: it.stats?.engagement_shares || it?.engagement_shares || 0,
      negativeFeedHideAll: it.stats?.negative_feedback_hide_all_post || it?.negative_feedback_hide_all_post || 0,
      negativeFeedHide: it.stats?.negative_feedback_hide_post || it?.negative_feedback_hide_post || 0,
      negativeFeedSpam: it.stats?.negative_feedback_report_spam || it?.negative_feedback_report_spam || 0,
      negativeFeedUnlike: it.stats?.negative_feedback_unlike_page || it?.negative_feedback_unlike_page || 0,
      postClicksLink: it.stats?.engagement_clicks_link || it?.engagement_clicks_link || 0,
      postClicksOthers: it.stats?.engagement_clicks_other || it?.engagement_clicks_other || 0,
      postClicksPhoto: it.stats?.engagement_clicks_photo || it?.engagement_clicks_photo || 0,
      impressionTotal,
      engagementTotal,
      engagementModalTotal:
        (it.stats?.engagement_comments || it?.engagement_comments || null) +
        (it.stats?.engagement_likes || it?.engagement_likes || null) +
        (it.stats?.engagement_shares || it?.engagement_shares || null),
      postCliksTotal,
      negativeFeedbackTotal:
        (it.stats?.negative_feedback_hide_all_post || it?.negative_feedback_hide_all_post || null) +
        (it.stats?.negative_feedback_hide_post || it?.negative_feedback_hide_post || null) +
        (it.stats?.negative_feedback_report_spam || it?.negative_feedback_report_spam || null) +
        (it.stats?.negative_feedback_unlike_page || it?.negative_feedback_unlike_page || null),
      lifetimeSpend: it?.facebook_boost?.active_boost_campaigns && Number.isInteger(it?.facebook_boost?.ad_lifetime_spend) ? it?.facebook_boost?.ad_lifetime_spend : null,
      spend: it?.facebook_boost?.ad_spend || it?.ad_spend || 0,
      rate: impressionTotal ? (engagementTotal || null) / impressionTotal : NOT_APPLICABLE,
      likes: it.stats?.engagement_likes || it?.engagement_likes || null,
      comments: it.stats?.engagement_comments || it?.engagement_comments || null,
      shares: it.stats?.engagement_shares || it?.engagement_shares || 0,
      clicks: postCliksTotal,
      organic: impressionsUnpaid,
      paid: it.stats?.impressions_paid || it?.impressions_paid,
      statsCount: it?.stats_count,
      facebookTags: it?.page_tag_details?.facebook || [],
      videoAverageTimeWatched: it.stats?.video_average_time_watched || it?.video_average_time_watched || 0,
      videoTotalTimeWatched: it.stats?.video_total_time_watched || it?.video_total_time_watched || 0,
      reelPlayCount: it.stats?.reel_play_count || it?.reel_play_count || 0,
      isIndividualPost
    };
  });
};

export const getContentAnalyticsModalData = (data: ICAModalIDResponseContent, link: calendarApiTypes.ICouponLinks[]) => {
  const returnData: IContentAnalyticsModalDataObj[] = [
    {
      label: 'Facebook',
      text: data.facebook_text,
      isEnabled: data.use_facebook,
      facebookTags: data.page_tag_details?.facebook || []
    },
    {
      label: 'X (Formerly Twitter)',
      text: data.twitter_text,
      isEnabled: data.use_twitter
    },
    {
      label: 'Instagram',
      text: data.instagram_text,
      isEnabled: data.use_instagram
    },
    {
      label: 'LinkedIn',
      text: data.linkedin_text,
      isEnabled: data.use_linkedin
    },
    {
      label: 'Google',
      text: data.google_text,
      isEnabled: data.use_google
    }
  ];
  return { dataObj: returnData.filter((it) => it.isEnabled === true), photo: data.photo_urls, video: data.video_url, couponLink: link, id: data.id };
};

export const downloadFormatHandlerCA = (data: any, id: number, accName: string) => {
  return data?.map((it: any) => ({
    'package ID': it.id || id,
    // 'account_name': accName,
    created: it.posted_at,
    'unpaid impressions': it?.impressions_unique || it?.impressions_unpaid,
    'paid impressions': it?.impressions_paid,
    'total impressions': it?.impressions_unique || it?.impressions_unpaid + it?.impressions_paid,
    likes: it?.engagement_likes,
    comments: it?.engagement_comments,
    shares: it?.engagement_shares,
    'photo clicks': it?.engagement_clicks_photo,
    'link clicks': it?.engagement_clicks_link,
    'other clicks': it?.engagement_clicks_other,
    'total engagement': it?.engagement_likes + it?.engagement_comments + it?.engagement_shares + it?.engagement_clicks_other + it?.engagement_clicks_photo + it?.engagement_clicks_link,
    'hide posts': it?.negative_feedback_hide_post,
    'hide all posts': it?.negative_feedback_hide_all_post,
    'unsubscribe from page': it?.negative_feedback_unlike_page,
    'reported as spam': it?.negative_feedback_report_spam,
    'photo URL': it?.image_url,
    ...(it?.spend === undefined ? {} : { sent: it?.spend }),
    text: it?.body || it?.text,
    'post URL': it?.post_url,
    ...('video_average_time_watched' in it && { 'video average time watched': it?.video_average_time_watched }),
    ...('video_total_time_watched' in it && { 'video total time watched': it?.video_total_time_watched }),
    ...('reel_play_count' in it && { 'reel play count': it?.reel_play_count })
    // ...(it?.content === undefined ? {} : { 'text': it?.content })
  }));
};

export const socialMediaImgHandler = (providerName: string) => {
  switch (providerName) {
    case COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK: {
      return 'fb-lv.svg';
    }
    case COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN: {
      return 'linkedin-lv.svg';
    }
    case COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK: {
      return 'tiktok-lv.svg';
    }
    case COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM:
    case COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_BUSINESS_PAGE:
    case COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_PUBLIC_CONTENT: {
      return 'instagram-lv.svg';
    }
    case COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE: {
      return 'google-lv.svg';
    }
    case COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST: {
      return 'pinterest-lv.svg';
    }
    default: {
      return 'twitter-lv.svg';
    }
  }
};

export const tableHeaderHandler = (label: string, contentFilter: IContentAnalyticsFilter) => {
  switch (label) {
    case ContentAnalyticsTitle.IMPRESSIONS: {
      return contentFilter.impressions !== ContentImpression.ALL
        ? `${label} (${CONTENT_IMPRESSION_FILTER.find((it) => it.value === contentFilter.impressions)?.label})`
        : // CONTENT_IMPRESSION_FILTER.find((it) => it.value === contentFilter.impressions)?.label
          label;
    }
    case ContentAnalyticsTitle.ENGAGEMENT: {
      return contentFilter.engagement !== ContentEngagement.ALL
        ? // `${label} (${CONTENT_ENGAGEMENT_FILTER.find((it) => it.value === contentFilter.engagement)?.label})`
          CONTENT_ENGAGEMENT_FILTER.find((it) => it.value === contentFilter.engagement)?.label
        : label;
    }
    default: {
      return label;
    }
  }
};

export const iframeSrcHandler = (
  providerName: string,
  postUrl: string | undefined,
  platformFilters: { [key: string]: string }[],
  path: { [key: string]: string }[],
  postId: number | string | null
) => {
  switch (providerName) {
    case platformFilters[1].value:
      return `${path[2].link}${postUrl}`;
    case platformFilters[2].value:
      return `${path[0].link}${postId}`;
    case platformFilters[5].value:
      return `${postUrl}${path[1].link}`;
    default:
      return postUrl;
  }
};

export const totalImpressionEngagementTotal = (data: any, filter: string, type: string | undefined) => {
  if (type === ContentAnalyticsTitle.ENGAGEMENT) {
    switch (filter) {
      case ContentEngagement.LIKES:
        return data?.engagement_likes;
      case ContentEngagement.COMMENTS:
        return data?.engagement_comments;
      case ContentEngagement.SHARES:
        return data?.engagement_shares;
      case ContentEngagement.CLICKS:
        return data?.engagement_clicks;
      default:
        return data?.engagement_total;
    }
  } else {
    switch (filter) {
      case ContentImpression.ORGANIC:
        return data?.impressions_unpaid;
      case ContentImpression.PAID:
        return data?.impressions_paid;
      default:
        return data?.impressions_total;
    }
  }
};

export const imageExistisHandler = (imageUrl: string, func: any) => {
  const imgExists = imageExists(imageUrl, null);
  imgExists.then((results: any) => func(results.isImageExists));
};

/** Get content detail for boost */
export const getContentDetailInCreator = (contentObj: ICATableData): apAccountReducerTypes.IAPSavedPostDetails => {
  return {
    current_tab: contentObj?.providerName || '',
    use_facebook: true,
    use_instagram: false,
    use_twitter: false,
    use_linkedin: false,
    use_google: false,
    use_tiktok: false,
    use_pinterest: false,
    facebook_text: contentObj?.content || '',
    twitter_text: '',
    linkedin_text: '',
    instagram_text: '',
    google_text: '',
    tiktok_text: '',
    pinterest_text: '',
    mobile_app_only_enabled: false,
    locked: false,
    link_index: 0,
    is_thumbnail_show: false,
    status_name: null,
    schedule_date_time: { selected: null, utcFormat: null },
    account_schedule_date_time: { selected: null, utcFormat: null },
    campaigns: [],
    instagram_reel: false,
    facebook_reel: false,
    google_call_to_action_type: null,
    google_call_to_action_url: null,
    facebook_call_to_action_type: null,
    facebook_call_to_action_link: null,
    link_preview_customized: false,
    video_cover_cloudinary_id: null,
    facebook_story: false,
    instagram_story: false,
    pinterest_board_name: null
  };
};

/** Get media data for create post */
export const getMediaListForCreator = (imageUrl: string, accountId: number): mgApiTypes.IMGListResponseObj[] => {
  let mediaList: mgApiTypes.IMGListResponseObj[] = [];
  if (imageUrl) {
    mediaList = [
      {
        account_id: accountId,
        cloudinary_id: null,
        comments: null,
        copy_to_descendants: false,
        created_at: '',
        created_by_user_id: 0,
        created_by_user_name: '',
        copied_from_account_name: null,
        franchisor_id: null,
        id: 1,
        liked: null,
        md5: null,
        medium_size_url: imageUrl,
        name: '',
        produced_by: null,
        tags_list: '',
        thumbnail_url: '',
        transformations: null,
        trashed: false,
        url: imageUrl,
        franchisor_name: null,
        account_name: null,
        account_address: null,
        cloudinary_resource_type: '',
        media_type: MG_MEDIA_TYPE.PHOTO,
        used_count: 0,
        created_user: {
          id: 0,
          name: '',
          profile_photo_url: null
        },
        media_release: false,
        brand_id: 0,
        brand_name: '',
        source: '',
        updated_at: '',
        verticals_list: '',
        created_user_profile_photo_url: null,
        original_franchisor_id: null,
        original_franchisor_name: null,
        locked: false,
        // focus_score: null,
        expires_at: null,
        ai_compliance_validated_at: null,
        ai_compliance_warning: null
      }
    ];
  }
  return mediaList;
};

/** Get boost data for content analytics request payload */
export const getAddBoostForContentPostReqPayload = (
  postDetails: null | fbAdsReducerTypes.IFacebookPostDetailsObj,
  facebookBoostConfig: fbAdsReducerTypes.IUpdateFacebookBoostConfig
): apAccountApiTypes.IAPAddBoostForSchPostReqPayload => {
  return {
    existing_post_facebook_post_id: postDetails?.facebookPostId || '',
    existing_post_account_id: postDetails?.accountId || 0,
    facebook_boost_configuration: {
      // connections: facebookBoostConfig.connections,
      connections: FbAdsConnectionsType.ALL_CONNECTIONS,
      coordinates: facebookBoostConfig.coordinates,
      duration: facebookBoostConfig.duration,
      gender: facebookBoostConfig.gender,
      home_coordinate: facebookBoostConfig.homeCoordinate,
      interests: facebookBoostConfig.interests,
      lifetime_spend: facebookBoostConfig.lifetimeSpend * 100,
      locations: facebookBoostConfig.locations,
      max_age: facebookBoostConfig.maxAge,
      min_age: facebookBoostConfig.minAge,
      objective: facebookBoostConfig.objective
    }
  };
};

export const getValidTableHeaderKey = (queryParams: { [key: string]: string }, isHubUser: boolean, multipleLocationId: number) => {
  const isLocalPost = queryParams.analytics === 'local_post';
  const isReels = queryParams.type === 'reels';
  const isStories = queryParams.type === 'stories';
  if (isHubUser && !isLocalPost && isReels) {
    return 'hubReels';
  } else if (isHubUser && !isLocalPost && isStories) {
    return 'hubStories';
  } else if (isHubUser && !multipleLocationId && !isLocalPost && !isReels && !isStories) {
    return 'hub';
  } else if (isHubUser && isLocalPost && isReels) {
    return 'localReels';
  } else if (isHubUser && isLocalPost && isStories) {
    return 'localStories';
  } else if (isHubUser && isLocalPost && !isReels && !isStories) {
    return 'local';
  } else if (!isHubUser && isReels) {
    return 'locationReels';
  } else if (!isHubUser && isStories) {
    return 'locationStories';
  } else if (isHubUser && multipleLocationId) {
    return 'multipleLocations';
  } else if (!isHubUser && !isReels && !isStories) {
    return 'promote';
  } else {
    return 'location';
  }
};
