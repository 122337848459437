import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import { getCurrentUserTabListRequest } from 'actions';
import { IStore, postsApiTypes } from 'types';
import { Loading, NoDataFound } from 'components';
import { dateFormatterForPostDetail, isSocialMediaActive } from 'utils/helpers';
import { useAccountSwitcherData, useActivePlatform } from 'utils/hooks';
import { AP_FRAN_SHOW_LINE_BREAK_TYPES, COMMON_SOCIAL_PROVIDER_TYPE, SCROLL_THRESHOLD, USER_OWNERSHIP, NoDataFoundProps } from 'utils/constants';
import { SocialIcons } from 'widgets/Icons';
import { ImageValidation, Video } from 'widgets/Media';
import { Paragraph } from 'widgets/Text';
import { UserDetailSearch } from './UserDetailSearch';

export const UserPostsList = () => {
  const dispatch = useDispatch();
  const activePlatforms = useActivePlatform();
  const { id, userOwnership, isValidId } = useAccountSwitcherData();

  const postsList = useSelector((state: IStore) => state.common.userDetailObj.postsList);
  const isListFetching = useSelector((state: IStore) => state.common.userDetailObj.isListFetching);
  const currentPage = useSelector((state: IStore) => state.common.userDetailObj.currentPage);
  const userId = useSelector((state: IStore) => state.common.userDetailObj.currentUserDetails?.id);
  const search = useSelector((state: IStore) => state.common.userDetailObj.search);
  const activeTab = useSelector((state: IStore) => state.common.userDetailObj.activeTab);
  const userDetails = useSelector((state: IStore) => state.common.userDetailObj?.currentUserDetails);

  useEffect(() => {
    if (id && userOwnership && isValidId && userId) {
      handlePostListRequest(1);
    }
  }, [id, userOwnership, isValidId, userId]); // eslint-disable-line

  const handleCheckActivePlatform = (platform: string) => {
    return isSocialMediaActive(activePlatforms, platform);
  };

  const handlePostListRequest = (page: number) => {
    if (userId) {
      dispatch(
        getCurrentUserTabListRequest({
          page,
          ...(userOwnership === USER_OWNERSHIP.FRANCHISOR ? { ancestorFranchisorId: id } : { accountId: id }),
          userId,
          mediaType: activeTab,
          ...(userDetails?.roles?.mobile_employee && Boolean(+userDetails?.roles?.mobile_employee) && { mobileEmployee: true }),
          ...(search && { search })
        })
      );
    }
  };

  const userPostPlatformText = (listItem: postsApiTypes.IPostDetailSavedPost): string => {
    if (listItem.use_facebook || listItem.use_instagram || listItem.use_twitter || listItem.use_linkedin || listItem.use_google) {
      return listItem.facebook_text || listItem.instagram_text || listItem.twitter_text || listItem.linkedin_text || listItem.google_text || listItem?.long_text || '';
    }
    return '';
  };

  return (
    <>
      <UserDetailSearch searchValue={search} handleInputSearch={() => handlePostListRequest(1)} />
      {isListFetching && currentPage === 1 ? (
        <Loading />
      ) : postsList.length ? (
        <div className={`infinite-list post-grid-wrp`} id="post-list-ifs">
          <InfiniteScroll
            scrollThreshold={SCROLL_THRESHOLD}
            next={() => {
              handlePostListRequest(currentPage + 1);
            }}
            hasMore
            dataLength={postsList.length}
            loader={isListFetching ? <Loading /> : null}
            className="local-ini"
            scrollableTarget="userDetailScrollTarget"
            height={500}
          >
            <div className="pls post-list__main">
              {postsList.map((listItem, index) => {
                return (
                  <div className={`m-item mi-compact ${listItem.video_url || listItem.photo_url || listItem.link_preview_image_url ? '' : 'only-text'}`} key={`user-posts-list-${index}`}>
                    <div className="mi-front">
                      <div className="mif-head">
                        <div className="mifh-top">
                          <div className="mif-left">
                            <div className="round-asset brand-icon">
                              <ImageValidation
                                isImgValid
                                defaultImg={
                                  listItem?.status_id === 3
                                    ? 'stats/approved'
                                    : [2, 5].includes(listItem?.status_id)
                                    ? 'pending-filled'
                                    : listItem?.status_id === 1 && !listItem?.rejected
                                    ? 'draft-filled'
                                    : listItem?.status_id === 1 && listItem?.rejected
                                    ? 'stats/rejected'
                                    : listItem?.published
                                    ? 'published'
                                    : ''
                                }
                                customName={'Asset status'}
                              />
                            </div>
                            <div className="prime-sub-dtls">
                              <h3 className="crtdOn">Created on</h3>
                              <span className="sub-dtls">{dateFormatterForPostDetail(listItem?.created_at)}</span>
                            </div>
                          </div>
                          <div className="mif-right">
                            <SocialIcons
                              useFacebook={handleCheckActivePlatform(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK) && listItem?.use_facebook}
                              useTwitter={handleCheckActivePlatform(COMMON_SOCIAL_PROVIDER_TYPE.TWITTER) && listItem?.use_twitter}
                              useInstagram={handleCheckActivePlatform(COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM) && listItem?.use_instagram}
                              useLinkedIn={handleCheckActivePlatform(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN) && listItem?.use_linkedin}
                              useTiktok={handleCheckActivePlatform(COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK) && listItem?.use_tiktok}
                              usePinterest={handleCheckActivePlatform(COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST) && listItem?.use_pinterest}
                              useGoogle={handleCheckActivePlatform(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE) && listItem.use_google}
                            />
                          </div>
                        </div>
                        <div className="msg-wrapper">
                          <Paragraph
                            customText={userPostPlatformText(listItem)}
                            facebookTagList={listItem?.use_facebook ? listItem?.page_tag_details?.facebook || [] : []}
                            actionType={AP_FRAN_SHOW_LINE_BREAK_TYPES.PREVIEW}
                          />
                        </div>
                      </div>
                      {listItem?.video_url || listItem?.photo_url || listItem?.link_preview_image_url ? (
                        <div className="m-ast">
                          {!listItem?.video_url && (listItem?.photo_url || listItem?.link_preview_image_url) ? (
                            <ImageValidation imgUrl={listItem?.photo_url || listItem?.link_preview_image_url || null} customClassname={'m-ast-itm m-ast-img'} customName={'Post Image'} />
                          ) : (
                            listItem?.video_url && (
                              <div className="video-outer-element">
                                <Video videoUrl={listItem?.video_url} light={listItem?.video_thumbnail_url} customClassname="m-ast-itm m-ast-video" />
                              </div>
                            )
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
          </InfiniteScroll>
        </div>
      ) : (
        !isListFetching && !postsList.length && <NoDataFound size={NoDataFoundProps.FULL} />
      )}
    </>
  );
};
