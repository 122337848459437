import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { ListCard } from './ListCard';
import { IStore, operationsListsContainerTypes } from 'types';
import { buildSelectedTree, findValidChildren, ITreeSelection } from 'utils/helpers';

export const DefineHubsAndLocations = ({ handleChange }: operationsListsContainerTypes.IDefineHubsAndLocationsProps) => {
  const selectedArr = useSelector((state: IStore) => state.operationsList.modifiedObj?.included_accounts);
  const availableTree = useSelector((state: IStore) => state.operationsList.treeData);
  const isListDetailFetching = useSelector((state: IStore) => state.operationsList.isListDetailFetching);
  const availableLocations = useSelector((state: IStore) => state.operationsList.modifiedObj?.missing_accounts);

  const selectedObj: ITreeSelection = useMemo(() => {
    if (selectedArr?.length > 0 && !isListDetailFetching && availableTree.length > 0) {
      return buildSelectedTree(
        availableTree,
        selectedArr.map((it: { id: number; name: string }) => String(it?.id))
      );
    }
    return { selected: [], checked: [], partiallyChecked: [] };
  }, [selectedArr, isListDetailFetching, availableTree]);

  return (
    <div className="create-as">
      <ListCard
        title="Available"
        subtitle={`(${selectedArr?.length + availableLocations?.length} Available Locations)`}
        treeData={availableTree}
        rootClass="available-card"
        checkable
        checkedKeys={{ checked: selectedObj.checked, halfChecked: selectedObj.partiallyChecked }}
        onCheck={(selectedObj: { checked: string[]; halfChecked: string[] }, e: any) => {
          const checkedObjId = e.node.key;
          const newSelected = selectedArr ? [...selectedArr] : [];
          const availableArr = availableLocations ? [...availableLocations] : [];
          const validChildren = findValidChildren(availableTree, checkedObjId);
          if (e.checked) {
            validChildren.forEach((it) => {
              const presentIndex = newSelected.findIndex((s) => s.id === Number(it));
              const availableObjIndex = availableArr.findIndex((s) => s.id === Number(it));
              if (presentIndex === -1) {
                newSelected.push(availableArr[availableObjIndex]);
                availableArr.splice(availableObjIndex, 1);
              }
            });
          } else {
            validChildren.forEach((it) => {
              const presentIndex = newSelected.findIndex((s) => s.id === Number(it));
              const availableObjIndex = availableArr.findIndex((s) => s.id === Number(it));
              if (availableObjIndex === -1) {
                availableArr.push(newSelected[presentIndex]);
                newSelected.splice(presentIndex, 1);
              }
            });
          }
          handleChange('included_accounts', newSelected);
          handleChange('missing_accounts', availableArr);
        }}
        checkStrictly
      />
      <ListCard title="Selected" subtitle={`(${selectedArr?.length} Of ${selectedArr?.length + availableLocations?.length} Locations)`} treeData={selectedObj.selected} rootClass="selected-card" />
    </div>
  );
};
