import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { apFranActionReducerTypes } from 'types';
import {
  ADD_POST_DELETE_CONTENT_SUCCESS_MSG,
  AP_FRAN_ADD_LINK_INIT_DATA,
  AP_INIT_EDITOR_STATE_FOR_CUSTOM_TEXT,
  AP_EXTRA_INFO_TAB_NAMES,
  AP_SELECTED_LOC_INIT_DATA,
  IAIResultsTab,
  IAISugesstionTabs,
  AP_HASHTAGS_INITIAL_VALUE,
  LANG_OPTION_VALUES
} from 'utils/constants';
import { getLinkDataForSelectedCoupon, getUniqueData } from 'utils/helpers';
import { AddPostFranMainActions } from 'actions';

type IAddPostFranActionReducer = apFranActionReducerTypes.IAddPostFranActionReducer;

const initialState: IAddPostFranActionReducer = {
  isFetching: false,
  error: null,
  deleteCheckBox: ['removeFromDB'],
  deletedObj: {
    isFetching: false,
    contentId: 0,
    deleteMessage: null
  },
  internalNotesList: [],
  linkObj: AP_FRAN_ADD_LINK_INIT_DATA,
  localizePreviewLinks: [],
  localizeLinkURL: '',
  textEditorState: AP_INIT_EDITOR_STATE_FOR_CUSTOM_TEXT,
  personalizedEditorState: AP_INIT_EDITOR_STATE_FOR_CUSTOM_TEXT,
  campaignData: [],
  activeTab: AP_EXTRA_INFO_TAB_NAMES.SETTINGS,
  isEmojiOpen: false,
  isPersonalizedEmojiOpen: false,
  creatorModalName: null,
  creatorVideoThumbnailModalName: null,
  isActiveCampaignUpdate: false,
  selectedLocData: AP_SELECTED_LOC_INIT_DATA,
  aiTabName: { parent: IAISugesstionTabs.CAPTIONS_AND_HASHTAGS, child: IAIResultsTab.CONTENTS },
  isShowAiImageTab: false,
  hashtagsListObj: AP_HASHTAGS_INITIAL_VALUE,
  isCustomizeLinkEnable: false,
  isCustomizeLinkAdded: false,
  isLocalizeLinkAdded: false,
  // translateLang: LANG_OPTION_VALUES.ENGLISH,
  isAICaptionTranslating: false,
  translationConfig: {
    facebook: {
      from_language: LANG_OPTION_VALUES.ENGLISH,
      to_language: null,
      original_text: '',
      isSocialMediaTranslated: false
    },
    twitter: {
      from_language: LANG_OPTION_VALUES.ENGLISH,
      to_language: null,
      original_text: '',
      isSocialMediaTranslated: false
    },
    linkedin: {
      from_language: LANG_OPTION_VALUES.ENGLISH,
      to_language: null,
      original_text: '',
      isSocialMediaTranslated: false
    },
    instagram: {
      from_language: LANG_OPTION_VALUES.ENGLISH,
      to_language: null,
      original_text: '',
      isSocialMediaTranslated: false
    },
    google: {
      from_language: LANG_OPTION_VALUES.ENGLISH,
      to_language: null,
      original_text: '',
      isSocialMediaTranslated: false
    },
    tiktok: {
      from_language: LANG_OPTION_VALUES.ENGLISH,
      to_language: null,
      original_text: '',
      isSocialMediaTranslated: false
    },
    pinterest: {
      from_language: LANG_OPTION_VALUES.ENGLISH,
      to_language: null,
      original_text: '',
      isSocialMediaTranslated: false
    }
  },
  isPreviewGenerated: false,
  isPinterestBoardFetching: false,
  pinterestBoards: []
};

export const addPostFranActionsReducer: Reducer<IAddPostFranActionReducer, AddPostFranMainActions> = (state: IAddPostFranActionReducer = initialState, action: AddPostFranMainActions) => {
  switch (action.type) {
    // ADD POST - DELETE CONTENT POST
    case types.AP_DELETE_CONTENT_REQUEST:
      return {
        ...state,
        deletedObj: {
          ...initialState.deletedObj,
          isFetching: true
        }
      };
    case types.AP_DELETE_CONTENT_RESPONSE:
      return {
        ...state,
        deletedObj: {
          isFetching: false,
          contentId: action.payload.id,
          deleteMessage: action.payload.id ? ADD_POST_DELETE_CONTENT_SUCCESS_MSG : null
        }
      };
    case types.AP_DELETE_CONTENT_FAILURE:
      return {
        ...state,
        deletedObj: {
          isFetching: false,
          contentId: 0,
          deleteMessage: null
        },
        error: action.payload || 'Unexpected error'
      };
    case types.AP_MASS_DELETE_CONTENT_REQUEST:
      return {
        ...state,
        deletedObj: {
          ...state.deletedObj,
          isFetching: true
        }
      };
    case types.AP_MASS_DELETE_CONTENT_RESPONSE:
      return {
        ...state,
        deletedObj: {
          ...state.deletedObj,
          isFetching: false
        }
      };
    case types.AP_MASS_DELETE_CONTENT_FAILURE:
      return {
        ...state,
        deletedObj: {
          ...state.deletedObj,
          isFetching: false
        }
      };
    // ADD POST - GET INTERNAL NOTES LIST
    case types.AP_GET_INTERNAL_NOTES_LIST_REQUEST:
      return {
        ...state
      };
    case types.AP_GET_INTERNAL_NOTES_LIST_RESPONSE:
      return {
        ...state,
        internalNotesList: action.payload
      };
    case types.AP_GET_INTERNAL_NOTES_LIST_FAILURE:
      return {
        ...state,
        internalNotesList: [],
        error: action.payload || 'Unexpected error'
      };
    // ADD POST - ADD INTERNAL NOTES LIST
    case types.AP_ADD_INTERNAL_NOTES_LIST_REQUEST:
      return {
        ...state
      };
    case types.AP_ADD_INTERNAL_NOTES_LIST_RESPONSE:
      return {
        ...state
      };
    case types.AP_ADD_INTERNAL_NOTES_LIST_FAILURE:
      return {
        ...state,
        error: action.payload || 'Unexpected error'
      };
    // ADD POST - ADD LINK
    case types.AP_ADD_LINK_REQUEST:
      return {
        ...state,
        linkObj: {
          isLinkFetching: true,
          linkData: null
        }
      };
    case types.AP_ADD_LINK_RESPONSE:
      return {
        ...state,
        linkObj: {
          isLinkFetching: false,
          linkData: action.payload
        }
      };
    case types.AP_ADD_LINK_FAILURE:
      return {
        ...state,
        linkObj: AP_FRAN_ADD_LINK_INIT_DATA,
        error: action.payload || 'Unexpected error'
      };
    // ADD POST - LOCALIZE PREVIEWS LINK
    case types.AP_LOCALIZE_PREVIEWS_LINK_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case types.AP_LOCALIZE_PREVIEWS_LINK_RESPONSE:
      return {
        ...state,
        localizePreviewLinks: action.payload,
        isPreviewGenerated: true,
        isFetching: false
      };
    case types.AP_LOCALIZE_PREVIEWS_LINK_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload || 'Unexpected error'
      };
    // ADD POST - DELETE LINK
    case types.AP_DELETE_LINK_REQUEST:
      return {
        ...state
      };
    case types.AP_DELETE_LINK_RESPONSE:
      return {
        ...state,
        linkObj: {
          ...state.linkObj,
          linkData: action.payload
        }
      };
    case types.AP_DELETE_LINK_FAILURE:
      return {
        ...state,
        linkObj: AP_FRAN_ADD_LINK_INIT_DATA,
        error: action.payload || 'Unexpected error'
      };
    // ADD POST - UPDATE LINK
    case types.AP_UPDATE_LINK_REQUEST:
      return {
        ...state
      };
    case types.AP_UPDATE_LINK_RESPONSE:
      return {
        ...state,
        linkObj: {
          ...state.linkObj,
          linkData: action.payload
        }
      };
    case types.AP_UPDATE_LINK_FAILURE:
      return {
        ...state,
        linkObj: AP_FRAN_ADD_LINK_INIT_DATA,
        error: action.payload || 'Unexpected error'
      };
    // ADD POST - CREATE LINK FOR COUPON
    case types.AP_CREATE_LINK_FOR_COUPON_REQUEST:
      return {
        ...state,
        linkObj: {
          ...state.linkObj,
          isLinkFetching: true,
          linkData: null
        }
      };
    case types.AP_CREATE_LINK_FOR_COUPON_RESPONSE:
      return {
        ...state,
        linkObj: {
          ...state.linkObj,
          isLinkFetching: false,
          linkData: action.payload
        }
      };
    case types.AP_CREATE_LINK_FOR_COUPON_FAILURE:
      return {
        ...state,
        linkObj: AP_FRAN_ADD_LINK_INIT_DATA,
        error: action.payload || 'Unexpected error'
      };
    // ADD POST - CREATE LINK FOR ACCOUNT COUPON
    case types.AP_CREATE_LINK_FOR_ACC_COUNPON_REQUEST:
      return {
        ...state,
        linkObj: {
          ...state.linkObj,
          isLinkFetching: true
        }
      };
    case types.AP_CREATE_LINK_FOR_ACC_COUNPON_RESPONSE:
      return {
        ...state,
        linkObj: {
          isLinkFetching: false,
          linkData: getLinkDataForSelectedCoupon(action.payload)
        }
      };
    case types.AP_CREATE_LINK_FOR_ACC_COUNPON_FAILURE:
      return {
        ...state,
        linkObj: AP_FRAN_ADD_LINK_INIT_DATA,
        error: action.payload || 'Unexpected error'
      };
    case types.AP_SET_DELETE_CONTENT_OBJ:
      return {
        ...state,
        deleteCheckBox: action.payload
      };
    case types.AP_SET_LINK_DATA:
      return {
        ...state,
        linkObj: {
          ...state.linkObj,
          linkData: action.payload
        }
      };
    case types.AP_UPDATE_CUSTOM_TEXT_EDITOR:
      return {
        ...state,
        textEditorState: {
          ...state.textEditorState,
          [action.payload.key]: action.payload.value
        }
      };
    case types.AP_UPDATE_PERSONALIZED_TEXT_EDITOR:
      return {
        ...state,
        personalizedEditorState: {
          ...state.personalizedEditorState,
          [action.payload.key]: action.payload.value
        }
      };
    case types.AP_SET_CUSTOM_TEXT_EDITOR_DATA:
      return {
        ...state,
        textEditorState: action.payload
      };
    case types.AP_SET_PERSONALIZED_TEXT_EDITOR_DATA:
      return {
        ...state,
        personalizedEditorState: action.payload
      };
    case types.AP_SET_CAMPAIGN_DATA:
      return {
        ...state,
        campaignData: getUniqueData(action.payload)
      };
    case types.AP_SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload
      };
    case types.AP_IS_EMOJI_OPEN:
      return {
        ...state,
        isEmojiOpen: action.payload
      };
    case types.AP_PERSONALIZED_IS_EMOJI_OPEN:
      return {
        ...state,
        isPersonalizedEmojiOpen: action.payload
      };
    case types.SET_CREATOR_MODAL_NAME:
      return {
        ...state,
        creatorModalName: action.payload
      };
    case types.SET_CREATOR_VIDEO_THUMBNAIL_MODAL_NAME:
      return {
        ...state,
        creatorVideoThumbnailModalName: action.payload
      };
    case types.SET_CREATOR_AI_TAB_NAME:
      return {
        ...state,
        aiTabName: action.payload
      };
    case types.AI_SHOW_IMAGE_TAB:
      return {
        ...state,
        isShowAiImageTab: action.payload
      };
    case types.SET_CUSTOMIZE_LINK_ENABLE:
      return {
        ...state,
        isCustomizeLinkEnable: action.payload
      };
    case types.ADD_CUSTOMIZE_LINK:
      return {
        ...state,
        isCustomizeLinkAdded: action.payload
      };
    case types.ADD_LOCALIZE_LINK:
      return {
        ...state,
        isLocalizeLinkAdded: action.payload
      };
    case types.ADD_LOCALIZE_LINK_URL:
      return {
        ...state,
        localizeLinkURL: action.payload
      };
    case types.AP_IS_CONFIRM_ACTIVE_CAMPAIGN_UPDATE:
      return {
        ...state,
        isActiveCampaignUpdate: action.payload
      };
    case types.AP_SET_SELECTED_LOC_DATA:
      return {
        ...state,
        selectedLocData: action.payload
      };
    case types.AP_GET_HASHTAGS_LIST_REQUEST:
      return {
        ...state,
        hashtagsListObj: {
          ...state.hashtagsListObj,
          isFetching: true
        },
        error: null
      };
    case types.AP_GET_HASHTAGS_LIST_RESPONSE:
      return {
        ...state,
        hashtagsListObj: {
          ...state.hashtagsListObj,
          isFetching: false,
          hashtagsList: action.payload
        }
      };
    case types.AP_GET_HASHTAGS_LIST_FAILURE:
      return {
        ...state,
        hashtagsListObj: {
          ...state.hashtagsListObj,
          isFetching: false
        },
        error: action.payload
      };
    case types.AP_CREATE_HASHTAG_RESPONSE:
      return {
        ...state,
        hashtagsListObj: {
          ...state.hashtagsListObj,
          hashtagsList: action.payload
        }
      };
    case types.AP_SELECT_HASHTAG_RESPONSE:
      return {
        ...state,
        hashtagsListObj: {
          ...state.hashtagsListObj,
          selectedHashtagsList: action.payload
        }
      };
    case types.AP_CREATE_HASHTAG_FAILURE:
      return {
        ...state,
        error: action.payload
      };

    case types.AP_DELETE_HASHTAG_RESPONSE:
      return {
        ...state,
        hashtagsListObj: {
          ...state.hashtagsListObj,
          hashtagsList: action.payload,
          selectedHashtagsList: []
        }
      };
    case types.AP_DELETE_HASHTAG_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case types.AP_CAPTION_TRANSLATE_REQUEST:
      return {
        ...state,
        isAICaptionTranslating: true
      };
    case types.AP_CAPTION_TRANSLATE_RESPONSE:
      return {
        ...state,
        isAICaptionTranslating: false,
        translationConfig: {
          ...state.translationConfig,
          [action.payload.socialMedia]: {
            ...state.translationConfig[action.payload.socialMedia],
            to_language: action.payload.toLanguage,
            from_language: action.payload.fromLanguage
          }
        }
      };
    case types.AP_CAPTION_TRANSLATE_FAILURE:
      return {
        ...state,
        error: action.payload,
        isAICaptionTranslating: false
      };
    case types.AP_SET_CREATOR_ORIGINAL_TEXT:
      return {
        ...state,
        translationConfig: {
          ...state.translationConfig,
          [action.payload.socialMedia]: {
            ...state.translationConfig[action.payload.socialMedia],
            original_text: action.payload.text ?? state.translationConfig[action.payload.socialMedia].original_text,
            to_language: action.payload.toLanguage === LANG_OPTION_VALUES.ORIGINAL_TEXT ? null : action.payload.toLanguage,
            from_language: action.payload.toLanguage === LANG_OPTION_VALUES.ORIGINAL_TEXT ? LANG_OPTION_VALUES.ENGLISH : action.payload.fromLanguage,
            isSocialMediaTranslated: action.payload.toLanguage === LANG_OPTION_VALUES.ORIGINAL_TEXT ? false : true
          }
        }
      };

    case types.AP_GET_PINTEREST_BOARDS_REQUEST:
      return {
        ...state,
        isPinterestBoardFetching: true,
        pinterestBoards: [],
        error: null
      };
    case types.AP_GET_PINTEREST_BOARDS_RESPONSE:
      return {
        ...state,
        isPinterestBoardFetching: false,
        pinterestBoards: action.payload?.length ? action.payload.map((board) => ({ id: board.id, value: board.name, label: board.name })) : []
      };
    case types.AP_GET_PINTEREST_BOARDS_FAILURE:
      return {
        ...state,
        isPinterestBoardFetching: false,
        pinterestBoards: [],
        error: action.payload
      };
    case types.AP_RESET_LOCALIZE_PREVIEW_LINKS:
      return {
        ...state
      };
    case types.AP_ACTION_RESET_ALL:
      return { ...initialState };
    default:
      return state;
  }
};
