import { useState, useEffect, useMemo } from 'react';
import { Modal, Accordion } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { commonModalPopup, IStore } from 'types';
import {
  ICommonDateTypes,
  AP_CONTENT_ACTION_TYPE,
  ADD_POST_APPROVE_AND_SYNDICATE_MSG,
  FranCreatorScheduleType,
  RIPPLE_COLOR,
  FranScheduledLocName,
  AP_SELECTED_LOC_INIT_DATA,
  LOCATION_LISTS,
  FranchisorSyndicationMediaType,
  CORP_HUBS,
  FranCreatorScheduleDateType,
  CALENDAR_YR_MN_DATE_FORMAT
} from 'utils/constants';
import { ImageValidation } from 'widgets/Media';
import {
  addPostGetSelectedContentObj,
  addPostUpdateLinkRequest,
  addPostUpdateContentRequest,
  addPostSetSelectedLocData,
  addPostMassUpdateForScheduledPostRequest,
  addFranchisorSyndicationRequest,
  selectFranchisorToSyndicate,
  franchisorSyndicationResetAll,
  postSyndicateSuccessResponse,
  addFranchisorSyndicationResponse,
  addFranchisorMultiSyndicationRequest,
  getFranCountriesListRequest,
  selectCountriesToSyndicate,
  resetSyndicationDetails
} from 'actions';
import {
  getStartOfDate,
  getEndOfDate,
  getUTCDateAndTimeForSelectedData,
  getLinkDataReqPayload,
  getReqPayloadForUpdateContent,
  atLeastApproved,
  getInitialSelectedContentData,
  getFormattedDate
} from 'utils/helpers';
import { PostSyndicatedModal } from './PostSyndicatedModal';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { useAccountSwitcherData, useActivePlatform } from 'utils/hooks';

import { FranSyndicateScheduleTiming } from './FranSyndicateScheduleTiming';
import { FranSyndicateWhere } from './FranSyndicateWhere';
import { FranSyndicateWho } from './FranSyndicateWho';
import { Loading } from 'components';

export const FranSyndicatePostModal = ({ isModalShow, handleModalClose, mediaType, postOrMediaId, isMultiSelect, handleMainModalClose }: commonModalPopup.IFranSyndicateModalProps) => {
  const dispatch = useDispatch();

  const { id } = useAccountSwitcherData();
  const activePlatforms = useActivePlatform();

  const activeUserId = useSelector((state: IStore) => state.login.activeUser?.id);
  const linkData = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj.linkData);
  const contentObj = useSelector((state: IStore) => state.addPostFranchisor.contentObj);
  const isApproveAndSyndicateFetching = useSelector((state: IStore) => state.addPostFranchisor.isApproveAndSyndicateFetching);
  const contentData = useSelector((state: IStore) => state.addPostFranchisor.contentData);
  const selectedMediaList = useSelector((state: IStore) => state.addPostFranchisor.selectedMediaList);
  const selectedFBTagList = useSelector((state: IStore) => state.addPostFranchisor.selectedFBTagList);
  const contentSuccessMsg = useSelector((state: IStore) => state.addPostFranchisor.updatedMessage.contentSuccessMsg);
  const selectedTags = useSelector((state: IStore) => state.addPostAccount.selectedTags);
  const instaType = useSelector((state: IStore) => state.addPostAccount.instaPhotoPreviewObj.actionType);
  const selectedLocData = useSelector((state: IStore) => state.addPostFranchisorAction.selectedLocData);
  const locationLists = useSelector((state: IStore) => state.accountSwitcher.accountSelectionList?.locationList || []);
  const isAiPost = useSelector((state: IStore) => state.aiContentCreator.isAiPost);
  const franchisorSyndication = useSelector((state: IStore) => state.franchisors.currentFranDetails?.franchisors_for_syndication);
  const selectedSyndicateFranchisors = useSelector((state: IStore) => state.addPostFranchisor.selectedSyndicateFranchisors);
  const postsDetails = useSelector((state: IStore) => state.posts.postsDetails);
  const postsLinks = useSelector((state: IStore) => state.posts.postsLinks);
  const postSyndicationSuccessMsg = useSelector((state: IStore) => state.posts.postSyndicationSuccessMsg);
  const isContentSupplier = useSelector((state: IStore) => state.franchisors.franchisorDetails?.content_supplier);
  const corpHubs = useSelector((state: IStore) => state.franchisors.corpHubs);
  const translationConfig = useSelector((state: IStore) => state.addPostFranchisorAction.translationConfig);
  const reducerTextEditorState = useSelector((state: IStore) => state.addPostFranchisorAction.textEditorState);
  const selectedPosts = useSelector((state: IStore) => state.campaigns.selectedPosts);
  const selectedMediaIds = useSelector((state: IStore) => state.mediaGallery.selectedMediaIds);
  const selectedSyndicateCountries = useSelector((state: IStore) => state.addPostFranchisor.selectedSyndicateCountries);
  const isCountryFetching = useSelector((state: IStore) => state.franchisors.isCountryFetching);
  const isPreviousSyndicationFetching = useSelector((state: IStore) => state.franchisors.isPreviousSyndicationFetching);
  const previousSyndication = useSelector((state: IStore) => state.franchisors.previousSyndication || []);

  const [locationType, setLocationType] = useState<FranScheduledLocName>(
    mediaType === FranchisorSyndicationMediaType.CONTENT && !isContentSupplier
      ? FranScheduledLocName.ALL_LOCATIONS
      : previousSyndication?.length
      ? FranScheduledLocName.FRANCHISE_CONSULTANT
      : FranScheduledLocName.ALL_FRANCHISE_CONSULTANT
  ); // FRAN_SCHEDULE_POST_TYPE.ALL_LOCATIONS

  const isContentWriter = franchisorSyndication?.length && (locationType === FranScheduledLocName.FRANCHISE_CONSULTANT || locationType === FranScheduledLocName.ALL_FRANCHISE_CONSULTANT);
  const isSyndicateStatusModalOpen = contentSuccessMsg === ADD_POST_APPROVE_AND_SYNDICATE_MSG || Boolean(postSyndicationSuccessMsg);

  useEffect(() => {
    dispatch(
      addPostGetSelectedContentObj({
        ...contentObj,
        scheduledBy: FranCreatorScheduleType.SYNDICATE_TO_ACCOUNT_DB
      })
    );
  }, []); // eslint-disable-line

  useEffect(() => {
    if (selectedLocData?.id) setLocationType(FranScheduledLocName.SPECIFIC_LOCATION_LIST);
  }, [selectedLocData?.id]); // eslint-disable-line

  useEffect(() => {
    if (contentSuccessMsg === ADD_POST_APPROVE_AND_SYNDICATE_MSG || postSyndicationSuccessMsg) {
      setLocationType(mediaType === FranchisorSyndicationMediaType.CONTENT ? FranScheduledLocName.ALL_LOCATIONS : FranScheduledLocName.ALL_FRANCHISE_CONSULTANT);
      // setOpenSyndicateModal(true);
    }
  }, [contentSuccessMsg, postSyndicationSuccessMsg]); // eslint-disable-line

  useEffect(() => {
    if (isContentSupplier && mediaType === FranchisorSyndicationMediaType.CONTENT) {
      dispatch(getFranCountriesListRequest({ franchisor_ids: selectedSyndicateFranchisors?.length ? selectedSyndicateFranchisors : corpHubs.map((hub) => hub.id) }));
    }
  }, [selectedSyndicateFranchisors, isContentSupplier, corpHubs, mediaType, dispatch]);

  useEffect(() => {
    if (previousSyndication?.length && isContentSupplier && previousSyndication?.length !== franchisorSyndication?.length) {
      setLocationType(FranScheduledLocName.FRANCHISE_CONSULTANT);
      dispatch(selectFranchisorToSyndicate(previousSyndication));
    }
  }, [previousSyndication, isContentSupplier, franchisorSyndication, dispatch]);

  const handleLocationTabSelection = (selectedKey: null | FranScheduledLocName) => {
    setLocationType(selectedKey || FranScheduledLocName.ALL_LOCATIONS);
    if (selectedLocData?.id) dispatch(addPostSetSelectedLocData(AP_SELECTED_LOC_INIT_DATA));
    if (selectedSyndicateFranchisors) dispatch(selectFranchisorToSyndicate([]));
    if (isContentSupplier) dispatch(selectCountriesToSyndicate([]));
  };

  const handleDateChange = (date: null | Date, type: string) => {
    const selectedDate = type === ICommonDateTypes.FROM_DATE ? getStartOfDate(date) : getEndOfDate(date);
    dispatch(
      addPostGetSelectedContentObj({
        ...contentObj,
        customDate: {
          ...contentObj.customDate,
          [type]: { selected: selectedDate, utcFormat: date ? getUTCDateAndTimeForSelectedData(selectedDate) : null }
        }
      })
    );
  };

  const handleUpdateContentData = (actionName: string) => {
    const contentObjForPostDetailView = getInitialSelectedContentData(
      activePlatforms,
      postsDetails
        ? {
            ...postsDetails,
            ...((!isContentWriter || isContentSupplier) && {
              start_date:
                contentObj?.dateType === FranCreatorScheduleDateType.CUSTOM && contentObj?.customDate?.fromDate.selected
                  ? getUTCDateAndTimeForSelectedData(contentObj?.customDate?.fromDate.selected)
                  : null,
              end_date:
                contentObj?.dateType === FranCreatorScheduleDateType.CUSTOM && contentObj?.customDate?.toDate.selected
                  ? getUTCDateAndTimeForSelectedData(contentObj?.customDate?.toDate.selected)
                  : null
            }),
            syndicate_to_account_databases: true
          }
        : null,
      contentObj,
      actionName,
      postsLinks[0]
    ); // content obj for post detail view
    const contentDetails = contentData?.id ? contentData : postsDetails;
    const contentValueObj = contentData?.id ? contentObj : contentObjForPostDetailView;

    if (linkData?.id && linkData?.link_preview_image_url_choices?.length > 1) {
      dispatch(addPostUpdateLinkRequest({ linkId: linkData.id, reqPayload: getLinkDataReqPayload(linkData, contentObj.linkIndex, contentObj.isThumbnailShow) }));
    }
    if (isMultiSelect) {
      dispatch(
        addFranchisorMultiSyndicationRequest({
          mediaType,
          isContentSupplier: isContentSupplier || false,
          [`${mediaType}_ids`]: mediaType === FranchisorSyndicationMediaType.CONTENT ? selectedPosts : selectedMediaIds,
          ...(!isContentSupplier && { syndicate_to_account_databases: true }),
          ...(isContentSupplier || [FranScheduledLocName.FRANCHISE_CONSULTANT, FranScheduledLocName.ALL_FRANCHISE_CONSULTANT].includes(locationType)
            ? { franchisor_ids: locationType === FranScheduledLocName.FRANCHISE_CONSULTANT ? selectedSyndicateFranchisors : franchisorSyndication?.map((franchisor) => franchisor.id) || [] }
            : { account_list_id: locationType === FranScheduledLocName.SPECIFIC_LOCATION_LIST ? selectedLocData?.id : null }),
          ...(contentObj.dateType === FranCreatorScheduleDateType.CUSTOM && {
            delete_from_platforms_after_expiry: contentValueObj.isDeleteAutomatically,
            start_date: contentObj.customDate.fromDate.utcFormat ? getFormattedDate(contentObj.customDate.fromDate.utcFormat, CALENDAR_YR_MN_DATE_FORMAT) : null,
            end_date: contentObj.customDate.toDate.utcFormat ? getFormattedDate(contentObj.customDate.toDate.utcFormat, CALENDAR_YR_MN_DATE_FORMAT) : null
          })
        })
      );
    } else if (contentDetails?.id && activeUserId && actionName && mediaType === FranchisorSyndicationMediaType.CONTENT) {
      const filteredTranslationConfig = Object.keys(translationConfig).reduce((acc: any, curr) => {
        if (translationConfig[curr]?.isSocialMediaTranslated) {
          acc[curr] = {
            from_language: translationConfig[curr].from_language,
            to_language: translationConfig[curr].to_language,
            original_text: translationConfig[curr].original_text
          };
        }
        return acc;
      }, {});
      const saveDraftReqPayload = getReqPayloadForUpdateContent(
        id,
        activeUserId,
        contentDetails?.created_at,
        contentValueObj,
        selectedMediaList,
        selectedTags,
        selectedFBTagList,
        actionName,
        selectedLocData,
        linkData || postsLinks[0],
        instaType,
        [],
        contentDetails.content_origins,
        reducerTextEditorState?.facebook,
        postsDetails,
        contentDetails?.ai_post || isAiPost,
        Object.keys(filteredTranslationConfig)?.length ? filteredTranslationConfig : null
        // getSyndicatedAccountIds(locationType, totalLocations, selectedLocations, corporateAccountId)
      );
      dispatch(
        addPostUpdateContentRequest({
          actionType: actionName,
          postId: contentDetails.id,
          reqPayload: saveDraftReqPayload,
          ...(isContentWriter && {
            syndicateFranchisor: locationType === FranScheduledLocName.FRANCHISE_CONSULTANT ? selectedSyndicateFranchisors : franchisorSyndication?.map((franchisor) => franchisor.id)
          }),
          ...(isContentSupplier && { country_codes: selectedSyndicateCountries })
        })
      );
      dispatch(postSyndicateSuccessResponse(ADD_POST_APPROVE_AND_SYNDICATE_MSG));
      handleContentMassUpdate();
    } else if (postOrMediaId && (isContentWriter || isContentSupplier)) {
      dispatch(
        addFranchisorSyndicationRequest({
          mediaType,
          id: postOrMediaId,
          franchisor_ids: locationType === FranScheduledLocName.FRANCHISE_CONSULTANT ? selectedSyndicateFranchisors : franchisorSyndication?.map((franchisor) => franchisor.id) || [],
          ...(isContentSupplier && { country_codes: selectedSyndicateCountries })
        })
      );
    }
  };

  const handleContentMassUpdate = () => {
    if (contentData?.id && atLeastApproved(contentData?.status_id)) {
      dispatch(addPostMassUpdateForScheduledPostRequest(contentData?.id));
    }
  };

  const disableSyndicateAction = () => {
    if (isApproveAndSyndicateFetching || isPreviousSyndicationFetching || (locationType === FranScheduledLocName.FRANCHISE_CONSULTANT && !selectedSyndicateFranchisors.length)) return true;
    else if (locationType === FranScheduledLocName.SPECIFIC_LOCATION_LIST && !selectedLocData.id) return true;
    else if (contentObj.dateType === FranCreatorScheduleDateType.CUSTOM && !contentObj?.customDate?.toDate?.selected) return true;
    return false;
  };

  const dropdownOptions = useMemo(() => {
    return isContentSupplier
      ? [
          {
            label: CORP_HUBS,
            options: corpHubs
          }
        ]
      : [
          {
            label: LOCATION_LISTS,
            options: locationLists.filter((Item) => !Item?.locked)
          }
        ];
  }, [locationLists, corpHubs, isContentSupplier]);

  const handleSyndicateResetData = () => {
    // setLocationType(FRAN_SCHEDULE_POST_TYPE.ALL_LOCATIONS);
    // dispatch(franchisorScheduleTreeReset());
    dispatch(resetSyndicationDetails());
    dispatch(franchisorSyndicationResetAll());
    handleModalClose();
  };

  const handleSelectSyndicateFranchisors = (id: number) => {
    dispatch(
      selectFranchisorToSyndicate(selectedSyndicateFranchisors.includes(id) ? selectedSyndicateFranchisors.filter((franchisorId) => franchisorId !== id) : [...selectedSyndicateFranchisors, id])
    );
    dispatch(selectCountriesToSyndicate([]));
  };

  return (
    <>
      <Modal
        // className="creator-tag-modal-wrp schedule-post-modal-hubuser schedule-post__main syndicate-post-modal-hubuser"
        className={`glbl__modal--wrp syndicate-post-modal-hubuser syndicate__modal--wrp accordGlobal ${isContentSupplier ? 'fssTiming-off' : 'fssTiming-on'}`}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isModalShow && !isSyndicateStatusModalOpen}
        animation={false}
        onHide={handleSyndicateResetData}
      >
        <div className="mod__close--icon">
          <ImageValidation isImgValid defaultImg={'close-creator'} customName={'close'} customClassname="mod__close--img" onClick={handleSyndicateResetData} />
        </div>

        <Modal.Body>
          {isPreviousSyndicationFetching && <Loading />}
          <h3 className="glbl__title--txt">Syndicate</h3>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Who</Accordion.Header>
              <Accordion.Body>
                <FranSyndicateWho
                  mediaType={mediaType}
                  locationType={locationType}
                  dropdownOptions={dropdownOptions}
                  handleLocationTabSelection={handleLocationTabSelection}
                  handleSelectSyndicateFranchisors={handleSelectSyndicateFranchisors}
                />
              </Accordion.Body>
            </Accordion.Item>
            {isContentSupplier && mediaType === FranchisorSyndicationMediaType.CONTENT && (
              <Accordion.Item eventKey="1">
                <Accordion.Header>Where</Accordion.Header>
                <Accordion.Body>
                  <FranSyndicateWhere contentObj={contentObj} handleDateChange={handleDateChange} dispatch={dispatch} />
                </Accordion.Body>
              </Accordion.Item>
            )}

            {(isContentSupplier || !isContentWriter) && mediaType === FranchisorSyndicationMediaType.CONTENT ? (
              <Accordion.Item eventKey="2">
                <Accordion.Header>When can this post be scheduled</Accordion.Header>
                <Accordion.Body>
                  <FranSyndicateScheduleTiming contentObj={contentObj} handleDateChange={handleDateChange} dispatch={dispatch} />
                </Accordion.Body>
              </Accordion.Item>
            ) : null}
          </Accordion>
          <div className="footer-section">
            <CustomRippleButton rippleClass={`ac-primary-box  ${disableSyndicateAction() || isCountryFetching ? `pointer-events-none` : ``}`} custColor={RIPPLE_COLOR.primary}>
              <button
                className={`ac-btn ac-primary ac-block ${disableSyndicateAction() || isCountryFetching ? `disabled` : ``}`}
                onClick={() => handleUpdateContentData(AP_CONTENT_ACTION_TYPE.APPROVE_AND_SYNDICATE)}
              >
                Syndicate
              </button>
            </CustomRippleButton>
          </div>
        </Modal.Body>
      </Modal>
      <PostSyndicatedModal
        isModalShow={isSyndicateStatusModalOpen}
        handleModalClose={() => {
          dispatch(postSyndicateSuccessResponse(''));
          dispatch(addFranchisorSyndicationResponse(null));
        }}
        handleMainModalClose={() => {
          handleModalClose();
          if (handleMainModalClose) handleMainModalClose();
        }}
        postOrMediaId={postOrMediaId}
        mediaType={mediaType}
      />
    </>
  );
};
