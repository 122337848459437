import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { ICommonDateTypes, ADD_SCH_POST_INVALID_DATE_MSG, RIPPLE_COLOR, AP_CONTENT_ACTION_TYPE } from 'utils/constants';
import { CustomDatePicker } from 'widgets/DatePicker';
import { endDateLessThanStartDate, utcFormatForDateValue, calendarInitScheduledTime, convertStringToDate /* dateObjBasedOnTimeZone */ } from 'utils/helpers';
import { checkDayIsEqual } from 'analytics/utils/helpers';
import { addPostResetAll, addPostUpdateSavedPostDetails /* calendarEditView */ } from 'actions';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { ImageValidation } from 'widgets/Media';
import { IStore, calendarContainerTypes } from 'types';

export const CalendarDatePicker = ({ modalOpenFunction, states, dispatchFunction, editData, endDate }: calendarContainerTypes.ICalendarDatePicker) => {
  const dispatch = useDispatch();

  const savedPostDetails = useSelector((state: IStore) => state.addPostAccount?.savedPostDetails);
  const timeZone = useSelector((state: IStore) => state.accounts.accountDetails?.account?.tzinfo_name || '');

  const isDateInvalid =
    savedPostDetails?.account_schedule_date_time.selected && savedPostDetails?.schedule_date_time?.selected
      ? !endDateLessThanStartDate(savedPostDetails?.account_schedule_date_time.selected, savedPostDetails?.schedule_date_time?.selected || null)
      : false;

  const isSameDateAndTime = checkDayIsEqual(savedPostDetails?.schedule_date_time.selected || null, savedPostDetails?.account_schedule_date_time.selected || null);

  const savedPostStartDate = editData?.start_date ? convertStringToDate(editData?.start_date) : null;
  const savedPostEndDate = endDate || editData?.end_date ? convertStringToDate(endDate || editData?.end_date) : null;
  const accountScheduleDateTime = savedPostDetails?.account_schedule_date_time.selected;
  const minDate = savedPostStartDate && accountScheduleDateTime && savedPostStartDate > accountScheduleDateTime ? savedPostStartDate : accountScheduleDateTime;
  const maxDate = savedPostEndDate;

  const handleDateChange = (date: null | Date, type: string) => {
    const selectedDateRangeObj = { selected: date, utcFormat: utcFormatForDateValue(timeZone, date) };
    dispatch(
      addPostUpdateSavedPostDetails(
        savedPostDetails
          ? {
              ...savedPostDetails,
              [type]: selectedDateRangeObj
            }
          : null
      )
    );
  };

  const scheduleHandler = () => {
    if (!isDateInvalid) {
      modalOpenFunction(false);

      if (!isSameDateAndTime) {
        dispatchFunction(AP_CONTENT_ACTION_TYPE.SCHEDULE, states?.id);
      }
    }
  };

  const cancelScheduleDate = () => {
    if (editData) {
      dispatch(
        addPostUpdateSavedPostDetails(
          savedPostDetails
            ? {
                ...savedPostDetails,
                schedule_date_time: calendarInitScheduledTime(timeZone || '', editData.scheduledTime)
              }
            : null
        )
      );
      // dispatch(calendarEditView({ editData: null, editId: 0, isEditEnabled: false }));
    } else {
      dispatch(addPostResetAll());
    }
    modalOpenFunction(false);
  };

  return (
    <Modal
      show
      className="calendar-main__datepicker creator-tag-modal-wrp schedule-post-modal calendar-mod esDate-mWrap"
      centered
      size="lg"
      backdropClassName="esDate-bckdrp"
      onHide={modalOpenFunction}
    >
      <ImageValidation defaultImg="mpcIcon" customClassname="close-icon close mod__close--img wh-30" customName={'close'} onClick={cancelScheduleDate} />
      <Modal.Body>
        <div className="custom-dp-popper">
          <CustomDatePicker
            selectedDate={savedPostDetails?.schedule_date_time.selected || null}
            dateRangeType={ICommonDateTypes.DATE_AND_TIME}
            onChange={(date) => handleDateChange(date, 'schedule_date_time')}
            timeInputLabel={''}
            showTimeInput
            inline
            minDate={minDate}
            maxDate={maxDate}
          />
        </div>
        {isDateInvalid && <div className="error-txt-login">{ADD_SCH_POST_INVALID_DATE_MSG}</div>}
        <div className="footer-btn-section">
          <CustomRippleButton rippleClass="ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
            <button className="ac-btn ac-secondary-white ac-outline ac-block border-0" onClick={cancelScheduleDate}>
              Cancel
            </button>
          </CustomRippleButton>
          <CustomRippleButton rippleClass={`ac-primary-box ${isSameDateAndTime || isDateInvalid ? `pointer-events-none` : ``}`} custColor={RIPPLE_COLOR.primary}>
            <button className={`ac-btn ac-primary ac-block ${isSameDateAndTime || isDateInvalid ? `disabled` : ``}`} onClick={scheduleHandler}>
              Ok
            </button>
          </CustomRippleButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};
