import { Modal, ModalBody, ModalFooter } from 'react-bootstrap';

import { commonModalPopup } from 'types';
import { RIPPLE_COLOR } from 'utils/constants';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { ImageValidation } from 'widgets/Media';

export const SyndicationDetailModal = ({ isShowModal, data, onModalClose }: commonModalPopup.ISyndicationDetailModalProps) => {
  const syndicationData = data.selectedSyndication;
  const isCountryOpen = data.isCountryOpen;

  return (
    <Modal show={isShowModal} className="cnfrm__delete--modal glbl__delete--modal glbl__unsave--modal icw-main st-wm" onHide={onModalClose}>
      <div className="mod__close--icon">
        <ImageValidation isImgValid defaultImg="mpcIcon" customClassname="close-icon close mod__close--img wh-30" customName={'close'} onClick={onModalClose} />
      </div>
      <ModalBody>
        <div className="ic-wrap">
          <h2>Syndicated Date</h2>
          <p className="sd-stamp">{syndicationData?.date}</p>

          <div className="stwm-sd">
            <ul className="sd-list">
              {isCountryOpen
                ? syndicationData?.country_codes.map((cc: string, index: number) => (
                    <li className="sdl-item sdl-country" key={`syndicated-cc-${index}`}>
                      <span>{cc}</span>
                    </li>
                  ))
                : syndicationData?.syndicated_brands.map((brand: string, index: number) => (
                    <li className="sdl-item sdl-brands" key={`syndicated-brand-${index}`}>
                      <span>{brand}</span>
                    </li>
                  ))}
            </ul>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="delete__btn--wrp">
          <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
            <button className={`ac-btn ac-primary ac-block`} onClick={onModalClose}>
              Done
            </button>
          </CustomRippleButton>
        </div>
      </ModalFooter>
    </Modal>
  );
};
