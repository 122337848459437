import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';

import { aiEngineApiTypes, aiEngineReducerTypes } from 'types/ai-engine';

// AI ENGINE - GET CHAT HISTORY
export const getAIEngineChatHistoryRequest = (payload: any) => createAction(actionTypes.AI_ENGINE_GET_CHAT_HISTORY_REQUEST, payload);
export const getAIEngineChatHistoryResponse = (payload: any) => createAction(actionTypes.AI_ENGINE_GET_CHAT_HISTORY_RESPONSE, payload);
export const getAIEngineChatHistoryFailure = (error: null | string) => createAction(actionTypes.AI_ENGINE_GET_CHAT_HISTORY_FAILURE, error);

// AI ENGINE - CREATE CHAT HISTORY
export const sendAIEngineNewChatRequest = (payload: aiEngineApiTypes.ICreateAIEngineRequest) => createAction(actionTypes.AI_ENGINE_SEND_NEW_CHAT_REQUEST, payload);
export const sendAIEngineNewChatResponse = (payload: aiEngineReducerTypes.IAIEngineChatHistory) => createAction(actionTypes.AI_ENGINE_SEND_NEW_CHAT_RESPONSE, payload);
export const sendAIEngineNewChatFailure = (error: null | string) => createAction(actionTypes.AI_ENGINE_SEND_NEW_CHAT_FAILURE, error);

// AI ENGINE - CREATE THREAD
export const createAIEngineThreadRequest = (payload: any) => createAction(actionTypes.AI_ENGINE_CREATE_THREAD_REQUEST, payload);
export const createAIEngineThreadResponse = (payload: any) => createAction(actionTypes.AI_ENGINE_CREATE_THREAD_RESPONSE, payload);
export const createAIEngineThreadFailure = (error: null | string) => createAction(actionTypes.AI_ENGINE_CREATE_THREAD_FAILURE, error);

// AI ENGINE - LIST THREAD
export const getAIEngineThreadListRequest = (payload: any) => createAction(actionTypes.AI_ENGINE_LIST_THREAD_REQUEST, payload);
export const getAIEngineThreadListResponse = (payload: any) => createAction(actionTypes.AI_ENGINE_LIST_THREAD_RESPONSE, payload);
export const getAIEngineThreadListFailure = (error: null | string) => createAction(actionTypes.AI_ENGINE_LIST_THREAD_FAILURE, error);

// AI ENGINE - DELETE CHAT
export const deleteAIEngineRequest = (payload: aiEngineApiTypes.IDeleteAIEngine) => createAction(actionTypes.AI_ENGINE_DELETE_REQUEST, payload);
export const deleteAIEngineResponse = (payload: { message: string }) => createAction(actionTypes.AI_ENGINE_DELETE_RESPONSE, payload);
export const deleteAIEngineFailure = (error: null | string) => createAction(actionTypes.AI_ENGINE_DELETE_FAILURE, error);

// AI ENGINE - RESET
export const resetAIEngine = () => createAction(actionTypes.AI_ENGINE_RESET);

// AI ENGINE GENERATING
export const aiEngineGenerating = () => createAction(actionTypes.AI_ENGINE_GENERATING);

// AI ENGINE - UPDATE CHAT HISTORY
export const updateChatHistory = (payload: any) => createAction(actionTypes.UPDATE_CHAT_HISTORY, payload);

// AI ENGINE - UPDATE CARD CONTENT
export const updateCardsDetails = (payload: any) => createAction(actionTypes.UPDATE_CARDS_DETAILS, payload);

// AI ENGINE - THREAD ID
export const setThreadId = (payload: string) => createAction(actionTypes.SET_THREAD_ID, payload);

const AI_ENGINE_ACTIONS = {
  getAIEngineChatHistoryRequest,
  getAIEngineChatHistoryResponse,
  getAIEngineChatHistoryFailure,
  sendAIEngineNewChatRequest,
  sendAIEngineNewChatResponse,
  sendAIEngineNewChatFailure,
  createAIEngineThreadRequest,
  createAIEngineThreadResponse,
  createAIEngineThreadFailure,
  getAIEngineThreadListRequest,
  getAIEngineThreadListResponse,
  getAIEngineThreadListFailure,
  deleteAIEngineRequest,
  deleteAIEngineResponse,
  deleteAIEngineFailure,
  resetAIEngine,
  aiEngineGenerating,
  updateChatHistory,
  updateCardsDetails,
  setThreadId
};

export type AiEngineActions = ActionsUnion<typeof AI_ENGINE_ACTIONS>;
