import { apFranContainerTypes } from 'types';
import { ImageValidation } from 'widgets/Media';

export const SocialMediaPreviewFooterIcons = ({ socialMedia, footerIconsList }: apFranContainerTypes.ICreatorSocialMediaPreviewFooterProps) => {
  return (
    <div className="p-footer">
      {footerIconsList.map((iconItem, index) => {
        return (
          <div className="footer-btn" key={`${socialMedia}-footer-icon-${index}`}>
            <div className={iconItem.divClassname}>
              <ImageValidation isImgValid defaultImg={iconItem.defaultImg} customName={iconItem.name} />
            </div>
            {iconItem.label && <span className={iconItem.labelClassname}>{iconItem.label}</span>}
            {/* {iconItem.count && socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER && <span className={iconItem.countClassname}>{iconItem.count}</span>} */}
          </div>
        );
      })}
    </div>
  );
};
