import { useCallback, useEffect, /* useEffect, */ useMemo, useState } from 'react';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { Button, Spinner, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router';

import { ImageValidation } from 'widgets/Media';
import { IStore, ITableHeader, operationsListsApiTypes } from 'types';
import {
  accountCreateScheduledPostRequest,
  deleteLocationListRequest,
  subscriptionsLocationToListRequest,
  updateLocationsHubListRequest,
  // updateLocationsHubListResponse,
  modifyLocationsHubListResponse,
  commonSetContentModifiedStatus,
  onChangeList
} from 'actions';
import {
  FEED_TYPE,
  LIST_HEADER,
  MODAL_BOX_TYPES,
  SUBSCRIBE_FEED_LIST_CONFIRMATION_MSG,
  USER_OWNERSHIP,
  RIPPLE_COLOR,
  CONNECTION_TABS,
  SIDE_NAV_NAME,
  ALERT_BOX_TYPES,
  FEED_LIST_ENROLL_ALREADY_EXIST,
  TOOLTIP_PLACEMENT,
  LIST_HEADER_IDENTIFIERS,
  COMMON_SOCIAL_PROVIDER_TYPE
} from 'utils/constants';
import { useAccountSwitcherData, useParamsDeconstructor, useSSOIdentifiersForAccount } from 'utils/hooks';
import { getAccValidSocialMediaForCreator, utcToLocalHandler } from 'utils/helpers';
import { alertBoxCall, DeleteAssetModalPopup, ModalPopup, VirtualTable } from 'components';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { CustomTooltip } from 'widgets/Tooltip';
import { PLATFORM_FILTER } from 'analytics/utils';
import { TMListManageSocialMediaModal } from './Modals';

export const TMLists = () => {
  const dispatch = useCallback(useDispatch(), []); // eslint-disable-line
  const navigate = useNavigate();
  const { id, userOwnership, currentRoute, optionalParams } = useAccountSwitcherData();
  const { isCSLocation } = useSSOIdentifiersForAccount();
  const { queryParams } = useParamsDeconstructor();

  const locationsGroupList = useSelector((state: IStore) => state.operationsList.locationsGroupList);
  const isFetching = useSelector((state: IStore) => state.operationsList.isListFetching);
  const isTreeFetching = useSelector((state: IStore) => state.operationsList.isTreeFetching);
  const isDeleteRequest = useSelector((state: IStore) => state.operationsList.isDeleteRequest);
  const isSaveRequest = useSelector((state: IStore) => state.operationsList.isSaveRequest);
  const isContentSupplierFranchisor = useSelector((state: IStore) => state.accountSwitcher?.content_supplier_franchisor || false);
  const modifyLocationGroups = useSelector((state: IStore) => state.operationsList.modifiedLocationGroupList);
  // const isSSO = useSelector((state: IStore) => state.login.isSSO);
  const accountWizardHideContentSupplierFeed = useSelector((state: IStore) => state.accounts?.accountDetails?.account?.features?.account_wizard_hide_content_supplier_feed || false);
  const feedBadgeUrl = useSelector((state: IStore) => state.accountSwitcher.feedBadgeUrl);
  const accountProfiles = useSelector((state: IStore) => state.accounts.accountDetails?.account_profiles || []);
  const gmbFeatureEnabled = useSelector((state: IStore) => state.accounts.accountDetails?.account.gmb_feature_enabled || false);

  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showAdditionalColumns, setShowAdditionalColumns] = useState(false);

  const SSOPageName = localStorage.getItem('SSOPageName');
  const SSOFeedID = localStorage.getItem('SSOFeedID');

  const isFromAccountWizard = optionalParams[0] === 'account_wizard' && optionalParams[2] === CONNECTION_TABS.FEED;
  const hideLockedCSFeedList = optionalParams[0] === 'account_wizard' ? accountWizardHideContentSupplierFeed : false;
  const activePlatforms = getAccValidSocialMediaForCreator(accountProfiles, gmbFeatureEnabled);
  // const OriginalLocationsGroupList = locationsGroupList;

  // const result = findChangedAttributes(modifyLocationGroups, OriginalLocationsGroupList);

  const { subscribedList, unSubscribedList, platformModifiedList } = useMemo(() => {
    if (!modifyLocationGroups.length) return { subscribedList: [], unSubscribedList: [], platformModifiedList: [] };
    return modifyLocationGroups.reduce(
      (
        acc: { subscribedList: operationsListsApiTypes.IDataResponse[]; unSubscribedList: operationsListsApiTypes.IDataResponse[]; platformModifiedList: operationsListsApiTypes.IDataResponse[] },
        curr: operationsListsApiTypes.IDataResponse
      ) => {
        const selectedLocationGroupList = locationsGroupList.find((it) => it.id === curr.id);
        if (!curr.account_included) {
          acc.unSubscribedList.push({ ...curr });
        } else if (selectedLocationGroupList?.account_included !== curr.account_included) {
          acc.subscribedList.push({ ...curr });
        } else {
          acc.platformModifiedList.push({ ...curr });
        }
        return acc;
      },
      { subscribedList: [], unSubscribedList: [], platformModifiedList: [] }
    );
  }, [modifyLocationGroups]); // eslint-disable-line

  useEffect(() => {
    dispatch(commonSetContentModifiedStatus(modifyLocationGroups.length ? true : false));
  }, [modifyLocationGroups, dispatch]);

  const locationsHubList = useMemo(() => {
    if (locationsGroupList.length > 0) {
      const publicLists = locationsGroupList.filter((location) =>
        userOwnership === USER_OWNERSHIP.ACCOUNT ? (hideLockedCSFeedList ? location.location_access && !location.locked : location.location_access) : !location.locked
      );
      const privateLists = locationsGroupList.filter((location) =>
        userOwnership === USER_OWNERSHIP.ACCOUNT ? (hideLockedCSFeedList ? !location.location_access || location.locked : !location.location_access) : location.locked
      );
      const filteredLocationsList = !showAdditionalColumns ? publicLists : publicLists.concat(privateLists);
      return filteredLocationsList.map((it) => {
        return { ...it, updated_at: moment(utcToLocalHandler(it.updated_at)).format('MMMM DD, YYYY @ hh:mm A') };
      });
    }
    return [];
  }, [locationsGroupList, showAdditionalColumns, userOwnership, hideLockedCSFeedList]);

  const hasAdditionalColumns = useMemo(() => {
    return Boolean(
      !hideLockedCSFeedList &&
        locationsGroupList.some((location) => (userOwnership === USER_OWNERSHIP.ACCOUNT && !location.location_access) || (userOwnership === USER_OWNERSHIP.FRANCHISOR && location.locked))
    );
  }, [hideLockedCSFeedList, locationsGroupList, userOwnership]);

  useEffect(() => {
    setTimeout(() => {
      if (locationsGroupList?.length && SSOPageName && SSOPageName === SIDE_NAV_NAME.CONTENT_FEED && SSOFeedID) {
        const feedListIndex = locationsGroupList.findIndex((item) => item.id === parseInt(SSOFeedID));
        if (locationsGroupList[feedListIndex].account_included) {
          alertBoxCall(ALERT_BOX_TYPES.SUCCESS, FEED_LIST_ENROLL_ALREADY_EXIST);
        } else {
          locationsGroupList.forEach((item) => {
            if (item.id === parseInt(SSOFeedID)) {
              // dispatch(updateLocationsHubListResponse({ ...item, account_included: !item?.account_included }));
              handleSelectFeed(item, item?.platforms);
            }
            setShowSaveModal(true);
          });
          // dispatch(modifyLocationsHubListResponse(locationsGroupList));
        }
      }
    }, 3000);
  }, [locationsGroupList?.length, dispatch]); // eslint-disable-line

  const handleSelectFeed = useCallback(
    (rowObj: operationsListsApiTypes.IDataResponse, platforms: Record<string, boolean> | null) => {
      const feedListIndex = modifyLocationGroups.findIndex((location) => location.id === rowObj.id);
      const updatedModifiedLocationGroups = [...modifyLocationGroups];
      const accountIncludedStatus = !rowObj.account_included;

      const getDefaultPlatforms = () => ({
        use_facebook: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK),
        use_instagram: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM),
        use_twitter: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.TWITTER),
        use_linkedin: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN),
        use_google: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE),
        use_tiktok: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK),
        use_pinterest: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST)
      });

      if (feedListIndex > -1) {
        updatedModifiedLocationGroups[feedListIndex] = {
          ...rowObj,
          account_included: accountIncludedStatus,
          platforms: platforms || getDefaultPlatforms()
        };
      } else {
        updatedModifiedLocationGroups.push({
          ...rowObj,
          account_included: accountIncludedStatus,
          platforms: accountIncludedStatus ? getDefaultPlatforms() : null
        });
      }
      dispatch(modifyLocationsHubListResponse(updatedModifiedLocationGroups));
    },
    [modifyLocationGroups, activePlatforms, dispatch]
  );

  const manageSocialMedia = useCallback(
    (rowObj: operationsListsApiTypes.IDataResponse) => {
      dispatch(
        onChangeList({
          key: 'platforms',
          value: {
            use_facebook: rowObj?.platforms?.use_facebook ?? false,
            use_instagram: rowObj?.platforms?.use_instagram ?? false,
            use_twitter: rowObj?.platforms?.use_twitter ?? false,
            use_linkedin: rowObj?.platforms?.use_linkedin ?? false,
            use_google: rowObj?.platforms?.use_google ?? false,
            use_tiktok: rowObj?.platforms?.use_tiktok ?? false,
            use_pinterest: rowObj?.platforms?.use_pinterest ?? false
          }
        })
      );
      navigate({
        pathname: `/${userOwnership}/${id}/${currentRoute}/${optionalParams.join('/')}/${rowObj.id}/manage_accounts`,
        search: `?${new URLSearchParams(queryParams).toString()}`
      });
    },
    [currentRoute, id, optionalParams, queryParams, userOwnership, dispatch, navigate]
  );

  const renderHeader = useCallback(
    (headerObj: ITableHeader<operationsListsApiTypes.IDataResponse>) => {
      switch (headerObj.identifier) {
        case 'name':
          return <div>{isCSLocation ? 'Content Source' : headerObj.labelString}</div>;
        default:
          return <div>{headerObj.labelString}</div>;
      }
    },
    [isCSLocation]
  );

  const renderColumn = useCallback(
    (rowObj: operationsListsApiTypes.IDataResponse, header: ITableHeader<operationsListsApiTypes.IDataResponse>) => {
      const selectedModifiedList = modifyLocationGroups.find((location) => location.id === rowObj.id);
      switch (header.identifier) {
        case LIST_HEADER_IDENTIFIERS.CREATED_USER:
          return <div>{rowObj[header.identifier]?.name}</div>;
        case LIST_HEADER_IDENTIFIERS.FEED_TYPE:
          return (
            <div className="slide-switch">
              <Form.Check
                type="switch"
                id="custom-switch-make-public"
                checked={rowObj.feed_type === FEED_TYPE.STANDARD ? false : true}
                onClick={(event) => event.stopPropagation()}
                onChange={(e: any) => {
                  dispatch(updateLocationsHubListRequest({ ...rowObj, feed_type: e.target.checked ? FEED_TYPE.CONTENT_SUPPLIER : FEED_TYPE.STANDARD }));
                }}
              />
            </div>
          );
        case LIST_HEADER_IDENTIFIERS.MAKE_PUBLIC:
          return (
            <div className="slide-switch mpSwitch">
              <Form.Check
                type="switch"
                id="custom-switch-make-public"
                checked={Boolean(rowObj?.location_access)}
                onClick={(event) => event.stopPropagation()}
                onChange={() => {
                  dispatch(updateLocationsHubListRequest({ ...rowObj, location_access: !rowObj?.location_access }));
                }}
                className={Boolean(rowObj?.location_access) ? 'active' : ''}
              />
            </div>
          );
        case LIST_HEADER_IDENTIFIERS.ENROLL:
          const isChecked = selectedModifiedList ? Boolean(selectedModifiedList?.account_included) : Boolean(rowObj?.account_included);
          return (
            <div className="slide-switch">
              <Form.Check
                type="switch"
                id="custom-switch-make-public"
                checked={isChecked}
                onClick={(event) => event.stopPropagation()}
                onChange={() => {
                  // if (isFromAccountWizard && !isSSO) {
                  //   dispatch(subscriptionsLocationToListRequest({ account_id: id, account_list_id: rowObj?.id, account_included: !rowObj?.account_included, isFromAccountWizard }));
                  // } else {
                  // dispatch(updateLocationsHubListResponse({ ...rowObj, account_included: !rowObj?.account_included }));
                  // }
                  handleSelectFeed(selectedModifiedList ? selectedModifiedList : rowObj, rowObj?.platforms);
                }}
                className={isChecked ? 'active' : ''}
              />
            </div>
          );
        case LIST_HEADER_IDENTIFIERS.BUTTON:
          return !rowObj.locked ? (
            <Button className="plain-btn" variant="link">
              {deleteId === rowObj.id && isDeleteRequest ? (
                <Spinner animation="border" variant="danger" />
              ) : (
                <div className="rel-icons pb-icon">
                  <ImageValidation
                    isImgValid
                    defaultImg="delete-b-red"
                    customName="Delete"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setDeleteId(rowObj.id);
                      setShowModal(true);
                    }}
                  />
                </div>
              )}
            </Button>
          ) : (
            <></>
          );
        case LIST_HEADER_IDENTIFIERS.NAME:
          return (
            <div className={`lfsCreator fBadge-img-active`}>
              {userOwnership === USER_OWNERSHIP.FRANCHISOR ? (
                <ImageValidation isImgValid defaultImg={!rowObj?.locked ? '' : 'lockGrey'} customName={'Location Access'} customClassname="lockedItem" />
              ) : null}
              <span className="lfsName">{rowObj?.name} </span>
              {userOwnership === USER_OWNERSHIP.ACCOUNT && rowObj?.locked && rowObj?.preferred_partner ? (
                <CustomTooltip
                  customPlacement={TOOLTIP_PLACEMENT.TOP}
                  customTooltipText={'This is a Preferred Partner.'}
                  customClassname={'custom-tooltip-long-text r-tbl-header__tip contetn-an analytics-content-pg ai-tooltip-pp'}
                  customInput={() => (
                    <div className="feedLogoIcon">
                      {feedBadgeUrl ? (
                        <ImageValidation isImgValid imgUrl={feedBadgeUrl} isNotSvgFormat customName="Feed-Badge-Logo" customClassname="fblIcon-d" />
                      ) : (
                        <ImageValidation isImgValid defaultImg="feedBadge-preferred" customName="Preferred Partner" customClassname="fblIcon" />
                      )}
                    </div>
                  )}
                />
              ) : (
                ''
              )}
            </div>
          );
        case LIST_HEADER_IDENTIFIERS.ENROLLMENT_COUNT:
          return <div className="enrollCount">{rowObj[header.identifier]}</div>;
        case LIST_HEADER_IDENTIFIERS.PREFERRED_PARTNER:
          return rowObj.locked ? (
            <div className="slide-switch mpSwitch">
              <Form.Check
                type="switch"
                id="custom-switch-make-public"
                checked={Boolean(rowObj?.preferred_partner)}
                onClick={(event) => event.stopPropagation()}
                onChange={() => {
                  dispatch(updateLocationsHubListRequest({ ...rowObj, preferred_partner: !rowObj?.preferred_partner }));
                }}
                className={Boolean(rowObj?.preferred_partner) ? 'active' : ''}
              />
            </div>
          ) : null;

        case LIST_HEADER_IDENTIFIERS.PLATFORM:
          const isFeedListSubscribed = selectedModifiedList ? selectedModifiedList?.account_included : rowObj?.account_included;
          const socialMediaActivePlatforms = PLATFORM_FILTER.filter((platform) =>
            Boolean(activePlatforms.includes(platform.value) && (selectedModifiedList?.id ? selectedModifiedList.platforms?.[`use_${platform.value}`] : rowObj.platforms?.[`use_${platform.value}`]))
          );
          return (
            <div className="item-g filter cf-platform-col">
              <div className="icon-wrapper fltr-imc selectsocial">
                {isFeedListSubscribed && socialMediaActivePlatforms?.length
                  ? socialMediaActivePlatforms?.map((platform) => (
                      <button type="button" key={`social-media-icon-${platform.value}`} className={`roundedbtn btn btn-link`}>
                        <ImageValidation isImgValid defaultImg={platform.platformImg || null} customName={'Platform'} />
                      </button>

                      // <ImageValidation key={`social-media-icon-${platform.value}`} isImgValid defaultImg={platform.platformImg || null} customName={'Platform'} />
                    ))
                  : null}
              </div>
              <button
                className={
                  (selectedModifiedList ? selectedModifiedList?.account_included : rowObj?.account_included) && activePlatforms.length
                    ? 'txt-lined txt-line-blue'
                    : 'txt-lined txt-lite-grey pointer-events-none'
                }
                onClick={() => manageSocialMedia(selectedModifiedList?.id ? selectedModifiedList : rowObj)}
              >
                Manage
              </button>
            </div>
          );
        default:
          return <div>{rowObj[header.identifier]}</div>;
      }
    },
    [isDeleteRequest, deleteId, dispatch, handleSelectFeed, userOwnership, feedBadgeUrl, activePlatforms, manageSocialMedia, modifyLocationGroups]
  );

  const tableHeader = useMemo(() => {
    const filteredHeader = LIST_HEADER.filter((it) => {
      if (it.identifier === LIST_HEADER_IDENTIFIERS.FEED_TYPE) {
        return isContentSupplierFranchisor && userOwnership === USER_OWNERSHIP.FRANCHISOR;
      }
      if (userOwnership === USER_OWNERSHIP.FRANCHISOR) {
        return (
          ![LIST_HEADER_IDENTIFIERS.ENROLL, LIST_HEADER_IDENTIFIERS.PLATFORM].includes(it.identifier) && (it.identifier === LIST_HEADER_IDENTIFIERS.PREFERRED_PARTNER ? hasAdditionalColumns : true)
        );
      }
      const locationHeaders = [LIST_HEADER_IDENTIFIERS.NAME, LIST_HEADER_IDENTIFIERS.ENROLL];
      if (isContentSupplierFranchisor && userOwnership === USER_OWNERSHIP.ACCOUNT) {
        locationHeaders.push(LIST_HEADER_IDENTIFIERS.PLATFORM);
      }
      return locationHeaders.includes(it.identifier);
    });
    return filteredHeader.map(
      (it): ITableHeader<operationsListsApiTypes.IDataResponse> => ({
        identifier: `${it.identifier}`,
        labelString: `${it.labelString}`,
        headerClassName: `${it.headerClassName}`,
        label: <div>{it.labelString}</div>,
        renderHeader,
        renderColumn
      })
    );
  }, [renderColumn, renderHeader, userOwnership, isContentSupplierFranchisor, hasAdditionalColumns]);

  const handleSaveModalClose = () => {
    setShowSaveModal(false);
  };

  const saveFeedList = (isFreshStart: boolean = false) => {
    // const result = findChangedAttributes(modifyLocationGroups, OriginalLocationsGroupList);
    modifyLocationGroups.forEach((feed: any) => {
      dispatch(
        subscriptionsLocationToListRequest({
          account_id: id,
          account_list_id: feed.id,
          account_included: feed.account_included,
          platforms: feed.platforms
        })
      );
      if (SSOFeedID && feed.obj1?.id === parseInt(SSOFeedID)) localStorage.removeItem('SSOFeedID');
    });
    if (isFreshStart) {
      dispatch(accountCreateScheduledPostRequest({ userOwnership, id, params: { delete_current_posts: 1 } }));
    }
    dispatch(modifyLocationsHubListResponse([]));
    handleSaveModalClose();
  };
  const revertFeedList = () => {
    dispatch(modifyLocationsHubListResponse([]));
    // if (modifyLocationGroups.length) {
    // modifyLocationGroups.forEach((feed) => {
    //   const selectedLocationGroupList = locationsGroupList.find((it) => it.id === feed.id);
    //   dispatch(updateLocationsHubListResponse({ ...feed, account_included: !feed?.account_included, platforms: selectedLocationGroupList?.platforms || null }));
    // });
    // }
    handleSaveModalClose();
  };

  return (
    <div className={`tml-main feed-table tml-list lpx pt-20 mbl-head--margin cfTableData ${locationsHubList.length === 0 ? 'csEdit' : 'resuseTable'}`}>
      <VirtualTable<operationsListsApiTypes.IDataResponse>
        data={[locationsHubList]}
        headers={tableHeader}
        tableClassName="responsiveTable table-cmn--skeleton"
        ipadBoundary={767}
        ipadColCount={2}
        mobileColCount={2}
        pinColumnsRight={[4]}
        isFetching={isFetching || isTreeFetching || isSaveRequest}
        onRowClick={(row) => {
          if (row && !row.locked && userOwnership === USER_OWNERSHIP.FRANCHISOR) {
            navigate({ pathname: `/${userOwnership}/${id}/${currentRoute}/${row.id}/step-1` });
          }
        }}
        scrollableTargetString={optionalParams[2] === CONNECTION_TABS.FEED ? 'scrollTargetAccountWizard' : 'scrollTarget'}
        additionalColumnTitle={`${showAdditionalColumns ? 'Hide' : 'Show'} ${userOwnership === USER_OWNERSHIP.FRANCHISOR ? 'Content Supplier(s)' : 'Other Content Supplier(s)'}`}
        hasAdditionalColumns={hasAdditionalColumns}
        handleShowAdditionalColumns={() => {
          setShowAdditionalColumns((prev) => !prev);
        }}
      />

      {!isFromAccountWizard && modifyLocationGroups?.length ? (
        <div className="stg-item form-configure r-mt4">
          <div className="fc-actions">
            <CustomRippleButton rippleClass="ripple-unset ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
              <button className={`ac-btn ac-secondary-white border-0 ac-outline size-sm`} onClick={() => revertFeedList()}>
                Cancel
              </button>
            </CustomRippleButton>
            <CustomRippleButton rippleClass="ripple-unset ac-primary-box" custColor={RIPPLE_COLOR.primary}>
              <button className={`ac-btn ac-primary size-sm`} type="submit" onClick={() => setShowSaveModal(true)}>
                Save
              </button>
            </CustomRippleButton>
          </div>
        </div>
      ) : null}
      {[optionalParams?.[1], optionalParams?.[4]].includes('manage_accounts') && <TMListManageSocialMediaModal />}

      <DeleteAssetModalPopup
        showModal={showModal}
        handleModalClose={() => {
          setShowModal(false);
        }}
        handleDelete={() => {
          if (deleteId) {
            dispatch(deleteLocationListRequest({ id: deleteId }));
            setShowModal(false);
          }
        }}
      />
      {showSaveModal && (
        <ModalPopup
          isModalShow={showSaveModal}
          containerClassName={`modal-confirmation confirm-delete ${showSaveModal ? `feed-ssm-on` : `feed-ssm-off`}`}
          modalHeaderType={MODAL_BOX_TYPES.INFO}
          modalBody={() => (
            <>
              <div className="edit-approval-post-wrp-modal">
                <div className="modal-message-wraps-text">{SUBSCRIBE_FEED_LIST_CONFIRMATION_MSG}</div>
              </div>
              <br />
              <div className="fs-wrap">
                {subscribedList.length ? (
                  <div className="feed-subscribed-list">
                    <p>
                      You will be <b>subscribed</b> to the following feeds:
                    </p>
                    {subscribedList.map((item, index) => (
                      <p className="feed-subscribed" key={`subscribedList-${index}`}>
                        {item.name}
                      </p>
                    ))}
                  </div>
                ) : null}
                {unSubscribedList.length ? (
                  <div className="feed-subscribed-list">
                    <p>
                      You will be <b>unsubscribed</b> from the following feeds:
                    </p>
                    {unSubscribedList.map((item, index) => (
                      <p className="feed-unsubscribed" key={`unSubscribedList-${index}`}>
                        {item.name}
                      </p>
                    ))}
                  </div>
                ) : null}
                {platformModifiedList.length ? (
                  <div className="feed-subscribed-list">
                    <p>
                      You have made <b>changes</b> to following feeds:
                    </p>
                    {platformModifiedList.map((item, index) => (
                      <p key={`platformModifiedList-${index}`}>{item.name}</p>
                    ))}
                  </div>
                ) : null}
              </div>
            </>
          )}
          modalCloseButton
          handleModalClose={handleSaveModalClose}
          isModalFooterShow
          modalFooter={() => {
            return (
              <div className="modal-btn-grp-wraps">
                <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.secondary}>
                  <button className={`ac-btn ac-secondary ac-block modal-btn-action-itm modal-ok-btn fssm-sb`} onClick={() => saveFeedList()}>
                    <span>Apply changes in 30 days</span>
                  </button>
                </CustomRippleButton>

                <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
                  <button className={`ac-btn ac-primary ac-block modal-btn-action-itm modal-ok-btn fssm-pb`} onClick={() => saveFeedList(true)}>
                    <span>Start now</span>
                  </button>
                </CustomRippleButton>

                <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.whiteGrey}>
                  <button className={`ac-btn ac-primary-white ac-outline modal-btn-action-itm modal-ok-btn fssm-cancel`} onClick={() => revertFeedList()}>
                    <span>Cancel</span>
                  </button>
                </CustomRippleButton>
              </div>
            );
          }}
        />
      )}
    </div>
  );
};
