import { ModalPopup } from 'components';
import { commonModalPopup } from 'types';
import { MODAL_BOX_TYPES, RIPPLE_COLOR } from 'utils/constants';
import { CustomRippleButton } from 'widgets/CustomRippleButton';

export const ScheduleSlotDeleteConfirmationModal = ({ isModalShow, handleModalClose, handleModalCancel }: commonModalPopup.IDeleteScheduleSlotProps) => {
  return (
    <ModalPopup
      isModalShow={isModalShow}
      modalHeaderType={MODAL_BOX_TYPES.DANGER}
      containerClassName={`cnfrm__delete--modal glbl__delete--modal package__delete--modal ecsModal`}
      modalBody={() => (
        <div>
          <div className="modal-message-wraps">Are you sure you want to permanently delete this scheduled slot?</div>
          <div className="createpost-wrp-new-section">
            <div className="form-check">
              <label className={`checkbox-item`}>
                <div className="label-txt">Deleting this schedule will also remove all future posts in your calendar scheduled for these times.</div>
              </label>
            </div>
          </div>
          {/* RAS-5553 hided the unused checkbox */}
          {/* <div className="createpost-wrp-new-section">
            <div className="form-check">
              <label className={`checkbox-item`}>
                <span className="checkbox-hover">
                  <input type="checkbox" className="option-input click-wave" name={'remove_from_platform'} defaultChecked disabled />
                </span>
                <div className="label-txt">Remove this scheduled slot from my platform</div>
                <span className={'checkmark'} />
              </label>
            </div>
            <div className="form-check">
              <label className={`checkbox-item`}>
                <span className="checkbox-hover">
                  <input type="checkbox" className="option-input click-wave" name={'remove_posts'} />
                </span>
                <div className="label-txt">Remove all currently scheduled posts associated with this scheduled slot</div>
                <span className={'checkmark'} />
              </label>
            </div>
          </div> */}
        </div>
      )}
      modalCloseButton
      handleModalClose={() => handleModalCancel()}
      isModalFooterShow
      modalFooter={() => (
        <div className="delete__btn--wrp">
          <CustomRippleButton rippleClass="ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
            <button className="ac-btn ac-secondary-white ac-outline ac-block border-0" onClick={handleModalCancel}>
              Cancel
            </button>
          </CustomRippleButton>
          <CustomRippleButton rippleClass={`ac-danger-box`} custColor={RIPPLE_COLOR.danger}>
            <button className={`ac-btn ac-danger ac-block`} onClick={handleModalClose}>
              Delete
            </button>
          </CustomRippleButton>
        </div>
      )}
    />
  );
};
