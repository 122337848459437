import { Tag } from 'react-tag-autocomplete';
import { Dispatch } from 'react';
import { NavigateFunction } from 'react-router-dom';

import { apAccountReducerTypes, apFranContainerTypes, loginReducerType, mgApiTypes, mgContainerTypes, mgReducerTypes } from 'types';
import {
  USER_OWNERSHIP,
  APPROVED_STATUS,
  CreatorMediaTypeForUser,
  MG_MEDIA_TYPE,
  MgSourceType,
  MG_CLOUD_MEDIA_TYPE,
  COMMON_SOCIAL_PROVIDER_TYPE,
  AP_INIT_EDITOR_STATE_FOR_CUSTOM_TEXT,
  AP_SAVED_POST_INIT_DETAILS,
  UTC_TIMESTAMP_FORMAT,
  ALERT_BOX_TYPES,
  COMMON_PAGE_TYPE,
  MG_FILE_SIZE_EXCEEDED_ERROR_MSG,
  MG_MAX_VIDEO_SIZE,
  MG_UNSUPPORTED_FILE_TYPE_MSG,
  MgMediaType,
  MediaDropPages,
  AI_PLAYBOOK_UPLOAD_PDF_DOCUMENT_MSG,
  MG_MAX_DOCUMENT_SIZE,
  MG_PROVIDER_TYPE
} from 'utils/constants';
import { convertStringToEditorState } from '../common';
import { getContentTextWithMaximumLimit } from '../common-helpers';
import { dateFormatByRequestedFormat, dateObjBasedOnTimeZone, getCurrentDate, getUTCForCurrentTime } from '../date-helper';
import { aiPlaybookSetDocUploadType, cloudSignRequest, mgSetAddedAssetsCount, mgSetUploadProgressInfo, mgUploadCloudMediaRequest, mgs3UploadCloudMediaRequest } from 'actions';
import { alertBoxCall } from 'components';
import { ICommonObj } from 'types/common/common-props';

/** Compare 2 objects and returns TRUE is objects are same */
export const compareTwoObjects = (obj1: any, obj2: any) => {
  const isObject = JSON.stringify(obj1) === JSON.stringify(obj2);
  return isObject;
};

/** Media Gallery - Get add asset request filters */
export const addAssetReqParams = ({
  cloudinaryResponse,
  createdUserId,
  createdUserName,
  id,
  userOwnership,
  mgAvailMediaForUser,
  isImgLockPost,
  currentName,
  topLevelFranchisorId,
  topLevelFranchisor,
  cloudinaryDataResponse,
  optionalReqParams,
  customizeThumbnail,
  mediaReleases,
  isAIComplianceValidated
}: mgContainerTypes.IMGAddAssetReqArg): mgContainerTypes.IMGAddAssetReqParams => {
  return {
    ...(cloudinaryResponse?.cloudinaryId && { cloudinary_data: cloudinaryDataResponse || null }),
    ...(cloudinaryResponse?.cloudinaryId && { cloudinary_id: cloudinaryResponse.cloudinaryId || null }),
    comments: '',
    copy_to_descendants: mgAvailMediaForUser === CreatorMediaTypeForUser.EVERYONE ? true : false,
    name: cloudinaryResponse.fileName,
    source: '',
    tags_list: cloudinaryResponse.tags || '',
    trashed: false,
    verticals_list: '',
    account_id: userOwnership === USER_OWNERSHIP.ACCOUNT ? id : null,
    account_name: userOwnership === USER_OWNERSHIP.ACCOUNT ? currentName : null,
    approved: false,
    brand_id: topLevelFranchisorId ? topLevelFranchisorId : null,
    brand_name: topLevelFranchisor ? topLevelFranchisor : null,
    created_by_user_name: createdUserName,
    created_by_user_id: createdUserId,
    franchisor_id: userOwnership === USER_OWNERSHIP.FRANCHISOR ? id : null,
    franchisor_name: userOwnership === USER_OWNERSHIP.FRANCHISOR ? currentName : null,
    md5: cloudinaryResponse.eTag || null,
    medium_size_url: null,
    original_franchisor_id: null,
    original_franchisor_name: '',
    produced_by: null,
    transformations: { enhance: false },
    url: cloudinaryResponse.secureUrl || null,
    quality_score: cloudinaryDataResponse?.quality_score || null,
    used_count: null,
    provider: cloudinaryResponse?.s3_md5 ? MG_PROVIDER_TYPE.S3 : MG_PROVIDER_TYPE.CLOUDINARY,
    ...(isAIComplianceValidated && { ai_compliance_validated_at: getUTCForCurrentTime() }),
    ...(cloudinaryResponse.mediaType === MG_MEDIA_TYPE.PHOTO && { locked: isImgLockPost }),
    // ...(cloudinaryResponse.mediaType === MG_MEDIA_TYPE.PHOTO && { focus_score: cloudinaryResponse.qualityAnalysis?.focus }),
    ...(cloudinaryResponse?.s3_md5 && { s3_md5: cloudinaryResponse?.s3_md5 }),
    ...(cloudinaryResponse?.mediaType === MG_MEDIA_TYPE.VIDEO && {
      cover_cloudinary_id: customizeThumbnail?.[0]?.cloudinaryId ? customizeThumbnail?.[0]?.cloudinaryId : cloudinaryResponse?.cover_cloudinary_id
    }),
    ...(customizeThumbnail?.[0]?.secureUrl && { thumbnail_url: customizeThumbnail?.[0]?.secureUrl }),
    ...(mediaReleases?.length && { media_releases: mediaReleases }),
    ...optionalReqParams
  };
};

/** Media Gallery - Update favourite tags based on editing tags */
export const getUpdatedMGFavTagsList = (tagsList: mgReducerTypes.IMediaGalleryTagsData[], tagName: string | number) => {
  return tagsList.reduce((acc: mgReducerTypes.IMediaGalleryTagsData[], curr) => {
    if (curr.name) {
      acc = [...acc, { ...curr, favourite: curr.name === tagName ? !curr.favourite : curr.favourite }];
    }
    return acc;
  }, []);
};

/** Get current franchisor id, if account -> then get immediate parent franchisor id */
export const getCurrentFranchisorId = (id: null | number, userOwnership: string | null, topLevelFranchisorId: number) => {
  let franchisorId: null | number = null;
  if (userOwnership === USER_OWNERSHIP.ACCOUNT) franchisorId = topLevelFranchisorId || null;
  else if (userOwnership === USER_OWNERSHIP.FRANCHISOR) franchisorId = id || null;
  return franchisorId;
};

/** Get Approved status - based on likes */
export const getApprovedStatus = (likedStatus?: null | boolean) => {
  let status = '';
  if (likedStatus === true) status = APPROVED_STATUS.YES;
  else if (likedStatus === false) status = APPROVED_STATUS.NO;
  else status = APPROVED_STATUS.NOT_YET;
  return status;
};

/** Generate selectedTags list in string */
export const getTagsListInString = (selectedTags: Tag[]) => {
  let tagsList = '';
  if (selectedTags && selectedTags.length) {
    selectedTags.forEach((tagItem) => (tagsList = `${tagsList}${tagsList === '' ? '' : ','}${tagItem.label}`));
  }
  return tagsList;
};

export const mgProvidedBy = (mgDetails: null | mgApiTypes.IMGListResponseObj) => {
  /*  if (mgDetails?.source === MgSourceType.LOCATION) {
    return mgDetails?.created_by_user_name || '';
  } else {
    if (mgDetails?.brand_name) {
      return mgDetails?.brand_name || '';
    } else {
      return mgDetails?.produced_by || '';
    }
  } */
  return mgDetails?.created_by_user_name || '';
};

export const mgProvidedById = (mgDetails: null | mgApiTypes.IMGListResponseObj) => {
  if (mgDetails?.source === MgSourceType.LOCATION) {
    return mgDetails?.created_by_user_id || 0;
  } else {
    if (mgDetails?.brand_id) {
      return mgDetails.brand_id || 0;
    } else {
      return mgDetails?.franchisor_id || 0;
    }
  }
};

export const mgLinkAccessProvided = (activeUser: null | loginReducerType.ILoginResponseUser, mgDetails: null | mgApiTypes.IMGListResponseObj) => {
  if (activeUser?.admin) {
    return true;
  } else if (activeUser?.franchisors_managed && activeUser.franchisors_managed.filter((e) => e.id === mgProvidedById(mgDetails)).length) {
    return true;
  } else {
    return false;
  }
};

export const mgLinkAccess = (activeUser: null | loginReducerType.ILoginResponseUser, mgDetails: null | mgApiTypes.IMGListResponseObj) => {
  const franchiseNumber = parseInt(String(mgDetails?.franchisor_id || 0), 10);

  if (activeUser?.admin) {
    return true;
  } else if (activeUser?.franchisors_managed?.length) {
    if (mgDetails?.source === MgSourceType.BRAND) {
      if (activeUser.franchisors_managed.filter((e) => e.id === franchiseNumber).length) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const mgAccountEditEnabled = (currentAccountId: null | number, mediaAccountId: null | number, mgDetails: null | mgApiTypes.IMGListResponseObj) => {
  if (currentAccountId === mediaAccountId && mgDetails?.franchisor_id && mgDetails?.active) return false;
  else if (currentAccountId === mediaAccountId) return true;
  else return false;
};

export const mgFranchisorEditEnabled = (mgDetails: null | mgApiTypes.IMGListResponseObj) => {
  if (mgDetails?.account_id || (mgDetails?.franchisor_id && mgDetails?.franchisor_id === mgDetails?.original_franchisor_id)) {
    return true;
  } else {
    return false;
  }
};

export const mgEditEnabled = (userOwnership: string, mgDetails: null | mgApiTypes.IMGListResponseObj, currentAccountId: null | number) => {
  return userOwnership === USER_OWNERSHIP.FRANCHISOR ? mgFranchisorEditEnabled(mgDetails) : mgAccountEditEnabled(currentAccountId, mgDetails?.account_id || null, mgDetails);
};

export const mgMediaAssetLikeDislikeFeature = (userOwnership: string, franMediaAssetLikeDislikeFeature?: null | boolean, accMediaAssetLikeDislikeFeatureEnabled?: null | boolean) => {
  return userOwnership === USER_OWNERSHIP.FRANCHISOR ? franMediaAssetLikeDislikeFeature !== false : accMediaAssetLikeDislikeFeatureEnabled || false;
};

export const mgAvailabilityModalMessage = (mediaType: string) => {
  return `Are you sure to make this ${mediaType === MG_MEDIA_TYPE.PHOTO ? MG_CLOUD_MEDIA_TYPE.IMAGE : MG_CLOUD_MEDIA_TYPE.VIDEO} available for everyone else?`;
};

export const getUseRssContentData = (activePlatforms: string[], mediaComments: string): apFranContainerTypes.IUseMediaContentData => {
  const isFacebook = activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK);
  const isTwitter = activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.TWITTER);
  const isInstagram = activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM);
  const isLinkedin = activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN);
  const isTiktok = activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK);
  const isGoogle = activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE);
  const isPinterest = activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST);
  return {
    facebook_text: isFacebook ? mediaComments : '',
    twitter_text: isTwitter ? getContentTextWithMaximumLimit(mediaComments, COMMON_SOCIAL_PROVIDER_TYPE.TWITTER) : '',
    instagram_text: isInstagram ? getContentTextWithMaximumLimit(mediaComments, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM) : '',
    linkedin_text: isLinkedin ? getContentTextWithMaximumLimit(mediaComments, COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN) : '',
    tiktok_text: isTiktok ? getContentTextWithMaximumLimit(mediaComments, COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK) : '',
    pinterest_text: isPinterest ? getContentTextWithMaximumLimit(mediaComments, COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST) : '',
    google_text: isGoogle ? mediaComments : '',
    use_facebook: isFacebook,
    use_twitter: isTwitter,
    use_instagram: isInstagram,
    use_linkedin: isLinkedin,
    use_tiktok: isTiktok,
    use_google: isGoogle,
    use_pinterest: isPinterest
  };
};

export const getUseMediaContentData = (activePlatforms: string[], mediaComments: string): apFranContainerTypes.IUseMediaContentData => {
  const activePlatform = activePlatforms[0];
  return {
    ...(activePlatform === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && { facebook_text: mediaComments }),
    ...(activePlatform === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER && { twitter_text: getContentTextWithMaximumLimit(mediaComments, COMMON_SOCIAL_PROVIDER_TYPE.TWITTER) }),
    ...(activePlatform === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && { instagram_text: getContentTextWithMaximumLimit(mediaComments, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM) }),
    ...(activePlatform === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN && { linkedin_text: getContentTextWithMaximumLimit(mediaComments, COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN) }),
    ...(activePlatform === COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK && { tiktok_text: mediaComments }),
    ...(activePlatform === COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST && { pinterest_text: getContentTextWithMaximumLimit(mediaComments, COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST) }),
    ...(activePlatform === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE && { google_text: mediaComments }),
    ...(activePlatform === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && { use_facebook: true }),
    ...(activePlatform === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER && { use_twitter: true }),
    ...(activePlatform === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && { use_instagram: true }),
    ...(activePlatform === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN && { use_linkedin: true }),
    ...(activePlatform === COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK && { use_tiktok: true }),
    ...(activePlatform === COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST && { use_pinterest: true }),
    ...(activePlatform === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE && { use_google: true })
  };
};

export const getUseMediaSavedPostData = (activePlatforms: string[], mediaComments: string, accountTzInfoName: string): apAccountReducerTypes.IAPSavedPostDetails => {
  const activePlatform = activePlatforms[0];

  return {
    ...AP_SAVED_POST_INIT_DETAILS,
    current_tab: activePlatforms[0],
    ...(activePlatform === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && { use_facebook: true }),
    ...(activePlatform === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && { use_instagram: true }),
    ...(activePlatform === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER && { use_twitter: true }),
    ...(activePlatform === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN && { use_linkedin: true }),
    ...(activePlatform === COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK && { use_tiktok: true }),
    ...(activePlatform === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE && { use_google: true }),
    ...(activePlatform === COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST && { use_pinterest: true }),
    ...(activePlatform === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && { facebook_text: mediaComments }),
    ...(activePlatform === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER && { twitter_text: getContentTextWithMaximumLimit(mediaComments, COMMON_SOCIAL_PROVIDER_TYPE.TWITTER) }),
    ...(activePlatform === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN && { linkedin_text: getContentTextWithMaximumLimit(mediaComments, COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN) }),
    ...(activePlatform === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && { instagram_text: getContentTextWithMaximumLimit(mediaComments, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM) }),
    ...(activePlatform === COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST && { pinterest_text: getContentTextWithMaximumLimit(mediaComments, COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST) }),
    ...(activePlatform === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE && { google_text: mediaComments }),
    schedule_date_time: dateObjBasedOnTimeZone(accountTzInfoName),
    account_schedule_date_time: dateObjBasedOnTimeZone(accountTzInfoName)
  };
};

// RAS-3889 -> creator page will be displayed with the selected link -> only first active platform to be highlighted
export const getUseRssContentTextField = (activePlatforms: string[], mediaComments: string): apFranContainerTypes.ICreatorEditorStateTextObj => {
  const activePlatform = activePlatforms[0];
  return {
    ...AP_INIT_EDITOR_STATE_FOR_CUSTOM_TEXT,
    ...(activePlatform.includes(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK) && { facebook: convertStringToEditorState(mediaComments) }),
    ...(activePlatform.includes(COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM) && {
      instagram: convertStringToEditorState(getContentTextWithMaximumLimit(mediaComments, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM))
    }),
    ...(activePlatform.includes(COMMON_SOCIAL_PROVIDER_TYPE.TWITTER) && { twitter: convertStringToEditorState(getContentTextWithMaximumLimit(mediaComments, COMMON_SOCIAL_PROVIDER_TYPE.TWITTER)) }),
    ...(activePlatform.includes(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN) && {
      linkedin: convertStringToEditorState(getContentTextWithMaximumLimit(mediaComments, COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN))
    }),
    ...(activePlatform.includes(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE) && { google: convertStringToEditorState(mediaComments) })
  };
};

export const getUseMediaContentTextField = (activePlatforms: string[], mediaComments: string): apFranContainerTypes.ICreatorEditorStateTextObj => {
  const activePlatform = activePlatforms[0];
  return {
    ...AP_INIT_EDITOR_STATE_FOR_CUSTOM_TEXT,
    ...(activePlatform.includes(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK) && { facebook: convertStringToEditorState(mediaComments) }),
    ...(activePlatform.includes(COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM) && {
      instagram: convertStringToEditorState(getContentTextWithMaximumLimit(mediaComments, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM))
    }),
    ...(activePlatform.includes(COMMON_SOCIAL_PROVIDER_TYPE.TWITTER) && { twitter: convertStringToEditorState(getContentTextWithMaximumLimit(mediaComments, COMMON_SOCIAL_PROVIDER_TYPE.TWITTER)) }),
    ...(activePlatform.includes(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN) && {
      linkedin: convertStringToEditorState(getContentTextWithMaximumLimit(mediaComments, COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN))
    }),
    ...(activePlatform.includes(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE) && { google: convertStringToEditorState(mediaComments) })
  };
};

export const uploadFileToCloud = async (props: {
  acceptedFiles: File[];
  response: mgReducerTypes.IMGCloudinaryDataObj[];
  addedAssetsCount: number;
  from: string;
  dispatch: Dispatch<any>;
  aiPlaybookDocType?: string | null;
  id?: number;
  userOwnership?: string;
  currentRoute?: string;
  queryParams?: ICommonObj<string>;
  optionalParams?: string[];
  navigate?: NavigateFunction;
  videoCloudinaryId?: string | null;
}) => {
  const {
    acceptedFiles,
    response,
    addedAssetsCount,
    from = MediaDropPages.MEDIA_GALLERY,
    dispatch,
    aiPlaybookDocType,
    id,
    userOwnership,
    currentRoute,
    queryParams,
    optionalParams,
    videoCloudinaryId,
    navigate
  } = props;
  const awsUploadFiles = acceptedFiles.filter((file) => ![MG_CLOUD_MEDIA_TYPE.IMAGE, MG_CLOUD_MEDIA_TYPE.VIDEO].includes(file.type.split('/')[0]) && file.size > MG_MAX_DOCUMENT_SIZE);
  const cloudinaryFiles = acceptedFiles.filter((file) => !(![MG_CLOUD_MEDIA_TYPE.IMAGE, MG_CLOUD_MEDIA_TYPE.VIDEO].includes(file.type.split('/')[0]) && file.size > MG_MAX_DOCUMENT_SIZE));
  const cloudinaryFilesName = cloudinaryFiles.map((file) => file.name);
  // const awsFilesName = awsUploadFiles.map((file) => file.name);
  const startTime = getCurrentDate().getTime();
  const currentUnixTimestamp = dateFormatByRequestedFormat(getCurrentDate(), UTC_TIMESTAMP_FORMAT);

  if (aiPlaybookDocType) {
    dispatch(aiPlaybookSetDocUploadType(aiPlaybookDocType ?? ''));
  }
  if (awsUploadFiles.length) {
    awsUploadFiles.forEach(async (file) => {
      const fileName = file?.name.substring(0, file?.name.lastIndexOf('.'));
      const extension = file?.name?.split('.').pop();
      const formData = new FormData();
      const key = `${fileName}_${currentUnixTimestamp}.${extension}`;
      formData.append('file', file);
      formData.append('isRevvUpload', 'false');
      formData.append('key', key);
      if (optionalParams && optionalParams[1] !== COMMON_PAGE_TYPE.NEW && id && navigate) {
        navigate({
          pathname: `/${userOwnership}/${id.toString()}/${currentRoute}/${MgMediaType.DOCUMENTS}/${COMMON_PAGE_TYPE.NEW}`,
          search: queryParams ? `?${new URLSearchParams(queryParams).toString()}` : ``
        });
      }

      dispatch(
        mgs3UploadCloudMediaRequest({
          filename: `${fileName}_${currentUnixTimestamp}.${extension}`,
          payload: formData,
          isRevv: false,
          onUploadProgress: (progressEvent: any) => {
            dispatch(mgSetUploadProgressInfo(calcProgressInfo(progressEvent, startTime)));
          }
        })
      );
    });

    dispatch(mgSetAddedAssetsCount(addedAssetsCount + awsUploadFiles.length));
  }
  if (cloudinaryFiles.length && currentUnixTimestamp) {
    dispatch(
      cloudSignRequest({
        unixTimestamp: +currentUnixTimestamp!,
        onSignatureCallback: (cloudSign) => {
          if (cloudSign.timestamp && cloudSign.apikey && cloudSign.signature && cloudSign.eager.length) {
            const fileUploadErrors: string[] = [];
            cloudinaryFiles.forEach(async (selectedFile) => {
              const selectedFileType = selectedFile.type.split('/')[0];
              if (selectedFileType === MG_CLOUD_MEDIA_TYPE.VIDEO && selectedFile.size > MG_MAX_VIDEO_SIZE) {
                alertBoxCall(ALERT_BOX_TYPES.ERROR, MG_FILE_SIZE_EXCEEDED_ERROR_MSG);
              } else {
                if (!!response.find((it: any) => it.fileName === selectedFile.name)) {
                  fileUploadErrors.push(selectedFile.name);
                } else {
                  if (optionalParams && optionalParams[1] !== COMMON_PAGE_TYPE.NEW && id && navigate) {
                    navigate({
                      pathname: `/${userOwnership}/${id.toString()}/${currentRoute}/${
                        selectedFileType === MG_CLOUD_MEDIA_TYPE.IMAGE ? MgMediaType.PHOTOS : selectedFileType === MG_CLOUD_MEDIA_TYPE.VIDEO ? MgMediaType.VIDEOS : MgMediaType.DOCUMENTS
                      }/${COMMON_PAGE_TYPE.NEW}`,
                      search: queryParams ? `?${new URLSearchParams(queryParams).toString()}` : ``
                    });
                  }
                  dispatch(mgSetAddedAssetsCount(addedAssetsCount + cloudinaryFiles.length));
                  dispatch(
                    mgUploadCloudMediaRequest({
                      timestamp: cloudSign.timestamp,
                      apiKey: cloudSign.apikey,
                      signature: cloudSign.signature,
                      eager: cloudSign.eager.join(),
                      file: selectedFile,
                      fileName: selectedFile.name,
                      uploadProgressCallback: (progressEvent: ProgressEvent, index: number, noOfChunks: number) => {
                        dispatch(mgSetUploadProgressInfo(calcProgressInfo(progressEvent, startTime, noOfChunks, index)));
                      },
                      order: cloudinaryFilesName,
                      from,
                      videoCloudinaryId
                    })
                  );
                }
              }
            });
            if (fileUploadErrors?.length > 0) {
              alertBoxCall(
                ALERT_BOX_TYPES.ERROR,
                `${fileUploadErrors?.length > 1 ? `The Following Files ${fileUploadErrors.join(',')} are already uploaded.` : `The Following ${fileUploadErrors[0]} File is already uploaded.`}`
              );
            }
          }
        }
      })
    );
  }
  if (!acceptedFiles.length) {
    alertBoxCall(ALERT_BOX_TYPES.ERROR, ([MediaDropPages.MEDIA_GALLERY, MediaDropPages.CREATOR] as string[]).includes(from) ? MG_UNSUPPORTED_FILE_TYPE_MSG : AI_PLAYBOOK_UPLOAD_PDF_DOCUMENT_MSG);
  }
};

export const s3Response = (fileName: string, url: string, id: string): mgApiTypes.IMGUploadCloudMediaRespData => {
  return {
    asset_id: '',
    public_id: '',
    version: 0,
    version_id: '',
    signature: '',
    width: 0,
    height: 0,
    format: MG_CLOUD_MEDIA_TYPE.RAW,
    resource_type: MG_CLOUD_MEDIA_TYPE.RAW,
    created_at: '',
    tags: [],
    pages: 0,
    bytes: 0,
    type: '',
    etag: '',
    placeholder: false,
    url,
    secure_url: url,
    folder: '',
    access_mode: '',
    info: null,
    image_metadata: null,
    colors: [],
    predominant: null,
    phash: '',
    illustration_score: 0,
    semi_transparent: false,
    grayscale: false,
    quality_analysis: {},
    quality_score: 0,
    original_filename: fileName,
    eager: [],
    api_key: '',
    s3_md5: JSON.parse(id),
    provider: MG_PROVIDER_TYPE.S3
  };
};

export const calcProgressInfo = (progressEvent: ProgressEvent, startTime: number, noOfChunks?: number, index?: number) => {
  const completedChunksPerc = noOfChunks && index ? Math.floor((index * 100) / noOfChunks) : 0;
  const uploadSpeed = progressEvent.loaded - (getCurrentDate().getTime() - startTime) / 1000;
  return {
    percentage: Math.floor(completedChunksPerc + ((progressEvent.loaded / progressEvent.total) * 100) / (noOfChunks || 1)),
    remainingTime: ((progressEvent.total - progressEvent.loaded) / uploadSpeed).toFixed()
  };
};
