import { useEffect, useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  filterStatus,
  getFranchisorDetailsRequest,
  socialContentsListRequest,
  socialContentsResetAll,
  socialContentsIsStatsCall,
  socialContentsStatsRequest,
  settingsGetHashTagsListRequest
} from 'actions';
import { IStore } from 'types';
import { useAccountSwitcherData, useNavBarData, useParamsDeconstructor } from 'utils/hooks';
import { scListRequestPayload, scStatsRequestPayload, scrollToTop, stringifyValidQueryParams, weekOrMonthBeforeTodayFilter } from 'utils/helpers';
import {
  NoDataFoundProps,
  SCROLL_THRESHOLD,
  SIDE_NAV_NAME,
  SC_FILTER_INIT_VALUE,
  FeedValue,
  SettingsHashtagsActionsVal,
  SETTINGS_HASHTAG_ACTIONS,
  SettingsHashtagsActionsIdentifier,
  USER_OWNERSHIP
} from 'utils/constants';
import { Loading, NoDataFound, SCList, SCStats } from 'components';

export const SocialContents = () => {
  const dispatch = useCallback(useDispatch(), []); // eslint-disable-line
  const { subNavPageName } = useNavBarData();
  const { id, userOwnership, isValidId } = useAccountSwitcherData();

  const dateRange = weekOrMonthBeforeTodayFilter(FeedValue.INBOX);
  const initialFilterValue = { ...SC_FILTER_INIT_VALUE, dateRange };
  const { filter, queryParams } = useParamsDeconstructor(initialFilterValue);

  const topLevelFranchisorId = useSelector((state: IStore) => state.accountSwitcher.topLevelFranchisorId);
  const isStatsCall = useSelector((state: IStore) => state.socialContents.isStatsCall);
  const currentPageCount = useSelector((state: IStore) => state.socialContents.currentPageCount);
  const isListFetching = useSelector((state: IStore) => state.socialContents.isListFetching);
  const socialContentsList = useSelector((state: IStore) => state.socialContents.socialContentsList);
  const pieReloadStatus = useSelector((state: IStore) => state.socialContents.pieReloadStatus);
  const automaticallyRefreshActivityFeed = useSelector((state: IStore) => state.accounts?.accountDetails?.account?.features?.automatically_refresh_activity_feed || false);
  const hashTagsState = useSelector((state: IStore) => state.settingsHashTags.hashTagsState);

  const interval = useRef<NodeJS.Timer | null>(null);

  const validQueryParams = stringifyValidQueryParams(queryParams);

  const dateRangeArr = useMemo(() => {
    return [filter.dateRange.fromDate.timeStamp || '', filter.dateRange.toDate.timeStamp || ''];
  }, [filter.dateRange]);

  useEffect(() => {
    scrollToTop();
    dispatch(filterStatus(true));
    return () => {
      dispatch(filterStatus(false));
      dispatch(socialContentsResetAll());
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [dispatch]); // eslint-disable-line

  useEffect(() => {
    if (id && userOwnership && isValidId && subNavPageName === SIDE_NAV_NAME.COMMUNITY_SANDBOX) {
      dispatch(getFranchisorDetailsRequest({ id, topLevelFranchisorId, userOwnership }));
    }
  }, [id, userOwnership, isValidId, topLevelFranchisorId, subNavPageName, dispatch]);

  useEffect(() => {
    if (pieReloadStatus) {
      dispatch(socialContentsIsStatsCall(true));
      handleSCListRequest(1);
    }
  }, [pieReloadStatus, dispatch]); // eslint-disable-line

  useEffect(() => {
    if (id && userOwnership && isValidId && isStatsCall) {
      dispatch(socialContentsStatsRequest(scStatsRequestPayload({ id, userOwnership, dateRange: dateRangeArr, queryParams, subNavPageName, topLevelFranchisorId })));
    }
  }, [id, userOwnership, isValidId, isStatsCall, dateRangeArr, queryParams, subNavPageName, topLevelFranchisorId, dispatch]);

  useEffect(() => {
    if (id && userOwnership && isValidId) {
      handleSCListRequest(1);
    }
  }, [id, userOwnership, isValidId, validQueryParams]); // eslint-disable-line

  useEffect(() => {
    if ([SettingsHashtagsActionsVal.IN_POST, SettingsHashtagsActionsVal.IN_COMMENT].includes(hashTagsState.action_status) && isValidId && userOwnership === USER_OWNERSHIP.ACCOUNT) {
      dispatch(
        settingsGetHashTagsListRequest({
          id,
          userOwnership,
          params: { action_status: SETTINGS_HASHTAG_ACTIONS.find((action) => action.value === hashTagsState.action_status)?.identifier || SettingsHashtagsActionsIdentifier.IN_POST },
          action_status: hashTagsState.action_status
        })
      );
    }
  }, [id, userOwnership, hashTagsState.action_status, isValidId, dispatch]);

  const handleSCListRequest = (page: number) => {
    if (automaticallyRefreshActivityFeed && !interval.current) {
      interval.current = setInterval(
        () =>
          dispatch(
            socialContentsListRequest(
              scListRequestPayload({ id, userOwnership, page, dateRange: dateRangeArr, queryParams, subNavPageName, topLevelFranchisorId, automaticallyRefreshActivityFeed: true })
            )
          ),
        10000
      );
    }
    dispatch(socialContentsListRequest(scListRequestPayload({ id, userOwnership, page, dateRange: dateRangeArr, queryParams, subNavPageName, topLevelFranchisorId })));
  };

  const scClassName = useMemo(() => {
    switch (subNavPageName) {
      case SIDE_NAV_NAME.COMMUNITY_SANDBOX:
        return {
          mainClassName: 'cisWrap sanbxMain',
          listClassName: ''
        };
      case SIDE_NAV_NAME.REPUTATION_OUTBOX:
        return {
          mainClassName: '',
          listClassName: ' reptOutbox'
        };
      default:
        return {
          mainClassName: '',
          listClassName: ''
        };
    }
  }, [subNavPageName]);

  return (
    <>
      <div className="mbl-head--margin outboxstat">
        <SCStats />
      </div>
      <div className={scClassName.mainClassName}>
        {isListFetching && currentPageCount === 1 ? (
          <Loading />
        ) : socialContentsList.length ? (
          <div className={`infinite-list${scClassName.listClassName}`} id={`${subNavPageName}-list-ifs`}>
            <InfiniteScroll
              scrollThreshold={SCROLL_THRESHOLD}
              next={() => handleSCListRequest(currentPageCount + 1)}
              hasMore
              dataLength={socialContentsList.length}
              loader={isListFetching ? <Loading /> : null}
              className="local-ini"
              scrollableTarget="scrollTarget"
            >
              <SCList />
            </InfiniteScroll>
          </div>
        ) : (
          !isListFetching && !socialContentsList.length && <NoDataFound size={NoDataFoundProps.FULL} />
        )}
      </div>
    </>
  );
};
