import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { CommonFilter } from 'components';
import { getAIEngineChatHistoryRequest, getAIEngineThreadListRequest, setThreadId, updateCardsDetails } from 'actions';
import { useAccountSwitcherData } from 'utils';
import { IStore } from 'types';

export const AIEngineFilter = () => {
  const dispatch = useDispatch();
  const { id } = useAccountSwitcherData();
  const { threadList, cardDetails } = useSelector((state: IStore) => state.aiEngine);

  useEffect(() => {
    dispatch(
      getAIEngineThreadListRequest({
        user_id: id
      })
    );
  }, [id]); // eslint-disable-line

  const ChatHistory = useCallback(() => {
    return (
      <div className="chat-history">
        <h2 className="chat-title">Chat History</h2>
        <div className="chat-group">
          {threadList.map((it: any, index: number) => {
            return (
              <div className="chat-item ch-options" key={`thread-${index}`}>
                <h2 className="dayStamp">Today</h2>
                <div className="choGroup">
                  <h4
                    className="cursor-pointer chat-name "
                    onClick={() => {
                      dispatch(setThreadId(it.thread_id));
                      dispatch(getAIEngineChatHistoryRequest(it.thread_id));
                      dispatch(updateCardsDetails({ cardContent: cardDetails.cardContent, isCardLoading: false }));
                    }}
                  >
                    {it.title}
                  </h4>
                </div>
                <p className="chat-date d-none">{moment(it.updated_at).format('lll')}</p>
                {/* <p className="chat-data">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temp...</p> */}
              </div>
            );
          })}
        </div>
      </div>
    );
  }, [threadList]); // eslint-disable-line

  return <CommonFilter filter={[]} handleChange={() => null} isDateRangeFilterEnable={false} sectionVariant="adv-ov-filter" renderPrefixData={ChatHistory} />;
};
