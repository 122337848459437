import { aiContentCreatorReducerTypes, mgApiTypes } from 'types';
import { MG_CLOUD_MEDIA_TYPE, MG_MEDIA_TYPE, REMOVE_HASHTAGS_WITH_SPACES_REGEX, SEPERATE_EMOJI_FROM_TEXT, SEPERATE_TEXT_TO_HASH_REGEX } from 'utils/constants';
import { getCloudinaryIdFromMediaUrl } from './common';

export const modalParserFromData = (data: { input: string; output: string }[], text: string): string => {
  const modalData = data.reduce((acc, curr) => {
    acc += `input: ${curr.input} output: ${curr.output}`;
    return acc;
  }, '');
  return `${modalData} input:${text} output: `;
};

export const splitTextFromHash = (textArray: aiContentCreatorReducerTypes.ICompletionResult['choices']) => {
  return textArray.reduce(
    (acc: { text: string[]; hash: string[] }, curr) => {
      const currentHash = curr.text.match(SEPERATE_TEXT_TO_HASH_REGEX);
      const currentText = curr.text.replace(SEPERATE_TEXT_TO_HASH_REGEX, '');
      acc.text.push(currentText.trim());
      if (currentHash) {
        const newData = currentHash.filter((it: string) => !acc.hash.includes(it));
        acc.hash = [...acc.hash, ...newData];
      }
      return acc;
    },
    { text: [], hash: [] }
  );
};

export const splitTextFromHashAndEmoji = (text: string) => {
  let currentText = text.replace(SEPERATE_TEXT_TO_HASH_REGEX, '').trim();
  currentText = currentText.replace(SEPERATE_EMOJI_FROM_TEXT, '').trim();
  return currentText;
};

export const splitTextAndHashtagsFromText = (text: string) => {
  const currentText = text.replace(REMOVE_HASHTAGS_WITH_SPACES_REGEX, '');
  const currentHash: string[] = text.match(REMOVE_HASHTAGS_WITH_SPACES_REGEX) || [];
  return { text: currentText, hashTag: currentHash.map((it) => it.trim()) /* removes extra spaces from hashtags */ };
};

export const getBrandMediaCloudinaryIdsForAI = (mediaList: mgApiTypes.IMGListResponseObj[]) => {
  return mediaList.reduce((acc: string[], curr) => {
    if (curr.cloudinary_id) acc = [...acc, curr.cloudinary_id];
    return acc;
  }, []);
};

export const getBrandMediaTypeForAI = (mediaList: mgApiTypes.IMGListResponseObj[]) => {
  return mediaList.find((it) => it.media_type === MG_MEDIA_TYPE.PHOTO)?.id ? MG_CLOUD_MEDIA_TYPE.IMAGE : MG_CLOUD_MEDIA_TYPE.VIDEO;
};

export const getLocMediaCloudinaryIdsForAI = ({ imageList, videoUrl }: { imageList: string[]; videoUrl: null | string }) => {
  if (imageList?.length) {
    return imageList.reduce((acc: string[], curr) => {
      if (curr) acc = [...acc, getCloudinaryIdFromMediaUrl(curr)];
      return acc;
    }, []);
  } else if (videoUrl) {
    return [getCloudinaryIdFromMediaUrl(videoUrl)];
  }
  return [];
};

export const getLocMediaTypeForAI = ({ imageList, videoUrl }: { imageList: string[]; videoUrl: null | string }) => {
  return imageList?.length ? MG_CLOUD_MEDIA_TYPE.IMAGE : videoUrl ? MG_CLOUD_MEDIA_TYPE.VIDEO : null;
};

export const getValidTextFromRegex = (text: string, regExArray: RegExp[]) => {
  return regExArray.reduce((acc, curr) => {
    const outputText = text.match(curr);
    if (outputText?.length) {
      acc = outputText[0];
    }
    return acc;
  }, '');
};
