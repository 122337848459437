import { apFranContainerTypes } from 'types';
import { MG_MEDIA_TYPE } from 'utils/constants';
import { ImageValidation, ReactVideoPlayer } from 'widgets/Media';

export const InstagramStoryPreview = ({ userName, media, imgStoryUrl, profilePic }: apFranContainerTypes.ISocialMediaStoryPreview) => {
  return (
    <div className="tt-wraps finsWrap insta-previewWrap insta-preview-story-wrap">
      {media?.media_type === MG_MEDIA_TYPE.VIDEO ? (
        <div
          className="tt-asset-wrap doWrap do-video"
          data-orientation="loading"
          ref={(el) => {
            if (el && media?.medium_size_url) {
              const video = document.createElement('video');
              video.src = media?.medium_size_url || '';
              video.onloadedmetadata = () => {
                el.setAttribute('data-orientation', video.videoHeight > video.videoWidth ? 'portrait' : 'landscape');
              };
            }
          }}
        >
          <ReactVideoPlayer
            videoUrl={media?.medium_size_url || ''}
            customClassname={'video full-width'}
            isPlayControl={false} // playVideo.includes(socialMediaName)
            controls={false}
            autoPlay={false}
            light={media?.thumbnail_url}
          />
        </div>
      ) : (
        <div className="tt-asset-wrap">
          <div
            className="cpp-wrap cpp-img doWrap do-img"
            data-orientation="loading"
            ref={(el) => {
              if (el && imgStoryUrl) {
                const img = new Image();
                img.src = imgStoryUrl;
                img.onload = () => el.setAttribute('data-orientation', img.naturalHeight > img.naturalWidth ? 'portrait' : 'landscape');
              }
            }}
          >
            <ImageValidation imgUrl={imgStoryUrl} customName={'Image asset'} />
          </div>
        </div>
      )}

      <div className="tiktok-Overlay">
        <div className="rPrevHead">
          <div className="rPrevStatusCounts">
            <span />
            <span />
            <span />
            <span />
          </div>

          <div className="rPrevProfile">
            <div className="p-header-title">
              <div className="p-avatar">
                <ImageValidation isImgValid customName={'Location Profile Item'} imgUrl={profilePic} defaultImg="user-avatar-common.png" isNotSvgFormat />
              </div>
              <div className="p-title">
                <h5>
                  <span className="rpUserName">{userName}</span> <span className="rpDuration">Just now</span>
                </h5>
                {/*  <div className="pUserlikings">
                  <ImageValidation isImgValid customName={'User Likings'} defaultImg="fb-prev-music" />
                  <span className="prev-userLikeList">Music Director, Actor, God</span>
                </div> */}
              </div>
            </div>

            <div className="r-prev-right">
              <ImageValidation isImgValid customName={'More'} defaultImg="fb-prev-more" />
              <ImageValidation isImgValid customName={'close'} defaultImg="fb-prev-close" />
            </div>
          </div>

          <div className="rPrevMute">
            <ImageValidation isImgValid customName={'Mute'} defaultImg="fb-prev-mute" />
          </div>
        </div>

        {/* <div className="play-btn">
          <ImageValidation isImgValid defaultImg={'play-tiktok'} customName={'tiktokPlay'} />
        </div> */}

        {/* <ImageValidation isImgValid defaultImg={'music-r-tiktok'} customName={'Music'} /> */}
      </div>
      <div className="rPrev-footer">
        <div className="rpSm">
          <span>Send message...</span>
        </div>
        <div className="rpEmojis">
          <ImageValidation isImgValid customName={'Like'} customClassname="rp-e-item" defaultImg="insta-prev-like" />
          <ImageValidation isImgValid customName={'Send'} customClassname="rp-e-item" defaultImg="insta-prev-send" />
        </div>
      </div>
    </div>
  );
};
