import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Tag } from 'react-tag-autocomplete';
import { components } from 'react-select';

import { contentFeedAddTags, contentFeedResetTags, getEligibleContentsCountRequest, onChangeList, operationListsResetModifiedObj, postsTagsRequest } from 'actions';
import { IStore, commonAccountSwitcher } from 'types';
import { ImageValidation } from 'widgets/Media';
import { ListCard } from './ListCard';
import { Loading } from 'components';
import {
  CONTENT_SUPPLIER_CONTENT_SOURCE_AND_FEED_OPTIONS,
  CONTENT_SUPPLIER_CONTENT_SOURCE_IDENTIFIER,
  CONTENT_SUPPLIER_CONTENT_SOURCE_LABELS,
  CONTENT_SUPPLIER_CONTENT_SOURCE_VALUES,
  FEED_TYPE,
  USER_OWNERSHIP
} from 'utils/constants';
import { buildSelectedTree, debounceMethod, findValidChildren, formNewInternalTag, ITreeSelection } from 'utils/helpers';
import { useAccountSwitcherData } from 'utils/hooks';
import { CustomDropdown } from 'widgets/CustomDropdown';
import { CustomReactTags } from 'widgets/CustomReactTags';

export const CreateLists = () => {
  const dispatch = useCallback(useDispatch(), []); // eslint-disable-line
  const navigate = useNavigate();
  const { id, userOwnership, currentRoute } = useAccountSwitcherData();

  const availableTree = useSelector((state: IStore) => state.operationsList.treeData);
  const isListDetailFetching = useSelector((state: IStore) => state.operationsList.isListDetailFetching);
  const selectedId = useSelector((state: IStore) => state.operationsList.modifiedObj?.id);
  const selectedArr = useSelector((state: IStore) => state.operationsList.modifiedObj?.included_accounts);
  const availableLocations = useSelector((state: IStore) => state.operationsList.modifiedObj?.missing_accounts);
  const isSaveRequest = useSelector((state: IStore) => state.operationsList.isSaveRequest);
  const contentSupplierHubs = useSelector((state: IStore) => state.operationsList.cs_franchisors);
  const contentFeedModifiedObj = useSelector((state: IStore) => state.operationsList.modifiedObj);
  const internalTagsList = useSelector((state: IStore) => state.posts.postsTagsList);
  // const contentFeedTags = useSelector((state: IStore) => state.operationsList.tags);
  const isContentSupplierFranchisor = useSelector((state: IStore) => state.accountSwitcher?.content_supplier_franchisor || false);
  const topLevelFranchisor = useSelector((state: IStore) => state.accountSwitcher.topLevelFranchisor);
  const isContentSupplier = useSelector((state: IStore) => state.franchisors.franchisorDetails?.content_supplier);
  const eligibleContentsCount = useSelector((state: IStore) => state.operationsList.eligible_contents_counts);

  const [searchValue, setSearchValue] = useState('');

  const selectedObj: ITreeSelection = useMemo(() => {
    if (selectedArr?.length > 0 && !isListDetailFetching && availableTree.length > 0) {
      return buildSelectedTree(
        availableTree,
        selectedArr.map((it: { id: number; name: string }) => String(it?.id))
      );
    }
    return { selected: [], checked: [], partiallyChecked: [] };
  }, [selectedArr, isListDetailFetching, availableTree]);

  const tagsList = useMemo(() => {
    return formNewInternalTag(internalTagsList, searchValue);
  }, [internalTagsList, searchValue]);

  let tagsApiTimeout: null | NodeJS.Timeout = null;

  const handleChange = (key: string, value: unknown) => {
    dispatch(onChangeList({ key, value }));
  };

  const handleContentFeed = (franchisorId: number) => {
    if (franchisorId) {
      handleChange(
        'content_suppliers',
        contentFeedModifiedObj?.content_suppliers.includes(franchisorId)
          ? contentFeedModifiedObj?.content_suppliers.filter((id: number) => id !== franchisorId)
          : [...contentFeedModifiedObj?.content_suppliers, franchisorId]
      );
    }
  };

  const getEligibleContentsCount = useCallback(() => {
    if (userOwnership === USER_OWNERSHIP.FRANCHISOR) {
      dispatch(
        getEligibleContentsCountRequest({
          account_list: {
            franchisor_id: id,
            content_source:
              CONTENT_SUPPLIER_CONTENT_SOURCE_AND_FEED_OPTIONS.find((source) => source.content_source === contentFeedModifiedObj?.content_source)?.identifier ||
              CONTENT_SUPPLIER_CONTENT_SOURCE_IDENTIFIER.ALL,
            content_supplier_franchisor_ids: contentFeedModifiedObj?.content_suppliers,
            tags_list: contentFeedModifiedObj?.tags_list?.join(','),
            content_type: contentFeedModifiedObj?.content_type
          }
        })
      );
    }
  }, [id, userOwnership, contentFeedModifiedObj?.content_source, contentFeedModifiedObj?.tags_list, contentFeedModifiedObj?.content_suppliers, contentFeedModifiedObj?.content_type, dispatch]);

  useEffect(() => {
    if (contentFeedModifiedObj?.feed_type === FEED_TYPE.CONTENT_SUPPLIER) getEligibleContentsCount();
  }, [contentFeedModifiedObj?.tags_list, contentFeedModifiedObj?.content_source, getEligibleContentsCount, contentFeedModifiedObj?.content_suppliers, contentFeedModifiedObj?.feed_type]);

  const handleAddTags = (tag: string) => {
    const tagName = !internalTagsList.length ? tag.replace('Add ', '') : tag;
    const updatedFeedTags = [...contentFeedModifiedObj?.tags_list, tagName];
    dispatch(contentFeedAddTags(updatedFeedTags));
    handleChange('tags_list', updatedFeedTags);
    setSearchValue('');
  };

  const handleInputChange = (search: string) => {
    setSearchValue(search);
    if (isContentSupplier) {
      dispatch(postsTagsRequest({ searchTag: search, contentSupplierId: id }));
    } else {
      dispatch(postsTagsRequest({ searchTag: search }));
    }
  };

  const handleDeleteTag = (tagName: string) => {
    const updatedFeedTags = contentFeedModifiedObj?.tags_list.filter((datum: any) => datum !== tagName);
    dispatch(contentFeedAddTags(updatedFeedTags));
    handleChange('tags_list', updatedFeedTags);
    setSearchValue('');
    getEligibleContentsCount();
  };

  const CustomOptionWithCheckbox = (props: commonAccountSwitcher.ICustomOptionProps | any) => {
    const { data } = props;
    return data?.isContentSupplier ? (
      <div
        className="scs-item cursor-pointer"
        onClick={() => {
          handleChange('content_source', CONTENT_SUPPLIER_CONTENT_SOURCE_VALUES.CONTENT_SUPPLIER);
          handleContentFeed(data?.id);
        }}
      >
        <input
          type="checkbox"
          onChange={(e) => {
            e.stopPropagation();
            handleContentFeed(data?.id);
          }}
          checked={contentFeedModifiedObj?.content_suppliers.includes(data?.id)}
        />
        <label>{data?.label}</label>
      </div>
    ) : (
      <components.Option {...props} />
    );
  };

  const modifiedCSHubs = contentSupplierHubs.reduce((acc: any[], curr) => {
    acc.push({
      label: curr.label,
      value: curr.value,
      isContentSupplier: true,
      id: curr.id
    });
    return acc;
  }, []);

  const listContentSupplierSource = useMemo(() => {
    const filteredCSSourceOptions = CONTENT_SUPPLIER_CONTENT_SOURCE_AND_FEED_OPTIONS.slice(1, CONTENT_SUPPLIER_CONTENT_SOURCE_AND_FEED_OPTIONS.length);
    return [...filteredCSSourceOptions, ...modifiedCSHubs].reduce((acc, curr) => {
      if (curr.label === CONTENT_SUPPLIER_CONTENT_SOURCE_LABELS.BRAND) {
        acc.push({
          id: curr.id,
          label: topLevelFranchisor,
          value: curr.value
        });
      } else {
        acc.push(curr);
      }
      return acc;
    }, []);
  }, [modifiedCSHubs, topLevelFranchisor]);

  const retrieveEligibleCount = () => {
    switch (contentFeedModifiedObj?.content_source) {
      case CONTENT_SUPPLIER_CONTENT_SOURCE_VALUES.BRAND: {
        return eligibleContentsCount?.eligible_brand_contents_count;
      }
      case CONTENT_SUPPLIER_CONTENT_SOURCE_VALUES.CONTENT_SUPPLIER: {
        return eligibleContentsCount?.eligible_content_supplier_contents_count;
      }
      default: {
        return eligibleContentsCount?.eligible_total_contents_count;
      }
    }
  };

  return (
    <div className="tml-main tml-createList pb-20 mbl-head--margin lpx pt-20 tmLf-nl bsFeedList">
      {isListDetailFetching ? (
        <Loading />
      ) : (
        <>
          <div className="tm__list--head">
            <div className="tml-top box-one mt-0">
              <div className="bs-fl-top r-flx r-flx-ac r-flx-jb">
                <div className="tmlm-item tmlm-left g-20 r-flx r-flx-ac">
                  <div className="back-nav">
                    <ImageValidation
                      isImgValid
                      defaultImg="arw-full-left-blue"
                      customName="Back"
                      onClick={() => {
                        if (!isSaveRequest) {
                          navigate({ pathname: `/${userOwnership}/${id}/${currentRoute}` });
                          dispatch(operationListsResetModifiedObj());
                        }
                      }}
                    />
                  </div>
                  <h3 className="glbl__title--txt">{selectedId ? 'My Lists' : 'Create a New Lists'}</h3>
                </div>
              </div>

              <div className="bs-cnl">
                <div className={`form-group form-field-item`}>
                  <input type="text" name={'name'} value={contentFeedModifiedObj?.name} autoComplete="off" onChange={(e) => handleChange('name', e.target.value)} />
                  <span className={`fltlabels`}>
                    {'Name of the List'}
                    <span className="mandatory cursor-help">*</span>
                  </span>
                </div>

                {isContentSupplierFranchisor && (
                  <>
                    <div className={`form-group form-field-item fg-dropdown m-align-items cs-item cs-clean`}>
                      <CustomDropdown
                        classNamePrefix="glbl__dropdown"
                        id="boost-duration-dropdown"
                        placeholder={'Select Type'}
                        options={CONTENT_SUPPLIER_CONTENT_SOURCE_AND_FEED_OPTIONS}
                        value={CONTENT_SUPPLIER_CONTENT_SOURCE_AND_FEED_OPTIONS.find((selectOption) => selectOption.value === contentFeedModifiedObj?.feed_type)}
                        onChange={(eventObj: any) => {
                          handleChange('feed_type', eventObj.value);
                          handleChange('tags_list', []);
                          dispatch(contentFeedResetTags());
                        }}
                        name={'feed_type'}
                      />
                      <span className={`fltlabels`}>
                        {'Select Type'}
                        <span className="mandatory cursor-help">*</span>
                      </span>
                    </div>

                    <div className="csfcWrap">
                      <div className="csf-left">
                        {contentFeedModifiedObj?.feed_type === FEED_TYPE.CONTENT_SUPPLIER ? (
                          <div className={`form-group form-field-item fg-dropdown m-align-items cs-item cs-clean`}>
                            <CustomDropdown
                              classNamePrefix="glbl__dropdown"
                              id="boost-duration-dropdown"
                              placeholder={'Content Source(s)'}
                              options={listContentSupplierSource}
                              value={listContentSupplierSource.find((selectOption: any) => selectOption.value === contentFeedModifiedObj?.content_source)}
                              onChange={(eventObj: any) => {
                                handleChange('content_source', eventObj.value);
                                handleChange('content_suppliers', []);
                              }}
                              name={'content_source'}
                              components={{ Option: CustomOptionWithCheckbox }}
                            />
                            <span className={`fltlabels`}>
                              {'Select Content Source(s)'}
                              <span className="mandatory cursor-help">*</span>
                            </span>
                          </div>
                        ) : null}
                        {contentFeedModifiedObj?.feed_type === FEED_TYPE.CONTENT_SUPPLIER ? (
                          <div className={`cscl-selTags`}>
                            <div className={`form-group form-field-item`}>
                              <CustomReactTags
                                key={`${id}${userOwnership}`}
                                suggestions={tagsList}
                                handleInputChange={(search: string) => {
                                  tagsApiTimeout = debounceMethod(search, tagsApiTimeout, handleInputChange);
                                }}
                                handleDelete={() => false}
                                maxSuggestionsLength={tagsList.length}
                                handleAddition={(tag: Tag) => handleAddTags(tag?.label)}
                                placeholder="Search"
                                autofocus={false}
                                allowNew
                                minQueryLength={1}
                              />
                              <span className={`fltlabels`}>{'Select Tags to filter content (Optional)'}</span>
                            </div>
                            {contentFeedModifiedObj?.tags_list?.length ? (
                              <div className="tags-wrp">
                                {contentFeedModifiedObj?.tags_list?.length &&
                                  contentFeedModifiedObj?.tags_list.map((tagDatum: string, index: number) => {
                                    return (
                                      <div className="list-expanded-tag-item" key={`loc-creator-tag-${index}`}>
                                        <span className="list-expanded-tag-text">{tagDatum}</span>
                                        <span className="list-expanded-tag-remove" onClick={() => handleDeleteTag(tagDatum)} />
                                      </div>
                                    );
                                  })}
                              </div>
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                      <div className="csf-right">
                        {contentFeedModifiedObj?.feed_type === FEED_TYPE.CONTENT_SUPPLIER ? (
                          <div className="apc-wrap">
                            <div className="apc-top">
                              <h3>Available Posts</h3>
                              <div className="apc-count">
                                <span>{retrieveEligibleCount() ?? 0}</span>
                              </div>
                            </div>
                            <div className="apc-base">
                              <ImageValidation isImgValid isNotSvgFormat defaultImg="apCountWavesBig.png" customClassname={'apcWave'} customName="Available Posts" />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="create-as">
            <ListCard
              title="Available"
              subtitle={`(${selectedArr?.length + availableLocations?.length} Available Locations)`}
              treeData={availableTree}
              rootClass="available-card"
              checkable
              checkedKeys={{ checked: selectedObj.checked, halfChecked: selectedObj.partiallyChecked }}
              onCheck={(selectedObj: { checked: string[]; halfChecked: string[] }, e: any) => {
                const checkedObjId = e.node.key;
                const newSelected = selectedArr ? [...selectedArr] : [];
                const availableArr = availableLocations ? [...availableLocations] : [];
                const validChildren = findValidChildren(availableTree, checkedObjId);
                if (e.checked) {
                  validChildren.forEach((it) => {
                    const presentIndex = newSelected.findIndex((s) => s.id === Number(it));
                    const availableObjIndex = availableArr.findIndex((s) => s.id === Number(it));
                    if (presentIndex === -1) {
                      newSelected.push(availableArr[availableObjIndex]);
                      availableArr.splice(availableObjIndex, 1);
                    }
                  });
                } else {
                  validChildren.forEach((it) => {
                    const presentIndex = newSelected.findIndex((s) => s.id === Number(it));
                    const availableObjIndex = availableArr.findIndex((s) => s.id === Number(it));
                    if (availableObjIndex === -1) {
                      availableArr.push(newSelected[presentIndex]);
                      newSelected.splice(presentIndex, 1);
                    }
                  });
                }
                dispatch(onChangeList({ key: 'included_accounts', value: newSelected }));
                dispatch(onChangeList({ key: 'missing_accounts', value: availableArr }));
              }}
              checkStrictly
            />
            <ListCard
              title="Selected"
              subtitle={`(${selectedArr?.length} Of ${selectedArr?.length + availableLocations?.length} Locations)`}
              treeData={selectedObj.selected}
              rootClass="selected-card"
            />
          </div>
        </>
      )}
    </div>
  );
};
