// ADD POST - GET CONTENT POST DATA
export const AP_GET_CONTENT_POST_DATA_REQUEST = 'add_post_franchisor/AP_GET_CONTENT_POST_DATA_REQUEST';
export const AP_GET_CONTENT_POST_DATA_RESPONSE = 'add_post_franchisor/AP_GET_CONTENT_POST_DATA_RESPONSE';
export const AP_GET_CONTENT_POST_DATA_FAILURE = 'add_post_franchisor/AP_GET_CONTENT_POST_DATA_FAILURE';

// ADD POST - GET SELECTED CONTENT OBJ
export const AP_GET_SELECTED_CONTENT_OBJ = 'add_post_franchisor/AP_GET_SELECTED_CONTENT_OBJ';

// ADD POST - GET SELECTED FB TAG LIST
export const AP_GET_SELECTED_FB_TAG_LIST = 'add_post_franchisor/AP_GET_SELECTED_FB_TAG_LIST';

// ADD POST - ADD CLOUDINARY MEDIA IN ASSETS
export const AP_ADD_CLOUDINARY_MEDIA_RESPONSE = 'add_post_franchisor/AP_ADD_CLOUDINARY_MEDIA_RESPONSE';
export const AP_ADD_CLOUDINARY_MEDIA_FAILURE = 'add_post_franchisor/AP_ADD_CLOUDINARY_MEDIA_FAILURE';

// ADD POST - UPDATE CONTENT
export const AP_UPDATE_CONTENT_REQUEST = 'add_post_franchisor/AP_UPDATE_CONTENT_REQUEST';
export const AP_UPDATE_CONTENT_RESPONSE = 'add_post_franchisor/AP_UPDATE_CONTENT_RESPONSE';
export const AP_UPDATE_CONTENT_FAILURE = 'add_post_franchisor/AP_UPDATE_CONTENT_FAILURE';

// ADD POST - GET LOCATION IDS BY SELECTING HUB
export const AP_GET_LOC_IDS_BASED_ON_HUB_REQUEST = 'add_post_franchisor/AP_GET_LOC_IDS_BASED_ON_HUB_REQUEST';
export const AP_GET_LOC_IDS_BASED_ON_HUB_RESPONSE = 'add_post_franchisor/AP_GET_LOC_IDS_BASED_ON_HUB_RESPONSE';
export const AP_GET_LOC_IDS_BASED_ON_HUB_FAILURE = 'add_post_franchisor/AP_GET_LOC_IDS_BASED_ON_HUB_FAILURE';

// ADD POST - GET LOCATION DETAILS BY SELECTING LOC
export const AP_GET_LOC_DETAIL_BASED_ON_LOC_REQUEST = 'add_post_franchisor/AP_GET_LOC_DETAIL_BASED_ON_LOC_REQUEST';
export const AP_GET_LOC_DETAIL_BASED_ON_LOC_RESPONSE = 'add_post_franchisor/AP_GET_LOC_DETAIL_BASED_ON_LOC_RESPONSE';
export const AP_GET_LOC_DETAIL_BASED_ON_LOC_FAILURE = 'add_post_franchisor/AP_GET_LOC_DETAIL_BASED_ON_LOC_FAILURE';

// ADD POST - ADD SCHEDULED MULTIPOSTS
export const AP_ADD_SCHEDULED_MULTIPOSTS_REQUEST = 'add_post_franchisor/AP_ADD_SCHEDULED_MULTIPOSTS_REQUEST';
export const AP_ADD_SCHEDULED_MULTIPOSTS_RESPONSE = 'add_post_franchisor/AP_ADD_SCHEDULED_MULTIPOSTS_RESPONSE';
export const AP_ADD_SCHEDULED_MULTIPOSTS_FAILURE = 'add_post_franchisor/AP_ADD_SCHEDULED_MULTIPOSTS_FAILURE';

// ADD POST - MASS UPDATE FOR SCHEDULED POST
export const AP_MASS_UPDATE_FOR_SCHEDULED_POST_REQUEST = 'add_post_franchisor/AP_MASS_UPDATE_FOR_SCHEDULED_POST_REQUEST';
export const AP_MASS_UPDATE_FOR_SCHEDULED_POST_RESPONSE = 'add_post_franchisor/AP_MASS_UPDATE_FOR_SCHEDULED_POST_RESPONSE';
export const AP_MASS_UPDATE_FOR_SCHEDULED_POST_FAILURE = 'add_post_franchisor/AP_MASS_UPDATE_FOR_SCHEDULED_POST_FAILURE';

// ADD POST - UPDATE CONTENT OBJ
export const AP_UPDATE_CONTENT_OBJ = 'add_post_franchisor/AP_UPDATE_CONTENT_OBJ';

// ADD POST - SET SELECTED MEDIA LIST
export const AP_SET_SELECTED_MEDIA_LIST = 'add_post_franchisor/AP_SET_SELECTED_MEDIA_LIST';

// ADD POST - SET SELECTED LOC LIST DATA
export const AP_SET_SELECTED_LOC_LIST_DATA = 'add_post_franchisor/AP_SET_SELECTED_LOC_LIST_DATA';

// ADD POST - SET  FRANCHISOR PAGE TYPE
export const AP_FRAN_SET_PAGE_TYPE = 'add_post_franchisor/AP_FRAN_SET_PAGE_TYPE';

// ADD POST - SET CONTENT SAVED DATE
export const AP_FRAN_SET_SAVED_DATE = 'add_post_franchisor/AP_FRAN_SET_SAVED_DATE';

export const USE_MEDIA_CONTENT_DATA = 'add_post_franchisor/USE_MEDIA_CONTENT_DATA';

// ADD POST - GET SM VIDEO COMPATIBILITY DATA
export const AP_GET_VIDEO_COMPATIBILITY_REQUEST = 'add_post_franchisor/AP_GET_VIDEO_COMPATIBILITY_REQUEST';
export const AP_GET_VIDEO_COMPATIBILITY_RESPONSE = 'add_post_franchisor/AP_GET_VIDEO_COMPATIBILITY_RESPONSE';
export const AP_GET_VIDEO_COMPATIBILITY_FAILURE = 'add_post_franchisor/AP_GET_VIDEO_COMPATIBILITY_FAILURE';

// ADD POST - GET SM VIDEO COMPATIBILITY DATA
export const AP_VIDEO_COMPATIBILITY_RESET = 'add_post_franchisor/AP_VIDEO_COMPATIBILITY_RESET';

// ADD POST RESET
export const AP_PREVIOUS_PACKAGE_DATA_RESET = 'add_post_franchisor/AP_PREVIOUS_PACKAGE_DATA_RESET';
export const AP_SCHEDULED_CONTENT_RESET = 'add_post_franchisor/AP_SCHEDULED_CONTENT_RESET';
export const AP_FRAN_UPDATED_MSG_RESET = 'add_post_franchisor/AP_FRAN_UPDATED_MSG_RESET';
export const AP_SET_FRAN_SELECTED_MEDIA_LIST_RESET = 'add_post_franchisor/AP_SET_FRAN_SELECTED_MEDIA_LIST_RESET';
export const AP_FRAN_RESET_ALL = 'add_post_franchisor/AP_FRAN_RESET_ALL';

// ADD POST - GET SM VIDEO COMPATIBILITY DATA
export const SELECTED_MEDIA_LIST_SORT = 'add_post_franchisor/SELECTED_MEDIA_LIST_SORT';

// ADD POST - ADD CUSTOM VIDEO THUMBNAIL
export const AP_ADD_CUSTOM_VIDEO_THUMBNAIL_REQUEST = 'add_post_franchisor/AP_ADD_CUSTOM_VIDEO_THUMBNAIL_REQUEST';

// ADD POST - RESET MEDIA EXPIRATION
export const AP_RESET_MEDIA_EXPIRATION = 'add_post_franchisor/AP_RESET_MEDIA_EXPIRATION';

// RESET FRANCHISOR SYNDICATION
export const FRANCHISOR_SYNDICATION_RESET_ALL = 'franchisors/RESET_CONTENT_WRITER_POST';
