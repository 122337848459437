import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tag } from 'react-tag-autocomplete';

import { IStore, settingsRallioProfileContainerTypes } from 'types';
import { compareProfileObjects, compareTwoObjects, formNewAITag, getUniqueValues, isValidHashtag } from 'utils/helpers';
import { useAccountSwitcherData } from 'utils/hooks';
import {
  ALERT_BOX_TYPES,
  CONNECTION_TABS,
  ENTER_VALID_HASHTAG,
  RALLIO_PROFILE_OBJ_TYPE,
  RALLIO_PROFILE_TAB_FILEDS,
  RIPPLE_COLOR,
  SETTINGS_HASHTAG_ACTIONS,
  SettingsHashtagsHeadline
} from 'utils/constants';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { CustomReactTags } from 'widgets/CustomReactTags';
import { alertBoxCall } from 'components/Common';
import { hashTagsResetAll, settingsGetHashTagsListRequest } from 'actions';

export const URLsSection = ({
  accountId,
  values,
  actualValues,
  handleBlur,
  handleChange,
  getInputTitleClassName,
  getInputBoxClassName,
  getClassNames,
  handleIndividualCancel,
  handleIndividualSave,
  setFieldValue
}: settingsRallioProfileContainerTypes.ISettingsCommonCompProps) => {
  const dispatch = useDispatch();

  const { id, userOwnership, optionalParams } = useAccountSwitcherData();
  const isAccountWizardScreen = optionalParams[0] === 'account_wizard';

  const hashtagsInitialState = useSelector((state: IStore) => state.settingsHashTags.hashTagsState);

  const [searchValue, setSearchValue] = useState<null | string>(null);

  const tagsCustomerAliasList = formNewAITag([], searchValue);

  useEffect(() => {
    if (!isAccountWizardScreen) dispatch(settingsGetHashTagsListRequest({ id, userOwnership }));
    return () => {
      dispatch(hashTagsResetAll());
    };
  }, [id, userOwnership, isAccountWizardScreen, dispatch]);

  const handleAddition = (tag: Tag) => {
    tag.label = tag?.label?.includes('Add ') ? tag?.label?.replace('Add ', '') : tag?.label;
    tag.label = tag?.label.indexOf('#') === 0 ? tag?.label : `#${tag?.label}`;
    if (isValidHashtag(tag?.label) && tag?.label?.length <= 50) {
      const addedTagsArray: string[] = [...values?.saved_hashtags, tag?.label];
      if (addedTagsArray?.length <= 5) {
        setFieldValue('saved_hashtags', getUniqueValues(addedTagsArray));
      }
    } else {
      alertBoxCall(ALERT_BOX_TYPES.ERROR, ENTER_VALID_HASHTAG);
    }
  };

  const handleInputChange = (search: string) => {
    if (!search.trim()) setSearchValue(null);
    else setSearchValue(search);
  };

  const isModifyURL = !compareProfileObjects(RALLIO_PROFILE_OBJ_TYPE.URL, values, actualValues);
  const isModifyHashtag = !compareTwoObjects(hashtagsInitialState, { action_status: values.action_status, saved_hashtags: values.saved_hashtags });

  return accountId ? (
    <div className="settings-rallio-profile-new-section wn-urls wnh uhWraps">
      <div className="content-full-wrp settins-content--wrp">
        <div className="content-left-section">
          <h3 className="global__title--txt">URLs</h3>
        </div>
        <div className="content-right-section form-elements-new">
          <div className={`form-group form-field-item ${getInputBoxClassName(RALLIO_PROFILE_TAB_FILEDS.JOB_PAGE_URL)}`}>
            <input
              type="text"
              name={RALLIO_PROFILE_TAB_FILEDS.JOB_PAGE_URL}
              className={getClassNames(RALLIO_PROFILE_TAB_FILEDS.JOB_PAGE_URL)}
              value={String(values[RALLIO_PROFILE_TAB_FILEDS.JOB_PAGE_URL] || '')}
              autoComplete="off"
              onBlur={handleBlur}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(RALLIO_PROFILE_TAB_FILEDS.JOB_PAGE_URL, e.target.value);
              }}
            />
            <span className={`fltlabels ${getInputTitleClassName(RALLIO_PROFILE_TAB_FILEDS.JOB_PAGE_URL)}`}>Job Page URL</span>
          </div>

          <div className={`form-group form-field-item ${getInputBoxClassName(RALLIO_PROFILE_TAB_FILEDS.WEBSITE_URL)}`}>
            <input
              type="text"
              name={RALLIO_PROFILE_TAB_FILEDS.WEBSITE_URL}
              className={getClassNames(RALLIO_PROFILE_TAB_FILEDS.WEBSITE_URL)}
              value={values[RALLIO_PROFILE_TAB_FILEDS.WEBSITE_URL] ? String(values[RALLIO_PROFILE_TAB_FILEDS.WEBSITE_URL]) : ''}
              autoComplete="off"
              onBlur={handleBlur}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(RALLIO_PROFILE_TAB_FILEDS.WEBSITE_URL, e.target.value);
              }}
            />
            <span className={`fltlabels ${getInputTitleClassName(RALLIO_PROFILE_TAB_FILEDS.WEBSITE_URL)}`}>Website URL</span>
          </div>
        </div>
      </div>

      {!isAccountWizardScreen && (
        <div className="srp-hashtags urh srph-main">
          <div className="glbl__modal--card globalCard card">
            <div className="card-body content-full-wrp settins-content--wrp">
              <div className="content-left-section">
                <h3 className="global__title--txt">Hashtags</h3>
              </div>
              <div className="content-right-section form-elements-new">
                <div className="elementsGrouping">
                  <p className="cnt-text">
                    <b>{SettingsHashtagsHeadline}</b>
                  </p>
                  <div className="elementSection">
                    <h3>Action</h3>
                    <div className="elementsInner">
                      <div className="horizontal-group">
                        {SETTINGS_HASHTAG_ACTIONS.map((action, index) => {
                          const isChecked = action?.value === values.action_status;
                          return (
                            // <div className="all-locs">
                            <label className={`cur-pointer r-flx r-flx-ac ${isChecked ? ` active` : ''}`} key={`hashtag-action-${index}`} onClick={() => setFieldValue('action_status', action.value)}>
                              <input
                                type="radio"
                                className="option-input radio"
                                name="action_status"
                                value={action.value}
                                onChange={() => setFieldValue('action_status', action.value)}
                                checked={isChecked}
                              />
                              <div className={`list-lbl cur-pointer`}>
                                <span className="lcs-name" title={action.label}>
                                  {action.label}
                                </span>
                              </div>
                            </label>
                            // </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="elementSection">
                    <h3>
                      Tags <span className="light-weight-txt">(You can add up to 5 hashtags)</span>
                    </h3>
                    <div className="elementsInner">
                      <div className="mg-detailed__main mg-detailed tags">
                        <div className="wb-top">
                          <div className="wb-f-main">
                            <div className="wb-fields">
                              <div className="wbf-item">
                                <div className="wbf-inputs">
                                  <div className="input-fields">
                                    <div className={`if-main if-h ${values.saved_hashtags.length < 5 ? '' : 'pointer-events-none disabled'}`}>
                                      <CustomReactTags
                                        key={`settings-hashtags`}
                                        suggestions={tagsCustomerAliasList}
                                        handleInputChange={(search: string) => {
                                          if (values.saved_hashtags.length < 5) {
                                            handleInputChange(search);
                                          }
                                        }}
                                        handleDelete={() => false}
                                        handleAddition={(tag: Tag) => handleAddition(tag)}
                                        placeholder="Add Tags"
                                        autofocus={false}
                                        inputAttributes={{
                                          disabled: values.saved_hashtags.length >= 5
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="tags-wrp">
                              {values.saved_hashtags?.length
                                ? values.saved_hashtags.map((tagItem: any, tagIndex: number) => {
                                    return (
                                      <div className="list-expanded-tag-item" key={tagIndex}>
                                        <span className="list-expanded-tag-text">{tagItem}</span>
                                        <span
                                          className="list-expanded-tag-remove"
                                          onClick={() =>
                                            setFieldValue(
                                              'saved_hashtags',
                                              values.saved_hashtags.filter((tag: any) => tag !== tagItem)
                                            )
                                          }
                                        />
                                      </div>
                                    );
                                  })
                                : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {optionalParams[2] !== CONNECTION_TABS.RALLIO_PROFILE && (isModifyURL || isModifyHashtag) && (
        <div className="indivual-button-section">
          <div className="stg-item form-configure">
            <CustomRippleButton rippleClass="ripple-unset ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
              <button
                className="ac-btn ac-secondary-white ac-outline size-xs border-0"
                onClick={() => {
                  if (isModifyURL) handleIndividualCancel(RALLIO_PROFILE_OBJ_TYPE.URL);
                  if (isModifyHashtag) handleIndividualCancel(RALLIO_PROFILE_OBJ_TYPE.HASHTAGS);
                }}
              >
                Cancel
              </button>
            </CustomRippleButton>
            <CustomRippleButton rippleClass={`ripple-unset ac-primary-box r-ml2`} custColor={RIPPLE_COLOR.primary}>
              <button
                className="ac-btn ac-primary size-xs"
                onClick={() => {
                  if (isModifyURL) handleIndividualSave(RALLIO_PROFILE_OBJ_TYPE.URL);
                  if (isModifyHashtag) handleIndividualSave(RALLIO_PROFILE_OBJ_TYPE.HASHTAGS);
                }}
              >
                Save
              </button>
            </CustomRippleButton>
          </div>
        </div>
      )}
    </div>
  ) : null;
};
