// AI ENGINE - GET CHAT HISTORY
export const AI_ENGINE_GET_CHAT_HISTORY_REQUEST = 'ai_engine/AI_ENGINE_GET_CHAT_HISTORY_REQUEST';
export const AI_ENGINE_GET_CHAT_HISTORY_RESPONSE = 'ai_engine/AI_ENGINE_GET_CHAT_HISTORY_RESPONSE';
export const AI_ENGINE_GET_CHAT_HISTORY_FAILURE = 'ai_engine/AI_ENGINE_GET_CHAT_HISTORY_FAILURE';

// AI ENGINE - CREATE CHAT HISTORY
export const AI_ENGINE_SEND_NEW_CHAT_REQUEST = 'ai_engine/AI_ENGINE_SEND_NEW_CHAT_REQUEST';
export const AI_ENGINE_SEND_NEW_CHAT_RESPONSE = 'ai_engine/AI_ENGINE_SEND_NEW_CHAT_RESPONSE';
export const AI_ENGINE_SEND_NEW_CHAT_FAILURE = 'ai_engine/AI_ENGINE_SEND_NEW_CHAT_FAILURE';

// AI ENGINE - CREATE THREAD
export const AI_ENGINE_CREATE_THREAD_REQUEST = 'ai_engine/AI_ENGINE_CREATE_THREAD_REQUEST';
export const AI_ENGINE_CREATE_THREAD_RESPONSE = 'ai_engine/AI_ENGINE_CREATE_THREAD_RESPONSE';
export const AI_ENGINE_CREATE_THREAD_FAILURE = 'ai_engine/AI_ENGINE_CREATE_THREAD_FAILURE';

// AI ENGINE - LIST THREAD
export const AI_ENGINE_LIST_THREAD_REQUEST = 'ai_engine/AI_ENGINE_LIST_THREAD_REQUEST';
export const AI_ENGINE_LIST_THREAD_RESPONSE = 'ai_engine/AI_ENGINE_LIST_THREAD_RESPONSE';
export const AI_ENGINE_LIST_THREAD_FAILURE = 'ai_engine/AI_ENGINE_LIST_THREAD_FAILURE';

// AI ENGINE - DELETE CHAT HISTORY
export const AI_ENGINE_DELETE_REQUEST = 'ai_engine/AI_ENGINE_DELETE_REQUEST';
export const AI_ENGINE_DELETE_RESPONSE = 'ai_engine/AI_ENGINE_DELETE_RESPONSE';
export const AI_ENGINE_DELETE_FAILURE = 'ai_engine/AI_ENGINE_DELETE_FAILURE';

// AI ENGINE - UPDATE CHAT HISTORY
export const UPDATE_CHAT_HISTORY = 'ai_engine/UPDATE_CHAT_HISTORY';

// AI ENGINE - THREAD ID
export const SET_THREAD_ID = 'ai_engine/SET_THREAD_ID';

// AI ENGINE - UPDATE CARD CONTENT
export const UPDATE_CARDS_DETAILS = 'ai_engine/UPDATE_CARDS_DETAILS';

// RESET AI ENGINE
export const AI_ENGINE_RESET = 'ai_engine/AI_ENGINE_RESET';

// AI ENGINE GENERATING
export const AI_ENGINE_GENERATING = 'ai_engine/AI_ENGINE_GENERATING';
