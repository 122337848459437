import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { getPostsRequest, addPostSetCampaignData, addPostSetInstaPhotoPreviewObj, addPostUpdateSavedPostDetails, postDetailDataReset } from 'actions';
import { IStore, scApiTypes } from 'types';
import { useAccountSwitcherData, useActivePlatform, useNavBarData } from 'utils/hooks';
import {
  canReply,
  capitalizeName,
  convertLocationTimezone,
  getPermittedImgsBasedOnPlatform,
  handleLinkNavigation,
  getEditOrSchedulePost,
  scPlatformImagesAndTooltipText,
  editOrSchedulePostDispatch,
  canLikeOrDislike,
  getAddMediaListForCreator
} from 'utils/helpers';
import { COMMON_SOCIAL_PROVIDER_TYPE, SC_TOOLTIP_TEXT, SC_LOCATION_IMAGE, USER_OWNERSHIP, SIDE_NAV_NAME, ROUTES, CreatorInstaPhotoType, SettingsHashtagsActionsVal } from 'utils/constants';
import { SCSettings, SCEngagementStats, SCLike, SCReuse, SCReply } from 'components';
import { CarousalImages, ImageLocationDetail, ImageValidation } from 'widgets/Media';
import { SocialMediaLinkPreview } from 'widgets/Preview';
import { CustomShowMoreText } from 'widgets/ShowMoreText';

export const SCList = () => {
  const dispatch = useCallback(useDispatch(), []); // eslint-disable-line
  const navigate = useNavigate();
  const activePlatforms = useActivePlatform();
  const { id, userOwnership } = useAccountSwitcherData();
  const { subNavPageName } = useNavBarData();

  const socialContentsList = useSelector((state: IStore) => state.socialContents.socialContentsList);
  const savedPostId = useSelector((state: IStore) => state.socialContents.savedPostId);
  const postsDetails = useSelector((state: IStore) => state.posts.postsDetails);
  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails);
  const menuAccess = useSelector((state: IStore) => state.accountSwitcher.menu_access);
  const hashTagsState = useSelector((state: IStore) => state.settingsHashTags.hashTagsState);

  const [showMoreDialogId, setshowMoreDialogId] = useState<number[]>([]);
  const [postPlatform, setPostPlatform] = useState<string | null>(null);

  const isHideReuse = useMemo(() => {
    if (id && userOwnership && menuAccess && subNavPageName === SIDE_NAV_NAME.COMMUNITY_SANDBOX) {
      const restrictions = menuAccess?.site_restrictions;
      if (userOwnership === USER_OWNERSHIP.FRANCHISOR && restrictions?.hub_user) {
        return restrictions.hub_user.includes('sandbox/reuse');
      } else if (userOwnership === USER_OWNERSHIP.ACCOUNT && restrictions?.location_user) {
        return restrictions.location_user.includes('sandbox/reuse');
      }
    }
    return false;
  }, [id, userOwnership, subNavPageName, menuAccess]);

  useEffect(() => {
    if (id && userOwnership === USER_OWNERSHIP.ACCOUNT && savedPostId) {
      dispatch(getPostsRequest({ postsDataId: savedPostId, id, userOwnership }));
    }
  }, [id, userOwnership, savedPostId]); // eslint-disable-line

  useEffect(() => {
    if (postsDetails && userOwnership === USER_OWNERSHIP.ACCOUNT) {
      const selectedMediaList = getAddMediaListForCreator(postsDetails, accountDetails?.account?.id || 0);
      dispatch(
        addPostUpdateSavedPostDetails(
          getEditOrSchedulePost({
            activePlatforms,
            postDetails: postsDetails,
            accountTzInfoName: accountDetails?.account?.tzinfo_name || '',
            postPlatform,
            savedHashtags: hashTagsState?.action_status === SettingsHashtagsActionsVal.IN_POST ? hashTagsState?.saved_hashtags : [],
            mediaList: selectedMediaList
          })
        )
      );
      editOrSchedulePostDispatch({
        activePlatforms,
        postsDetails,
        hashtags: hashTagsState?.action_status === SettingsHashtagsActionsVal.IN_POST ? hashTagsState?.saved_hashtags : [],
        mediaList: selectedMediaList,
        dispatch
      });
      if (postsDetails?.use_instagram && postsDetails?.photo_urls?.length) {
        dispatch(
          addPostSetInstaPhotoPreviewObj({
            actionType: postsDetails?.pad_instagram_photos ? CreatorInstaPhotoType.PAD : CreatorInstaPhotoType.CROP,
            photoUrl: { originalUrls: [], previewUrls: [] }
          })
        );
      }
      if (postsDetails?.campaigns?.length) {
        dispatch(addPostSetCampaignData(postsDetails?.campaigns));
      }
      dispatch(postDetailDataReset());
      navigate(ROUTES.CONTENT_CREATOR.replace(':type', userOwnership).replace(':id', id.toString()).replace('/*', ''));
      setPostPlatform(null);
    }
  }, [postsDetails, userOwnership]); // eslint-disable-line

  const handleShowMoreText = (id: number) => {
    setshowMoreDialogId(showMoreDialogId);
    const listDialogId = socialContentsList.filter((filterDatum) => filterDatum.id === id)[0];
    setshowMoreDialogId([...showMoreDialogId, listDialogId.id]);
  };

  const scClassName = (isTextOnly?: boolean) => {
    switch (subNavPageName) {
      case SIDE_NAV_NAME.COMMUNITY_SANDBOX:
        return {
          mainClassName: ' san-list-view__main sbMain',
          listItemClassName: isTextOnly ? ' gmi-text' : ''
        };
      case SIDE_NAV_NAME.REPUTATION_OUTBOX:
        return {
          mainClassName: '',
          listItemClassName: ' animate__animated animate__fadeIn outbox__main'
        };
      default:
        return {
          mainClassName: '',
          listItemClassName: ''
        };
    }
  };

  return (
    <>
      {subNavPageName === SIDE_NAV_NAME.COMMUNITY_SANDBOX && (
        <div className="lpx location-peer-wrp--sb">
          <div className="location-peer-wrp sandboxlist-wrp">
            <div className="location-img">
              <ImageValidation isImgValid customName={'More'} defaultImg={`location-icon-blue`} />
              {userOwnership === USER_OWNERSHIP.ACCOUNT ? 'What are your peers up to?' : 'What are your locations up to?'}
            </div>
          </div>
        </div>
      )}
      <div className={`list-view${scClassName().mainClassName}`}>
        {socialContentsList.map((listItem: scApiTypes.ISCList, index: number) => {
          const isCanReply = canReply(listItem);

          const imagesUrl = getPermittedImgsBasedOnPlatform(
            [COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_BUSINESS_PAGE, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_PUBLIC_CONTENT].includes(listItem.provider)
              ? COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
              : listItem.provider,
            listItem.photo_urls || []
          );

          const scPlatformImagesAndTooltipTextProps = {
            type: listItem.type || '',
            platform: listItem.provider || '',
            isPostedUsingRallio: listItem.posted_using_rallio || null
          };

          return (
            <div className={`list-item${scClassName(!listItem.is_video && !listItem.image_url).listItemClassName}`} key={`${subNavPageName}-list-${index}`}>
              <div className="li-top">
                <div className="round-asset platform-icon">
                  <ImageValidation
                    isImgValid
                    defaultImg={
                      listItem.provider === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
                        ? 'fb-lv'
                        : [COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_BUSINESS_PAGE, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_PUBLIC_CONTENT].includes(listItem.provider)
                        ? 'instagram-lv'
                        : listItem.provider === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN
                        ? 'linkedin-lv'
                        : listItem.provider === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER
                        ? 'twitter-lv'
                        : listItem.provider === COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK
                        ? 'tiktok-lv'
                        : 'pinterest-lv'
                    }
                    customName={'Social Media'}
                  />
                </div>
                <div className="round-asset brand-icon">
                  <ImageValidation
                    isImgValid
                    imgUrl={listItem.account_image_url}
                    defaultImg={'user-avatar-common.png'}
                    isNotSvgFormat
                    customClassname={''}
                    customName={`${subNavPageName} Asset Item`}
                  />
                </div>
                <div className="lvt-details">
                  <h3>{listItem.account_name && capitalizeName(listItem.account_name)}</h3>
                  <ImageLocationDetail
                    isTooltip
                    isImageExists={false}
                    customTooltipText={scPlatformImagesAndTooltipText({ ...scPlatformImagesAndTooltipTextProps, scImagesAndTooltipTextObj: SC_TOOLTIP_TEXT })}
                    customImgName={scPlatformImagesAndTooltipText({ ...scPlatformImagesAndTooltipTextProps, scImagesAndTooltipTextObj: SC_LOCATION_IMAGE })}
                    customClassname={'lvt-brief'}
                    className={'pu-asset lvtb-icon'}
                    customName={'Posted Using'}
                    locationName={`Posted using:`}
                    createdAt={convertLocationTimezone(listItem.originally_posted_at, listItem.account_time_zone)}
                  />
                </div>
                <SCSettings type={listItem.type || ''} platform={listItem.provider || ''} actionUrl={listItem.action_url || null} id={listItem.id} />
              </div>
              <div className="li-base">
                {listItem.details && (
                  <CustomShowMoreText
                    onClick={() => handleShowMoreText(listItem.id)}
                    expanded={showMoreDialogId.includes(listItem.id) ? !!showMoreDialogId : false}
                    customPClassname={'gm-text'}
                    customText={listItem.details}
                  />
                )}
                <div className="lv-assets">
                  {imagesUrl?.length ? <CarousalImages imagesList={imagesUrl} showThumbs={false} customClassname="lva-item" handleImageUrl={handleLinkNavigation} /> : null}
                </div>
                {listItem?.provider !== COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE && listItem?.link?.id ? (
                  <SocialMediaLinkPreview
                    imageUrl={listItem?.link?.link_preview_image_url || null}
                    linkPreviewTitle={listItem?.link?.link_preview_title || ''}
                    linkUrl={listItem?.link?.url || ''}
                    isShowThumbnail={listItem?.link?.link_preview_image_url ? true : false}
                    linkPreviewDescription={listItem?.link?.link_preview_description || ''}
                    socialMedia={listItem?.provider}
                    isLinkCustomized={listItem.link?.link_preview_customized || false}
                    // actionType={listItem.provider === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? listItem?.facebook_call_to_action_type : listItem?.google_call_to_action_type || null}
                  />
                ) : null}
                <div className="glow-wrap">
                  {' '}
                  {/* For glow when hovering the images in post */}
                  <i className="glow" />
                </div>
                <SCEngagementStats stats={listItem.stats} />
                <div className="vc-controls">
                  {canLikeOrDislike(listItem?.provider) && <SCLike platform={listItem.provider || ''} id={listItem.id} liked={listItem.liked || false} />}
                  {!isHideReuse && <SCReuse platform={listItem.provider || ''} id={listItem.id} type={listItem.type || ''} setPostPlatform={setPostPlatform} />}
                </div>
                {isCanReply && <SCReply platform={listItem.provider || ''} id={listItem.id} accountImageUrl={listItem.account_image_url || ''} postedFrom={listItem.from || null} />}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
