import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { FranchisorsProfileActions } from 'actions';
import { franchisorReducerTypes } from 'types';
import { FRAN_SCHEDULE_POST_TYPE, FRAN_SCHEDULE_INIT_TREE_DATA, FRAN_ADS_BANNER_INIT_RES } from 'utils/constants';
import { getDirectLocsForHubsOrListsUpdatedData, deleteItemInLocationTree, getCurrentHubOrLocListStats, updateFranchisorSyndicateWithLabel } from 'utils/helpers';

type IFranchisorsReducer = franchisorReducerTypes.IFranchisorsReducer;

const initialState: IFranchisorsReducer = {
  isPermissionFetching: false,
  isError: false,
  error: null,
  franchisorsPermissionList: null,
  franchisorDetails: null,
  isDataFetching: false,
  currentFranDetails: null,
  franScheduleTree: FRAN_SCHEDULE_INIT_TREE_DATA,
  isFranchisorUpdating: false,
  corpHubs: [],
  partnerBanners: FRAN_ADS_BANNER_INIT_RES,
  franchisorSubscriptions: [],
  isCountryFetching: false,
  countries: [],
  isPreviousSyndicationFetching: false,
  previousSyndication: []
};

export const franchisorsReducer: Reducer<IFranchisorsReducer, FranchisorsProfileActions> = (state: IFranchisorsReducer = initialState, action: FranchisorsProfileActions) => {
  switch (action.type) {
    // FRANCHISORS - GET/UPDATE FRANCHISORS PERMISSION LIST REQUEST
    case types.FRANCHISOR_PERMISSION_LIST_REQUEST:
      return {
        ...state,
        isPermissionFetching: action.payload?.editedContent?.id ? true : false,
        isError: false,
        error: null
      };
    case types.FRANCHISOR_PERMISSION_LIST_RESPONSE:
      return {
        ...state,
        isPermissionFetching: false,
        franchisorsPermissionList: action.payload,
        isError: false,
        error: null
      };
    case types.FRANCHISOR_PERMISSION_LIST_FAILURE:
      return {
        ...state,
        isPermissionFetching: false,
        franchisorsPermissionList: null,
        isError: true,
        error: action.payload
      };
    // FRANCHISORS - GET OR UPDATE AND HANDLE FRANCHISOR RESPONSE
    case types.FRANCHISOR_GET_OR_UPDATE_PROFILE_REQUEST: {
      return {
        ...state,
        isError: false,
        error: null,
        isFranchisorUpdating: Boolean(action.payload?.editedContent)
      };
    }
    case types.FRANCHISOR_GET_OR_UPDATE_PROFILE_RESPONSE:
      return {
        ...state,
        franchisorDetails: action.payload?.franchisor,
        isError: false,
        error: null,
        isFranchisorUpdating: false
      };
    case types.FRANCHISOR_GET_OR_UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        error: action.payload || 'Unexpected Error',
        isFranchisorUpdating: false,
        isError: true
      };
    // FRANCHISORS - SET FRANCHISOR PROFILE RESET
    case types.FRANCHISOR_PROFILE_RESET:
      return { ...initialState };
    case types.FRANCHISOR_PERMISSION_LIST_RESET:
      return {
        ...state,
        franchisorsPermissionList: initialState.franchisorsPermissionList
      };
    // GET FRANCHISOR DETAILS
    case types.GET_FRANCHISOR_DETAILS_REQUEST:
      return {
        ...state,
        isDataFetching: true,
        error: null
      };
    case types.GET_FRANCHISOR_DETAILS_RESPONSE:
      const updateFranchisorSyndicate = updateFranchisorSyndicateWithLabel(action?.payload?.franchisors_for_syndication || []);
      return {
        ...state,
        isDataFetching: false,
        currentFranDetails: action.payload ? { ...action.payload, franchisors_for_syndication: updateFranchisorSyndicate } : null
      };
    case types.GET_FRANCHISOR_DETAILS_FAILURE:
      return {
        ...state,
        isDataFetching: false,
        currentFranDetails: null,
        error: action.payload || 'Unexpected Error'
      };
    case types.GET_DIRECT_LOCS_FOR_HUB_OR_LOC_LIST_REQUEST:
      return {
        ...state,
        franScheduleTree: {
          ...state.franScheduleTree,
          isLocFetching: true
        }
      };
    case types.GET_DIRECT_LOCS_FOR_HUB_OR_LOC_LIST_RESPONSE:
      return {
        ...state,
        franScheduleTree: {
          ...state.franScheduleTree,
          isLocFetching: false,
          schLocationTree: getDirectLocsForHubsOrListsUpdatedData(state.franScheduleTree.schLocationTree, action.payload, state.franScheduleTree.hubOrLocStats)
        }
      };
    case types.GET_DIRECT_LOCS_FOR_HUB_OR_LOC_LIST_FAILURE:
      return {
        ...state,
        franScheduleTree: {
          ...state.franScheduleTree,
          isLocFetching: false
        },
        error: action.payload || 'Unexpected error'
      };
    case types.DELETE_ITEM_IN_LOCATION_TREE:
      const deletedScheduledList = deleteItemInLocationTree(state.franScheduleTree.schLocationTree, action.payload);
      return {
        ...state,
        franScheduleTree: {
          ...state.franScheduleTree,
          hubOrLocStats: getCurrentHubOrLocListStats(deletedScheduledList),
          schLocationTree: deletedScheduledList
        }
      };
    case types.SET_FRAN_SCHEDULE_TREE_STATS:
      return {
        ...state,
        franScheduleTree: {
          ...state.franScheduleTree,
          hubOrLocStats: action.payload
        }
      };
    case types.SET_FRANCHISOR_SCHEDULE_LOC_TYPE:
      return {
        ...state,
        franScheduleTree: {
          ...initialState.franScheduleTree,
          schLocationTree: [],
          locationType: action.payload ?? FRAN_SCHEDULE_POST_TYPE.ALL_LOCATIONS
        }
      };

    case types.CONTENT_SUPPLIER_GET_CORP_FRANCHISORS_LIST_REQUEST:
      return {
        ...state,
        corpHubs: []
      };
    case types.CONTENT_SUPPLIER_GET_CORP_FRANCHISORS_LIST_RESPONSE:
      return {
        ...state,
        corpHubs: action.payload
      };
    case types.CONTENT_SUPPLIER_GET_CORP_FRANCHISORS_LIST_FAILURE:
      return {
        ...state,
        corpHubs: [],
        error: action.payload
      };
    case types.GET_PARTNER_BANNERS_RESPONSE:
      return {
        ...state,
        partnerBanners: { ...state.partnerBanners, ...(action.payload.isRandom ? { randomPartnerBanners: action.payload.data } : { partnerBannersList: action.payload.data }) }
      };
    case types.GET_PARTNER_BANNERS_FAILURE:
      return {
        ...state,
        partnerBanners: { ...state.partnerBanners, randomPartnerBanners: [] },
        error: action.payload
      };
    case types.FRANCHISOR_SCHEDULE_TREE_RESET:
      return {
        ...state,
        franScheduleTree: FRAN_SCHEDULE_INIT_TREE_DATA
      };
    case types.RESET_PARTNER_BANNER:
      return {
        ...state,
        partnerBanners: initialState.partnerBanners
      };
    case types.FRANCHISOR_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case types.GET_FRAN_SUBSCRIPTION_TYPES_REQUEST:
      return {
        ...state,
        franchisorSubscriptions: initialState.franchisorSubscriptions
      };
    case types.GET_FRAN_SUBSCRIPTION_TYPES_RESPONSE:
      return {
        ...state,
        franchisorSubscriptions: action.payload
      };
    case types.GET_SYNDICATION_COUNTRIES_LIST_REQUEST:
      return {
        ...state,
        error: null,
        isCountryFetching: true,
        countries: []
      };
    case types.GET_SYNDICATION_COUNTRIES_LIST_RESPONSE:
      return {
        ...state,
        countries: action.payload,
        isCountryFetching: false
      };
    case types.GET_SYNDICATION_COUNTRIES_LIST_FAILURE:
      return {
        ...state,
        isCountryFetching: false,
        error: action.payload
      };
    case types.GET_PREVIOUS_SYNDICATION_REQUEST:
      return {
        ...state,
        error: null,
        isPreviousSyndicationFetching: true,
        previousSyndication: []
      };
    case types.GET_PREVIOUS_SYNDICATION_RESPONSE:
      return {
        ...state,
        isPreviousSyndicationFetching: false,
        previousSyndication: action.payload
      };
    case types.GET_PREVIOUS_SYNDICATION_FAILURE:
      return {
        ...state,
        isPreviousSyndicationFetching: false,
        error: action.payload
      };
    case types.RESET_SYNDICATION_DETAILS: {
      return {
        ...state,
        countries: initialState.countries,
        previousSyndication: initialState.previousSyndication
        // corpHubs: initialState.corpHubs
      };
    }
    default:
      return state;
  }
};
