import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { TagSuggestion } from 'react-tag-autocomplete';

import {
  MG_FILTER_INITIAL_VALUE,
  USER_OWNERSHIP,
  MG_FILTER_MEDIA_TYPE,
  MgMediaType,
  MG_USAGE_FILTER_TYPE,
  MG_FILTER_MODEL_RELEASE_TYPE,
  MediaGalleryValidQueryParams,
  BUTTON_PROPS,
  MgSourceType,
  MgUsageType,
  CommonValidQueryParams,
  COMMON_VALID_QUERY_PARAMS_OBJ,
  MG_FILTER_RATING_TYPE,
  AI_POSTS_FILTER_TYPE,
  AIPostType,
  MG_FILTER_SORT_BY,
  MgMediaSortType,
  MG_FILTER_MEDIA_EXPIRATION_STATUS,
  MG_POST_SOURCE_TYPE_FILTER_OPTION,
  FranchisorSyndicationMediaType,
  MG_SYNDICATED_FILTER_TYPE
} from 'utils/constants';
import { IStore, mgReducerTypes } from 'types';
import { getFormattedNumber, getPostFavTagsFromQueryParams, debounceMethod, generateOptionsForReactTag } from 'utils/helpers';
import { useAccountSwitcherData, useParamsDeconstructor, useParamsValidator } from 'utils/hooks';
import { ImageValidation } from 'widgets/Media';
import { CommonFilter, MultiSelectMediaOrPostModal } from 'components';
import { handleFilterChange } from 'analytics/utils/helpers';
import { CustomReactTags } from 'widgets/CustomReactTags';
import { mgResetSelectedMediaList, postsTagsRequest } from 'actions';
import { IFiterHandleChangeData } from 'types/common/filter';
import { MultiSelectMGInfinityScrollList } from 'containers/Content/MediaGallery/SubPages';

export const MediaGalleryFilter = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id, userOwnership, currentRoute, optionalParams } = useAccountSwitcherData();
  const { queryParams, filter } = useParamsDeconstructor({ ...MG_FILTER_INITIAL_VALUE });

  const postsTagsList = useSelector((state: IStore) => state.posts.postsTagsList);
  const isTagFetching = useSelector((state: IStore) => state.mediaGallery.isTagFetching);
  const favTagsList = useSelector((state: IStore) => state.mediaGallery.favTagsList);
  const allMediaCount = useSelector((state: IStore) => state.mediaGallery.filterStats.allMediaCount);
  const brandMediaCount = useSelector((state: IStore) => state.mediaGallery.filterStats.brandMediaCount);
  const locationMediaCount = useSelector((state: IStore) => state.mediaGallery.filterStats.locationMediaCount);
  const contentSupplierCount = useSelector((state: IStore) => state.mediaGallery.filterStats.contentSupplierCount);
  const newlyAddedMediaCount = useSelector((state: IStore) => state.mediaGallery.filterStats.newlyAddedMediaCount);
  const usedMediaCount = useSelector((state: IStore) => state.mediaGallery.filterStats.usedMediaCount);
  const unusedMediaCount = useSelector((state: IStore) => state.mediaGallery.filterStats.unusedMediaCount);
  const isContentSupplierFranchisor = useSelector((state: IStore) => state.accountSwitcher?.content_supplier_franchisor || false);
  const isContentSupplier = useSelector((state: IStore) => state.franchisors.franchisorDetails?.content_supplier || false);
  const isAISubscribed = useSelector((state: IStore) => state.aiContentCreator.isAISubscribed);
  const isBrand = useSelector((state: IStore) => state.accountSwitcher.isBrand);
  const accountSwitcherId = useSelector((state: IStore) => state.accountSwitcher.id);
  const isAccountActivated = useSelector((state: IStore) => state.accounts.accountDetails?.account?.activated || false) || (userOwnership === USER_OWNERSHIP.FRANCHISOR && true);

  const [isMultiSelectModalOpen, setIsMultiSelectModalOpen] = useState(false);

  let mgTagsApiTimeout: null | NodeJS.Timeout = null;

  const usageFiltersList = queryParams?.source === MgSourceType.ACCOUNT ? MG_USAGE_FILTER_TYPE : MG_USAGE_FILTER_TYPE.slice(0, 3);
  const mediaGallerySorceFilter = MG_POST_SOURCE_TYPE_FILTER_OPTION.filter((it) => (!isContentSupplierFranchisor ? it.value !== 'syndicated' : it.value));
  const updatedFavoriteList = generateOptionsForReactTag(favTagsList.filter((item) => item.favourite));

  useParamsValidator(
    { ...CommonValidQueryParams, ...MediaGalleryValidQueryParams },
    {
      ...COMMON_VALID_QUERY_PARAMS_OBJ,
      source: mediaGallerySorceFilter.map((it) => it.value),
      post_used: usageFiltersList.map((it) => it.value),
      used: ['1'],
      unused: ['1'],
      newly_added: ['1'],
      media_release: MG_FILTER_MODEL_RELEASE_TYPE.map((it) => it.value),
      rating: MG_FILTER_RATING_TYPE.map((it) => it.value),
      ai_type: AI_POSTS_FILTER_TYPE.map((it) => it.value),
      sort_by: MG_FILTER_SORT_BY.map((it) => it.value),
      active: MG_FILTER_MEDIA_EXPIRATION_STATUS.map((it) => it.value),
      content_supplier_id: ['1'],
      syndicated_to_any_franchisor: MG_SYNDICATED_FILTER_TYPE.map((filter) => filter.value)
    }
  );

  const mediaGalleryFilter = {
    ...filter,
    source: queryParams?.source || '',
    post_used: queryParams?.used ? MgUsageType.USED : queryParams?.unused ? MgUsageType.UNUSED : queryParams?.newly_added ? MgUsageType.NEWLY_ADDED : MgUsageType.ALL,
    media_release: queryParams?.media_release || MgSourceType.ALL,
    rating: queryParams?.rating || MgSourceType.ALL,
    tags_list: queryParams?.tags_list ? getPostFavTagsFromQueryParams(favTagsList, queryParams?.tags_list || '') : [],
    ai_type: queryParams?.ai_type || AIPostType.ALL,
    sort_by: queryParams?.sort_by || MgMediaSortType.NEWEST_FIRST,
    active: queryParams?.active || AIPostType.ALL,
    content_supplier_id: queryParams?.content_supplier_id || AIPostType.ALL,
    syndicated_to_any_franchisor: queryParams?.syndicated_to_any_franchisor || AIPostType.ALL
  };

  const checkBoxFilter = [
    {
      title: 'Sort By',
      filter: MG_FILTER_SORT_BY.map((it) => ({
        value: it.value,
        label: it.label
      })),
      value: MediaGalleryValidQueryParams.SORT_BY
    },
    {
      title: 'Source',
      filter: mediaGallerySorceFilter.map((it, index) => ({
        value: it.value,
        label:
          allMediaCount || brandMediaCount || locationMediaCount || contentSupplierCount
            ? `${it.label} ${
                index === 0
                  ? `(${getFormattedNumber(allMediaCount || 0)})`
                  : index === 1
                  ? `(${getFormattedNumber(brandMediaCount || 0)})`
                  : index === 2
                  ? `(${getFormattedNumber(locationMediaCount || 0)})`
                  : index === 3
                  ? `(${getFormattedNumber(contentSupplierCount || 0)})`
                  : ''
              }`
            : it.label
      })),
      isShowCSSelector: isContentSupplierFranchisor && queryParams.source === MgSourceType.CONTENT_SUPPLIER,
      value: MediaGalleryValidQueryParams.SOURCE
    },
    {
      title: 'Usage',
      filter: usageFiltersList.map((it, index) => ({
        value: it.value,
        label:
          usedMediaCount || unusedMediaCount || newlyAddedMediaCount
            ? `${it.label} (${
                index === 0
                  ? getFormattedNumber(usedMediaCount + unusedMediaCount || 0)
                  : index === 1
                  ? getFormattedNumber(usedMediaCount || 0)
                  : index === 2
                  ? getFormattedNumber(unusedMediaCount || 0)
                  : getFormattedNumber(newlyAddedMediaCount || 0)
              })`
            : it.label
      })),
      value: MediaGalleryValidQueryParams.POST_USED
    },
    {
      title: 'Syndication',
      filter: MG_SYNDICATED_FILTER_TYPE.map((it, index) => ({
        value: it.value,
        label: it.label
        // Hided because unavailability of counts
        // usedMediaCount || unusedMediaCount || newlyAddedMediaCount
        //   ? `${it.label} (${
        //       index === 0
        //         ? getFormattedNumber(usedMediaCount + unusedMediaCount || 0)
        //         : index === 1
        //         ? getFormattedNumber(usedMediaCount || 0)
        //         : index === 2
        //         ? getFormattedNumber(unusedMediaCount || 0)
        //         : getFormattedNumber(newlyAddedMediaCount || 0)
        //     })`
        // : it.label
      })),
      value: MediaGalleryValidQueryParams.SYNDICATED_TO_ANY_FRANCHISOR
    },
    {
      title: 'Model Release Form',
      filter: MG_FILTER_MODEL_RELEASE_TYPE,
      value: MediaGalleryValidQueryParams.MEDIA_RELEASE
    },
    {
      title: 'Rating',
      filter: MG_FILTER_RATING_TYPE,
      value: MediaGalleryValidQueryParams.RATING
    },
    /* {
      title: 'AI Type',
      filter: AI_POSTS_FILTER_TYPE,
      value: 'ai_type'
    }, */
    {
      title: 'Media Expiration',
      filter: MG_FILTER_MEDIA_EXPIRATION_STATUS,
      value: MediaGalleryValidQueryParams.ACTIVE
    }
  ];

  const initialSource = useMemo(() => {
    if (isBrand) {
      return MgSourceType.BRAND;
    } else {
      return MgSourceType.ACCOUNT;
    }
  }, [isBrand]);

  const tagsList = mediaGalleryFilter.tags_list.filter((it: mgReducerTypes.IMediaGalleryTagsData) => !it.favourite);

  useEffect(() => {
    if (isAccountActivated) navigate({ search: `?${new URLSearchParams({}).toString()}` }, { state: {}, replace: true });
  }, [userOwnership, isAccountActivated]); // eslint-disable-line

  useEffect(() => {
    if (isAccountActivated && !isContentSupplier && initialSource === MgSourceType.BRAND && !queryParams.source && accountSwitcherId === id) {
      const params = { ...queryParams, source: MgSourceType.BRAND };
      navigate({ search: `?${new URLSearchParams(params).toString()}` }, { state: {}, replace: true });
    } else if (isAccountActivated && !isContentSupplier && initialSource === MgSourceType.ACCOUNT && !queryParams.source && accountSwitcherId === id) {
      const params = { ...queryParams, source: MgSourceType.ACCOUNT };
      navigate({ search: `?${new URLSearchParams(params).toString()}` }, { state: {}, replace: true });
    } else if (isContentSupplier) {
      const params = { ...queryParams, source: MgSourceType.ALL };
      navigate({ search: `?${new URLSearchParams(params).toString()}` }, { state: {}, replace: true });
    }
  }, [navigate, isContentSupplier, initialSource, queryParams, accountSwitcherId, id, isAccountActivated]);

  const handleURLPathname = (pathname: string) => {
    navigate({ pathname, search: `?${new URLSearchParams(queryParams).toString()}` });
  };

  const handleChange = (data: IFiterHandleChangeData, isClear = false) => {
    const queryParamsObj = handleFilterChange(data, isClear, id, queryParams);
    let pathname = `/${userOwnership}/${id.toString()}/${currentRoute}/${optionalParams[0]}`;
    if (isClear) {
      if (queryParams?.text) queryParamsObj.text = queryParams?.text || '';
      pathname = `/${userOwnership}/${id.toString()}/${currentRoute}/${MgMediaType.PHOTOS}`;
      delete queryParamsObj?.sort_by;
    }
    if (data.type) {
      pathname = `/${userOwnership}/${id.toString()}/${currentRoute}/${data.type}`;
      if (data.type === MgMediaType.DOCUMENTS) {
        delete queryParamsObj.ai_type;
        delete queryParamsObj.used;
        delete queryParamsObj.media_release;
      }
    }
    if (data.source) {
      if (data.source !== MgSourceType.ACCOUNT) delete queryParamsObj.newly_added;
      if (data.source !== MgSourceType.CONTENT_SUPPLIER) delete queryParamsObj.content_supplier_id;
      /* if (data.source === MgSourceType.ALL) delete queryParamsObj.source;
      else */ queryParamsObj.source = data.source;
    }
    if (data.post_used) {
      if (data.post_used === MgUsageType.USED) {
        queryParamsObj.used = 1;
        delete queryParamsObj.unused;
        delete queryParamsObj.newly_added;
      } else if (data.post_used === MgUsageType.UNUSED) {
        queryParamsObj.unused = 1;
        delete queryParamsObj.used;
        delete queryParamsObj.newly_added;
      } else if (data.post_used === MgUsageType.NEWLY_ADDED) {
        queryParamsObj.newly_added = 1;
        delete queryParamsObj.used;
        delete queryParamsObj.unused;
      } else {
        delete queryParamsObj.used;
        delete queryParamsObj.unused;
        delete queryParamsObj.newly_added;
      }
    }
    if (data.media_release) {
      if (data.media_release === MgSourceType.ALL) delete queryParamsObj.media_release;
      else queryParamsObj.media_release = data.media_release;
    }
    if (data.rating) {
      if (data.rating === MgSourceType.ALL) delete queryParamsObj.rating;
      else queryParamsObj.rating = data.rating;
    }
    if (data.rating) {
      if (data.rating === MgSourceType.ALL) delete queryParamsObj.rating;
      else queryParamsObj.rating = data.rating;
    }
    if (data.tags_list) {
      if (data.tags_list?.length) queryParamsObj.tags_list = data.tags_list.map((it: TagSuggestion) => it.label).join(',');
      else delete queryParamsObj.tags_list;
    }
    if (data.ai_type) {
      if (data.ai_type === MgSourceType.ALL) delete queryParamsObj.ai_type;
      else queryParamsObj.ai_type = data.ai_type;
    }
    if (data.sort_by) {
      if (data.sort_by === MgMediaSortType.NEWEST_FIRST) delete queryParamsObj.sort_by;
      else queryParamsObj.sort_by = data.sort_by;
    }
    if (data.active) {
      if (data.active === MgSourceType.ALL) delete queryParamsObj.active;
      else queryParamsObj.active = data.active;
    }
    if (data.content_supplier_id) {
      if (data.content_supplier_id === MgSourceType.ALL) delete queryParamsObj.content_supplier_id;
      else queryParamsObj.content_supplier_id = data.content_supplier_id;
    }
    if (data.syndicated_to_any_franchisor) {
      if (data.syndicated_to_any_franchisor === AIPostType.ALL) delete queryParamsObj.syndicated_to_any_franchisor;
      else queryParamsObj.syndicated_to_any_franchisor = data.syndicated_to_any_franchisor;
    }
    if (data?.multi_select) {
      setIsMultiSelectModalOpen(true);
    }
    navigate({ pathname, search: `?${new URLSearchParams(queryParamsObj).toString()}` });
  };

  const handleTagSelection = (tagObj: TagSuggestion) => {
    handleChange({
      tags_list:
        mediaGalleryFilter.tags_list && !mediaGalleryFilter.tags_list.map((it: TagSuggestion) => it.label).includes(tagObj.label)
          ? [...mediaGalleryFilter.tags_list, tagObj]
          : mediaGalleryFilter.tags_list.filter((it: TagSuggestion) => it.label !== tagObj.label)
    });
  };

  const handleSearchTags = (searchValue: string) => {
    dispatch(postsTagsRequest({ searchTag: searchValue }));
  };

  const isClearFilterDisabled = Boolean(
    optionalParams[0] === MgMediaType.PHOTOS &&
      !queryParams?.tags_list &&
      !queryParams?.source &&
      !queryParams?.used &&
      !queryParams?.unused &&
      !queryParams?.newly_added &&
      !queryParams?.media_release &&
      !queryParams?.rating &&
      !queryParams?.ai_type &&
      !queryParams?.sort_by &&
      !queryParams?.active &&
      !queryParams?.content_supplier_id &&
      !queryParams?.syndicated_to_any_franchisor
  );

  const renderCheckboxFilter = () => {
    let updatedCheckboxFilter = [...checkBoxFilter];

    if (!isAISubscribed) {
      updatedCheckboxFilter = updatedCheckboxFilter.filter((filterOption) => filterOption.value !== MediaGalleryValidQueryParams.AI_IMAGE);
    }
    if (isContentSupplier) {
      updatedCheckboxFilter = updatedCheckboxFilter.filter((filterOption) => ![MediaGalleryValidQueryParams.SOURCE, MediaGalleryValidQueryParams.POST_USED].includes(filterOption.value));
    } else {
      updatedCheckboxFilter = updatedCheckboxFilter.filter((filterOption) => filterOption.value !== MediaGalleryValidQueryParams.SYNDICATED_TO_ANY_FRANCHISOR);
    }
    if (optionalParams[0] === MgMediaType.VIDEOS) {
      updatedCheckboxFilter = updatedCheckboxFilter.filter((filterOption) => filterOption.value !== MediaGalleryValidQueryParams.AI_IMAGE);
    } else if (optionalParams[0] === MgMediaType.DOCUMENTS) {
      updatedCheckboxFilter = updatedCheckboxFilter.filter(
        (filterOption) => ![MediaGalleryValidQueryParams.AI_IMAGE, MediaGalleryValidQueryParams.POST_USED, MediaGalleryValidQueryParams.MEDIA_RELEASE].includes(filterOption.value)
      );
    }
    return updatedCheckboxFilter;
  };

  const listPrimaryButtons = useCallback(() => {
    const primaryButtons = [
      {
        ...BUTTON_PROPS.CLEAR_FILTER,
        isDisable: isClearFilterDisabled
      }
    ];
    if (userOwnership === USER_OWNERSHIP.FRANCHISOR && optionalParams[0] !== MgMediaType.DOCUMENTS) {
      primaryButtons.unshift({
        ...BUTTON_PROPS.PRIMARY_BUTTON,
        label: 'Multi Select',
        value: 'multi_select',
        parentVariant: 'filter-item'
      });
    }
    return primaryButtons;
  }, [userOwnership, isClearFilterDisabled, optionalParams]);

  return (
    <CommonFilter
      filter={{ ...mediaGalleryFilter }}
      handleChange={handleChange}
      analyticsPlatformFilter={[]}
      primaryButtons={listPrimaryButtons()}
      isDateRangeFilterEnable={false}
      filterPageType={'media'}
      checkBoxFilter={renderCheckboxFilter()}
      sectionVariant={`mg-filter mg-filter__main${optionalParams.length === 1 ? `` : ` events-none pointer-events-none`}`}
      renderPrefixData={() => (
        <>
          <div className="filter-item">
            <h3>Tags</h3>
            <div className="search-tk post-search post-list__search">
              <CustomReactTags
                key={`mg-tag-${id}-${userOwnership}`}
                tags={tagsList}
                suggestions={postsTagsList}
                maxSuggestionsLength={postsTagsList.length}
                handleInputChange={(search: string) => {
                  mgTagsApiTimeout = debounceMethod(search.trim(), mgTagsApiTimeout, handleSearchTags);
                }}
                handleDelete={(postIdx) => postIdx > -1 && handleTagSelection(tagsList[postIdx])}
                handleAddition={(tag) => handleTagSelection(tag)}
                placeholder={'Search tags'}
                autofocus={false}
              />
            </div>
          </div>
          {!isTagFetching && favTagsList.length ? (
            <div className="filter-item filter-item-tag__item">
              <div className="imtc ef-edit">
                <h3>Favorite Tags</h3>
                {!isTagFetching && userOwnership === USER_OWNERSHIP.FRANCHISOR && (
                  <>
                    <div className="edit-icon-media">
                      <ImageValidation
                        isImgValid
                        defaultImg={'create_post-edit'}
                        customName="Edit"
                        onClick={() => handleURLPathname(`/${userOwnership}/${id.toString()}/${currentRoute}/${optionalParams[0]}/favorite_tags`)}
                      />
                      <span className="cf-values" onClick={() => handleURLPathname(`/${userOwnership}/${id.toString()}/${currentRoute}/${optionalParams[0]}/favorite_tags`)}>
                        Edit
                      </span>
                    </div>
                  </>
                )}
              </div>
              <div className="fts">
                {updatedFavoriteList?.length
                  ? updatedFavoriteList.map((tagItem, index) => {
                      return (
                        <span
                          key={`mg-fav-tags-${index}`}
                          className={`fav-tags${mediaGalleryFilter.tags_list.find((datum: TagSuggestion) => datum.label === tagItem.label) ? ` active` : ``}`}
                          onClick={() => handleTagSelection(tagItem)}
                        >
                          {tagItem.label}
                        </span>
                      );
                    })
                  : null}
              </div>
            </div>
          ) : null}
          <div className="filter-item">
            <h3>Type</h3>
            <div className={`form-group`}>
              {MG_FILTER_MEDIA_TYPE.map((item, index) => {
                const isChecked = item.value === optionalParams[0];
                return (
                  <label key={`mg-type-radio-${index}`} className={isChecked ? 'active' : ''}>
                    <input
                      value={item.value}
                      type="radio"
                      className="option-input radio"
                      name={'type'}
                      checked={isChecked}
                      onChange={(event) => {
                        handleChange({ type: event.target.value });
                      }}
                    />
                    <span className="labelText">{item.label}</span>
                  </label>
                );
              })}
            </div>
          </div>
          {isMultiSelectModalOpen ? (
            <MultiSelectMediaOrPostModal
              isShowModal={isMultiSelectModalOpen}
              onModalClose={() => {
                setIsMultiSelectModalOpen(false);
                // reset selected media ids
                dispatch(mgResetSelectedMediaList());
                if (queryParams?.multi_syndicated_to_any_franchisor) {
                  const queryParamObj = { ...queryParams };
                  delete queryParamObj?.multi_syndicated_to_any_franchisor;
                  navigate({ search: `?${new URLSearchParams(queryParamObj).toString()}` });
                }
              }}
              type={
                optionalParams[0] === MgMediaType.PHOTOS
                  ? FranchisorSyndicationMediaType.PHOTO
                  : optionalParams[0] === MgMediaType.VIDEOS
                  ? FranchisorSyndicationMediaType.VIDEO
                  : FranchisorSyndicationMediaType.DOCUMENT
              }
            >
              <MultiSelectMGInfinityScrollList height={785} />
            </MultiSelectMediaOrPostModal>
          ) : null}

          {/* <div className="filter-item">
            <h3>Sort By</h3>
            <div className={`form-group`}>
              {MG_FILTER_SORT_BY.map((item, index) => {
                const isChecked = item.value === optionalParams[0];
                return (
                  <label key={`mg-type-radio-${index}`} className={isChecked ? 'active' : ''}>
                    <input
                      value={item.value}
                      type="radio"
                      className="option-input radio"
                      name={'type'}
                      checked={isChecked}
                      onChange={(event) => {
                        handleChange({ type: event.target.value });
                      }}
                    />
                    <span className="labelText">{item.label}</span>
                  </label>
                );
              })}
            </div>
          </div> */}
        </>
      )}
    />
  );
};
