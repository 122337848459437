import { APP_TITLE } from './common';

// MEDIA GALLERY
export const MG_FETCHING_ASSETS_STATS_FAILED = 'Fetching media gallery assets stats failed!';
export const MG_FETCHING_ASSETS_LIST_FAILED = 'Fetching media gallery assets list failed!';
export const MG_UPLOAD_MEDIA_IN_CLOUDINARY_FAILED = 'Upload media asset in cloudinary failed!';
export const MG_EXCEPTION_ERROR = 'Error occured in media gallery';
export const MG_FETCHING_FAV_TAGS_FAILED = 'Fetching media gallery favourite tags list failed!';
export const MG_ADD_MEDIA_ASSET_SUCCESS_MSG = 'Asset added successfully!';
export const MG_ADD_MULTIPLE_MEDIA_ASSETS_SUCCESS_MSG = 'Assets added successfully!';
export const MG_ADD_MEDIA_ASSET_FAILURE_MSG = 'Failed to add media asset. Try again!';
export const MG_DELETE_ASSET_SUCCESS_MSG = 'Asset deleted';
export const MG_DELETE_ASSET_FAILURE_MSG = 'You are denied to delete the asset';
export const MG_DELETE_ASSET_CONFIRM_MSG = 'Are you sure?';
export const MSG_RELEASE_DOWNLOAD_SUCCESS = 'Model Release Form downloaded successfully!';
export const MSG_RELEASE_DOWNLOAD_NOT_AVAIL = 'Model Release Form not available to download!';
export const MG_LIKE_FAILED = 'Fetching media gallery like failed!';
export const MG_DISLIKE_FAILED = 'Fetching media gallery dislike failed!';
export const MG_DETAIL_DATA_FAILED = 'Fetching media gallery detail data failed!';
export const MG_UPDATE_DETAIL_DATA_FAILED = 'Updating media gallery detail data failed!';
export const MG_UPDATE_DETAIL_DATA_SUCCES = 'Asset updated successfully';
export const MG_CONTENT_ALREADY_LOCKED = 'The asset owner has locked this content.';
export const MG_CLONE_ASSET_SUCCESS_MSG = 'Asset cloned successfully!';
export const MG_CLONE_ASSET_FAILED_MSG = 'Failed to clone media asset. Try again!';
export const MG_UNSUPPORTED_FILE_TYPE_MSG = 'Please upload only image, video and document formats!';
export const MG_UPLOAD_MEDIA_FAILED = 'Upload media asset failed!';

// POSTS
export const POST_FETCHING_LIST_FAILED = `Fetching posts list failed!`;
export const POST_FETCHING_DETAIL_DATA_FAILED = 'Fetching posts detail data failed!';
export const POST_FETCHING_STATS_FAILED = `Fetching posts stats failed!`;
export const POST_FETCHING_TAG_LIST_FAILED = 'Fetching posts tag list failed!';
export const POST_FETCHING_TAG_STATS_FAILED = 'Fetching posts tag stats failed!';
export const POST_DELETE_SUCCESS_MSG = `Post deleted`;
export const POST_DELETE_FAILURE_MSG = `You are denied to delete the post`;
export const POST_DELETE_CONFIRM_MSG = `Are you sure?`;
export const POST_COPY_SUCCESS_MSG = `Alright! Your content has been copied.`;
export const POST_COPY_FAILURE_MSG = `Your content has been copied failed.`;
export const POST_DETAIL_INT_NOTES_LIST_FAILURE_MSG = `Internal notes list failed!`;
export const POST_DETAIL_ADD_INT_NOTE_FAILURE_MSG = `Add internal notes failed!`;
export const POST_EDIT_CONTENT_DRAFT_FAILURE_MSG = `Post detail edit content draft failed!`;
export const POST_EDIT_CONTENT_EXPIRE_DATE_FAILURE_MSG = `Post detail update expire date failed!`;
export const POSTS_REJECT_SAVED_POST_SUCCESSFULLY = 'Post Rejected';
export const POSTS_REJECT_SAVED_POST_FAILED = 'Post has not been rejected';
export const POSTS_PACKAGE_EXPIRED_MSG = 'Sorry, either this package has expired or its start date is more than 60 days from now.';
export const POSTS_SYNDICATE_EXPIRED_MSG = 'Sorry, either this post has expired.';
export const POST_EXCEPTION_ERROR = `Error occured in posts`;
export const POST_NOT_AVAILABLE_MSG = `This post is currently not available for publishing.`;

// CAMPAIGNS
export const CAMPAIGNS_LIST_FAIL_MSG = 'Fetching campaigns list failed!';
export const CAMPAIGNS_STATS_FAIL_MSG = 'Fetching campaign stats failed!';
export const CAMPAIGNS_EVENT_LIST_FAIL_MSG = 'Fetching campaign event list failed!';
export const CAMPAIGNS_EVENT_FAIL_MSG = 'Fetching campaign event failed!';
export const CREATE_OR_UPDATE_CAMPAIGN_EVENT_FAIL_MSG = 'Create or update campaign event failed!';
export const INVALID_INPUT_ERROR_MSG = 'Invalid input';
export const CAMPAIGN_ALREADY_SCHEDULED_LOC_ERROR_MSG = 'This campaign has been already scheduled to the selected locations';
export const STOP_CAMPAIGN_EVENT_FAIL_MSG = 'Stop campaign event failed!';
export const CAMPAIGN_POST_DELETE_EVENT_FAIL_MSG = 'Campaign posts delete event failed!';
export const CREATE_OR_UPDATE_CAMPAIGN_FAIL_MSG = 'Create or update campaign failed!';
export const GET_CAMPAIGN_DETAIL_FAIL_MSG = 'Get campaign detail failed!';
export const DELETE_CAMPAIGN_SUCCESS_MSG = 'Campaign deleted successfully!';
export const DELETE_CAMPAIGN_FAIL_MSG = 'Delete campaign failed!';
export const DEACTIVATE_CAMPAIGN_EVENT_FAIL_MSG = 'Deactivate campaign event failed!';
export const CAMPAIGN_SORT_POSTS_LIST_FAIL_MSG = 'Posts sorting failed!';
export const ADD_POSTS_TO_CAMPAIGN_FAIL_MSG = 'Add posts to campaign failed!';
export const CAMPAIGNS_DELETE_POSTS_FAIL_MSG = 'Remove campaign posts failed!';
export const CAMPAIGNS_POST_LIST_FAIL_MSG = 'Fetching campaigns post list failed!';
export const CAMPAIGN_POST_ADD_CAMPAIGN_SUCCESS_MSG = 'Campaign added successfully!';
export const CAMPAIGN_POST_UPDATE_CAMPAIGN_SUCCESS_MSG = 'Campaign updated successfully!';
export const ACTIVE_CAMPAIGN_CONFIRM_MSG = 'Deleting a campaign with active schedules will remove all posts scheduled for future!';
export const DELETE_ACTIVE_CAMPAIGN_CONFIRM_MSG = 'You are trying to delete a campaign with active schedules, are you sure you want to delete this campaign?';
export const DELETE_CAMPAIGN_CONFIRM_MSG = 'Are you sure you want to delete this campaign?';
export const STOP_CAMPAIGN_SCHEDULE_CONFIRM_MSG = 'Are you sure you want to stop this campaign schedule?';
export const STOP_CAMPAIGN_CONFIRM_MSG = 'You are trying to stop a campaign with active schedules, are you sure you want to stop this campaign?';
export const STOP_CAMPAIGN_CONFIRM_SEC_MSG = 'Stopping a campaign with active schedules will remove all posts scheduled for future!';
export const POST_ASSIGNED_TO_CAMPAIGN_SUCCESS_MSG = 'Post assigned to campaign';
export const TIME_SLOT_ALREADY_EXISTED_ERROR_MSG = 'The time slot is already existed. Try different time';
export const ACTIVE_CAMPAIGN_MODAL_CONFIRM_MSG = 'You are editing an active campaign. Scheduled posts against this campaign will be re-arranged. Do you want to continue?';
export const ACTIVE_CAMPAIGN_WARN_MSG = 'You are editing an active campaign. Scheduled posts against campaign will be re-arranged.';
export const CAMPAIGNS_EXCEPTION_ERROR = `Error occured in campaigns`;

// ADD POST
export const ADD_POST_SAVE_POST_NOW_SUCCESS_MSG = 'Got it! Your post is on its way, and we’ve saved it in your database for later use';
export const ADD_POST_SCHEDULE_POST_SUCCESS_MSG = 'We’ve scheduled your post and saved it in your database for later use';
export const ADD_POST_SEND_TO_APPROVAL_SUCCESS_MSG = 'Your post has been sent for approval';
export const ADD_POST_SAVE_AS_DRAFT_SUCCESS_MSG = 'Your post has been saved as draft';
export const ADD_POST_SAVE_POST_NOW_FAILED = 'Save post now failed!';
export const ADD_POST_SAVE_TO_DATABASE_SUCCESS_MSG = 'This post has been saved to your custom database for later use';
export const ADD_POST_SAVE_AS_SCHEDULED_FAILED = 'Save as scheduled post failed!';
export const ADD_POST_UPDATE_SAVED_POST_SUCCESS_MSG = 'Your post has successfully been updated';
export const ADD_POST_UPDATE_SAVED_POST_FAILED = 'Update saved post failed!';
export const ADD_POST_COUPON_LIST_FAILED = 'Coupon list failed!';
export const ADD_POST_FB_TAG_LIST_FAILED = 'Add post FB tag list failed!';
export const ADD_POST_GET_INSTA_PHOTO_PREVIEW_FAILURE = 'Add post instagram photo preview failed!';
export const ADD_POST_ADD_BOOST_FOR_SCH_POST_SUCCESS_MSG = 'Your post will be boosted with the settings you specified';
export const ADD_POST_ADD_BOOST_FOR_SCH_POST_FAILURE = 'Add post boost for scheduled post failed!';
export const ADD_BOOST_FOR_SCH_POST_NO_DATA_MSG = 'None of the accounts you selected are configured for Facebook boost.';
export const ADD_POST_EXCEPTION_ERROR = `Error occured in create post`;
export const EDIT_POST_CONFIRM_MSG = `Are you sure you want to edit an approved post?
The post will need to be resubmitted for approval.`;
export const ADD_POST_APPROVE_AND_SYNDICATE_MSG = `This post has been syndicated`;
export const ADD_SCH_POST_INVALID_DATE_MSG = `Sorry, you cannot schedule a post in the past.`;
export const ADD_BOOST_FOR_CONTENT_ANALYTICS_SUCCESS_MSG = 'The post has been boosted with the settings you specified';

// FORGOT PASSWORD
export const FGP_EXCEPTION_ERROR_MSG = `Failed to send message to e-mail address`;
export const FGP_SUCCESS_MSG = 'Reset password link has been successfully sent to your email.';
export const FGP_ERROR_MSG = 'Email not found';

// LOGIN
export const LOGIN_EXCEPTION_ERROR_MSG = `Failed to login`;
export const GET_LOGIN_USER_DATA_FAILED = `Get login user data failed!`;
export const UPDATE_USER_DETAIL_FAILED = `Update user detail failed`;
export const IMPERSONATE_USER_FAILED = 'Impersonate user API failure';
export const INVALID_ACCOUNT_FOR_IMPERSONATED_USER_MSG = 'You have no brand or location access. Please contact your admin.';

// RESET PASSWORD
export const FE_ERROR_MSG = 'Failed to fetch user email';
export const RSP_EXCEPTION_ERROR_MSG = `Failed to reset password to e-mail address`;

// ACCOUNT SWITCHER
export const MSG_ACC_SWITCHER_ERROR = 'Error occured in account switcher';
export const LOCATION_SELECTOR_ERROR = 'Error occured in location selector request';
export const GET_LOC_BASED_ON_HUB_OR_LOC_LIST_ERROR = 'Error occured in location request';

// ACCOUNT
export const FRANCHISEE_PERMISSION_DATA_FAILURE = 'Get franchisee permission failed!';
export const ACCOUNT_ACTIVATE_FAILURE = 'Activate account failed!';
export const ACCOUNT_ERROR_MSG = 'Error occured in accounts';

// FRANCHISORS
export const FRANCHISORS_EXCEPTION_ERROR_MSG = 'Error occured in franchisors';

// CLOUDINARY UPLOAD TOAST MESSAGES
export const CLOUD_IMG_UPLOAD_FAILED = `Cloudinary image upload failed!`;
export const CLOUD_SIGN_FAILED = `Cloudinary signature generation failed!`;

// MENU ACCESSIBILITY
export const MA_EXCEPTION_ERROR_MSG = 'Error occured in menu accessible';
export const MA_FETCHING_DATA_FAILED = 'Fetching menu accessible data failed!';

// SETTINGS RALLIO PROFLIE
export const ACCOUNTS_UPDATE_PROFILE_FAILED = 'Saving rallio profile failed';
export const ACCOUNTS_GET_PROFILE_FAILED = 'Get rallio profile data failed';
export const ACCOUNTS_ADD_PROFILE_FAILED = 'Add rallio profile data failed';
export const ACCOUNT_PROFILE_EXCEPTION_ERROR = `Error occured in rallio profile`;

// SETTINGS - PERMISSION
export const SETTINGS_SUCCESS_MSG = 'Saved';
export const SETTINGS_FAILURE_MSG = 'Failed';

// SETTINGS RALLIO PROFILE UPDATE RESPONSE
export const RALLIO_PROFILE_DETAILS_UPDATE_SUCCESS = 'Profile updated successfully';
export const RALLIO_PROFILE_DETAILS_UPDATE_FAIL = 'Profile updation Failed';
export const RALLIO_PROFILE_HEADER_TOOLTIP = `This is how your business name will appear in your ${APP_TITLE} account.`;

// SETTINGS GET TIME ZONE
export const GET_TIME_ZONES_FAILED = 'Fetching time zones failed';
// SETTINGS GET COUNTRIES CODE
export const GET_COUNTRIES_CODE_FAILED = 'Fetching countries code failed';

// INBOX
export const INBOX_FETCHING_LIST_FAILED = `Fetching inbox list failed!`;
export const INBOX_FETCHING_STATS_FAILED = `Fetching inbox stats failed`;
export const INBOX_DISMISS_FAILED = `Dismiss list failed!`;
export const INBOX_PIE_RELOAD_FAILED = `Reload list failed!`;
export const INBOX_REPLY_FAILED = `Inbox reply failed!`;
export const INBOX_COMMENTS_LIKE_FAILED = `Comments like failed!`;
export const INBOX_COMMENTS_FAVOURITE_FAILED = `Comments favourite failed!`;
export const INBOX_COMMENTS_DELETE_CONFIRM_MSG = `Are you sure you want to delete this comment?`;
export const INBOX_RETWEET_FAILED = `Retweet failed!`;
export const INBOX_EXCEPTION_ERROR = `Error occured in inbox`;
export const INBOX_DELETE_FAILED_MSG = `Sorry, we couldn't delete this comment — please try again later or contact ${APP_TITLE} for support.`;
export const INBOX_NO_POSTS_INFO_MSG = 'You have no posts with unseen engagement during this period.';
export const INBOX_NO_SAVED_POST_COMMENTS_MSG = 'You have no posts with favourite comments.';

// REVIEWS
export const REVIEWS_FETCHING_LIST_FAILED = `Fetching reviews list failed!`;
export const REVIEWS_FETCHING_STATS_FAILED = `Fetching review stats failed!`;
export const REVIEWS_ADD_REPLY_FAILED = `Adding reply to a post failed!`;
export const REVIEWS_COMPLETE_POST_FAILED = `Completing review post failed!`;
export const REVIEWS_FETCHING_INTERNAL_NOTES_FAILED = `Fetching reviews internal notes failed!`;
export const REVIEWS_ADD_INTERNAL_NOTE_FAILED = `Add reviews internal note failed!`;
export const REVIEWS_HANDLE_FAILED = `Review handled api failed!`;
export const REVIEWS_SEND_FOR_REPLY_APPROVAL_FAILED = `Send for reply approval failed!`;
export const REVIEWS_UPDATE_SUBMITTED_REPLY_FAILED = `Updating submitted reply failed!`;
export const REVIEWS_APPROVE_REPLY_FAILED = `Approve a reply failed!`;
export const REVIEWS_REJECT_REPLY_FAILED = `Reject a reply failed!`;
export const REVIEWS_DELETE_REPLY_FAILED = `Delete a reply failed!`;
export const REVIEWS_SEND_FOR_REPLY_APPROVAL_SUCCESS_MSG = `A reply has been sent for approval`;
export const REVIEWS_ADD_REPLY_SUCCESS_MSG = `Reply sent!`;
export const REVIEWS_REJECT_REPLY_SUCCESS_MSG = `Reply rejected`;
export const REVIEWS_ADD_INT_NOTES_SUCCESS_MSG = `Internal note sent!`;
export const REVIEWS_EXCEPTION_ERROR = `Error occured in reviews`;
export const ENABLE_MANUAL_SETTINGS_AI_REVIEW_RESPONDER = `Please enable and configure the AI review settings to generate review responses. Click here to get started.`;

// CALENDAR
export const GET_SCHEDULED_POST_LIST_FAILED = 'Get scheduled post list failed!';
export const GET_CALENDAR_ITEMS_LIST_FAILED = 'Get calendar items list failed!';
export const UPDATE_REQUESTED_POST_FAILED = 'Requested post failed!';
export const UPDATE_SCHEDULE_POST_UPDATE_SUCCESS = 'Schedule post updated successfully!';
export const UPDATE_SCHEDULE_POST_UPDATE_FAILED = 'Schedule post failed!';
export const UPDATE_SAVED_POST_UPDATE_SUCCESS = 'Saved post updated successfully!';
export const UPDATE_SAVED_POST_UPDATE_FAILED = 'Update saved post Failed!';
export const POST_APPROVED_SUCCESSFULLY = 'Post approved!';
export const POST_APPROVED_FAILED = 'Post approved failed!';
export const DELETE_SCH_POST_CONTENT_FAILED = 'Delete scheduled post content failed!';
export const CALENDAR_EXCEPTION_ERROR = `Error occured in calendar`;
export const DELETE_SCH_CONTENT_POST_SUCCESS = `Removed successfully`;
export const DELETE_SCH_CONTENT_POST_FAIL = `Removed failed`;
export const REMOVED_MSG = 'Removed';
export const CONNECT_SOCIAL_MEDIA_TO_APPROVE_THE_POST = 'To approve this post, you need to connect a social media account.';
export const CALENDAR_SELECT_FUTURE_DATE_WARNING = 'Please select a future date & time to create a new schedule.';

// ADD POST FRANCHISOR
export const ADD_POST_CONTENT_DATA_FAILED = 'Add post content post data failed!';
export const ADD_POST_INTERNAL_NOTES_FAILED = 'Add post internal notes failed!';
export const ADD_POST_GET_LOC_LIST_FAILED = 'Add post location list failed!';
export const ADD_POST_ADD_LINK_FAILED = 'Add post add link failed!';
export const ADD_POST_DELETE_LINK_FAILED = 'Add post delete link failed!';
export const ADD_POST_UPDATE_LINK_FAILED = 'Add post update link failed!';
export const ADD_POST_DELETE_CUSTOM_LOC_MSG = 'Are you sure you would like to delete this list?';
export const ADD_POST_DELETE_CONTENT_DATA_FAILED = 'Delete content post data failed!';
export const ADD_POST_UPDATE_CONTENT_FAILED = 'Add post update content failed!';
export const ADD_POST_ADD_SCHEDULED_MULTIPOSTS_FAILED = 'Add post add scheduled multiposts failed!';
export const ADD_POST_MASS_UPDATE_FOR_SCH_POST_FAILED = 'Add post mass update for scheduled post failed!';
export const ADD_POST_DELETE_CONTENT_SUCCESS_MSG = 'Deleted!';
export const ADD_POST_SAVE_CONTENT_DRAFT_SUCCESS_MSG = 'Saved!';
export const ADD_POST_APPROVE_AND_SAVE_TO_DB_SUCCESS_MSG = 'Approved!';
export const ADD_POST_APPOVED_BUT_NOT_SCHEDULE_SUCCESS_MSG = 'Approved post but not scheduled!';
export const ADD_POST_SCHEDULE_SUCCESS_MSG = 'Done! You can check your posting schedule to see this post';
export const ADD_POST_FRAN_POST_NOW_SUCCESS_MSG = 'You got it! Your post will be sent out momentarily';
export const ADD_CONTENT_FRAN_GET_VERTICALS_FAILED = 'Get add content vericals failed';
export const ADD_POST_INVALID_LINK_MSG = 'Invalid Link';
export const GET_PINTEREST_BOARDS_FAILED_MSG = 'Error occurred while fetching pinterest boards ';

// DIRECTORY LISTINGS
export const DL_CATEGORIES_FAILED = `Directory listings categories failed!`;
export const DL_SYNUP_LISTING_FAILED = `Directory listings synup listing failed!`;
export const DL_UPDATE_FAILED = `Directory listings update failed`;
export const DIRECTORY_LISTINGS_ERROR = `Error occured in directory listing`;
export const DL_UPDATED_SUCCESSFULLY = `Saved!`;
export const DL_UPDATED_FAILED = `Failed`;
export const DL_INVALID_END_TIME_WARN = `End time can not be before start time! Please select a valid time to continue.`;
export const DL_INVALID_START_TIME_WARN = `Start time can not be after end time! Please select a valid time to continue.`;
export const DL_INVALID_SECOND_SLOT_WARN = `Second time slot must start after first time slot! Please select a valid time to continue.`;
export const DL_LOC_LISTING_NAME_TOOLTIP = `This is how your business name will appear on sites like Google, Yelp, etc`;
export const DL_INVALID_DATE_WARN = `Date must start after today's date! Please select a valid date to continue.`;
export const DL_EXISTS_DATE_WARN = `Date already exists! Please select a valid Deleteddate to continue`;

// USER LOGINS
export const USER_LOGINS_ERROR = `Error occured in inbox`;
export const UL_ACCOUNT_OWNERS_FAILED = `User logins account owners failed!`;
export const UL_ACCOUNT_OWNERS_CHECK_EMAIL_FAILED = `Account owners check email failed!`;
export const UL_UPDATE_ACCOUNT_OWNERS_FAILED = `Update account owners failed!`;
export const UL_DELETE_ACCOUNT_OWNERS_FAILED = `Delete account owners failed!`;
export const UL_DELETE_ACCOUNT_OWNER_SUCCESS_MSG = `Removed!`;
export const UL_RESEND_INIVITE_FOR_USER_FAILED = `Resend invite for user failed!`;
export const UL_RESEND_INVITE_FOR_USER_SUCCESS_MSG = `Mail Sent!`;
export const UL_ACTIVE_USER_DELETED_MSG = 'The user has been deleted. Please add the user again to login using this credential if needed.';
// CONNECTIONS
export const CONNECTIONS_ERROR = `Error occured in connections`;
export const CONNECTIONS_LOC_LIST_FAILED = `Connections location list failed!`;
// PROFILE IMAGERY
export const PROFILE_IMAGERY_ERROR = `Error occured in profile imagery`;
export const PROFILE_IMAGERY_LIST_FAILED = `Profile imagery list failed!`;
export const FILTER_PROFILE_IMAGERY_ERROR = `Error occured in profile imagery`;
export const FILTER_PROFILE_IMAGERY_LIST_FAILED = `Filter profile imagery list failed!`;
export const PROFILE_IMAGERY_ACCOUNT_PICTURE_UPDATE_FAIL = `Profile imagery Account picture update failed`;
export const UPDATE_PI_PROFILE_PHOTO_SUCCESS = 'Got it! Your profile photo will be changed momenterily.';
export const UPDATE_PI_COVER_PHOTO_SUCCESS = 'Got it! Your cover photo will be changed momenterily.';
export const UPDATE_PI_COVER_PHOTO_FAILED = 'Cover photo update failed!';
// ADVOCACY ONBOARD
export const ADVOCACY_ONBOARD_ERROR = `Error occured in advocacy onboard`;
export const ADVOCACY_ONBOARD_STATS_FAILED = `Advocacy onboard stats failed!`;
export const ADVOCACY_ONBOARD_LIST_FAILED = `Advocacy onboard list failed!`;
export const ADVOCACY_ONBOARD_MULTIPLE_RE_INVITE_FAILED = `Advocacy onboard multiple re-invite failed!`;
export const ADVOCACY_ONBOARD_RE_INVITE_MESSAGE = `Invitation email has been sent`;

// FB ADS TARGETING
export const FB_ADS_TARGETING_CHOICES_FAILURE = `FB Ads targeting choices failed!`;
export const FB_ADS_DISCONNECT_TARGETING_CHOICES_FAILURE = `FB Ads disconnect targeting choices failed!`;
export const FB_ADS_INTEREST_LIST_FAILURE = `An error occurred fetching the search results -- please try again.`;
export const FB_ADS_UPDATE_ACC_DEFAULT_CONFIG_FAILURE = `FB Ads update account default config failed!`;
export const FB_ADS_GET_AD_ACCOUNT_UUID_FAILURE = `FB Ads get ad account uuid failed!`;
export const FB_ADS_GET_AD_ACCOUNT_ID_FAILURE = `FB Ads get ad account id failed!`;
export const FB_ADS_ADD_AD_PREVIEW_FAILURE = `FB Ads add ad preview failed!`;
export const FB_ADS_UPDATE_ACC_DEFAULT_CONFIG_SUCCESS_MSG = `Default settings updated`;
export const FB_ADS_DISCONNECT_DELETE_CONFIRM_MSG = `Any posts you have already boosted will remain boosted after you remove your ad account.
 You can use Calendar to delete any boosted posts you have scheduled for the future. Are you sure you want to continue?`;
export const FB_ADS_GET_AVERAGE_LOCATIONS_SPEND_ERROR_MSG = 'You must set a higher lifetime spend';

// REWARD PROGRAM
export const RP_ERROR = `Error occured in reward programs`;
export const RP_STATS_FAILED = `Reward programs stats failed!`;
export const RP_LIST_FAILED = `Reward programs lists failed!`;
export const RP_GET_FAILED = `Get reward programs failed!`;
export const RP_DELETE_FAILED = `Delete reward programs failed!`;
export const RP_DELETE_MESSAGE = `Reward program deleted!`;
export const RP_ACHIEVERS_LIST_FAILED = `Reward programs deleted!`;
export const RP_END_DATE_VALIDATE_FAIL = 'End time must begin after start time. unable to continue.';
export const RP_CREATE_PROGRAM_SUCCESS = 'Program saved successfully!';
export const RP_CREATE_PROGRAM_FAIL = 'Program save failed!';
export const RP_UPDATE_PROGRAM_SUCCESS = 'Program updated successfully!';
export const RP_COPY_PROGRAM_SUCCESS_MSG = 'Program copied successfully!';
export const RP_UPDATE_PROGRAM_FAIL = 'Program updates failed!';
export const RP_LOCATION_LIST_FAILED = `Reward programs location list failed!`;
export const RP_PROMOTION_LIST_FAILED = `Reward programs promotion list failed!`;
export const RP_PROGRAM_DELETE_CONFIRM = `Are you sure you want to delete this reward program?`;
export const RP_CANCEL_PROGRAM_DELETE_MSG = `Are you sure you want to cancel this reward program?`;
export const RP_BUDGET_VALIDATE_FAIL_MSG = 'Your total reward amount should be less or equal to budget amount. Unable to continue.';
export const RP_ACTION_VALIDATE_FAIL_MSG = 'Condition already chosen. Unable to continue.';
export const RP_DETAIL_BUDGET_VALIDATE_FAIL_MSG = 'Your budget should be greater or equal to previous budget amount. Unable to continue.';

// OVERVIEW
export const OVERVIEW_EXCEPTION_ERROR = 'Error occured in overview';
export const OVERVIEW_LOCATION_LIST_FAILURE = 'Overview location list failed';
export const OVERVIEW_TOTAL_METRICS_FAILURE = 'Overview total metrics failed';
export const OVERVIEW_RECENT_POSTS_LIST_FAILURE = 'Overview recent posts list failed';
export const OVERVIEW_POST_METRICS_FAILURE = 'Overview post metrics failed';
export const OVERVIEW_MONTHLY_POST_METRICS_FAILURE = 'Overview monthly post metrics failed';

export const EA_OVERVIEW_TOTAL_ADVOCATES = 'Total advocates that have been sent an invite';
export const EA_OVERVIEW_INACTIVE_STATUS = 'Advocates with no recent activity';
export const EA_OVERVIEW_TOTAL_IMAGE_ASSETS = ' Total image assets uploaded by employees';
export const EA_OVERVIEW_TOTAL_IMAGE_ASSETS_USED = 'Total image assets from employees that are already used in posts';
export const EA_OVERVIEW_TOTAL_IMAGE_ASSETS_UNUSED = 'Total image assets from employees that are not yet used in posts';
export const EA_OVERVIEW_TOTAL_VIDEO_ASSETS = ' Total video assets uploaded by employees';
export const EA_OVERVIEW_TOTAL_VIDEO_ASSETS_USED = 'Total video assets from employees that are already used in posts';
export const EA_OVERVIEW_TOTAL_VIDEO_ASSETS_UNUSED = 'Total video assets from employees that are not yet used in posts';
export const EA_OVERVIEW_TOTAL_POST = 'Total posts created for this location by all users';
export const EA_OVERVIEW_USED_POST = 'Scheduled posts, yet to be published';
export const EA_OVERVIEW_PUBLISHED_POST = 'Total published posts for this location';

export const EA_OVERVIEW_TOTAL_POST_TEXT = 'Total posts created for this location by all users';
export const EA_OVERVIEW_USED_POST_TEXT = 'Scheduled posts, yet to be published';
export const EA_OVERVIEW_PUBLISHED_POST_TEXT = ' Total published posts for this location';

// ADVOCACY LEADERBOARD
export const ADV_LB_EXCEPTION_ERROR = 'Error occured in advocacy leaderboard';
export const ADV_LB_LIST_FAILURE = 'Leaderboard assets list failed';
export const ADV_LB_FILTER_STATS_FAILURE = 'Leaderboard filter stats failed';

// STRIPE
export const MSG_STRIPE_NO_CUSTOMERS_DATA = 'No customers found';
export const MSG_STRIPE_NO_CARDS_DATA = 'No cards found';
export const MSG_STRIPE_API_EXCEPTION = "Error occured in stripe api call's";

// CONTENT -COUPONS
export const COUPONS_LIST_FAILED = 'Coupons list failed!';
export const COUPON_DETAIL_VIEW_FAILED = 'Coupon detail failed!';
export const DELETE_COUPON_SUCCESS = 'Deleted!';
export const ADD_COUPON_FAILED = 'Add coupon failed!';
export const EDIT_COUPON_FAILED = 'Update coupon failed!';
export const DELETE_COUPON_FAILED = 'Delete Failed';
export const CP_CLOUD_UPLOAD_MEDIA_FAILED = 'Add cloudinary media for coupon failed!';
export const COUPON_EXCEPTION_ERROR = 'Error occured in coupons';

// NOTIFICATION SETTINGS
export const SAVE_NOTIFICATION_SETTINGS_FAILED = 'Save notification settings failed!';
export const NOTIFICATION_SETTINGS_EXCEPTION_ERROR = 'Error occured in notification settings!';
export const SAVE_PREFERENCES_SUCCESS = 'Updated your preferences!';
export const SAVE_PREFERENCES_FAILURE = 'Update preferences failed!';
export const NS_ENTER_PHONE_NUMBER_WARN_MSG = 'Please enter phone number under SMS Notification settings!';
export const NS_INVALID_PHONE_NUMBER_WARN_MSG = 'Please enter valid phone number!';

// CHANGE PASSWORD
export const CHANGE_PASSWORD_EXCEPTION_ERROR = 'Error occured in password change!';
export const INCORRECT_CHANGE_PASSWORD = 'Incorrect current password';

// ADMIN AREA
export const ADMIN_AREA_ACCOUNT_LIST_FAILED = 'Get admin area account list failed!';
export const ADMIN_AREA_FRANCHISORS_LIST_FAILED = 'Get admin area franchisors list failed!';
export const ADMIN_AREA_USERS_LIST_FAILED = 'Get admin area users list failed!';
export const ADMIN_AREA_GET_USERS_DETAIL_FAILED = 'Get admin area user detail failed!';
export const ADMIN_AREA_UPDATE_USERS_DETAIL_FAILED = 'Update admin area user detail failed!';
export const ADMIN_AREA_ADD_USER_INVITE_EMAIL_FAILED = 'Add admin area user invite email failed!';
export const ADMIN_AREA_EXCEPTION_ERROR = 'Error occured in admin area';
export const ADMIN_AREA_EXIST_EMAIL_FAIL_MSG = 'Failed -- does this email address already have a login?';

export const EXPORT_DOWNLOAD_STARTED_MSG = 'Download started';

export const MG_FILE_SIZE_EXCEEDED_ERROR_MSG = 'File size exceeds its maximum limit of 500MB';
export const MG_DOCUMENT_SIZE_EXCEEDED_ERROR_MSG = 'File size exceeds its maximum limit of 40MB';

// RSS FEEDS
export const RSS_FEEDS_EXCEPTION_ERROR = `Error occured in rss feeds`;

// SALES
export const SCHEDULE_DEMO_CONTACT_EMAIL_MESSAGE = `Thanks for contacting us! We will be in touch with you shortly.`;
export const SCHEDULE_DEMO_CONTACT_EMAIL_FAILED_MESSAGE = `Email failed!`;
export const REVV_LOGIN_FAILED = `Revv connection failed. Try again!`;
export const SALES_EXCEPTION_ERROR = `Error occured in sales`;

// FB ADS US ZIP CODE VALIDATION
export const US_ZIP_CODE_VALIDATION_ERROR = `Please specify a valid US zip code`;

// ERROR PAGE
export const SOMETHING_WENT_WRONG = 'Oops! Something went wrong.';
export const RELOAD = 'Click Ok to reload the app.';

// OPERTION LISTS
export const OPERATION_LISTS_ERROR_MSG = 'Error occured in operation lists';
export const OPERATION_LISTS_LOC_HUB_LIST_ERROR = 'Locations hub lists failed';
export const OPERATION_LISTS_LOC_TREE_ERROR = 'Location tree failed';
export const OPERATION_LISTS_LOC_HUB_DETAIL_ERROR = 'Location hub detail failed';
export const OPERATION_LISTS_SAVE_LOC_GROUP_ERROR = 'Save location group failed';
export const OPERATION_LISTS_DELETE_LOC_LIST_ERROR = 'Delete location list failed';
export const SUBSCRIPTIONS_LOCATION_TO_LIST_ERROR = 'Subscription location to list failed';
export const SUBSCRIPTIONS_LISTS_ERROR_MSG = 'Error occured in subscription lists';

// AI CONTENT CREATOR
export const AI_CONTENT_CREATOR = 'Error occured in AI content creator';
export const AI_GPT3_MODEL_SUGGEST_FAILED = 'AI Model retrieved failed. Try again!';
export const AI_DRAFT_CREATION_FAILED = 'AI post creation failed. Try again!';
export const AI_SUBSCRIPTION_PLAN_FAILED = 'AI subscription plan creation failed. Try again!';
export const AI_TRIAL_SUBSCRIPTION_PLAN_SUCCESS = 'AI trial subscription started successfully';
export const AI_SUBSCRIPTION_CANCEL_SUCCESS = 'AI Subscription successfully cancelled';
export const AI_SUBSCRIPTION_UNSUBSCRIBE_SUCCESS = 'AI Subscription successfully Unsubscribed';
export const AI_SUBSCRIPTION_UPGRADE_SUCCESS = 'AI Subscription Updated successfully';
export const AI_SUBSCRIPTION_INFO_MESSAGE = 'Please select a card to proceed.';
export const AI_SUBSCRIPTION_RENEW_FAIL = 'AI Subscription cannot be renewed';
export const AI_SUBSCRIPTION_RENEW_SUCCESS = 'AI Subscription renewed successfully';
export const AI_SUBSCRIPTION_UPGRADE_YEARLY_SUCCESS = 'AI subscription upgraded successfully.';
export const AI_HASHTAGS_NOT_RETREIVED = 'No hashtags were generated!\n\n Rallio AI is still learning! Click the generate button to try again.';
export const AI_IOS_SUBSCRIPTION = 'Subscription can be managed by you from the platform where you have made the purchase (Rallio AI App Store).';
export const AI_ASSISTANT_DEFAULT_TEXT = 'AI Prompt Ideas” for suggestions on how to optimize the AI results or simply click, “Generate” to see the results.';

// USER DETAIL MODAL - //COMMON
export const GET_USERS_DETAIL_FAILED = 'Get user detail failed!';
export const UPDATE_USERS_DETAIL_FAILED = 'Update user detail failed!';
export const GET_CURRENT_USER_TAB_LIST_FETCHING_FAILED = 'Get current user tab list fetching failed!';
export const USER_DETAIL_EXCEPTION_ERROR = 'Error occured in user detail';
export const LOAD_ENV_VARS_FAILED = 'Env load failed.';

export const DONE_SUCCESS_MSG = 'Done!';

// SOCIAL CONTENTS
export const SC_PIE_RELOAD_FAILED = 'Reload list failed';
export const SC_EXCEPTION_ERROR = 'Error occured in social contents';
export const SC_DELETE_CONFIRM_MSG = 'Are you sure you want to delete this post?';
export const SC_HIDE_CONFIRM_MSG = 'Are you sure you want to mark this post as read?';
export const SC_COMMENTS_FAILED = `Comments failed!`;

// AI PLAYBOOK
export const AI_PLAYBOOK_UPDATE_SUCCESS = 'AI playbook updated successfully!';
export const AI_PLAYBOOK_UPDATE_FAILED = 'Update ai playbook failed!';
export const AI_PLAYBOOK_ERROR = 'Error occured in ai playbook!';
export const AI_PLAYBOOK_DOCUMENT_DELETE_CONFIRM_MSG = `Are you sure you want to delete this document?`;
export const AI_PLAYBOOK_CUSTOMER_PERSONA_CONFIRM_MSG = `Are you sure you want to delete this customer persona?`;
export const AI_PLAYBOOK_ACTIVITY_GOAL_DELETE_CONFIRM_MSG = `Are you sure you want to delete this activity goal?`;
export const AI_PLAYBOOK_DOCUMENT_CREATE_SUCCESS_MSG = 'Document uploaded successfully!';
export const CUSTOMER_PERSONA_GENERATE_SUCCESS_MSG = 'Customer persona generated successfully!';
export const AI_PLAYBOOK_DOCUMENT_DELETE_SUCCESS_MSG = 'Document deleted successfully!';
export const AI_PLAYBOOK_UPLOAD_PDF_DOCUMENT_MSG = 'Please upload pdf document!';
export const AI_PLAYBOOK_COMPLIANCE_SUMMARY_GENERATE_SUCCESS_MSG = 'Compliance summary generated successfully!';
export const AI_PLAYBOOK_COMPLIANCE_SUMMARY_DELETE_CONFIRM_MSG = `Deleting the summary will remove all the compliance settings. Are you sure you want to delete this summary?`;
export const AI_PLAYBOOK_COMPLIANCE_SUMMARY_HAS_WARNING_MSG = 'Error occured while generating compliance summary!';
export const AI_PLAYBOOK_EDIT_COMPLIANCE_SUMMARY_WARNING_MSG = `Editing the compliance summary will directly influence how the Al monitors compliance. Your changes here will guide its behavior and enforcement. Please proceed carefully.`;
export const COMPLIANCE_UNSAVED_WARN_MSG = `You have unsaved changes that will be lost. Click “Generate Compliance Summary & Approve” button to save the changes.`;

// AI IMAGE GENERATION
export const AI_IMAGE_GENERATION_UPDATE_RESPONSE = 'Selected image(s) is being saved to the media.';
export const AI_IMAGES_NOT_RETREIVED = 'No Images were generated!\n\n Rallio AI is still learning! Click the regenerate button to try again.';
export const AI_STABLE_DIFFUSION_IMAGE_GENERATION_FAILURE = 'Oops! Image generation may be temporarily offline. Please try again.';

// KEYWORDS CATEGORY
export const KEYWORDS_ADD_CATEGORY_SUCCESS_MSG = 'Category added successfully!';
export const KEYWORDS_UPDATE_CATEGORY_SUCCESS_MSG = 'Category updated successfully!';
export const KEYWORDS_DELETE_CATEGORY_SUCCESS_MSG = 'Category deleted successfully!';
export const KEYWORDS_DELETE_CATEGORY_FAILURE_MSG = 'Failed to delete category. Try again!';
export const KEYWORDS_DELETE_CATEGORY_DELETE_CONFIRM = `Are you sure you want to delete this category?`;

// POST SYNDICATION
export const POST_SYNDICATION_EXCEPTION_ERROR = `Error occured in post syndication`;
export const ADD_MEDIA_APPROVE_AND_SYNDICATE_MSG = `This media has been syndicated`;

// HASHTAG
export const ENTER_VALID_HASHTAG = `Please type valid hashtag`;

// DIRECT MESSAGE THREAD
export const DIRECT_MESSAGE_FETCHING_FAILED = `Direct message fetching failed`;

// ACTIVITY GOAL
export const ACTIVITY_GOAL_CREATE_SUCCESS_MSG = `Activity goal created successfully!`;
export const ACTIVITY_GOAL_UPDATE_SUCCESS_MSG = `Activity goal updated successfully!`;
export const ACTIVITY_GOAL_DELETE_SUCCESS_MSG = `Activity goal deleted successfully!`;
export const ACTIVITY_GOAL_CREATE_FAILURE_MSG = `Failed to create activity goal. Try again!`;
export const ACTIVITY_GOAL_UPDATE_FAILURE_MSG = `Failed to update activity goal. Try again!`;
export const ACTIVITY_GOAL_DELETE_FAILURE_MSG = `Failed to delete activity goal. Try again!`;

// COMMUNICATION
export const COMMUNICATION_CREATE_SUCCESS_MSG = 'Communication created successfully!';
export const COMMUNICATION_UPDATE_SUCCESS_MSG = 'Communication updated successfully!';
export const COMMUNICATION_DELETE_SUCCESS_MSG = 'Communication deleted successfully!';
export const COMMUNICATION_CREATE_FAILURE_MSG = 'Failed to create communication. Try again!';
export const COMMUNICATION_UPDATE_FAILURE_MSG = 'Failed to update communication. Try again!';
export const COMMUNICATION_DELETE_FAILURE_MSG = 'Failed to delete communication. Try again!';
export const COMMUNICATION_DELETE_CONFIRM_MSG = `Are you sure you want to delete this message?`;

// CONTENT SUPPLIER
export const CONTENT_SUPPLIER_EXCEPTION_ERROR = 'Error occured in content supplier';

// CONTENT SCHEDULER SLOT LIST
export const ACCOUNT_SCHEDULER_CREATE_SLOT_SUCCESS_MSG = 'Schedule created successfully. Eligible posts will be scheduled shortly!';
export const ACCOUNT_SCHEDULER_UPDATE_SLOT_SUCCESS_MSG = 'Schedule updated successfully. Eligible posts will be scheduled shortly!';
export const ACCOUNT_SCHEDULER_DELETE_SLOT_SUCCESS_MSG = 'Scheduled slot deleted successfully!';
export const ACCOUNT_SCHEDULER_CREATE_SLOT_FAILURE_MSG = 'Failed to create schedule slot. Try again!';
export const ACCOUNT_SCHEDULER_UPDATE_SLOT_FAILURE_MSG = 'Failed to update schedule slot. Try again!';
export const ACCOUNT_SCHEDULER_DELETE_SLOT_FAILURE_MSG = 'Failed to delete schedule slot. Try again!';
export const ACCOUNT_SCHEDULER_DELETE_SLOT_CONFIRM_MSG = `Are you sure you want to delete this slot?`;
export const CONTENT_SCHEDULER_INFO_MSG = `Setup your content calendar to automatically publish what you want, when you want it. Click on any day in the calendar to get started!`;
export const ACCOUNT_SCHEDULER_EXCEPTION_ERROR = 'Error occured in account scheduler';
export const LOCATION_USER_TIME_SLOT_DISABLE_ALERT_MSG = 'You cannot modify this schedule because it was created by a brand-level user.';
export const BRAND_USER_FEED_NOT_AVALIABLE_MSG = 'You currently have no active subscriptions to create a schedule. Please subscribe to a feed to proceed.';

// AI ASSISTANT
export const AI_ASSISTANT_NEW_CHAT_FAILURE_MSG = 'Oops! AI assistant may be temporarily offline. Please try it again.';
export const AI_ASSISTANT_CHAT_HISTORY_LIST_FAILURE_MSG = 'Error occured in ai assistant';
export const CLEAR_CHAT_HISTORY_CONFIRM_MSG = `Are you sure you want to clear chat history?`;

/*SETTINGS BRAND LOGO */
export const SETTINGS_BRAND_LOGO_DELETE_CONFIRM_MSG = `Are you sure you want to delete brand logo?`;
export const SETTINGS_BRAND_LOGO_UNSUPPORTED_FILE_TYPE_MSG = 'Please upload only image format!';
export const SETTINGS_FEED_BADGE_LOGO_DELETE_CONFIRM_MSG = `Are you sure you want to delete feed badge logo?`;

// SETTINGS PARTNER BANNERS
export const PARTNER_BANNER_EXCEPTION_ERROR = 'Error occured in partner banners';

// FEED LIST
export const JOIN_FEED_LIST_MSG = 'Are you sure want to join this feed list?';
export const LEAVE_FEED_LIST_MSG = 'Are you sure want to leave this feed list?';
export const SUBSCRIBE_FEED_LIST_CONFIRMATION_MSG =
  'Changing your Feed selections will update your scheduled content. Would you like to delete your current schedule and restart with your selected feeds, or keep the existing schedule and apply the changes in 30 days?';
export const SHUFFLE_POSTS_CONFIRMATION_MSG = 'Do you want a fresh selection of automated posts in your calendar?';
export const DISCOUNT_PRICE_OFFER_MSG = {
  MSG1: 'Welcome!',
  MSG2: 'Your account is already paid for.',
  MSG3: `However, if you'd like additional features, upgrade in the first 7 days and receive 20% off!`
};
export const FEED_LIST_ENROLL_ALREADY_EXIST = 'You have already been enrolled!';

export const MEDIA_RELEASE_INFO_TEXT = `By uploading content, you confirm that you have obtained the necessary consent or permission for the use of any such photos and/or videos.`;
export const NO_SIGNATURE_WARN_MSG = 'Please complete the signature';
export const NO_AI_RESPONSE_PREVIEW_MSG = `We would like to show you a few sample responses to your existing reviews, but you currently do not have any. We strongly recommend that you connect your Facebook or Google accounts to Rallio before enabling this feature.`;
export const AI_REVIEWS_PREVIEW_FETCHING_LIST_FAILED = `Oops, something went wrong. We couldn’t generate the previews—please try again.`;
export const AI_REVIEW_RESPONDER_LOCALIZE_INFO = `Checking this option will use the local phone number and email in place of the ones defined above.`;
export const AI_REVIEW_RESPONDER_PREVIEW_LOADING_TEXT = 'Prior to starting, let me generate some previews for you.';
export const CONNECT_SOCIAL_PLATFORMS_TEXT = 'Connect your social platforms';
export const CONNECT_SOCIAL_PLATFORMS_SECOND_TEXT = 'Let’s light this up! Connect at least one social platform, and the numbers will roll in!';

export const MASS_UPDATE_TAGS_SUCCESS_MSG = 'Tags updated Successfully';
export const MASS_UPDATE_TAGS_FAILURE_MSG = 'Tags updated failed!';

export const SSO_ACCOUNT_SWITCH_BRAND_ERROR = 'Error occured in sso account switch to brand!.';

export const NO_SOCIAL_MEDIA_CONNECTED_MSG = 'No social media platforms connected!. please connect at least one social media platform and try again.';

// SETTINGS HASH TAGS
export const HASHTAGS_EXCEPTION_ERROR = 'Error occured in hashtags';
