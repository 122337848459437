export enum SettingsHashtagsActionsVal {
  OFF = 'off',
  IN_COMMENT = 'in_comment',
  IN_POST = 'in_post'
}

export enum SettingsHashtagsActionsIdentifier {
  OFF = 0,
  IN_COMMENT = 1,
  IN_POST = 2
}

export enum SettingsHashtagsActionsLabel {
  OFF = 'Off',
  IN_COMMENT = 'Added as First Comment',
  IN_POST = 'Added to Caption'
}

export const SettingsHashtagsHeadline = 'Automatically add specific hashtags to the end of your post captions or as the first comment.';

export const SETTINGS_HASHTAG_ACTIONS = [
  { id: 1, label: SettingsHashtagsActionsLabel.OFF, value: SettingsHashtagsActionsVal.OFF, identifier: SettingsHashtagsActionsIdentifier.OFF },
  { id: 2, label: SettingsHashtagsActionsLabel.IN_COMMENT, value: SettingsHashtagsActionsVal.IN_COMMENT, identifier: SettingsHashtagsActionsIdentifier.IN_COMMENT },
  { id: 3, label: SettingsHashtagsActionsLabel.IN_POST, value: SettingsHashtagsActionsVal.IN_POST, identifier: SettingsHashtagsActionsIdentifier.IN_POST }
];
