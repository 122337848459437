import { useState, useEffect, useContext, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Tag } from 'react-tag-autocomplete';

import { commonModalPopup, IStore } from 'types';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { ImageValidation } from 'widgets/Media';
import { useAccountSwitcherData } from 'utils/hooks';
import {
  ALERT_BOX_TYPES,
  RIPPLE_COLOR,
  // SEPERATE_TEXT_TO_HASH_REGEX,
  USER_OWNERSHIP,
  ENTER_VALID_HASHTAG,
  // REMOVE_SPACE_FROM_TEXT,
  // SPLIT_HASHTAGS,
  COMMON_SOCIAL_PROVIDER_TYPE,
  MG_MEDIA_TYPE,
  GET_HASHTAG_ONLY_REGEX,
  ADD_POST_CONTENT_MAX_TEXT_LENGTH
} from 'utils/constants';
import {
  addPostCreateHashtagRequest,
  addPostDeleteHashtagRequest,
  addPostGetHashtagsListRequest,
  addPostSelectHashtagResponse,
  addPostUpdateSavedPostDetails,
  addPostGetSelectedContentObj
} from 'actions';
import { alertBoxCall, Loading } from 'components';
import {
  convertEditorStateToString,
  convertStringToEditorState,
  /* debounceMethod, */ formNewAITag,
  getUniqueHashtags,
  insertHashtagAtCursorPosition,
  isValidHashtag,
  removeHashtagsFromText
} from 'utils/helpers';
import { EditorStateContext } from '..';
import { NO_DATA_FOUND } from 'analytics/utils';
import { CustomReactTags } from 'widgets/CustomReactTags';

export const AddHashtag = (props: commonModalPopup.IDetailModalPopup & { id: number }) => {
  const { isModalShow, handleModalClose } = props;

  const dispatch = useDispatch();
  const { id, userOwnership } = useAccountSwitcherData();

  const isLinkFetching = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj.isLinkFetching);
  const { hashtagsList, isFetching, selectedHashtagsList } = useSelector((state: IStore) => state.addPostFranchisorAction.hashtagsListObj);
  const savedPostDetails = useSelector((state: IStore) => state.addPostAccount.savedPostDetails);
  const contentObj = useSelector((state: IStore) => state.addPostFranchisor.contentObj);
  const selectedMediaList = useSelector((state: IStore) => state.addPostFranchisor.selectedMediaList);

  const [hashtagText, setHashtagText] = useState('');
  const [hashtags, setHashtags] = useState<string[]>(hashtagsList);
  const [selectedHashtags, setSelectedHashtags] = useState<string[]>(selectedHashtagsList);
  const [textEditorState, setEditorState] = useContext(EditorStateContext);
  const [creatorText, setCreatorText] = useState(savedPostDetails?.[`${savedPostDetails?.current_tab}_text`] || contentObj.customText[`${contentObj.currentTab}`] || '');

  const searchHashtagTimeout = useRef<null | NodeJS.Timeout>(null);

  const tagsCustomerAliasList = formNewAITag([], hashtagText);

  const isAllHashtags = selectedHashtags.length === hashtags.length;

  useEffect(() => {
    dispatch(addPostGetHashtagsListRequest({ id, userOwnership }));
  }, [dispatch, id, userOwnership]);

  useEffect(() => {
    if (searchHashtagTimeout.current) clearTimeout(searchHashtagTimeout.current);
    setHashtagText('');
    setHashtags(hashtagsList);
    setSelectedHashtags(selectedHashtagsList);
  }, [hashtagsList]); // eslint-disable-line

  const addOrRemoveHashtags = (platform: string) => {
    const uniqueHashtags = getUniqueHashtags(selectedHashtags, creatorText);
    const newState = uniqueHashtags?.length ? insertHashtagAtCursorPosition(textEditorState[platform], uniqueHashtags) : convertStringToEditorState(creatorText);
    const modifiedText = uniqueHashtags?.length ? convertEditorStateToString(newState) : creatorText;
    setEditorState({ ...textEditorState, [platform]: newState });
    return modifiedText;
  };

  const addHashtagsToCreator = () => {
    const modifiedText = addOrRemoveHashtags(savedPostDetails && userOwnership === USER_OWNERSHIP.ACCOUNT ? savedPostDetails.current_tab : contentObj.currentTab);
    const hashTagsLimit: string[] = modifiedText.match(GET_HASHTAG_ONLY_REGEX) || [];
    dispatch(addPostSelectHashtagResponse(selectedHashtags));
    if (savedPostDetails && userOwnership === USER_OWNERSHIP.ACCOUNT) {
      dispatch(
        addPostUpdateSavedPostDetails({
          ...savedPostDetails,
          [`use_${savedPostDetails.current_tab}`]: modifiedText.trim()?.length
            ? savedPostDetails?.current_tab === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
              ? Boolean(selectedMediaList?.length && hashTagsLimit.length < 30 && modifiedText.length <= ADD_POST_CONTENT_MAX_TEXT_LENGTH[savedPostDetails?.current_tab.toUpperCase()])
              : savedPostDetails?.current_tab === COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK
              ? selectedMediaList?.[0]?.media_type === MG_MEDIA_TYPE.VIDEO
              : savedPostDetails?.current_tab === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
              ? savedPostDetails?.facebook_reel
                ? selectedMediaList?.[0]?.media_type === MG_MEDIA_TYPE.VIDEO
                : Boolean(modifiedText)
              : Boolean(modifiedText && modifiedText.length <= ADD_POST_CONTENT_MAX_TEXT_LENGTH[savedPostDetails?.current_tab.toUpperCase()])
            : false,
          [`${savedPostDetails.current_tab}_text`]: modifiedText
        })
      );
    } else {
      dispatch(
        addPostGetSelectedContentObj({
          ...contentObj,
          postStatus: {
            ...contentObj.postStatus,
            [contentObj.currentTab]: modifiedText.trim().length
              ? contentObj?.currentTab === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
                ? Boolean(selectedMediaList?.length && hashTagsLimit.length < 30 && modifiedText.length <= ADD_POST_CONTENT_MAX_TEXT_LENGTH[contentObj?.currentTab.toUpperCase()])
                : contentObj?.currentTab === COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK
                ? selectedMediaList?.[0]?.media_type === MG_MEDIA_TYPE.VIDEO
                : contentObj?.currentTab === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
                ? contentObj?.facebook_reel
                  ? selectedMediaList?.[0]?.media_type === MG_MEDIA_TYPE.VIDEO
                  : Boolean(modifiedText)
                : Boolean(modifiedText && modifiedText.length <= ADD_POST_CONTENT_MAX_TEXT_LENGTH[contentObj?.currentTab.toUpperCase()])
              : false
          },
          customText: {
            ...contentObj?.customText,
            [contentObj.currentTab]: modifiedText
          }
        })
      );
    }
    handleModalClose();
  };

  const handleHashtags = (isSelected: boolean, name: string) => {
    const state = isSelected ? selectedHashtags.filter((datum) => datum !== name) : [...selectedHashtags, name];
    setSelectedHashtags(state);
    if (isSelected) {
      setCreatorText((text: string) => removeHashtagsFromText([name], creatorText));
    }
  };

  const handleSelectAllHashtags = (isChecked: boolean) => {
    setSelectedHashtags(isChecked ? hashtagsList : []);
    if (!isChecked) {
      setCreatorText(() => removeHashtagsFromText(selectedHashtagsList, creatorText));
    }
  };

  const createHashtags = (tag: Tag) => {
    // const validHashtags: string[] = [];
    // const splittedHashtags = hashtagText.match(SPLIT_HASHTAGS);
    // splittedHashtags?.forEach((it) => {
    //   const removeSpaceFromHashTags = String(`#${it}`.replace(REMOVE_SPACE_FROM_TEXT, '').match(SEPERATE_TEXT_TO_HASH_REGEX));
    //   return validHashtags.push(removeSpaceFromHashTags);
    // });
    // if (!validHashtags.includes('null')) {
    //   validHashtags?.forEach((it) => {
    //     dispatch(addPostCreateHashtagRequest({ id, userOwnership, data: it }));
    //   });
    // } else {
    //   alertBoxCall(ALERT_BOX_TYPES.ERROR, ENTER_VALID_HASHTAG);
    // }

    tag.label = tag?.label?.includes('Add ') ? tag?.label?.replace('Add ', '') : tag?.label;
    tag.label = tag?.label.indexOf('#') === 0 ? tag?.label : `#${tag?.label}`;

    if (isValidHashtag(tag?.label) && tag?.label?.length <= 50) {
      dispatch(addPostCreateHashtagRequest({ id, userOwnership, data: tag?.label }));
    } else {
      alertBoxCall(ALERT_BOX_TYPES.ERROR, ENTER_VALID_HASHTAG);
    }
  };

  const deleteHashTag = () => {
    dispatch(addPostDeleteHashtagRequest({ id, userOwnership, data: selectedHashtags }));
  };

  return (
    <>
      <Modal className="sm-modal-wrp addHashTags" size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={isModalShow} animation={false} onHide={handleModalClose}>
        <div className="mod__close--icon">
          <ImageValidation defaultImg="mpcIcon" customClassname="close-icon close mod__close--img wh-30" customName={'close'} onClick={handleModalClose} />
        </div>
        <Modal.Body>
          <h3 className="add__link--title">Hashtags</h3>
          <div className="filesUpload adlinkselected">
            <div className="fu-cnt">
              <div className="fuitem">
                <div className="fui-left">
                  {/* <Form.Group className="form-group form-field-item"> */}
                  {/* <input
                      type="text"
                      className="form-control"
                      placeholder="Search / Add Hashtag"
                      value={hashtagText || ''}
                      onChange={(event) => setHashtagText(event.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          createHashtags();
                        }
                      }}
                      onKeyUp={() => {
                        // added debounce method for search
                        searchHashtagTimeout.current = debounceMethod(hashtagText.trim(), searchHashtagTimeout.current, (searchValue: string) => {
                          if (searchValue.length) {
                            setHashtags(hashtagsList.filter((list) => list.toLowerCase().includes(searchValue.trim().toLowerCase())));
                          } else {
                            setHashtags(hashtagsList);
                          }
                        });
                      }}
                    /> */}
                  {/* {hashtagText.trim() && !isFetching ? (
                      <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
                        <button className="add-items" onClick={createHashtags}>
                          <ImageValidation isImgValid defaultImg="add-item-cBlue" customName="Add HashTags" />
                        </button>
                      </CustomRippleButton>
                    ) : null} */}
                  {/* </Form.Group> */}
                  <div className="srp-hashtags urh ht-dds">
                    <div className="glbl__modal--card globalCard card">
                      <div className="card-body content-full-wrp settins-content--wrp">
                        <div className="elementsInner">
                          <CustomReactTags
                            key={`settings-hashtags`}
                            suggestions={!hashtagsList.find((hashtag) => hashtag === (hashtagText.indexOf('#') === 0 ? hashtagText : `#${hashtagText}`)) ? tagsCustomerAliasList : []}
                            handleInputChange={(search: string) => {
                              setHashtagText(search);
                              if (search) {
                                setHashtags(hashtagsList.filter((list) => list.toLowerCase().includes(search.trim().toLowerCase())));
                              } else {
                                setHashtags(hashtagsList);
                              }
                            }}
                            handleDelete={() => false}
                            handleAddition={(tag: Tag) => createHashtags(tag)}
                            placeholder="Search / Add Hashtag"
                            autofocus={false}
                            delimiterKeys={!hashtagsList.find((hashtag) => hashtag === (hashtagText.indexOf('#') === 0 ? hashtagText : `#${hashtagText}`)) ? undefined : []}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="aih-w-group">
                  <div className="aih-w-head">
                    <div className="aih-w-left">
                      {selectedHashtags.length ? (
                        <>
                          <h3>Selected</h3>
                          <span className="sel-count-wrap">
                            (<span className="sel-count">{selectedHashtags.length}</span> {`${selectedHashtags.length > 1 ? 'Hashtags' : 'Hashtag'}`})
                          </span>
                        </>
                      ) : null}
                    </div>
                    <div className="aih-w-right">
                      {hashtags.length ? (
                        <div className={`saDatas`}>
                          <label key={`checkbox`} className={'checkbox-item active checkbox-item'}>
                            <span className="checkbox-hover">
                              <input
                                value={'all'}
                                type="checkbox"
                                className="option-input checkbox"
                                name="All"
                                checked={isAllHashtags}
                                onChange={(event) => handleSelectAllHashtags(event?.target.checked)}
                              />
                            </span>
                            <span className="labelText">Select All</span>
                          </label>
                        </div>
                      ) : null}
                      {selectedHashtags.length ? (
                        <button className="aih-w-right-btn" onClick={deleteHashTag}>
                          <ImageValidation isImgValid defaultImg="delete-b-red" customName="Delete HashTags" />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <div className="ai-hashtags">
                    {isFetching ? (
                      <Loading />
                    ) : !hashtags.length ? (
                      <p className="ncData">{NO_DATA_FOUND}</p>
                    ) : (
                      hashtagsList.length &&
                      hashtags.map((name, index) => {
                        const isSelected = selectedHashtags.includes(name);
                        return (
                          <div className={`ai-picks aitht-item cursor-pointer ${isSelected ? 'active' : ''}`} key={`hashtag-${index}`} onClick={() => handleHashtags(isSelected, name)}>
                            <div className="ait-front">
                              <span>{name}</span>
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
                <div className="btn-wrp-separate">
                  <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
                    <button type="button" className={'ac-btn ac-primary ac-block'} onClick={addHashtagsToCreator}>
                      {!isLinkFetching ? `Done` : `Generating...`}
                    </button>
                  </CustomRippleButton>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
