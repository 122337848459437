import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { IStore } from 'types';
import { useActivePlatform, useNavBarData } from 'utils/hooks';
import { COMMON_SOCIAL_PROVIDER_TYPE, SIDE_NAV_NAME } from 'utils/constants';
import { CommonStats } from 'components';

export const SCStats = () => {
  const activePlatforms = useActivePlatform();
  const { subNavPageName } = useNavBarData();

  const isStatsFetching = useSelector((state: IStore) => state.socialContents.isStatsFetching);
  const facebook = useSelector((state: IStore) => state.socialContents.socialContentsStats.facebook);
  const instagram = useSelector((state: IStore) => state.socialContents.socialContentsStats.instagram);
  const twitter = useSelector((state: IStore) => state.socialContents.socialContentsStats.twitter);
  const linkedin = useSelector((state: IStore) => state.socialContents.socialContentsStats.linkedin);
  const tiktok = useSelector((state: IStore) => state.socialContents.socialContentsStats.tiktok);
  const pinterest = useSelector((state: IStore) => state.socialContents.socialContentsStats.pinterest);
  const totalStatCount = useSelector((state: IStore) => state.socialContents.socialContentsStats.totalStatCount);
  const brandActivePlatforms = useSelector((state: IStore) => state.socialContents.brandActivePlatforms);

  const platforms = useMemo(() => {
    switch (subNavPageName) {
      case SIDE_NAV_NAME.COMMUNITY_SANDBOX:
        return brandActivePlatforms.filter((it) => it !== COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE && it !== COMMON_SOCIAL_PROVIDER_TYPE.YELP);
      case SIDE_NAV_NAME.REPUTATION_OUTBOX:
        return activePlatforms.filter((it) => it !== COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE);
      default:
        return [];
    }
  }, [activePlatforms, brandActivePlatforms, subNavPageName]);

  return (
    <CommonStats
      data={{
        facebook,
        twitter,
        instagram,
        linkedin,
        tiktok,
        pinterest,
        totalStatCount
      }}
      activePlatforms={platforms}
      isLoading={isStatsFetching}
      // title={subNavPageName}
      title="TOTALS"
    />
  );
};
