import { put, takeLatest } from 'redux-saga/effects';

import {
  customerBasedSurveysRequestFailure,
  customerBasedSurveysResponses,
  customerSendSurveyRequestFailure,
  customerSendSurveyResponses,
  sendQueuedSurveysFailure,
  sendQueuedSurveysResponse,
  sendSurveyRequestFailure,
  sendSurveyResponse,
  surveyListRequestFailure,
  surveyListResponse,
  surveyMetricsRequestFailure,
  surveyMetricsResponse
} from 'revv/actions';
import * as actionTypes from 'revv/actions/action-types';
import { ISagaPayload, ISurveyListRequestPayload, SagaValue, SagaYield, ISurveyMetricRequestPayload, ISendSurveyRequest, INewSurveyRequestParams } from 'revv/types';
import { API_ROUTES, surveyResposneHandler } from 'revv/utils';
import { API_TYPES } from 'utils/constants';
import { apiCall, defaultRevvHeader } from 'utils/helpers';

function* sendSurveyCustomerRequest(action: ISagaPayload<ISendSurveyRequest>): Generator<SagaYield, void, SagaValue> {
  try {
    const response = yield apiCall({
      apiPath: API_ROUTES.sendSurvey.apiPath,
      action: API_ROUTES.sendSurvey.method,
      type: API_TYPES.REVV,
      headers: defaultRevvHeader(),
      data: { data: action.payload }
    });
    if (response.status === 200) {
      yield put(customerSendSurveyResponses('Survey sent successfully.'));
    } else {
      yield put(customerSendSurveyRequestFailure('Error on send survey.'));
    }
  } catch (error: any) {
    yield put(customerSendSurveyRequestFailure(error));
  }
}

function* loadSurveyMetricsRequest(action: ISagaPayload<ISurveyMetricRequestPayload>): Generator<SagaYield, void, SagaValue> {
  try {
    const response = yield apiCall({
      apiPath: API_ROUTES.surveyMetrics.apiPath,
      action: API_ROUTES.surveyMetrics.method,
      headers: defaultRevvHeader(),
      type: API_TYPES.REVV,
      params: {
        from_date: action.payload?.fromDate,
        to_date: action.payload?.toDate
      },
      data: {
        rallio_account_ids: action.payload?.rallioLocationId,
        rallio_franchisor_ids: action.payload?.rallioFranchisorId,
        location_ids: action.payload?.locationIds
      }
    });
    if (response.status === 200) {
      const dataObj = {
        percSurveyAgreedCount: response.data?.data?.perc_surveys_agreed_to_review,
        percSurveyCompletedCount: response.data?.data?.perc_surveys_completed,
        percSurveyLeftFeedBackCount: response.data?.data?.perc_surveys_left_feedback,
        percSurveyNotSentCount: response.data?.data?.perc_surveys_not_sent,
        percSurveySentCount: response.data?.data?.perc_surveys_sent,
        surveyAgreedCount: response.data?.data?.surveys_agreed_to_review_count,
        surveyAvgRatingCount: response.data?.data?.surveys_average_rating,
        surveyCompletedCount: response.data?.data?.surveys_completed_count_by_create_at,
        surveyCreatedCount: response.data?.data?.surveys_created_count,
        surveyFeedBackCount: response.data?.data?.feedback_count,
        surveyNotSentCount: response.data?.data?.surveys_not_sent_count,
        surveyNPSCount: response.data?.data?.surveys_nps,
        surveySentCount: response.data?.data?.surveys_sent_count_by_create_at,
        surveyCancelledCount: response.data?.data?.surveys_cancelled_count,
        surveyAverageRatingCreated: response?.data?.data.surveys_average_rating_by_create_at,
        totalLocation: response?.data?.data.total_locations_count,
        totalMappingLocationsCount: response?.data?.data.total_mapping_locations_count
      };
      yield put(surveyMetricsResponse(dataObj));
    } else {
      yield put(surveyMetricsRequestFailure('Error occured on fetching Survey Metrics.'));
    }
  } catch (error) {
    yield put(surveyMetricsRequestFailure('Error'));
  }
}

function* loadSurveyListRequest(action: ISagaPayload<ISurveyListRequestPayload>): Generator<SagaYield, void, SagaValue> {
  try {
    const response = yield apiCall({
      apiPath: API_ROUTES.surveyList.apiPath,
      action: API_ROUTES.surveyList.method,
      headers: defaultRevvHeader(),
      type: API_TYPES.REVV,
      params: {
        'filter[from_transaction_date]': undefined,
        'filter[to_transaction_date]': undefined,
        'filter[keyword]': action.payload?.searchKeyword,
        'filter[survey_rating]': action.payload?.surveyRating,
        'filter[survey_status]': action.payload?.surveyStatus,
        'page[size]': action.payload?.pageSize,
        'page[number]': action.payload?.pageNumber,
        'filter[retail_type]': action.payload?.retailType
      },
      data: {
        from: action.payload?.fromDate,
        to: action.payload?.toDate,
        location_ids: action.payload?.locationIds,
        rallio_account_ids: action.payload?.rallioLocationId,
        rallio_franchisor_ids: action.payload?.rallioFranchisorId
      }
    });
    if (response.status === 200) {
      if (response.data) {
        const surveyListResponseData = surveyResposneHandler(response.data?.data);
        yield put(surveyListResponse(surveyListResponseData));
      }
    } else {
      yield put(surveyListRequestFailure('Error occured on fetching Survey List.'));
    }
  } catch (error) {
    yield put(surveyListRequestFailure('Error'));
  }
}

function* getCustomerRequest(action: ISagaPayload<INewSurveyRequestParams>): Generator<SagaYield, void, SagaValue> {
  try {
    if (action.payload) {
      const response = yield apiCall({
        apiPath: API_ROUTES.getCustomer.apiPath,
        action: API_ROUTES.getCustomer.method,
        type: API_TYPES.REVV,
        headers: defaultRevvHeader(),
        params: {
          phone_number: action.payload?.phoneNumber,
          email: action.payload?.email
        }
      });
      if (response.status >= 200 && response.status < 400) {
        if (action.payload.callback) {
          yield put(sendSurveyResponse(''));
          action.payload.callback({ isValid: true, id: response.data.data.id });
        }
      } else {
        if (action.payload.callback) {
          yield put(sendSurveyRequestFailure(''));
          action.payload.callback({ isValid: false });
        }
      }
    }
  } catch (error: any) {
    yield put(sendSurveyRequestFailure('Error Occured'));
  }
}

function* sendQueuedSurveysRequest(): Generator<SagaYield, void, SagaValue> {
  try {
    const response = yield apiCall({
      apiPath: API_ROUTES.sendQueuedSurveys.apiPath,
      action: API_ROUTES.sendQueuedSurveys.method,
      type: API_TYPES.REVV,
      headers: defaultRevvHeader()
    });
    if (response.status === 204) {
      yield put(sendQueuedSurveysResponse('All unsent surveys will be delivered to your customers shortly.'));
    } else {
      yield put(sendQueuedSurveysFailure('Error on send queued surveys.'));
    }
  } catch (error: any) {
    yield put(sendQueuedSurveysFailure(error));
  }
}

function* getCustomerBasedSurvey(action: ISagaPayload<ISurveyListRequestPayload>): Generator<SagaYield, void, SagaValue> {
  try {
    const response = yield apiCall({
      apiPath: API_ROUTES.surveyList.apiPath,
      action: API_ROUTES.surveyList.method,
      headers: defaultRevvHeader(),
      type: API_TYPES.REVV,
      params: {
        'filter[from_date]': action.payload?.fromDate,
        'filter[to_date]': action.payload?.toDate,
        'filter[keyword]': action.payload?.searchKeyword,
        'filter[survey_rating]': action.payload?.surveyRating,
        'filter[survey_status]': action.payload?.surveyStatus,
        'page[size]': action.payload?.pageSize,
        'page[number]': action.payload?.pageNumber,
        'filter[retail_type]': action.payload?.retailType,
        'filter[customer_id]': action.payload?.customerId
      },
      data: {
        location_ids: action.payload?.locationIds,
        rallio_account_ids: action.payload?.rallioLocationId,
        rallio_franchisor_ids: action.payload?.rallioFranchisorId
      }
    });
    if (response.status === 200) {
      const surveyListResponseData = surveyResposneHandler(response.data?.data);
      yield put(customerBasedSurveysResponses({ data: surveyListResponseData }));
    } else {
      yield put(customerBasedSurveysRequestFailure('OOPS! Something went wrong.'));
    }
  } catch (error: any) {
    yield put(customerBasedSurveysRequestFailure(error || 'OOPS! Something went wrong.'));
  }
}

export function* takeSurveyRequest() {
  yield takeLatest(actionTypes.CUSTOMER_SEND_SURVEY_REQUEST, sendSurveyCustomerRequest);
  yield takeLatest(actionTypes.SURVEY_METRICS_REQUEST, loadSurveyMetricsRequest);
  yield takeLatest(actionTypes.SURVEY_LIST_REQUEST, loadSurveyListRequest);
  yield takeLatest(actionTypes.SEND_SURVEY_REQUEST, getCustomerRequest);
  yield takeLatest(actionTypes.SEND_QUEUED_SURVEYS_REQUEST, sendQueuedSurveysRequest);
  yield takeLatest(actionTypes.CUSTOMER_BASED_SURVEYS_REQUEST, getCustomerBasedSurvey);
}
