import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { Button, Container } from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import moment from 'moment-timezone';
import { useState } from 'react';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';

import { ModalPopup, NameCircle } from 'revv/components';
import { IDashboardSurveyQuestionList } from 'revv/types';
import { config } from 'config';
import { Loading } from 'components';
import { findSmiley } from 'revv/utils';

interface IDetailedViewData {
  data: {
    locationId?: string;
    locationName?: string;
    date?: string;
    retailType?: string;
    customerName?: string;
    customerMail?: string;
    customerComment?: string;
    resolvedComment?: string;
    resolvedDate?: string;
    mobileNumber?: number | null | undefined;
    isDataAvailable?: boolean;
    ratingValue?: number;
  };
  questionList?: IDashboardSurveyQuestionList[];
}
interface ILocationListDetailedViewProps {
  detailedViewData: IDetailedViewData | null;
  isEnabled: boolean;
  enablePagination?: boolean;
  currentPage: number;
  totalPage?: number;
  updatePageNum?: (pageNum: number) => void;
  setModal: () => void;
  isFetching?: boolean;
  isCommentFieldEnabled?: boolean;
  isResolved?: boolean;
  classVariant?: string;
  triggerResolveNotification?: () => void;
  notificationComment?: string;
  updateNotificationComment?: React.Dispatch<React.SetStateAction<string>>;
  isPaginationDisabled?: boolean;
  getSurveyBasedOnUser?: () => void;
}

export const NotificationResolvingView = ({
  isEnabled,
  setModal,
  isFetching,
  isCommentFieldEnabled,
  isResolved,
  detailedViewData,
  currentPage,
  updatePageNum,
  totalPage,
  triggerResolveNotification,
  notificationComment,
  updateNotificationComment,
  isPaginationDisabled,
  getSurveyBasedOnUser
}: ILocationListDetailedViewProps) => {
  const [isResolvedCommentEnabled, setCommentResolved] = useState(false);

  return (
    <ModalPopup
      className={`non-notification__modal-container non-psp__modal notifResolvingView`}
      show={isEnabled}
      centered
      fullView
      bodyClass="p-0"
      backdropClasses="non-psp__modal-backdrop"
      onHide={setModal}
      renderBody={() => (
        <Container className="p-0" fluid>
          <img
            src={`${config.cdnRevvImgUrl}/modal-prev.svg`}
            alt="navigation"
            className={`nav-prev__icon ${currentPage <= 1 ? 'd-none' : 'cur-pointer'} 
            ${isPaginationDisabled ? 'd-none' : ''}`}
            onClick={() => {
              if (updatePageNum) {
                updatePageNum(currentPage - 1);
              }
            }}
          />
          <img
            src={`${config.cdnRevvImgUrl}/modal-next.svg`}
            alt="navigation"
            className={`nav-next__icon  ${currentPage === totalPage || currentPage === 0 ? 'd-none' : 'cur-pointer'} 
            ${isPaginationDisabled ? 'd-none' : ''}`}
            onClick={() => {
              if (updatePageNum) {
                updatePageNum(currentPage + 1);
              }
            }}
          />
          <div className="mod-close__icon cur-pointer" onClick={setModal}>
            <img src={`${config.cdnRevvImgUrl}/mpcIcon.svg`} alt="close" className="non-psp-mod-close" />
          </div>
          {isCommentFieldEnabled || isResolvedCommentEnabled ? (
            <div className="modal-container--wrp resolved-area padd-0">
              <aside className="resolved-edit__wrp">
                <span className="resolved-lbl">
                  How was it resolved ?<span className="required">*</span>
                </span>
                <textarea
                  placeholder={isResolvedCommentEnabled ? '' : 'Type your resolve comment here...'}
                  className="resolved-txt"
                  readOnly={isResolved}
                  value={notificationComment}
                  onChange={(event) => {
                    if (updateNotificationComment) {
                      updateNotificationComment(event.target.value);
                    }
                  }}
                />
              </aside>
            </div>
          ) : null}
          {isFetching ? <Loading /> : null}
          <div className="dashboard-detailed__modal--scroll">
            {detailedViewData?.data.isDataAvailable === false ? (
              <div className="h-100 notification__modal-inner--list with-resolved-textarea d-flex align-items-center justify-content-center">No location details found</div>
            ) : (
              <div className="h-100 notification__modal-inner--list with-resolved-textarea">
                <div className="notification-detailed-view">
                  <div className="resolve-btn--wrp d-flex align-items-center justify-content-between flex-column flex-md-row">
                    <div className="loc-name__hldr">
                      <div className="d-flex align-items-center flex-column flex-md-row notification__loc-wrp">
                        <span className="notification__loc-lbl text-dotted" title={detailedViewData?.data.locationName}>
                          {detailedViewData?.data.locationName}
                        </span>
                        {/* <hr className="v-line" />
                        <span className="notification__loc-lbl">ID: {detailedViewData?.data?.locationId || ''}</span> */}
                      </div>
                      <div className="notification__date fs-secondary">{detailedViewData?.data.date || ''}</div>
                    </div>
                    {isResolved ? (
                      <div className="resolved-view" onClick={() => setCommentResolved(!isResolvedCommentEnabled)}>
                        <div className="lbl-wrp">
                          <span className="lbl">Resolved</span>
                          <span className="sub-lbl">on {moment(detailedViewData?.data?.resolvedDate?.substring(0, 19) || '').format('MMM DD, hh:mm A')}</span>
                        </div>
                        <img src={`${isResolvedCommentEnabled ? `${config.cdnRevvImgUrl}/resolved-view.svg` : `${config.cdnRevvImgUrl}/resolve-view.svg`}`} alt="resolve" className="view" />
                      </div>
                    ) : null}
                    {isCommentFieldEnabled ? (
                      <div className="action-btn__wrp">
                        <Button
                          className={`clear-btn ${notificationComment ? '' : 'custom-disabled'}`}
                          onClick={() => {
                            if (updateNotificationComment) {
                              updateNotificationComment('');
                            }
                          }}
                        >
                          Clear
                        </Button>
                        <Button
                          className={`resolved-btn ${notificationComment ? '' : 'custom-disabled'}`}
                          onClick={() => {
                            if (triggerResolveNotification) {
                              triggerResolveNotification();
                            }
                          }}
                        >
                          Resolve
                        </Button>
                      </div>
                    ) : null}
                  </div>
                  <div className="note-name-mail--wrp d-flex align-items-center">
                    <NameCircle name={detailedViewData?.data?.customerName || ''} onClick={getSurveyBasedOnUser} />
                    {/* <span className="text-dotted customer-name">{detailedViewData?.data?.customerName}</span> */}
                    {detailedViewData?.data?.customerMail || detailedViewData?.data?.mobileNumber ? (
                      <span className="text-dotted email">{detailedViewData?.data?.customerMail || detailedViewData?.data?.mobileNumber || '-'}</span>
                    ) : null}
                  </div>
                  <article className="notification__description mt-1 mb-2 fs-secondary text-break overflow-auto">
                    <Scrollbars autoHide>{detailedViewData?.data?.customerComment || '-'}</Scrollbars>
                  </article>
                </div>
                <div className="notification__table-container ">
                  <Scrollbars autoHide>
                    <Table className="revv-dboard">
                      <Thead>
                        <Tr>
                          <Th>Questions</Th>
                          <Th>Rating</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {detailedViewData &&
                          detailedViewData?.questionList?.map((question: IDashboardSurveyQuestionList, index) => {
                            return (
                              <Tr key={index}>
                                <Td>
                                  <div className="d-flex align-items-center question-wrp">
                                    <span className="q-no">{`Q${index + 1}`}</span>
                                    <div className="question">{question.question}</div>
                                  </div>
                                </Td>
                                <Td>
                                  <div className="d-flex align-items-center">
                                    {detailedViewData.data.ratingValue && detailedViewData.data.ratingValue >= 0 ? (
                                      <img src={`${config.cdnRevvImgUrl}/${detailedViewData.data.ratingValue > 0 ? 'star' : 'star-grey'}.svg`} alt="review-status" />
                                    ) : findSmiley(question?.answer?.toLocaleLowerCase() || '') ? (
                                      <img className="smiley-icon" src={findSmiley(question?.answer?.toLocaleLowerCase() || '')} alt="smiley" />
                                    ) : null}
                                    <div className="score-lbl">
                                      {detailedViewData.data.ratingValue && detailedViewData.data.ratingValue >= 0 ? detailedViewData.data.ratingValue : question?.answer}
                                    </div>
                                  </div>
                                </Td>
                              </Tr>
                            );
                          })}
                      </Tbody>
                    </Table>
                  </Scrollbars>
                </div>
              </div>
            )}
          </div>
        </Container>
      )}
    />
  );
};
