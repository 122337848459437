import { SocialMediaPreviewCommentSec, SocialMediaPreviewFooterIcons } from 'containers/Content/Creator/CommonModal';
import { apFranContainerTypes } from 'types';
import { AP_FRAN_SHOW_LINE_BREAK_TYPES, CREATOR_LINKEDIN_PREVIEW_FOOTER_ICONS, MG_MEDIA_TYPE, SettingsHashtagsActionsVal } from 'utils/constants';
import { ImageValidation, ReactVideoPlayer } from 'widgets/Media';
import { Paragraph } from 'widgets/Text';
import { SocialMediaLinkPreview } from './SocialMediaLinkPreview';

export const LinkedinPostPreview = ({
  socialMedia,
  hashtagState,
  accSocialMedia,
  imagesLimitBasedOnPlatform,
  selectedMediaList,
  playVideo,
  setPlayVideo,
  isSocialMediaPostEnabled,
  caption,
  userName,
  profilePic,
  linkData,
  isThumbnailShow,
  ImageGridCol
}: apFranContainerTypes.ISocialMediaTwitterPostPreview) => {
  return (
    <div className="in-pn-preview">
      <div className="p-header" key={`creator-profile`}>
        <div className="p-header-title">
          <div className="p-avatar">
            <ImageValidation imgUrl={profilePic} defaultImg={'user-avatar-common.png'} customName={'Franchisor Profile Item'} isNotSvgFormat />
          </div>
          <div className="p-title">
            <h5>{userName}</h5>
            {/* <span className="in-light-txt">3 followers</span> */}
            <div className="in-tsp">
              <span className="in-light-txt">Just Now</span>
              <ImageValidation isImgValid defaultImg={'linkedin-dot'} customClassname="fbn-public" customName={''} />
              <div className="publicView">
                <ImageValidation isImgValid defaultImg={'linkedin-public'} customClassname="fbn-public" customName={'Public'} />
              </div>
            </div>
          </div>
        </div>

        <ImageValidation isImgValid defaultImg={'in-pn-more'} customClassname="in-p-more" customName={'More'} />
      </div>
      <div className="p-body previewtLinks p-0">
        <div className="msg-wrapper">
          <Paragraph customClassname="gm-text" customText={caption} actionType={AP_FRAN_SHOW_LINE_BREAK_TYPES.PREVIEW} />
          <span className="strans-__text d-none">Show translation</span>
        </div>
        <div className="am-media-preview pmg">
          {selectedMediaList.length >= 1 && selectedMediaList[0]?.media_type === MG_MEDIA_TYPE.PHOTO ? (
            imagesLimitBasedOnPlatform.length > 1 ? (
              <ImageGridCol imageUrls={imagesLimitBasedOnPlatform} socialMedia="linkedin" />
            ) : (
              <div
                className="np-post-img doWrap do-img"
                data-orientation="loading"
                ref={(el) => {
                  if (el && imagesLimitBasedOnPlatform[0]) {
                    const img = new Image();
                    img.src = imagesLimitBasedOnPlatform[0];
                    img.onload = () => el.setAttribute('data-orientation', img.naturalHeight > img.naturalWidth ? 'portrait' : 'landscape');
                  }
                }}
              >
                <ImageValidation imgUrl={imagesLimitBasedOnPlatform[0]} customName={'Image asset'} />
              </div>
            )
          ) : null}
          {selectedMediaList[0]?.media_type === MG_MEDIA_TYPE.VIDEO && (
            <div
              className="video-outer-element doWrap do-video"
              data-orientation="loading"
              ref={(el) => {
                if (el && selectedMediaList?.[0]?.medium_size_url) {
                  const video = document.createElement('video');
                  video.src = selectedMediaList[0]?.medium_size_url || '';
                  video.onloadedmetadata = () => {
                    el.setAttribute('data-orientation', video.videoHeight > video.videoWidth ? 'portrait' : 'landscape');
                  };
                }
              }}
            >
              <ReactVideoPlayer
                videoUrl={selectedMediaList[0]?.medium_size_url || ''}
                customClassname={'video'}
                isPlayControl={playVideo.includes('linkedin')}
                controls={false}
                onClick={() => {
                  if (isSocialMediaPostEnabled) {
                    setPlayVideo([...playVideo, 'linkedin']);
                  }
                }}
                light={selectedMediaList[0]?.thumbnail_url}
              />
            </div>
          )}
        </div>
      </div>
      {linkData?.id ? (
        <SocialMediaLinkPreview
          imageUrl={linkData?.link_preview_image_url || null}
          linkPreviewTitle={linkData?.link_preview_title || ''}
          linkUrl={linkData?.url || ''}
          isShowThumbnail={isThumbnailShow || false}
          linkPreviewDescription={linkData?.link_preview_description || ''}
          socialMedia={socialMedia}
          isLinkCustomized={linkData?.link_preview_customized || false}
          actionType={null}
        />
      ) : null}
      {/* <span className="commentCounter">1 comment</span> */}
      <SocialMediaPreviewFooterIcons socialMedia={socialMedia} footerIconsList={CREATOR_LINKEDIN_PREVIEW_FOOTER_ICONS} />
      {hashtagState && hashtagState.action_status === SettingsHashtagsActionsVal.IN_COMMENT && hashtagState.saved_hashtags?.length ? (
        <SocialMediaPreviewCommentSec socialMedia={socialMedia} hashtagState={hashtagState} accSocialMedia={accSocialMedia ?? []} />
      ) : null}
    </div>
  );
};
