import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import parse from 'html-react-parser';

import { ImageValidation, Video } from 'widgets/Media';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { IStore, calendarContainerTypes } from 'types';
import {
  calendarConvertDateBasedOnTimeZone,
  calendarInitScheduledTime,
  convertLocationTimezone,
  canEditScheduledPostDateAndTime,
  checkSiteRestriction,
  convertURLAsLink,
  dateObjBasedOnTimeZone,
  getPermittedImgsBasedOnPlatform,
  getTimezoneAbbreviation,
  calendarUTCtoLocalHandler
} from 'utils/helpers';
import {
  ALERT_BOX_TYPES,
  AP_FRAN_SHOW_LINE_BREAK_TYPES,
  AP_SAVED_POST_INIT_DETAILS,
  CALENDAR_DATE_FORMAT,
  CALENDAR_FILTER_INITIAL_VALUE,
  COMMON_SOCIAL_MEDIA_NAME,
  COMMON_SOCIAL_PROVIDER_TYPE,
  CONNECT_SOCIAL_MEDIA_TO_APPROVE_THE_POST,
  EMAIL_SHORT_DATE_FORMAT,
  GOOGLE_CALL_TO_ACTION_SELECT_OPTIONS,
  LOCATION_TIME_ZONE_DATE_TIME_FORMAT,
  MODAL_BOX_TYPES,
  RIPPLE_COLOR,
  SCHEDULE_PARAM_TYPE,
  SHUFFLE_POSTS_CONFIRMATION_MSG,
  USER_OWNERSHIP
} from 'utils/constants';
import { useAccountSwitcherData, useParamsDeconstructor, useSiteRestriction } from 'utils/hooks';
import { accountCreateScheduledPostRequest, addPostUpdateSavedPostDetails, calendarDeleteModalOption, calendarEditView, calendarLocationUpdates, calendarShuffleAutomatedCount } from 'actions';
import { IPlatformFilter } from 'types/common/filter';
import { Paragraph } from 'widgets/Text';
import { CalendarIconHandler } from './CalendarIconHandler';
import { FBCallToActionPreview, ModalPopup, alertBoxCall } from 'components';

export const CalendarViewPostFilter = ({
  data,
  isButtonsEnabled,
  handleEditCorporatePost,
  canEditAccountPost,
  detailPostId,
  availablePlatform,
  handleOnChangePostContent,
  canApprove,
  setScheduleModal
}: calendarContainerTypes.ICalendarFilterDefaultProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userOwnership, id, currentRoute, optionalParams } = useAccountSwitcherData();
  const { calendarRestriction } = useSiteRestriction();
  const { queryParams } = useParamsDeconstructor({ ...CALENDAR_FILTER_INITIAL_VALUE });

  const { savedPostDetails } = useSelector((state: IStore) => state.addPostAccount);
  const postsDetails = useSelector((state: IStore) => state.posts.postsDetails);
  const editId = useSelector((state: IStore) => state.calendar.editableData.editId);
  const accountName = useSelector((state: IStore) => state.accountSwitcher.currentName);
  const accountTimeZone = useSelector((state: IStore) => state.accounts.accountDetails?.account?.tzinfo_name || '');
  const isFranchisee = useSelector((state: IStore) => state.accounts.accountDetails?.account_profiles[0]?.is_franchisee || false);
  const overriddenCorporatePostsLocked = useSelector((state: IStore) => state.accounts.permissions.franchiseePermissions?.overridden_corporate_posts_locked || false);
  const menuAccess = useSelector((state: IStore) => state.accountSwitcher.menu_access);
  const isContentSupplierFranchisor = useSelector((state: IStore) => state.accountSwitcher?.content_supplier_franchisor || false);

  const [statePlatform, setStatePlatform] = useState('');
  const [showModal, setShowModal] = useState(false);

  const calenderViewData: null | calendarContainerTypes.ICalendarDataType = data.length && +detailPostId ? data.find((it: any) => it?.id === +detailPostId) || null : data.length ? data[0] : null;
  const isHubUser = USER_OWNERSHIP.FRANCHISOR === userOwnership;
  const contentText = !statePlatform
    ? calenderViewData?.dataObj?.find((it) => it.isEnabled)
    : calenderViewData?.dataObj?.find((it: calendarContainerTypes.IDataObjType) => it.isEnabled && it.label === statePlatform);
  const canEditAccountDate =
    !isHubUser && calenderViewData?.id
      ? canEditScheduledPostDateAndTime(calenderViewData.isCorporatePost, isFranchisee, overriddenCorporatePostsLocked, calenderViewData?.abilities?.can_edit_schedule)
      : false;

  const isHideApprove = !isHubUser ? checkSiteRestriction(USER_OWNERSHIP.ACCOUNT, menuAccess, 'calendar/awaiting_approval') : false;

  useEffect(() => {
    if (calenderViewData?.id) setStatePlatform(calenderViewData?.dataObj?.find((it) => it.isEnabled)?.label || '');
  }, [calenderViewData]); // eslint-disable-line

  const editClickHandler = (data: null | calendarContainerTypes.ICalendarDataType) => {
    if (data) dispatch(calendarEditView({ isEditEnabled: false, editId: data.id, editData: data }));
  };

  const photoUrls = calenderViewData?.id
    ? getPermittedImgsBasedOnPlatform(statePlatform, statePlatform === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM ? calenderViewData?.instagramImageUrls || [] : calenderViewData?.imageUrls || [])
    : [];

  const EditPostionHandler = () => {
    return (
      <CustomRippleButton rippleClass={`ac-secondary-box mb-20 ${!isButtonsEnabled || (!isHubUser && !canEditAccountPost) ? 'pointer-events-none' : ''}`} custColor={RIPPLE_COLOR.primary}>
        <button
          className={`ac-btn ac-secondary-white ac-outline ac-block h-default r-flx r-flx-jc r-flx-ac ${!isButtonsEnabled || (!isHubUser && !canEditAccountPost) ? 'disabled' : ''}`}
          onClick={() => (calenderViewData?.isCorporatePost ? handleEditCorporatePost(calenderViewData) : editClickHandler(calenderViewData))}
        >
          {editId && (!postsDetails || !savedPostDetails) ? <Spinner animation="border" /> : 'Edit'}
        </button>
      </CustomRippleButton>
    );
  };

  return calenderViewData ? (
    <>
      {/* Facebook section start */}
      {USER_OWNERSHIP.ACCOUNT === userOwnership && isContentSupplierFranchisor ? (
        <div className="filter-item shfWrap">
          <CustomRippleButton rippleClass={`ripple-unset ac-primary-box shuffle-btn`} custColor={RIPPLE_COLOR.whiteBlue}>
            <button className="ac-btn ac-primary-white ac-outline ac-icon-btn ac-block csv pie-reload--btn" onClick={() => setShowModal(true)}>
              <ImageValidation isImgValid defaultImg={'shuffle-btn'} customName="shuffleIcon" />
              <span>Shuffle Automated Posts</span>
            </button>
          </CustomRippleButton>
        </div>
      ) : null}
      <div className="cal-filter-first-section mb-20 d-none">
        <div className="cal-filter-socialpreview-wrp">
          {availablePlatform.map((it: any) => it.value).some((datum: any) => datum && calenderViewData?.dataObj.map((it) => it.isEnabled && it.label).includes(datum)) ? (
            <div className="grid-section">
              <div className="left-side">
                <div className="gmhl-img">
                  <ImageValidation isImgValid defaultImg={availablePlatform.find((data: IPlatformFilter) => data.value === statePlatform)?.image || ''} isNotSvgFormat customName={statePlatform} />
                </div>
                <span>
                  {statePlatform === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
                    ? COMMON_SOCIAL_MEDIA_NAME.FACEBOOK
                    : statePlatform === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
                    ? COMMON_SOCIAL_MEDIA_NAME.INSTAGRAM_BUSINESS
                    : statePlatform === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER
                    ? COMMON_SOCIAL_MEDIA_NAME.X
                    : statePlatform === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN
                    ? COMMON_SOCIAL_MEDIA_NAME.LINKEDIN
                    : statePlatform === COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK
                    ? COMMON_SOCIAL_MEDIA_NAME.TIKTOK
                    : statePlatform === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE
                    ? COMMON_SOCIAL_MEDIA_NAME.GOOGLE_MY_BUSINESS
                    : statePlatform === COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST
                    ? COMMON_SOCIAL_MEDIA_NAME.PINTEREST
                    : ''}
                </span>
              </div>
              <div className="fltr-imc selectsocial">
                {calenderViewData?.dataObj?.map((it: calendarContainerTypes.IDataObjType, index: number) => {
                  const platformImage = availablePlatform.find((data: IPlatformFilter) => it.isEnabled && data.value === it?.label)?.platformImg || '';
                  return (
                    platformImage && (
                      <button key={index} type="button" className={`roundedbtn btn btn-link img-siz${statePlatform === it?.label ? ` active` : ''}`} onClick={() => setStatePlatform(it?.label)}>
                        <ImageValidation isImgValid defaultImg={platformImage} customName={it?.label} />
                      </button>
                    )
                  );
                })}
              </div>
            </div>
          ) : null}
        </div>
        {/**/}
        {/* FB preivew */}
        <div className="cal-social-preview-sec pt-20">
          <div className="card-bg">
            <div className="overlay">
              <div className="p-avatar">
                <ImageValidation
                  defaultImg="expand"
                  customName="expand"
                  onClick={() => {
                    navigate({
                      pathname: `/${userOwnership}/${id.toString()}/${currentRoute}${optionalParams[0] ? `/${optionalParams[0]}` : ''}/${calenderViewData?.id?.toString()}`,
                      search: queryParams ? `?${new URLSearchParams(queryParams).toString()}` : ``
                    });
                  }}
                />
              </div>
            </div>
            <div className="p-header">
              <div className="p-header-title">
                <div className="p-avatar">
                  <ImageValidation imgUrl={calenderViewData?.avatarImg} defaultImg={'user-avatar-common.png'} isNotSvgFormat customClassname={'p-avatar'} customName={'calendar user profile img'} />
                </div>
                <div className="p-title">
                  <h5>{accountName}</h5>
                  <p>
                    {isHubUser && calenderViewData
                      ? `@ ${
                          calenderViewData.post_now
                            ? convertLocationTimezone(calenderViewData?.scheduledTime, CALENDAR_DATE_FORMAT)
                            : calendarUTCtoLocalHandler(calenderViewData?.scheduledTime, CALENDAR_DATE_FORMAT)
                        }`
                      : `@${calendarConvertDateBasedOnTimeZone(accountTimeZone, calenderViewData?.scheduledTime!, LOCATION_TIME_ZONE_DATE_TIME_FORMAT, true)}`}
                  </p>
                </div>
                <CalendarIconHandler
                  isBoosted={statePlatform === 'facebook' && calenderViewData?.isBoosted}
                  isAwaiting={!isHubUser ? calenderViewData?.draft : false}
                  isCampaign={calenderViewData?.campaign}
                />
              </div>
            </div>
            <div className="p-body">
              {contentText?.text ? (
                <>
                  <Paragraph
                    facebookTagList={contentText?.tag ? contentText?.tag : []}
                    customText={contentText?.personalizedText ? contentText?.personalizedText?.trim() : contentText?.text?.trim() || ''}
                    customClassname="post-message"
                    actionType={AP_FRAN_SHOW_LINE_BREAK_TYPES.PREVIEW}
                  />
                </>
              ) : (
                <p>{calenderViewData?.linkTitle} </p>
              )}
              {photoUrls?.length && !calenderViewData.linkUrl ? (
                <>
                  <div className="am-media-preview">
                    <ImageValidation customClassname="social-media-post  full-width" imgUrl={photoUrls[0]} customName="social media post" />
                  </div>
                  <div className={`grid-image-section ${photoUrls?.length > 1 ? '' : 'd-none'}`}>
                    {photoUrls?.length > 1
                      ? photoUrls?.slice(1).map((it: string, index: number) => {
                          return <ImageValidation key={`calendar-sch-post-img-${index}`} customClassname="social-media-post  full-width" imgUrl={it} customName="social media post" />;
                        })
                      : null}
                  </div>
                </>
              ) : calenderViewData?.video ? (
                <div className="am-media-preview">
                  <div className="video-outer-element">
                    <Video videoUrl={calenderViewData?.video} light={calenderViewData?.video_thumbnail_url} customClassname={'full-width'} />
                  </div>
                </div>
              ) : null}
              {(calenderViewData.linkTitle || calenderViewData.linkUrl || calenderViewData.linkDescription) && statePlatform !== COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE ? (
                ![COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK, COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN].includes(statePlatform) ? (
                  <FBCallToActionPreview
                    imageUrl={calenderViewData?.linkPreviewPhoto || photoUrls[0] || null}
                    title={calenderViewData?.linkTitle || ''}
                    linkUrl={calenderViewData?.linkUrl || ''}
                    description={calenderViewData?.linkDescription || ''}
                    actionType={calenderViewData?.facebook_call_to_action_type || ''}
                    socialMedia={statePlatform}
                    isLinkCustomized={calenderViewData?.link_preview_customized || false}
                  />
                ) : (
                  <div className="coupon-link-preview-wrp">
                    <div className="grid-section flex-wrap">
                      <div className="left-section flex-removed">
                        {calenderViewData.linkPreviewPhoto || photoUrls[0] ? (
                          <ImageValidation imgUrl={calenderViewData.linkPreviewPhoto || photoUrls[0]} customClassname="img-thumb" customName={'Link preview'} />
                        ) : null}
                      </div>
                      <div className="right-section flex-fullview">
                        <div className="learn-more-section b-r-none">
                          <div className="url-head">{calenderViewData.linkUrl}</div>
                          <a href={calenderViewData.linkUrl} target="_blank" rel="noopener noreferrer">
                            <div className="url-subhead-txt">{calenderViewData.linkTitle}</div>
                          </a>
                          <Paragraph customText={calenderViewData.linkDescription} />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              ) : null}
              {statePlatform === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE && calenderViewData.google_call_to_action_url && calenderViewData.google_call_to_action_type && (
                <div className="insta-social-sec-wrp">
                  <div className="lefticon">
                    {parse(
                      convertURLAsLink(
                        calenderViewData.google_call_to_action_url,
                        GOOGLE_CALL_TO_ACTION_SELECT_OPTIONS.find((option) => option.value === calenderViewData.google_call_to_action_type)?.label || ''
                      )
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="p-footer">
              <div className="footer-btn">
                <div className="fb-like-icon cal-fltr-icons">
                  <ImageValidation defaultImg="fb-like" customName="like" />
                </div>
                <span className="social-text">Like</span>
              </div>
              <div className="footer-btn">
                <div className="cal-fltr-icons">
                  <ImageValidation defaultImg="comment" customName="comment" />
                </div>
                <span className="social-text">Comment</span>
              </div>
              <div className="footer-btn">
                <div className="cal-fltr-icons">
                  <ImageValidation defaultImg="share" customName="share" />
                </div>
                <span className="social-text">Share</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Facebook section end */}
      <div className={`calendar-flr-btn-wrp d-none`}>
        {isHubUser && !calendarRestriction.schedule ? (
          <div className="sche-text" onClick={() => setScheduleModal(true)}>
            <div>
              <ImageValidation defaultImg="location-grey" customName="add-img-btn" />
            </div>
            Scheduled To <span>{calenderViewData.includedAccountsCount}</span>
          </div>
        ) : (
          !calendarRestriction.schedule && (
            <div
              className={`remove-btn-cal ${!canEditAccountDate ? 'pointer-events-none' : ''}`}
              onClick={() => {
                setScheduleModal(true);
                dispatch(
                  addPostUpdateSavedPostDetails({
                    ...AP_SAVED_POST_INIT_DETAILS,
                    schedule_date_time: calendarInitScheduledTime(accountTimeZone, calenderViewData?.scheduledTime),
                    account_schedule_date_time: dateObjBasedOnTimeZone(accountTimeZone, calenderViewData?.start_date)
                  })
                );
              }}
            >
              <span className="inner-text">
                <>{calendarConvertDateBasedOnTimeZone(accountTimeZone, calenderViewData.scheduledTime, EMAIL_SHORT_DATE_FORMAT, true)}</>
              </span>
              <span className="inner-next-text"> {`(${getTimezoneAbbreviation(accountTimeZone)})`}</span>
            </div>
          )
        )}
        {isHubUser && !calendarRestriction.edit && <EditPostionHandler />}
        {!calendarRestriction.delete && (
          <CustomRippleButton rippleClass="ac-danger-box mb-20" custColor={RIPPLE_COLOR.danger}>
            <button
              className={`ac-btn ac-danger ac-block ${isButtonsEnabled ? '' : 'disabled'}`}
              onClick={() => {
                dispatch(calendarDeleteModalOption({ isOpenDeleteModal: true, isConfirmDelete: false }));
                dispatch(calendarLocationUpdates({ type: SCHEDULE_PARAM_TYPE.HIDDEN, bool: true, data: calenderViewData }));
              }}
            >
              Remove
            </button>
          </CustomRippleButton>
        )}
        {!isHubUser && !isHideApprove && !calendarRestriction.approve ? (
          <CustomRippleButton rippleClass={`ac-primary-box mb-20 ${!canApprove ? 'pointer-events-none' : ''}`} custColor={RIPPLE_COLOR.primary}>
            <button
              className={`ac-btn ac-primary ac-block ${!canApprove ? 'disabled' : ''}`}
              onClick={() => {
                if (calenderViewData?.activePlatform) {
                  handleOnChangePostContent('draft', false, calenderViewData);
                } else {
                  alertBoxCall(ALERT_BOX_TYPES.ERROR, CONNECT_SOCIAL_MEDIA_TO_APPROVE_THE_POST);
                }
              }}
            >
              {!canApprove ? 'Approved' : 'Approve'}
            </button>
          </CustomRippleButton>
        ) : null}
        {!isHubUser && !calendarRestriction.edit && <EditPostionHandler />}
        {/* <div className='btn edit-btn' onClick={() => editClickHandler(calenderViewData)}>Edit</div> */}

        {/* {!isHubUser && (
                    <>
                      <span className="clander-filter-divider">
                        <hr />
                      </span>
                      {isHideApprove ? null : (
                        <CustomRippleButton rippleClass={`ac-secondary-box mb-20 edit-ripple__wrp ${canApprove ? 'pointer-events-none' : ''}`} custColor={RIPPLE_COLOR.whiteBlue}>
                          <button
                            className={`ac-btn ac-secondary-white ac-outline border-0 ac-block ${canApprove ? 'disabled' : ''}`}
                            onClick={() => {
                              if (calenderViewData?.activePlatform) {
                                handleOnChangePostContent('draft', false, calenderViewData);
                              } else {
                                alertBoxCall(ALERT_BOX_TYPES.ERROR, CONNECT_SOCIAL_MEDIA_TO_APPROVE_THE_POST);
                              }
                            }}
                          >
                            Cancel
                          </button>
                        </CustomRippleButton>
                      )}
                    </>
                  )} */}
      </div>
      <ModalPopup
        isModalShow={showModal}
        containerClassName={`modal-confirmation confirm-delete feed-ssm-on shuffleAutoPosts`}
        modalHeaderType={MODAL_BOX_TYPES.INFO}
        modalBody={() => (
          <>
            <div className="edit-approval-post-wrp-modal">
              <div className="modal-message-wraps-text">{SHUFFLE_POSTS_CONFIRMATION_MSG}</div>
            </div>
          </>
        )}
        modalCloseButton
        handleModalClose={() => setShowModal(false)}
        isModalFooterShow
        modalFooter={() => {
          return (
            <div className="modal-btn-grp-wraps">
              <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
                <button
                  className={`ac-btn ac-primary ac-block modal-btn-action-itm modal-ok-btn fssm-pb`}
                  onClick={() => {
                    dispatch(accountCreateScheduledPostRequest({ userOwnership, id, params: { delete_current_posts: 1 } }));
                    dispatch(calendarShuffleAutomatedCount());
                    setShowModal(false);
                  }}
                >
                  <span>Shuffle Automated Posts</span>
                </button>
              </CustomRippleButton>

              <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.whiteGrey}>
                <button className={`ac-btn ac-primary-white ac-outline modal-btn-action-itm modal-ok-btn fssm-cancel`} onClick={() => setShowModal(false)}>
                  <span>Cancel</span>
                </button>
              </CustomRippleButton>
            </div>
          );
        }}
      />
    </>
  ) : null;
};
