import { APP_TITLE } from 'utils/constants';

export enum SCValidQueryParams {
  PLATFORM = 'platform',
  START_DATE = 'start_date',
  END_DATE = 'end_date'
}

export enum SCLocationValidQueryParams {
  HUB_ID = 'hub_id',
  LOCATION_LIST_ID = 'location_list_id',
  LOCATION_ID = 'location_id'
}

export enum SCSandboxValidQueryParams {
  POSTS = 'posts'
}

export enum SCOutboxValidQueryParams {
  ENGAGEMENT = 'engagement'
}

export const SC_POSTS_STATUS_FILTER = [
  { value: 'all', label: 'All Posts' },
  { value: 'top_performing_posts', label: 'Top Performing Posts' }
];

export const SC_LOCATION_IMAGE = {
  rallio_platform: 'logo.svg',
  facebook: 'fb-lv.svg',
  instagram: 'instagram-lv.svg',
  twitter: 'twitter-lv.svg',
  linkedin: 'linkedin-lv.svg',
  tiktok: 'tiktok-lv.svg',
  pinterest: 'pinterest-lv.svg'
};

export const SC_TOOLTIP_TEXT = {
  rallio_platform: `This content was posted from the ${APP_TITLE} Platform`,
  facebook: 'This content was posted from Facebook',
  instagram: 'This content was posted from Instagram',
  twitter: 'This content was posted from X (Formerly Twitter)',
  linkedin: 'This content was posted from Linkedin',
  tiktok: 'This content was posted from TikTok',
  pinterest: 'This content was posted from Pinterest'
};

export const SC_ENGAGEMENT_VALUE = [
  { value: 'all', label: 'All' },
  { value: 'Like', label: 'Like' },
  { value: 'Comment', label: 'Comment' },
  { value: 'Post', label: 'Post' },
  { value: 'Message', label: 'Message' }
];

export const SC_LOCATION_TOOLTIP_ICONS_NAME = {
  LIKE: 'Like',
  REPLY: 'Reply',
  LINK: 'Link to Post',
  DELETE: 'Delete Published Post',
  RETWEET: 'Retweet',
  RALLIO_PLATFORM: `This content was posted from the ${APP_TITLE} Platform`,
  SOCIAL_FB: 'This content was posted from Facebook',
  SOCIAL_TWITTER: 'This content was posted from X (Formerly Twitter)',
  SOCIAL_INSTA: 'This content was posted from Instagram',
  SOCIAL_LINKEDIN: 'This content was posted from LinkedIn',
  SOCIAL_TIKTOK: 'This content was posted from TikTok',
  SOCIAL_PINTEREST: 'This content was posted from Pinterest'
};
